import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { SideMenuItem } from '../types';

export const databaseMenuSchema: SideMenuItem[] = [
  {
    text: 'AppKey',
    icon: faDatabase,
    href: '/dashboard/demo',
  },
  {
    text: 'FieldKey ',
    icon: faDatabase,
    href: '/dashboard/schema',
  },
  {
    text: 'MasterCustomer',
    icon: faDatabase,
    href: '/dashboard/schema',
  },
  {
    text: 'PodioWebhooks',
    icon: faDatabase,
    href: '/dashboard/leadtools',
  },
  {
    text: 'Appointments',
    icon: faDatabase,
    href: '/dashboard/leadtools',
  },
  {
    text: 'Contact',
    icon: faDatabase,
    href: '/dashboard/leadtools',
  },
  {
    text: 'Lead',
    icon: faDatabase,
    href: '/dashboard/leadtools',
  },
];
