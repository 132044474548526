import * as React from 'react';
import Select from 'react-select';
import { Textography } from '../../Textography';
import { useDashboardContext } from './Dashboard.dux';
import { ViewContextOption, ViewRoleOption } from './types';

export const Topbar = () => {
  const { state, dispatch } = useDashboardContext();

  const roleOptions: ViewRoleOption[] = [
    { label: 'Developer', value: 'DEVELOPER' },
    { label: 'Analyst', value: 'ANALYST' },
    { label: 'Accounting', value: 'ACCOUNTING' },
    { label: 'Marketing', value: 'MARKETING' },
  ];

  const ViewContextOptions: ViewContextOption[] = [
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Database', value: 'DATABASE' },
    { label: 'Podio', value: 'PODIO' },
    { label: 'Analytics', value: 'ANALYTICS' },
  ];

  return (
    <div className="flex flex-row w-full   items-center">
      <div className="py-2 w-40">
        <Select
          options={roleOptions}
          value={roleOptions.find((option) => option.value === state?.role)}
          onChange={(option) => {
            dispatch({
              type: 'CHANGE_ROLE',
              data: {
                role: option?.value,
              },
            });
          }}
        />
      </div>
      <div className="flex flex-grow p-4 gap-4">
        {ViewContextOptions.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              dispatch({
                type: 'CHANGE_CONTEXT',
                data: { context: item.value },
              });
            }}
          >
            <Textography
            variant="subtitle"
              className={`cursor-pointer hover:text-c-fluent-green-500 ${
                state.context === item.value && 'text-c-fluent-green-400'
              }`}
            >
              {item.label}
            </Textography>
          </div>
        ))}
      </div>
    </div>
  );
};
