/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSetterTimeSheets
// ====================================================

export interface GetSetterTimeSheets_timeSheets_crumbs {
  timeStamp: any;
  lat: number;
  lng: number;
}

export interface GetSetterTimeSheets_timeSheets {
  id: number;
  startTime: any;
  endTime: any | null;
  userId: number | null;
  status: TimeSheetStatus | null;
  crumbs: GetSetterTimeSheets_timeSheets_crumbs[];
}

export interface GetSetterTimeSheets {
  timeSheets: GetSetterTimeSheets_timeSheets[];
}

export interface GetSetterTimeSheetsVariables {
  userId: number;
  selectedStartDate: any;
  selectedEndDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClockInUser
// ====================================================

export interface ClockInUser_createOneTimeSheet_user {
  id: number | null;
}

export interface ClockInUser_createOneTimeSheet {
  id: number;
  startTime: any;
  status: TimeSheetStatus | null;
  user: ClockInUser_createOneTimeSheet_user | null;
}

export interface ClockInUser {
  createOneTimeSheet: ClockInUser_createOneTimeSheet;
}

export interface ClockInUserVariables {
  userId: number;
  startTime: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClockOutUser
// ====================================================

export interface ClockOutUser_updateOneTimeSheet {
  status: TimeSheetStatus | null;
  endTime: any | null;
}

export interface ClockOutUser {
  updateOneTimeSheet: ClockOutUser_updateOneTimeSheet | null;
}

export interface ClockOutUserVariables {
  endTime: any;
  timeSheetId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCrumb
// ====================================================

export interface CreateCrumb_createOneCrumb {
  timeSheetId: number;
  lat: number;
  lng: number;
  timeStamp: any;
}

export interface CreateCrumb {
  createOneCrumb: CreateCrumb_createOneCrumb;
}

export interface CreateCrumbVariables {
  lat: number;
  lng: number;
  timestamp: any;
  timeSheetId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInfo
// ====================================================

export interface GetInfo_appointments {
  title: string | null;
  startDate: any | null;
  notes: string | null;
  formattedAddress: string | null;
}

export interface GetInfo {
  appointments: GetInfo_appointments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRepAppointment
// ====================================================

export interface GetRepAppointment_appointment {
  id: number;
  title: string | null;
}

export interface GetRepAppointment {
  appointment: GetRepAppointment_appointment | null;
}

export interface GetRepAppointmentVariables {
  appointmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNext
// ====================================================

export interface getNext_getNextLevelIncentive {
  numFluentApproved: number | null;
}

export interface getNext {
  getNextLevelIncentive: getNext_getNextLevelIncentive | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMatchUp
// ====================================================

export interface getMatchUp_users_participant_matchUp_scoreboard {
  status: string | null;
  seed: number | null;
  title: string | null;
  score: number | null;
}

export interface getMatchUp_users_participant_matchUp {
  active: boolean | null;
  startDate: any | null;
  endDate: any | null;
  scoreboard: (getMatchUp_users_participant_matchUp_scoreboard | null)[] | null;
}

export interface getMatchUp_users_participant {
  matchUp: getMatchUp_users_participant_matchUp[];
}

export interface getMatchUp_users {
  name: string | null;
  participant: getMatchUp_users_participant[];
}

export interface getMatchUp {
  users: getMatchUp_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserNotifications
// ====================================================

export interface GetUserNotifications_notifications_user {
  name: string | null;
}

export interface GetUserNotifications_notifications {
  createdAt: any | null;
  title: string | null;
  data: any | null;
  body: string | null;
  subtitle: string | null;
  id: string;
  status: string | null;
  statusMessage: string | null;
  user: GetUserNotifications_notifications_user | null;
}

export interface GetUserNotifications {
  notifications: GetUserNotifications_notifications[];
}

export interface GetUserNotificationsVariables {
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: podcastListScreenQuery
// ====================================================

export interface podcastListScreenQuery_podcasts {
  id: string;
  createdAt: any | null;
  updatedAt: any | null;
  title: string | null;
  summary: string | null;
  postDate: any | null;
  podcastUrl: string | null;
  thumbnailUrl: string | null;
}

export interface podcastListScreenQuery {
  podcasts: (podcastListScreenQuery_podcasts | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: users
// ====================================================

export interface users_users {
  id: number | null;
  name: string | null;
}

export interface users {
  users: users_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPodcast
// ====================================================

export interface GetPodcast_podcasts {
  id: string;
  title: string | null;
  summary: string | null;
  postDate: any | null;
  podcastUrl: string | null;
  thumbnailUrl: string | null;
  hosts: string[];
  guests: string[];
}

export interface GetPodcast {
  podcasts: (GetPodcast_podcasts | null)[] | null;
}

export interface GetPodcastVariables {
  podcast_id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFinals
// ====================================================

export interface getFinals_tournament_rounds_matchUps_scoreboard {
  id: number | null;
  title: string | null;
  score: number | null;
  seed: number | null;
  status: string | null;
}

export interface getFinals_tournament_rounds_matchUps {
  id: number;
  order: number | null;
  startDate: any | null;
  endDate: any | null;
  scoreboard: (getFinals_tournament_rounds_matchUps_scoreboard | null)[] | null;
}

export interface getFinals_tournament_rounds {
  id: number;
  startDate: any | null;
  endDate: any | null;
  group: string | null;
  roundNumber: number;
  matchUps: getFinals_tournament_rounds_matchUps[];
}

export interface getFinals_tournament {
  title: string | null;
  rounds: getFinals_tournament_rounds[];
}

export interface getFinals {
  tournament: getFinals_tournament | null;
}

export interface getFinalsVariables {
  tournamentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNextLevelIncentive
// ====================================================

export interface getNextLevelIncentive_getNextLevelIncentive {
  numFluentApproved: number | null;
}

export interface getNextLevelIncentive {
  getNextLevelIncentive: getNextLevelIncentive_getNextLevelIncentive | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTournamentInfo
// ====================================================

export interface getTournamentInfo_tournament_rounds_matchUps_scoreboard {
  id: number | null;
  title: string | null;
  score: number | null;
  seed: number | null;
  status: string | null;
}

export interface getTournamentInfo_tournament_rounds_matchUps {
  id: number;
  order: number | null;
  startDate: any | null;
  endDate: any | null;
  scoreboard: (getTournamentInfo_tournament_rounds_matchUps_scoreboard | null)[] | null;
}

export interface getTournamentInfo_tournament_rounds {
  id: number;
  startDate: any | null;
  endDate: any | null;
  group: string | null;
  roundNumber: number;
  matchUps: getTournamentInfo_tournament_rounds_matchUps[];
}

export interface getTournamentInfo_tournament {
  title: string | null;
  rounds: getTournamentInfo_tournament_rounds[];
}

export interface getTournamentInfo {
  tournament: getTournamentInfo_tournament | null;
}

export interface getTournamentInfoVariables {
  tournamentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: updateActiveScoreboards
// ====================================================

export interface updateActiveScoreboards_matchUps_calculateScoreboard {
  id: number;
}

export interface updateActiveScoreboards_matchUps_scoreboard {
  title: string | null;
  score: number | null;
}

export interface updateActiveScoreboards_matchUps {
  calculateScoreboard: updateActiveScoreboards_matchUps_calculateScoreboard | null;
  startDate: any | null;
  endDate: any | null;
  scoreboard: (updateActiveScoreboards_matchUps_scoreboard | null)[] | null;
}

export interface updateActiveScoreboards {
  matchUps: updateActiveScoreboards_matchUps[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOneGeneratedLead
// ====================================================

export interface createOneGeneratedLead_createOneGeneratedLead {
  id: number | null;
  status: STATUS_TYPE;
  message: string | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface createOneGeneratedLead {
  createOneGeneratedLead: createOneGeneratedLead_createOneGeneratedLead;
}

export interface createOneGeneratedLeadVariables {
  data: GeneratedLeadCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFluentApproved
// ====================================================

export interface getFluentApproved_getFluentApproved {
  id: number | null;
  name: string | null;
  role: string | null;
  team: string | null;
  count: number | null;
  numFluentApproved: number | null;
}

export interface getFluentApproved {
  getFluentApproved: getFluentApproved_getFluentApproved | null;
}

export interface getFluentApprovedVariables {
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOfficesByManagerId
// ====================================================

export interface getOfficesByManagerId_officesByManagerId {
  id: number;
  name: string | null;
  salesTeam: team_type | null;
}

export interface getOfficesByManagerId {
  officesByManagerId: (getOfficesByManagerId_officesByManagerId | null)[] | null;
}

export interface getOfficesByManagerIdVariables {
  salesRepId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: officesByTeam
// ====================================================

export interface officesByTeam_officesByTeam {
  id: number;
  name: string | null;
  salesTeam: team_type | null;
}

export interface officesByTeam {
  officesByTeam: (officesByTeam_officesByTeam | null)[] | null;
}

export interface officesByTeamVariables {
  team: TEAM_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOfficesByContext
// ====================================================

export interface getOfficesByContext_getOfficesByContext_firstOfficeReport_overallReport {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
}

export interface getOfficesByContext_getOfficesByContext_firstOfficeReport_salesReps {
  fullName: string | null;
  id: number | null;
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
}

export interface getOfficesByContext_getOfficesByContext_firstOfficeReport_setters {
  fullName: string | null;
  id: number | null;
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
}

export interface getOfficesByContext_getOfficesByContext_firstOfficeReport {
  id: number | null;
  name: string | null;
  team: string | null;
  overallReport: getOfficesByContext_getOfficesByContext_firstOfficeReport_overallReport | null;
  salesReps: (getOfficesByContext_getOfficesByContext_firstOfficeReport_salesReps | null)[] | null;
  setters: (getOfficesByContext_getOfficesByContext_firstOfficeReport_setters | null)[] | null;
}

export interface getOfficesByContext_getOfficesByContext_offices {
  id: number;
  name: string | null;
}

export interface getOfficesByContext_getOfficesByContext {
  firstOfficeReport: getOfficesByContext_getOfficesByContext_firstOfficeReport | null;
  offices: (getOfficesByContext_getOfficesByContext_offices | null)[] | null;
}

export interface getOfficesByContext {
  getOfficesByContext: getOfficesByContext_getOfficesByContext | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUsers
// ====================================================

export interface getUsers_users {
  email: string | null;
}

export interface getUsers {
  users: getUsers_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCloserLeaderboard
// ====================================================

export interface getCloserLeaderboard_getCloserLeaderboard {
  id: number | null;
  fullName: string | null;
  numFluentApproved: number | null;
  team: string | null;
  rank: number | null;
  officeName: string | null;
}

export interface getCloserLeaderboard {
  getCloserLeaderboard: (getCloserLeaderboard_getCloserLeaderboard | null)[] | null;
}

export interface getCloserLeaderboardVariables {
  period: periodKeys;
  team?: team_type | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSetterLeaderboard
// ====================================================

export interface getSetterLeaderboard_getSetterLeaderboard {
  id: number | null;
  fullName: string | null;
  numPitched: number | null;
  team: string | null;
  rank: number | null;
  officeName: string | null;
}

export interface getSetterLeaderboard {
  getSetterLeaderboard: (getSetterLeaderboard_getSetterLeaderboard | null)[] | null;
}

export interface getSetterLeaderboardVariables {
  period: periodKeys;
  team?: team_type | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getReportBySetterId
// ====================================================

export interface getReportBySetterId_MasterCustomerReportBySetterId_overallReport {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
}

export interface getReportBySetterId_MasterCustomerReportBySetterId_masterCustomers {
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  region: string | null;
  team: team_type | null;
  outcome: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
  leadSourceType: string | null;
}

export interface getReportBySetterId_MasterCustomerReportBySetterId {
  id: number | null;
  name: string | null;
  role: string | null;
  team: string | null;
  count: number | null;
  overallReport: getReportBySetterId_MasterCustomerReportBySetterId_overallReport | null;
  masterCustomers: (getReportBySetterId_MasterCustomerReportBySetterId_masterCustomers | null)[] | null;
}

export interface getReportBySetterId {
  MasterCustomerReportBySetterId: getReportBySetterId_MasterCustomerReportBySetterId | null;
}

export interface getReportBySetterIdVariables {
  setterId?: number | null;
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getViewOptionList
// ====================================================

export interface getViewOptionList_ViewOptionListByContext_teams {
  value: string | null;
  label: string | null;
}

export interface getViewOptionList_ViewOptionListByContext_offices {
  value: string | null;
  label: string | null;
}

export interface getViewOptionList_ViewOptionListByContext_managers {
  value: string | null;
  label: string | null;
}

export interface getViewOptionList_ViewOptionListByContext {
  teams: (getViewOptionList_ViewOptionListByContext_teams | null)[] | null;
  offices: (getViewOptionList_ViewOptionListByContext_offices | null)[] | null;
  managers: (getViewOptionList_ViewOptionListByContext_managers | null)[] | null;
}

export interface getViewOptionList {
  ViewOptionListByContext: getViewOptionList_ViewOptionListByContext | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MasterCustomerDetail
// ====================================================

export interface MasterCustomerDetail_MasterCustomerDetail_salesRep {
  id: number;
  fullName: string | null;
}

export interface MasterCustomerDetail_MasterCustomerDetail_setter {
  id: number;
  fullName: string | null;
}

export interface MasterCustomerDetail_MasterCustomerDetail_location {
  streetName: string | null;
  streetNumber: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  lat: any | null;
  lng: any | null;
}

export interface MasterCustomerDetail_MasterCustomerDetail_contact {
  phoneWork: string | null;
  phonePersonal: string | null;
  emailWork: string | null;
  emailPersonal: string | null;
}

export interface MasterCustomerDetail_MasterCustomerDetail {
  id: number;
  fullName: string | null;
  salesRep: MasterCustomerDetail_MasterCustomerDetail_salesRep | null;
  setter: MasterCustomerDetail_MasterCustomerDetail_setter | null;
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  region: string | null;
  team: team_type | null;
  outcome: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
  leadSourceType: string | null;
  location: MasterCustomerDetail_MasterCustomerDetail_location | null;
  contact: MasterCustomerDetail_MasterCustomerDetail_contact | null;
}

export interface MasterCustomerDetail {
  MasterCustomerDetail: MasterCustomerDetail_MasterCustomerDetail | null;
}

export interface MasterCustomerDetailVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOnePodcast
// ====================================================

export interface createOnePodcast_createPodcast {
  title: string | null;
  summary: string | null;
  podcastUrl: string | null;
  postDate: any | null;
  thumbnailUrl: string | null;
}

export interface createOnePodcast {
  createPodcast: createOnePodcast_createPodcast | null;
}

export interface createOnePodcastVariables {
  title?: string | null;
  summary?: string | null;
  podcastUrl?: string | null;
  postDate?: string | null;
  thumbnailUrl?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOnePodcast
// ====================================================

export interface deleteOnePodcast_deleteOnePodcast {
  id: string;
  title: string | null;
  summary: string | null;
  podcastUrl: string | null;
  postDate: any | null;
  thumbnailUrl: string | null;
}

export interface deleteOnePodcast {
  deleteOnePodcast: deleteOnePodcast_deleteOnePodcast | null;
}

export interface deleteOnePodcastVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: dispositionLead
// ====================================================

export interface dispositionLead_dispositionLead {
  id: number;
  fullName: string | null;
  outcome: string | null;
}

export interface dispositionLead {
  dispositionLead: dispositionLead_dispositionLead | null;
}

export interface dispositionLeadVariables {
  id: number;
  outcome: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOfficeCustomers
// ====================================================

export interface getOfficeCustomers_MasterCustomerOfficeReport_masterCustomers {
  id: number;
  fullName: string | null;
  outcome: string | null;
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  leadSourceType: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
}

export interface getOfficeCustomers_MasterCustomerOfficeReport {
  id: number | null;
  name: string | null;
  count: number | null;
  masterCustomers: (getOfficeCustomers_MasterCustomerOfficeReport_masterCustomers | null)[] | null;
}

export interface getOfficeCustomers {
  MasterCustomerOfficeReport: getOfficeCustomers_MasterCustomerOfficeReport | null;
}

export interface getOfficeCustomersVariables {
  type: OfficeReportRequestType;
  ids: number[];
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOfficeReport
// ====================================================

export interface getOfficeReport_MasterCustomerOfficeReport_overallReport {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

export interface getOfficeReport_MasterCustomerOfficeReport_salesReps {
  id: number | null;
  fullName: string | null;
  officeName: string | null;
  officeId: number | null;
  team: string | null;
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

export interface getOfficeReport_MasterCustomerOfficeReport_setters {
  id: number | null;
  fullName: string | null;
  officeName: string | null;
  officeId: number | null;
  team: string | null;
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

export interface getOfficeReport_MasterCustomerOfficeReport {
  id: number | null;
  name: string | null;
  role: string | null;
  team: string | null;
  count: number | null;
  overallReport: getOfficeReport_MasterCustomerOfficeReport_overallReport | null;
  salesReps: (getOfficeReport_MasterCustomerOfficeReport_salesReps | null)[] | null;
  setters: (getOfficeReport_MasterCustomerOfficeReport_setters | null)[] | null;
}

export interface getOfficeReport {
  MasterCustomerOfficeReport: getOfficeReport_MasterCustomerOfficeReport | null;
}

export interface getOfficeReportVariables {
  type: OfficeReportRequestType;
  ids: number[];
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRepReport
// ====================================================

export interface getRepReport_MasterCustomerReportBySalesRepId_overallReport {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

export interface getRepReport_MasterCustomerReportBySalesRepId_masterCustomers {
  fullName: string | null;
  id: number;
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  region: string | null;
  team: team_type | null;
  outcome: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
  leadSourceType: string | null;
}

export interface getRepReport_MasterCustomerReportBySalesRepId {
  id: number | null;
  name: string | null;
  team: string | null;
  count: number | null;
  overallReport: getRepReport_MasterCustomerReportBySalesRepId_overallReport | null;
  masterCustomers: (getRepReport_MasterCustomerReportBySalesRepId_masterCustomers | null)[] | null;
}

export interface getRepReport {
  MasterCustomerReportBySalesRepId: getRepReport_MasterCustomerReportBySalesRepId | null;
}

export interface getRepReportVariables {
  salesRepId?: number | null;
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MasterCustomerReport
// ====================================================

export interface MasterCustomerReport_MasterCustomerReport_overallReport {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

export interface MasterCustomerReport_MasterCustomerReport_periodReports {
  period: string | null;
  gte: string | null;
  lte: string | null;
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
}

export interface MasterCustomerReport_MasterCustomerReport_masterCustomers {
  id: number;
  fullName: string | null;
  outcome: string | null;
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  leadSourceType: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
}

export interface MasterCustomerReport_MasterCustomerReport {
  id: number | null;
  name: string | null;
  role: string | null;
  team: string | null;
  count: number | null;
  overallReport: MasterCustomerReport_MasterCustomerReport_overallReport | null;
  periodReports: (MasterCustomerReport_MasterCustomerReport_periodReports | null)[] | null;
  masterCustomers: (MasterCustomerReport_MasterCustomerReport_masterCustomers | null)[] | null;
}

export interface MasterCustomerReport {
  MasterCustomerReport: MasterCustomerReport_MasterCustomerReport | null;
}

export interface MasterCustomerReportVariables {
  salesRepId?: number | null;
  setterId?: number | null;
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getReportBySalesRepId
// ====================================================

export interface getReportBySalesRepId_MasterCustomerReportBySalesRepId_overallReport {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
}

export interface getReportBySalesRepId_MasterCustomerReportBySalesRepId_periodReports {
  period: string | null;
  gte: string | null;
  lte: string | null;
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
}

export interface getReportBySalesRepId_MasterCustomerReportBySalesRepId_masterCustomers {
  fullName: string | null;
  id: number;
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  region: string | null;
  team: team_type | null;
  outcome: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
  leadSourceType: string | null;
}

export interface getReportBySalesRepId_MasterCustomerReportBySalesRepId {
  id: number | null;
  name: string | null;
  team: string | null;
  count: number | null;
  overallReport: getReportBySalesRepId_MasterCustomerReportBySalesRepId_overallReport | null;
  periodReports: (getReportBySalesRepId_MasterCustomerReportBySalesRepId_periodReports | null)[] | null;
  masterCustomers: (getReportBySalesRepId_MasterCustomerReportBySalesRepId_masterCustomers | null)[] | null;
}

export interface getReportBySalesRepId {
  MasterCustomerReportBySalesRepId: getReportBySalesRepId_MasterCustomerReportBySalesRepId | null;
}

export interface getReportBySalesRepIdVariables {
  salesRepId?: number | null;
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPodcasts
// ====================================================

export interface getPodcasts_podcasts {
  id: string;
  title: string | null;
  summary: string | null;
  podcastUrl: string | null;
  thumbnailUrl: string | null;
  postDate: any | null;
}

export interface getPodcasts {
  podcasts: (getPodcasts_podcasts | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesRepCustomersByContext
// ====================================================

export interface SalesRepCustomersByContext_SalesRepCustomersByContext {
  id: number;
  fullName: string | null;
  outcome: string | null;
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  installCompleteDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  installGreenLights: string | null;
  leadSourceType: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
}

export interface SalesRepCustomersByContext {
  SalesRepCustomersByContext: (SalesRepCustomersByContext_SalesRepCustomersByContext | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assignCloserToMasterCustomer
// ====================================================

export interface assignCloserToMasterCustomer_assignCloserToMasterCustomer_salesRep {
  id: number;
  fullName: string | null;
}

export interface assignCloserToMasterCustomer_assignCloserToMasterCustomer {
  id: number;
  fullName: string | null;
  salesRep: assignCloserToMasterCustomer_assignCloserToMasterCustomer_salesRep | null;
}

export interface assignCloserToMasterCustomer {
  assignCloserToMasterCustomer: assignCloserToMasterCustomer_assignCloserToMasterCustomer | null;
}

export interface assignCloserToMasterCustomerVariables {
  id: number;
  salesRepId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assignSetterToMasterCustomer
// ====================================================

export interface assignSetterToMasterCustomer_assignSetterToMasterCustomer_setter {
  id: number;
  fullName: string | null;
}

export interface assignSetterToMasterCustomer_assignSetterToMasterCustomer {
  id: number;
  fullName: string | null;
  setter: assignSetterToMasterCustomer_assignSetterToMasterCustomer_setter | null;
}

export interface assignSetterToMasterCustomer {
  assignSetterToMasterCustomer: assignSetterToMasterCustomer_assignSetterToMasterCustomer | null;
}

export interface assignSetterToMasterCustomerVariables {
  id: number;
  setterId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignCloser
// ====================================================

export interface AssignCloser_assignAppointmentToCloser_assignedTo {
  fullName: string | null;
  id: number;
}

export interface AssignCloser_assignAppointmentToCloser {
  id: number;
  assignedTo: AssignCloser_assignAppointmentToCloser_assignedTo | null;
  title: string | null;
  status: string | null;
}

export interface AssignCloser {
  assignAppointmentToCloser: AssignCloser_assignAppointmentToCloser | null;
}

export interface AssignCloserVariables {
  appointmentId: number;
  salesRepId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCRMAppointmentDetails
// ====================================================

export interface GetCRMAppointmentDetails_appointments_leadInfo_contact {
  firstName: string | null;
  lastName: string | null;
  name: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  phonePersonal: string | null;
  emailPersonal: string | null;
}

export interface GetCRMAppointmentDetails_appointments_leadInfo {
  appointmentStartDate: any | null;
  appointmentEndDate: any | null;
  contact: GetCRMAppointmentDetails_appointments_leadInfo_contact | null;
}

export interface GetCRMAppointmentDetails_appointments {
  id: number;
  startDate: any | null;
  status: string | null;
  formattedAddress: string | null;
  notes: string | null;
  summary: string | null;
  leadInfo: GetCRMAppointmentDetails_appointments_leadInfo | null;
}

export interface GetCRMAppointmentDetails {
  appointments: GetCRMAppointmentDetails_appointments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QueryClosers
// ====================================================

export interface QueryClosers_salesReps {
  fullName: string | null;
  id: number;
}

export interface QueryClosers {
  salesReps: QueryClosers_salesReps[];
}

export interface QueryClosersVariables {
  textInput: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOneLeadHook
// ====================================================

export interface createOneLeadHook_createOneLeadHook {
  id: number;
}

export interface createOneLeadHook {
  createOneLeadHook: createOneLeadHook_createOneLeadHook;
}

export interface createOneLeadHookVariables {
  data: LeadHookCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getLeadPipelineWebhooks
// ====================================================

export interface getLeadPipelineWebhooks_leadHooks {
  id: number;
  provider: string | null;
  campaign: string | null;
  type: string | null;
  status: LeadHookStatus | null;
  endpoint: string | null;
}

export interface getLeadPipelineWebhooks {
  leadHooks: getLeadPipelineWebhooks_leadHooks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOneLeadHook
// ====================================================

export interface deleteOneLeadHook_deleteOneLeadHook {
  id: number;
}

export interface deleteOneLeadHook {
  deleteOneLeadHook: deleteOneLeadHook_deleteOneLeadHook | null;
}

export interface deleteOneLeadHookVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateoneLeadHook
// ====================================================

export interface updateoneLeadHook_updateOneLeadHook {
  id: number;
}

export interface updateoneLeadHook {
  updateOneLeadHook: updateoneLeadHook_updateOneLeadHook | null;
}

export interface updateoneLeadHookVariables {
  where: LeadHookWhereUniqueInput;
  data: LeadHookUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: appKeysForPodioHooks
// ====================================================

export interface appKeysForPodioHooks_appKeys {
  id: number;
  podioAppName: string | null;
  podioAppId: string;
  podioAppToken: string | null;
  name: string | null;
}

export interface appKeysForPodioHooks {
  appKeys: appKeysForPodioHooks_appKeys[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fieldKeysForPodioHooks
// ====================================================

export interface fieldKeysForPodioHooks_fieldKeys {
  id: number;
  nodeName: string;
  podioFieldId: number;
  externalId: string | null;
}

export interface fieldKeysForPodioHooks {
  fieldKeys: fieldKeysForPodioHooks_fieldKeys[];
}

export interface fieldKeysForPodioHooksVariables {
  appKeyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOnePodioWebhook
// ====================================================

export interface createOnePodioWebhook_createOnePodioWebhook {
  id: number;
  createdAt: any | null;
  updatedAt: any | null;
  workspace: string | null;
  appName: string | null;
  appId: number | null;
  fieldId: number | null;
  externalFieldId: string | null;
  url: string | null;
  summary: string | null;
  description: string | null;
}

export interface createOnePodioWebhook {
  createOnePodioWebhook: createOnePodioWebhook_createOnePodioWebhook;
}

export interface createOnePodioWebhookVariables {
  data: PodioWebhookCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: crmPodioWebhook
// ====================================================

export interface crmPodioWebhook_podioWebhooks {
  id: number;
  workspace: string | null;
  appName: string | null;
  appId: number | null;
  fieldId: number | null;
  externalFieldId: string | null;
  url: string | null;
  summary: string | null;
  description: string | null;
  podioHookId: number | null;
}

export interface crmPodioWebhook {
  podioWebhooks: crmPodioWebhook_podioWebhooks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOnePodioWebhook
// ====================================================

export interface deleteOnePodioWebhook_deleteOnePodioWebhook {
  id: number;
  createdAt: any | null;
  updatedAt: any | null;
  workspace: string | null;
  appName: string | null;
  appId: number | null;
  fieldId: number | null;
  externalFieldId: string | null;
  url: string | null;
  summary: string | null;
  description: string | null;
  podioHookId: number | null;
}

export interface deleteOnePodioWebhook {
  deleteOnePodioWebhook: deleteOnePodioWebhook_deleteOnePodioWebhook | null;
}

export interface deleteOnePodioWebhookVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRepStats
// ====================================================

export interface GetRepStats_salesReps_masterCustomers {
  systemSize: number | null;
}

export interface GetRepStats_salesReps {
  fullName: string | null;
  masterCustomers: GetRepStats_salesReps_masterCustomers[];
}

export interface GetRepStats {
  salesReps: GetRepStats_salesReps[];
}

export interface GetRepStatsVariables {
  searchValue?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: fieldKeysSchema
// ====================================================

export interface fieldKeysSchema_fieldKeys {
  id: number;
  enabled: boolean | null;
  nodeName: string;
  externalId: string | null;
  podioFieldId: number;
  type: string | null;
}

export interface fieldKeysSchema {
  fieldKeys: fieldKeysSchema_fieldKeys[];
}

export interface fieldKeysSchemaVariables {
  appId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clickEnabledMutation
// ====================================================

export interface clickEnabledMutation_updateOneFieldKey {
  id: number;
  enabled: boolean | null;
}

export interface clickEnabledMutation {
  updateOneFieldKey: clickEnabledMutation_updateOneFieldKey | null;
}

export interface clickEnabledMutationVariables {
  id: number;
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminVerifyTimeSheet
// ====================================================

export interface adminVerifyTimeSheet_updateOneTimeSheet {
  id: number;
}

export interface adminVerifyTimeSheet {
  updateOneTimeSheet: adminVerifyTimeSheet_updateOneTimeSheet | null;
}

export interface adminVerifyTimeSheetVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: admingCreateTimesheet
// ====================================================

export interface admingCreateTimesheet_createOneTimeSheet {
  id: number;
}

export interface admingCreateTimesheet {
  createOneTimeSheet: admingCreateTimesheet_createOneTimeSheet;
}

export interface admingCreateTimesheetVariables {
  data: TimeSheetCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersForSelectOptions
// ====================================================

export interface UsersForSelectOptions_users {
  id: number | null;
  name: string | null;
}

export interface UsersForSelectOptions {
  users: UsersForSelectOptions_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminEditTimesheet
// ====================================================

export interface adminEditTimesheet_updateOneTimeSheet {
  id: number;
}

export interface adminEditTimesheet {
  updateOneTimeSheet: adminEditTimesheet_updateOneTimeSheet | null;
}

export interface adminEditTimesheetVariables {
  where: TimeSheetWhereUniqueInput;
  data: TimeSheetUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdminTimesheets
// ====================================================

export interface getAdminTimesheets_timeSheets_user {
  id: number | null;
  name: string | null;
}

export interface getAdminTimesheets_timeSheets_crumbs {
  lat: number;
  lng: number;
  timeStamp: any;
}

export interface getAdminTimesheets_timeSheets {
  id: number;
  status: TimeSheetStatus | null;
  startTime: any;
  endTime: any | null;
  user: getAdminTimesheets_timeSheets_user | null;
  note: string | null;
  crumbs: getAdminTimesheets_timeSheets_crumbs[];
}

export interface getAdminTimesheets {
  timeSheets: getAdminTimesheets_timeSheets[];
}

export interface getAdminTimesheetsVariables {
  where?: TimeSheetWhereInput | null;
  orderBy?: TimeSheetOrderByInput[] | null;
  first?: number | null;
  last?: number | null;
  before?: TimeSheetWhereUniqueInput | null;
  after?: TimeSheetWhereUniqueInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: crmChangeUserPassword
// ====================================================

export interface crmChangeUserPassword {
  sendNewPasswordKey: string | null;
}

export interface crmChangeUserPasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: crmQueryUsers
// ====================================================

export interface crmQueryUsers_users_salesRepInfo {
  podioId: string | null;
}

export interface crmQueryUsers_users_setterInfo {
  podioId: string | null;
}

export interface crmQueryUsers_users {
  id: number | null;
  name: string | null;
  email: string | null;
  role: user_role | null;
  team: team_type;
  enabled: boolean | null;
  salesRepInfo: crmQueryUsers_users_salesRepInfo | null;
  setterInfo: crmQueryUsers_users_setterInfo | null;
  roles: string[];
  appAccess: string[];
  featureAccess: string[];
}

export interface crmQueryUsers {
  users: crmQueryUsers_users[];
}

export interface crmQueryUsersVariables {
  where?: UserWhereInput | null;
  orderBy?: UserOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: crmAddUser
// ====================================================

export interface crmAddUser_createOneUser_salesRepInfo {
  podioId: string | null;
}

export interface crmAddUser_createOneUser_setterInfo {
  podioId: string | null;
}

export interface crmAddUser_createOneUser {
  id: number | null;
  name: string | null;
  email: string | null;
  role: user_role | null;
  team: team_type;
  enabled: boolean | null;
  salesRepInfo: crmAddUser_createOneUser_salesRepInfo | null;
  setterInfo: crmAddUser_createOneUser_setterInfo | null;
  roles: string[];
  appAccess: string[];
  featureAccess: string[];
}

export interface crmAddUser {
  createOneUser: crmAddUser_createOneUser;
}

export interface crmAddUserVariables {
  data: UserCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: crmEditUser
// ====================================================

export interface crmEditUser_updateOneUser_salesRepInfo {
  podioId: string | null;
}

export interface crmEditUser_updateOneUser_setterInfo {
  podioId: string | null;
}

export interface crmEditUser_updateOneUser {
  id: number | null;
  name: string | null;
  email: string | null;
  role: user_role | null;
  team: team_type;
  enabled: boolean | null;
  salesRepInfo: crmEditUser_updateOneUser_salesRepInfo | null;
  setterInfo: crmEditUser_updateOneUser_setterInfo | null;
  roles: string[];
  appAccess: string[];
  featureAccess: string[];
}

export interface crmEditUser {
  updateOneUser: crmEditUser_updateOneUser | null;
}

export interface crmEditUserVariables {
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNextLevelIncentiveForDisplay
// ====================================================

export interface getNextLevelIncentiveForDisplay_getNextLevelIncentive {
  numFluentApproved: number | null;
}

export interface getNextLevelIncentiveForDisplay {
  getNextLevelIncentive: getNextLevelIncentiveForDisplay_getNextLevelIncentive | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPodcastsFluentPro
// ====================================================

export interface getPodcastsFluentPro_podcasts {
  id: string;
  postDate: any | null;
  podcastUrl: string | null;
  thumbnailUrl: string | null;
  summary: string | null;
  title: string | null;
  hosts: string[];
  guests: string[];
  createdAt: any | null;
  updatedAt: any | null;
}

export interface getPodcastsFluentPro {
  podcasts: (getPodcastsFluentPro_podcasts | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAppointmentDetails
// ====================================================

export interface GetAppointmentDetails_appointment_leadInfo_contact {
  name: string | null;
  phonePersonal: string | null;
  emailPersonal: string | null;
  lat: any | null;
  lng: any | null;
}

export interface GetAppointmentDetails_appointment_leadInfo {
  appointmentStartDate: any | null;
  appointmentEndDate: any | null;
  utilityBill: string | null;
  averageBill: string | null;
  creditScore: string | null;
  homeOwner: string | null;
  contact: GetAppointmentDetails_appointment_leadInfo_contact | null;
}

export interface GetAppointmentDetails_appointment {
  id: number;
  leadId: number | null;
  formattedAddress: string | null;
  leadInfo: GetAppointmentDetails_appointment_leadInfo | null;
}

export interface GetAppointmentDetails {
  appointment: GetAppointmentDetails_appointment | null;
}

export interface GetAppointmentDetailsVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRepSolarAppointments
// ====================================================

export interface GetRepSolarAppointments_appointments_leadInfo {
  id: number;
  appointmentStartDate: any | null;
  appointmentEndDate: any | null;
}

export interface GetRepSolarAppointments_appointments {
  id: number;
  leadId: number | null;
  title: string | null;
  notes: string | null;
  startDate: any | null;
  formattedAddress: string | null;
  leadInfo: GetRepSolarAppointments_appointments_leadInfo | null;
}

export interface GetRepSolarAppointments {
  appointments: GetRepSolarAppointments_appointments[];
}

export interface GetRepSolarAppointmentsVariables {
  startDate?: any | null;
  endDate?: any | null;
  userId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAppointments
// ====================================================

export interface GetAppointments_appointments_leadInfo_contact {
  name: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  phonePersonal: string | null;
  emailPersonal: string | null;
}

export interface GetAppointments_appointments_leadInfo {
  appointmentStartDate: any | null;
  appointmentEndDate: any | null;
  contact: GetAppointments_appointments_leadInfo_contact | null;
}

export interface GetAppointments_appointments {
  id: number;
  leadId: number | null;
  formattedAddress: string | null;
  startDate: any | null;
  title: string | null;
  notes: string | null;
  summary: string | null;
  status: string | null;
  leadInfo: GetAppointments_appointments_leadInfo | null;
}

export interface GetAppointments {
  appointments: GetAppointments_appointments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCalendarAppointments
// ====================================================

export interface GetCalendarAppointments_appointments_leadInfo {
  appointmentEndDate: any | null;
}

export interface GetCalendarAppointments_appointments {
  title: string | null;
  startDate: any | null;
  leadInfo: GetCalendarAppointments_appointments_leadInfo | null;
  notes: string | null;
  formattedAddress: string | null;
}

export interface GetCalendarAppointments {
  appointments: GetCalendarAppointments_appointments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getLeaderboardByOption
// ====================================================

export interface getLeaderboardByOption_leaderboards {
  id: number;
  title: string | null;
  gte: any | null;
  lte: any | null;
  results: any | null;
  statKey: string | null;
}

export interface getLeaderboardByOption {
  leaderboards: getLeaderboardByOption_leaderboards[];
}

export interface getLeaderboardByOptionVariables {
  month?: string | null;
  year?: string | null;
  type?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCurrentMonthLeaderboard
// ====================================================

export interface getCurrentMonthLeaderboard_dynamicLeaderboard {
  results: any | null;
}

export interface getCurrentMonthLeaderboard {
  dynamicLeaderboard: getCurrentMonthLeaderboard_dynamicLeaderboard | null;
}

export interface getCurrentMonthLeaderboardVariables {
  gte: string;
  lte: string;
  statKey: statKeys;
  type: RepTypes;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LeadDetailScreenQuery
// ====================================================

export interface LeadDetailScreenQuery_masterCustomer_setter_contact {
  phoneWork: string | null;
  phonePersonal: string | null;
  emailWork: string | null;
  emailPersonal: string | null;
}

export interface LeadDetailScreenQuery_masterCustomer_setter {
  id: number;
  fullName: string | null;
  contact: LeadDetailScreenQuery_masterCustomer_setter_contact | null;
}

export interface LeadDetailScreenQuery_masterCustomer_salesRep_contact {
  phoneWork: string | null;
  phonePersonal: string | null;
  emailWork: string | null;
  emailPersonal: string | null;
}

export interface LeadDetailScreenQuery_masterCustomer_salesRep {
  id: number;
  fullName: string | null;
  contact: LeadDetailScreenQuery_masterCustomer_salesRep_contact | null;
}

export interface LeadDetailScreenQuery_masterCustomer_location {
  streetAddress: string | null;
  lat: any | null;
  lng: any | null;
  streetNumber: string | null;
  streetName: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface LeadDetailScreenQuery_masterCustomer_contact {
  phoneWork: string | null;
  phonePersonal: string | null;
  emailWork: string | null;
  emailPersonal: string | null;
}

export interface LeadDetailScreenQuery_masterCustomer {
  id: number;
  fullName: string | null;
  dateAccountCreated: any | null;
  dateLeadSubmitted: any | null;
  appointmentDate: any | null;
  dateOutcomeUpdated: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  dateInstallReady: any | null;
  dateNtpSubmitted: any | null;
  dateNtpApproved: any | null;
  dateEngineeringComplete: any | null;
  nemApprovedDate: any | null;
  dateAllPermitsApproved: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  dateScheduledInstall: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  datePtoSubmitted: any | null;
  ptoReceivedDate: any | null;
  dateM1Submitted: any | null;
  dateM1Received: any | null;
  dateCanceled: any | null;
  setter: LeadDetailScreenQuery_masterCustomer_setter | null;
  salesRep: LeadDetailScreenQuery_masterCustomer_salesRep | null;
  location: LeadDetailScreenQuery_masterCustomer_location | null;
  contact: LeadDetailScreenQuery_masterCustomer_contact | null;
}

export interface LeadDetailScreenQuery {
  masterCustomer: LeadDetailScreenQuery_masterCustomer | null;
}

export interface LeadDetailScreenQueryVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: queryLeadsListScreen
// ====================================================

export interface queryLeadsListScreen_masterCustomersByContext {
  id: number;
  fullName: string | null;
  leadSourceType: string | null;
  dateLeadSubmitted: any | null;
  dateOutcomeUpdated: any | null;
  outcome: string | null;
  dateAccountCreated: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  installCompleteDate: any | null;
}

export interface queryLeadsListScreen {
  masterCustomersByContext: (queryLeadsListScreen_masterCustomersByContext | null)[] | null;
}

export interface queryLeadsListScreenVariables {
  where?: MasterCustomerWhereInput | null;
  orderBy?: MasterCustomerOrderByInput | null;
  take?: number | null;
  skip?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeclineAppointment
// ====================================================

export interface DeclineAppointment_updateOneAppointment {
  status: string | null;
  id: number;
}

export interface DeclineAppointment {
  updateOneAppointment: DeclineAppointment_updateOneAppointment | null;
}

export interface DeclineAppointmentVariables {
  appointmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getStats
// ====================================================

export interface getStats_salesRep_stats {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numFluentApproved: number | null;
  numInstalled: number | null;
  numAccountCreated: number | null;
}

export interface getStats_salesRep {
  stats: getStats_salesRep_stats | null;
}

export interface getStats {
  salesRep: getStats_salesRep | null;
}

export interface getStatsVariables {
  salesRepId?: number | null;
  gte?: string | null;
  lte?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOfficeOptions_admin
// ====================================================

export interface getOfficeOptions_admin_offices {
  id: number;
  name: string | null;
}

export interface getOfficeOptions_admin {
  offices: getOfficeOptions_admin_offices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOfficeOptions_team
// ====================================================

export interface getOfficeOptions_team_offices {
  id: number;
  name: string | null;
}

export interface getOfficeOptions_team {
  offices: getOfficeOptions_team_offices[];
}

export interface getOfficeOptions_teamVariables {
  team?: team_type | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOfficeOptions_manager
// ====================================================

export interface getOfficeOptions_manager_offices {
  id: number;
  name: string | null;
}

export interface getOfficeOptions_manager {
  offices: getOfficeOptions_manager_offices[];
}

export interface getOfficeOptions_managerVariables {
  managerId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: officeStats
// ====================================================

export interface officeStats_offices_stats {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numFluentApproved: number | null;
  numInstalled: number | null;
}

export interface officeStats_offices_closers_stats {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numFluentApproved: number | null;
  numInstalled: number | null;
}

export interface officeStats_offices_closers {
  fullName: string | null;
  stats: officeStats_offices_closers_stats | null;
}

export interface officeStats_offices_setters_stats {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numFluentApproved: number | null;
  numInstalled: number | null;
}

export interface officeStats_offices_setters {
  fullName: string | null;
  stats: officeStats_offices_setters_stats | null;
}

export interface officeStats_offices {
  name: string | null;
  stats: officeStats_offices_stats | null;
  closers: officeStats_offices_closers[];
  setters: officeStats_offices_setters[];
}

export interface officeStats {
  offices: officeStats_offices[];
}

export interface officeStatsVariables {
  gte?: string | null;
  lte?: string | null;
  officeId: number;
  source?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: queryGetOffices
// ====================================================

export interface queryGetOffices_offices_managers {
  id: number;
  fullName: string | null;
}

export interface queryGetOffices_offices_closers {
  id: number;
  fullName: string | null;
}

export interface queryGetOffices_offices_setters {
  id: number;
  fullName: string | null;
}

export interface queryGetOffices_offices {
  id: number;
  name: string | null;
  managers: queryGetOffices_offices_managers[];
  closers: queryGetOffices_offices_closers[];
  setters: queryGetOffices_offices_setters[];
  salesTeam: team_type | null;
}

export interface queryGetOffices {
  offices: queryGetOffices_offices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: salesReps
// ====================================================

export interface salesReps_salesReps_user {
  email: string | null;
}

export interface salesReps_salesReps {
  id: number;
  fullName: string | null;
  user: salesReps_salesReps_user | null;
}

export interface salesReps {
  salesReps: salesReps_salesReps[];
}

export interface salesRepsVariables {
  where?: SalesRepWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: setters
// ====================================================

export interface setters_setters_user {
  email: string | null;
}

export interface setters_setters {
  id: number;
  fullName: string | null;
  user: setters_setters_user | null;
}

export interface setters {
  setters: setters_setters[];
}

export interface settersVariables {
  where?: SetterWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOneOffice
// ====================================================

export interface getOneOffice_office_managers_user {
  email: string | null;
}

export interface getOneOffice_office_managers {
  id: number;
  fullName: string | null;
  user: getOneOffice_office_managers_user | null;
}

export interface getOneOffice_office_closers_user {
  email: string | null;
}

export interface getOneOffice_office_closers {
  id: number;
  fullName: string | null;
  user: getOneOffice_office_closers_user | null;
}

export interface getOneOffice_office_setters_user {
  email: string | null;
}

export interface getOneOffice_office_setters {
  id: number;
  fullName: string | null;
  user: getOneOffice_office_setters_user | null;
}

export interface getOneOffice_office {
  id: number;
  name: string | null;
  managers: getOneOffice_office_managers[];
  closers: getOneOffice_office_closers[];
  setters: getOneOffice_office_setters[];
  salesTeam: team_type | null;
}

export interface getOneOffice {
  office: getOneOffice_office | null;
}

export interface getOneOfficeVariables {
  where: OfficeWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateOffice
// ====================================================

export interface updateOffice_updateOneOffice_managers {
  fullName: string | null;
}

export interface updateOffice_updateOneOffice_closers {
  fullName: string | null;
}

export interface updateOffice_updateOneOffice_setters {
  fullName: string | null;
}

export interface updateOffice_updateOneOffice {
  id: number;
  managers: updateOffice_updateOneOffice_managers[];
  closers: updateOffice_updateOneOffice_closers[];
  setters: updateOffice_updateOneOffice_setters[];
}

export interface updateOffice {
  updateOneOffice: updateOffice_updateOneOffice | null;
}

export interface updateOfficeVariables {
  where: OfficeWhereUniqueInput;
  data: OfficeUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: salesRepsForOptions
// ====================================================

export interface salesRepsForOptions_salesReps_user {
  email: string | null;
}

export interface salesRepsForOptions_salesReps {
  id: number;
  fullName: string | null;
  user: salesRepsForOptions_salesReps_user | null;
}

export interface salesRepsForOptions {
  salesReps: salesRepsForOptions_salesReps[];
}

export interface salesRepsForOptionsVariables {
  where?: SalesRepWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: settersForOptions
// ====================================================

export interface settersForOptions_setters_user {
  email: string | null;
}

export interface settersForOptions_setters {
  id: number;
  fullName: string | null;
  user: settersForOptions_setters_user | null;
}

export interface settersForOptions {
  setters: settersForOptions_setters[];
}

export interface settersForOptionsVariables {
  where?: SetterWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setNewPassword
// ====================================================

export interface setNewPassword_setNewPassword {
  auth: boolean | null;
}

export interface setNewPassword {
  setNewPassword: setNewPassword_setNewPassword | null;
}

export interface setNewPasswordVariables {
  password: string;
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: draftUser
// ====================================================

export interface draftUser_updateOneParticipant_user_setterInfo_SalesEmployee {
  id: number;
}

export interface draftUser_updateOneParticipant_user_setterInfo {
  id: number;
  SalesEmployee: draftUser_updateOneParticipant_user_setterInfo_SalesEmployee | null;
}

export interface draftUser_updateOneParticipant_user_salesRepInfo_SalesEmployee {
  id: number;
}

export interface draftUser_updateOneParticipant_user_salesRepInfo {
  SalesEmployee: draftUser_updateOneParticipant_user_salesRepInfo_SalesEmployee | null;
}

export interface draftUser_updateOneParticipant_user {
  name: string | null;
  id: number | null;
  setterInfo: draftUser_updateOneParticipant_user_setterInfo | null;
  salesRepInfo: draftUser_updateOneParticipant_user_salesRepInfo | null;
}

export interface draftUser_updateOneParticipant {
  user: draftUser_updateOneParticipant_user[];
}

export interface draftUser {
  updateOneParticipant: draftUser_updateOneParticipant | null;
}

export interface draftUserVariables {
  userId?: number | null;
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllParticipantsFortitudeForDrafted
// ====================================================

export interface getAllParticipantsFortitudeForDrafted_participants_user_setterInfo_SalesEmployee {
  id: number;
}

export interface getAllParticipantsFortitudeForDrafted_participants_user_setterInfo {
  id: number;
  SalesEmployee: getAllParticipantsFortitudeForDrafted_participants_user_setterInfo_SalesEmployee | null;
}

export interface getAllParticipantsFortitudeForDrafted_participants_user_salesRepInfo_SalesEmployee {
  id: number;
}

export interface getAllParticipantsFortitudeForDrafted_participants_user_salesRepInfo {
  SalesEmployee: getAllParticipantsFortitudeForDrafted_participants_user_salesRepInfo_SalesEmployee | null;
}

export interface getAllParticipantsFortitudeForDrafted_participants_user {
  name: string | null;
  id: number | null;
  setterInfo: getAllParticipantsFortitudeForDrafted_participants_user_setterInfo | null;
  salesRepInfo: getAllParticipantsFortitudeForDrafted_participants_user_salesRepInfo | null;
}

export interface getAllParticipantsFortitudeForDrafted_participants {
  id: number;
  title: string | null;
  user: getAllParticipantsFortitudeForDrafted_participants_user[];
}

export interface getAllParticipantsFortitudeForDrafted {
  participants: getAllParticipantsFortitudeForDrafted_participants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllParticipantsFortitude
// ====================================================

export interface getAllParticipantsFortitude_participants_user_setterInfo_SalesEmployee {
  id: number;
}

export interface getAllParticipantsFortitude_participants_user_setterInfo {
  id: number;
  SalesEmployee: getAllParticipantsFortitude_participants_user_setterInfo_SalesEmployee | null;
}

export interface getAllParticipantsFortitude_participants_user_salesRepInfo_SalesEmployee {
  id: number;
}

export interface getAllParticipantsFortitude_participants_user_salesRepInfo {
  SalesEmployee: getAllParticipantsFortitude_participants_user_salesRepInfo_SalesEmployee | null;
}

export interface getAllParticipantsFortitude_participants_user {
  name: string | null;
  id: number | null;
  setterInfo: getAllParticipantsFortitude_participants_user_setterInfo | null;
  salesRepInfo: getAllParticipantsFortitude_participants_user_salesRepInfo | null;
}

export interface getAllParticipantsFortitude_participants {
  id: number;
  title: string | null;
  user: getAllParticipantsFortitude_participants_user[];
}

export interface getAllParticipantsFortitude {
  participants: getAllParticipantsFortitude_participants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFortitudeMatchUpScoreById
// ====================================================

export interface getFortitudeMatchUpScoreById_FortitudeMatchUpScoreById_closers {
  userId: number | null;
  employeeId: number | null;
  name: string | null;
  role: string | null;
  office: string | null;
  count: number | null;
  countTotal: number | null;
  penaltyCount: number | null;
  penaltyTotal: number | null;
  total: number | null;
}

export interface getFortitudeMatchUpScoreById_FortitudeMatchUpScoreById_setters {
  userId: number | null;
  employeeId: number | null;
  name: string | null;
  role: string | null;
  office: string | null;
  count: number | null;
  countTotal: number | null;
  penaltyCount: number | null;
  penaltyTotal: number | null;
  total: number | null;
}

export interface getFortitudeMatchUpScoreById_FortitudeMatchUpScoreById {
  matchUpId: number | null;
  roundId: number | null;
  participantId: number | null;
  title: string | null;
  gte: string | null;
  lte: string | null;
  closers: (getFortitudeMatchUpScoreById_FortitudeMatchUpScoreById_closers | null)[] | null;
  setters: (getFortitudeMatchUpScoreById_FortitudeMatchUpScoreById_setters | null)[] | null;
  totalPitched: number | null;
  totalApptsForSetters: number | null;
  totalApptsForClosers: number | null;
  totalCloserFluentApproved: number | null;
  totalSetterFluentApproved: number | null;
  totalSetterFluentApprovedByAppt: number | null;
  closerFAonCloserAppts: number | null;
  setterFAonPitched: number | null;
  setterFAonSetterAppts: number | null;
}

export interface getFortitudeMatchUpScoreById {
  FortitudeMatchUpScoreById: (getFortitudeMatchUpScoreById_FortitudeMatchUpScoreById | null)[] | null;
}

export interface getFortitudeMatchUpScoreByIdVariables {
  matchUpId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFortitudeMatchUpsByRound
// ====================================================

export interface getFortitudeMatchUpsByRound_FortitudeParticipantsScoresByRound_closers {
  userId: number | null;
  employeeId: number | null;
  name: string | null;
  role: string | null;
  office: string | null;
  count: number | null;
  countTotal: number | null;
  penaltyCount: number | null;
  penaltyTotal: number | null;
  total: number | null;
}

export interface getFortitudeMatchUpsByRound_FortitudeParticipantsScoresByRound_setters {
  userId: number | null;
  employeeId: number | null;
  name: string | null;
  role: string | null;
  office: string | null;
  count: number | null;
  countTotal: number | null;
  penaltyCount: number | null;
  penaltyTotal: number | null;
  total: number | null;
}

export interface getFortitudeMatchUpsByRound_FortitudeParticipantsScoresByRound {
  matchUpId: number | null;
  roundId: number | null;
  participantId: number | null;
  title: string | null;
  closers: (getFortitudeMatchUpsByRound_FortitudeParticipantsScoresByRound_closers | null)[] | null;
  setters: (getFortitudeMatchUpsByRound_FortitudeParticipantsScoresByRound_setters | null)[] | null;
  totalPitched: number | null;
  totalApptsForSetters: number | null;
  totalApptsForClosers: number | null;
  totalCloserFluentApproved: number | null;
  totalSetterFluentApproved: number | null;
  totalSetterFluentApprovedByAppt: number | null;
  closerFAonCloserAppts: number | null;
  setterFAonPitched: number | null;
  setterFAonSetterAppts: number | null;
}

export interface getFortitudeMatchUpsByRound {
  FortitudeParticipantsScoresByRound: (getFortitudeMatchUpsByRound_FortitudeParticipantsScoresByRound | null)[] | null;
}

export interface getFortitudeMatchUpsByRoundVariables {
  roundId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNonDispositionedForCloser
// ====================================================

export interface getNonDispositionedForCloser_appointments_leadInfo_contact {
  name: string | null;
}

export interface getNonDispositionedForCloser_appointments_leadInfo {
  contact: getNonDispositionedForCloser_appointments_leadInfo_contact | null;
}

export interface getNonDispositionedForCloser_appointments_Deal {
  outcome_: string[];
  link: string | null;
}

export interface getNonDispositionedForCloser_appointments {
  startDate: any | null;
  salesEmployeeId_SalesRep: number | null;
  link: string | null;
  leadInfo: getNonDispositionedForCloser_appointments_leadInfo | null;
  Deal: getNonDispositionedForCloser_appointments_Deal | null;
}

export interface getNonDispositionedForCloser {
  appointments: getNonDispositionedForCloser_appointments[];
}

export interface getNonDispositionedForCloserVariables {
  employeeId: number;
  gte: any;
  lte: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MasterCustomerReportCompare
// ====================================================

export interface MasterCustomerReportCompare_MasterCustomerReport_overallReport {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

export interface MasterCustomerReportCompare_MasterCustomerReport_masterCustomers {
  id: number;
  podioId: string | null;
  fullName: string | null;
  dateLeadSubmitted: any | null;
  dateOutcomeUpdated: any | null;
  dateAccountCreated: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
}

export interface MasterCustomerReportCompare_MasterCustomerReport {
  overallReport: MasterCustomerReportCompare_MasterCustomerReport_overallReport | null;
  masterCustomers: (MasterCustomerReportCompare_MasterCustomerReport_masterCustomers | null)[] | null;
}

export interface MasterCustomerReportCompare {
  MasterCustomerReport: MasterCustomerReportCompare_MasterCustomerReport | null;
}

export interface MasterCustomerReportCompareVariables {
  filter?: CustomerReportFilter | null;
  orderBy?: CustomerReportOrderBy | null;
  paginate?: CustomerReportPaginate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createUserFromForm
// ====================================================

export interface createUserFromForm_createOneUser {
  id: number | null;
  name: string | null;
  email: string | null;
  role: user_role | null;
  team: team_type;
  enabled: boolean | null;
}

export interface createUserFromForm {
  createOneUser: createUserFromForm_createOneUser;
}

export interface createUserFromFormVariables {
  name: string;
  email: string;
  role: user_role;
  team: team_type;
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertOneFieldKey
// ====================================================

export interface upsertOneFieldKey_upsertOneFieldKey {
  id: number;
  nodeName: string;
  podioFieldId: number;
  externalId: string | null;
}

export interface upsertOneFieldKey {
  upsertOneFieldKey: upsertOneFieldKey_upsertOneFieldKey;
}

export interface upsertOneFieldKeyVariables {
  where: FieldKeyWhereUniqueInput;
  create: FieldKeyCreateInput;
  update: FieldKeyUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOneFieldKey
// ====================================================

export interface deleteOneFieldKey_deleteOneFieldKey {
  id: number;
  nodeName: string;
}

export interface deleteOneFieldKey {
  deleteOneFieldKey: deleteOneFieldKey_deleteOneFieldKey | null;
}

export interface deleteOneFieldKeyVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: appKeysForSelect
// ====================================================

export interface appKeysForSelect_appKeys {
  id: number;
  podioAppId: string;
  podioAppToken: string | null;
  name: string | null;
  team: team_type;
}

export interface appKeysForSelect {
  appKeys: appKeysForSelect_appKeys[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: appKeyAndFieldsForPodio
// ====================================================

export interface appKeyAndFieldsForPodio_appKey_fieldKeys {
  id: number;
  podioFieldId: number;
  nodeName: string;
  externalId: string | null;
}

export interface appKeyAndFieldsForPodio_appKey {
  id: number;
  podioAppId: string;
  podioAppToken: string | null;
  name: string | null;
  team: team_type;
  fieldKeys: appKeyAndFieldsForPodio_appKey_fieldKeys[];
}

export interface appKeyAndFieldsForPodio {
  appKey: appKeyAndFieldsForPodio_appKey | null;
}

export interface appKeyAndFieldsForPodioVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: appsAndFields
// ====================================================

export interface appsAndFields_appKeys_fieldKeys {
  id: number;
  podioFieldId: number;
  nodeName: string;
  externalId: string | null;
}

export interface appsAndFields_appKeys {
  id: number;
  podioAppId: string;
  podioAppToken: string | null;
  name: string | null;
  team: team_type;
  fieldKeys: appsAndFields_appKeys_fieldKeys[];
}

export interface appsAndFields {
  appKeys: appsAndFields_appKeys[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTournamentOptions
// ====================================================

export interface getTournamentOptions_tournaments {
  id: number;
  title: string | null;
}

export interface getTournamentOptions {
  tournaments: getTournamentOptions_tournaments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createParticipantForTournament
// ====================================================

export interface createParticipantForTournament_createOneParticipant_user {
  name: string | null;
}

export interface createParticipantForTournament_createOneParticipant {
  title: string | null;
  user: createParticipantForTournament_createOneParticipant_user[];
}

export interface createParticipantForTournament {
  createOneParticipant: createParticipantForTournament_createOneParticipant;
}

export interface createParticipantForTournamentVariables {
  userId: number;
  tournamentId: number;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOneParticipant
// ====================================================

export interface deleteOneParticipant_deleteOneParticipant {
  title: string | null;
}

export interface deleteOneParticipant {
  deleteOneParticipant: deleteOneParticipant_deleteOneParticipant | null;
}

export interface deleteOneParticipantVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QueryUsersForTournament
// ====================================================

export interface QueryUsersForTournament_users_salesRepInfo {
  podioId: string | null;
}

export interface QueryUsersForTournament_users_setterInfo {
  podioId: string | null;
}

export interface QueryUsersForTournament_users {
  id: number | null;
  name: string | null;
  email: string | null;
  role: user_role | null;
  team: team_type;
  enabled: boolean | null;
  salesRepInfo: QueryUsersForTournament_users_salesRepInfo | null;
  setterInfo: QueryUsersForTournament_users_setterInfo | null;
}

export interface QueryUsersForTournament {
  users: QueryUsersForTournament_users[];
}

export interface QueryUsersForTournamentVariables {
  where?: UserWhereInput | null;
  orderBy?: UserOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QueryParticipantsForTournament
// ====================================================

export interface QueryParticipantsForTournament_participants_user {
  id: number | null;
  name: string | null;
}

export interface QueryParticipantsForTournament_participants {
  id: number;
  title: string | null;
  user: QueryParticipantsForTournament_participants_user[];
}

export interface QueryParticipantsForTournament {
  participants: QueryParticipantsForTournament_participants[];
}

export interface QueryParticipantsForTournamentVariables {
  tournamentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMatchup
// ====================================================

export interface createMatchup_createOneMatchUp_participants {
  id: number;
  title: string | null;
}

export interface createMatchup_createOneMatchUp {
  participants: createMatchup_createOneMatchUp_participants[];
}

export interface createMatchup {
  createOneMatchUp: createMatchup_createOneMatchUp;
}

export interface createMatchupVariables {
  data: MatchUpCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMatchUps
// ====================================================

export interface getMatchUps_matchUps_scoreboard {
  title: string | null;
  score: number | null;
  seed: number | null;
}

export interface getMatchUps_matchUps {
  id: number;
  scoreboard: (getMatchUps_matchUps_scoreboard | null)[] | null;
}

export interface getMatchUps {
  matchUps: getMatchUps_matchUps[];
}

export interface getMatchUpsVariables {
  where?: MatchUpWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRoundOptions
// ====================================================

export interface getRoundOptions_rounds {
  id: number;
  roundNumber: number;
}

export interface getRoundOptions {
  rounds: getRoundOptions_rounds[];
}

export interface getRoundOptionsVariables {
  where: RoundWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUserRole
// ====================================================

export interface updateUserRole_updateOneUser {
  id: number | null;
  email: string | null;
  role: user_role | null;
}

export interface updateUserRole {
  updateOneUser: updateUserRole_updateOneUser | null;
}

export interface updateUserRoleVariables {
  id: number;
  role: user_role;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUserStatus
// ====================================================

export interface updateUserStatus_updateOneUser {
  id: number | null;
  email: string | null;
  enabled: boolean | null;
}

export interface updateUserStatus {
  updateOneUser: updateUserStatus_updateOneUser | null;
}

export interface updateUserStatusVariables {
  id: number;
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUserTeam
// ====================================================

export interface updateUserTeam_updateOneUser {
  id: number | null;
  email: string | null;
  team: team_type;
}

export interface updateUserTeam {
  updateOneUser: updateUserTeam_updateOneUser | null;
}

export interface updateUserTeamVariables {
  id: number;
  team: team_type;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangePassword
// ====================================================

export interface ChangePassword {
  sendNewPasswordKey: string | null;
}

export interface ChangePasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: heyo
// ====================================================

export interface heyo_salesReps_contact {
  emailWork: string | null;
}

export interface heyo_salesReps {
  contact: heyo_salesReps_contact | null;
}

export interface heyo {
  salesReps: heyo_salesReps[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QueryUsersForSystems
// ====================================================

export interface QueryUsersForSystems_users_salesRepInfo {
  podioId: string | null;
}

export interface QueryUsersForSystems_users_setterInfo {
  podioId: string | null;
}

export interface QueryUsersForSystems_users {
  id: number | null;
  name: string | null;
  email: string | null;
  role: user_role | null;
  team: team_type;
  enabled: boolean | null;
  salesRepInfo: QueryUsersForSystems_users_salesRepInfo | null;
  setterInfo: QueryUsersForSystems_users_setterInfo | null;
}

export interface QueryUsersForSystems {
  users: QueryUsersForSystems_users[];
}

export interface QueryUsersForSystemsVariables {
  where?: UserWhereInput | null;
  orderBy?: UserOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectAppKeysQuery
// ====================================================

export interface SelectAppKeysQuery_appKeys {
  id: number;
  podioAppId: string;
  podioAppToken: string | null;
  podioAppName: string | null;
  modelName: string | null;
  name: string | null;
  team: team_type;
  enabled: boolean | null;
}

export interface SelectAppKeysQuery {
  appKeys: SelectAppKeysQuery_appKeys[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: googleLogin
// ====================================================

export interface googleLogin_googleLogin_user_salesRepInfo_SalesEmployee {
  id: number;
}

export interface googleLogin_googleLogin_user_salesRepInfo {
  id: number;
  SalesEmployee: googleLogin_googleLogin_user_salesRepInfo_SalesEmployee | null;
}

export interface googleLogin_googleLogin_user_setterInfo_SalesEmployee {
  id: number;
}

export interface googleLogin_googleLogin_user_setterInfo {
  id: number;
  SalesEmployee: googleLogin_googleLogin_user_setterInfo_SalesEmployee | null;
}

export interface googleLogin_googleLogin_user {
  id: number | null;
  email: string | null;
  name: string | null;
  role: user_role | null;
  team: team_type;
  profilePic: string | null;
  salesRepInfo: googleLogin_googleLogin_user_salesRepInfo | null;
  setterInfo: googleLogin_googleLogin_user_setterInfo | null;
  officeName: string | null;
  roles: string[];
  appAccess: string[];
  featureAccess: string[];
}

export interface googleLogin_googleLogin {
  auth: boolean | null;
  message: string | null;
  user: googleLogin_googleLogin_user | null;
}

export interface googleLogin {
  googleLogin: googleLogin_googleLogin | null;
}

export interface googleLoginVariables {
  googleToken: string;
  type?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login_user_salesRepInfo_SalesEmployee {
  id: number;
}

export interface login_login_user_salesRepInfo {
  id: number;
  SalesEmployee: login_login_user_salesRepInfo_SalesEmployee | null;
}

export interface login_login_user_setterInfo_SalesEmployee {
  id: number;
}

export interface login_login_user_setterInfo {
  id: number;
  SalesEmployee: login_login_user_setterInfo_SalesEmployee | null;
}

export interface login_login_user {
  id: number | null;
  email: string | null;
  name: string | null;
  role: user_role | null;
  team: team_type;
  profilePic: string | null;
  salesRepInfo: login_login_user_salesRepInfo | null;
  setterInfo: login_login_user_setterInfo | null;
  officeName: string | null;
  roles: string[];
  appAccess: string[];
  featureAccess: string[];
}

export interface login_login {
  auth: boolean | null;
  message: string | null;
  token: string | null;
  user: login_login_user | null;
}

export interface login {
  login: login_login | null;
}

export interface loginVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CoreMasterCustomerDates
// ====================================================

export interface CoreMasterCustomerDates {
  dateLeadSubmitted: any | null;
  dateOutcomeUpdated: any | null;
  dateAccountCreated: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CoreNumCounts
// ====================================================

export interface CoreNumCounts {
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numAccountCreated: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CoreMasterCustomerFragment
// ====================================================

export interface CoreMasterCustomerFragment {
  fullName: string | null;
  id: number;
  dateLeadSubmitted: any | null;
  contractSignedDate: any | null;
  fluentApprovedDate: any | null;
  welcomeCallCompleteDate: any | null;
  siteSurveyCompleteDate: any | null;
  cadCompleteDate: any | null;
  permitReceivedDate: any | null;
  hoaApprovedDate: any | null;
  installCompleteDate: any | null;
  inspectionCompleteDate: any | null;
  nemApprovedDate: any | null;
  ptoReceivedDate: any | null;
  stepsRequiredForFluentApproved: string | null;
  region: string | null;
  team: team_type | null;
  outcome: string | null;
  dOutcome: string | null;
  vOutcome: string | null;
  leadSourceType: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RepNumCount
// ====================================================

export interface RepNumCount {
  id: number | null;
  fullName: string | null;
  officeName: string | null;
  officeId: number | null;
  team: string | null;
  numLeadSubmitted: number | null;
  numPitched: number | null;
  numContractSigned: number | null;
  numFluentApproved: number | null;
  numNonFluentApproved: number | null;
  numWelcomeCall: number | null;
  numSiteSurvey: number | null;
  numCADComplete: number | null;
  numPermit: number | null;
  numHOA: number | null;
  numInstalled: number | null;
  numInspection: number | null;
  numNEM: number | null;
  numPTO: number | null;
  pitchedToLeads: number | null;
  signedToPitched: number | null;
  approvedToSigned: number | null;
  installedToApproved: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: crmUser
// ====================================================

export interface crmUser_salesRepInfo {
  podioId: string | null;
}

export interface crmUser_setterInfo {
  podioId: string | null;
}

export interface crmUser {
  id: number | null;
  name: string | null;
  email: string | null;
  role: user_role | null;
  team: team_type;
  enabled: boolean | null;
  salesRepInfo: crmUser_salesRepInfo | null;
  setterInfo: crmUser_setterInfo | null;
  roles: string[];
  appAccess: string[];
  featureAccess: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ASSIGNMENT_TYPE {
  MANUAL = "MANUAL",
  PRIORITY = "PRIORITY",
}

export enum EliminationType {
  DOUBLE_ELIMINATION = "DOUBLE_ELIMINATION",
  SINGLE_ELIMINATION = "SINGLE_ELIMINATION",
}

export enum FieldKeyRelationType {
  MANY_TO_MANY = "MANY_TO_MANY",
  MANY_TO_ONE = "MANY_TO_ONE",
  ONE_TO_MANY = "ONE_TO_MANY",
  ONE_TO_ONE = "ONE_TO_ONE",
}

export enum GENERATED_TYPE {
  CLOSER = "CLOSER",
  SETTER = "SETTER",
}

export enum LeadHookStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/**
 * Enum for office reports to know where to use the Ids
 */
export enum OfficeReportRequestType {
  MANAGERS = "MANAGERS",
  OFFICES = "OFFICES",
  TEAM = "TEAM",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

/**
 * Enum for periods
 */
export enum RepTypes {
  CLOSER = "CLOSER",
  SETTER = "SETTER",
}

export enum STATUS_TYPE {
  ERROR = "ERROR",
  IDLE = "IDLE",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum TEAM_TYPE {
  ALL = "ALL",
  DAUNTLESS = "DAUNTLESS",
  FS3 = "FS3",
  VALOR = "VALOR",
}

export enum TimeSheetStatus {
  APP_TERMINATED = "APP_TERMINATED",
  CLOCKED_IN = "CLOCKED_IN",
  CLOCKED_OUT = "CLOCKED_OUT",
  EDITED = "EDITED",
  ERROR = "ERROR",
}

/**
 * Enum for periods
 */
export enum periodKeys {
  ALLTIME = "ALLTIME",
  CUSTOM = "CUSTOM",
  LAST12MONTHS = "LAST12MONTHS",
  LAST3MONTHS = "LAST3MONTHS",
  LAST4QUARTERS = "LAST4QUARTERS",
  LAST4WEEKS = "LAST4WEEKS",
  LAST6MONTHS = "LAST6MONTHS",
  LASTMONTH = "LASTMONTH",
  LASTQUARTER = "LASTQUARTER",
  LASTWEEK = "LASTWEEK",
  LASTYEAR = "LASTYEAR",
  MTD = "MTD",
  QTD = "QTD",
  TODAY = "TODAY",
  WTD = "WTD",
  YESTERDAY = "YESTERDAY",
  YTD = "YTD",
}

/**
 * Enum for what dates to filter by
 */
export enum pipelineDateKeys {
  all = "all",
  appointmentDate = "appointmentDate",
  cadCompleteDate = "cadCompleteDate",
  contractSignedDate = "contractSignedDate",
  dateAccountCreated = "dateAccountCreated",
  dateAllPermitsApproved = "dateAllPermitsApproved",
  dateCanceled = "dateCanceled",
  dateEngineeringComplete = "dateEngineeringComplete",
  dateInstallReady = "dateInstallReady",
  dateLeadSubmitted = "dateLeadSubmitted",
  dateM1Received = "dateM1Received",
  dateM1Submitted = "dateM1Submitted",
  dateNtpApproved = "dateNtpApproved",
  dateNtpSubmitted = "dateNtpSubmitted",
  dateOutcomeUpdated = "dateOutcomeUpdated",
  datePtoSubmitted = "datePtoSubmitted",
  dateScheduledInstall = "dateScheduledInstall",
  fluentApprovedDate = "fluentApprovedDate",
  hoaApprovedDate = "hoaApprovedDate",
  inspectionCompleteDate = "inspectionCompleteDate",
  installCompleteDate = "installCompleteDate",
  nemApprovedDate = "nemApprovedDate",
  nonFluentApproved = "nonFluentApproved",
  permitReceivedDate = "permitReceivedDate",
  pitched = "pitched",
  ptoReceivedDate = "ptoReceivedDate",
  siteSurveyCompleteDate = "siteSurveyCompleteDate",
  welcomeCallCompleteDate = "welcomeCallCompleteDate",
}

/**
 * Enum for stats
 */
export enum statKeys {
  approvedToSigned = "approvedToSigned",
  installedToApproved = "installedToApproved",
  numAccountCreated = "numAccountCreated",
  numAllPermitsApproved = "numAllPermitsApproved",
  numAppointments = "numAppointments",
  numCADComplete = "numCADComplete",
  numCanceled = "numCanceled",
  numContractSigned = "numContractSigned",
  numEngineeringComplete = "numEngineeringComplete",
  numFluentApproved = "numFluentApproved",
  numHOA = "numHOA",
  numInspection = "numInspection",
  numInstallReady = "numInstallReady",
  numInstalled = "numInstalled",
  numLeadSubmitted = "numLeadSubmitted",
  numM1Received = "numM1Received",
  numM1Submitted = "numM1Submitted",
  numNEM = "numNEM",
  numNonFluentApproved = "numNonFluentApproved",
  numNtpApproved = "numNtpApproved",
  numNtpSubmitted = "numNtpSubmitted",
  numOutcomeUpdated = "numOutcomeUpdated",
  numPTO = "numPTO",
  numPermit = "numPermit",
  numPitched = "numPitched",
  numPtoSubmitted = "numPtoSubmitted",
  numScheduledInstall = "numScheduledInstall",
  numSiteSurvey = "numSiteSurvey",
  numWelcomeCall = "numWelcomeCall",
  pitchedToLeads = "pitchedToLeads",
  signedToPitched = "signedToPitched",
}

export enum team_type {
  ALL = "ALL",
  DAUNTLESS = "DAUNTLESS",
  FS3 = "FS3",
  NONE = "NONE",
  VALOR = "VALOR",
}

export enum user_role {
  ADMIN = "ADMIN",
  REP = "REP",
  SALES_MANAGER = "SALES_MANAGER",
  SALES_REP = "SALES_REP",
  SETTER = "SETTER",
  SUPER_ADMIN = "SUPER_ADMIN",
  TEAM_MANAGER = "TEAM_MANAGER",
  USER = "USER",
}

export interface ActivityCreateNestedManyWithoutAppointmentInput {
  create?: ActivityCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: ActivityCreateOrConnectWithoutAppointmentInput[] | null;
  connect?: ActivityWhereUniqueInput[] | null;
}

export interface ActivityCreateOrConnectWithoutAppointmentInput {
  where: ActivityWhereUniqueInput;
  create: ActivityCreateWithoutAppointmentInput;
}

export interface ActivityCreateWithoutAppointmentInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  type?: string | null;
  description?: string | null;
  source?: string | null;
  meta?: any | null;
}

export interface ActivityListRelationFilter {
  every?: ActivityWhereInput | null;
  some?: ActivityWhereInput | null;
  none?: ActivityWhereInput | null;
}

export interface ActivityScalarWhereInput {
  AND?: ActivityScalarWhereInput[] | null;
  OR?: ActivityScalarWhereInput[] | null;
  NOT?: ActivityScalarWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  type?: StringNullableFilter | null;
  description?: StringNullableFilter | null;
  source?: StringNullableFilter | null;
  meta?: JsonNullableFilter | null;
}

export interface ActivityUpdateManyMutationInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  source?: NullableStringFieldUpdateOperationsInput | null;
  meta?: any | null;
}

export interface ActivityUpdateManyWithWhereWithoutAppointmentInput {
  where: ActivityScalarWhereInput;
  data: ActivityUpdateManyMutationInput;
}

export interface ActivityUpdateManyWithoutAppointmentInput {
  create?: ActivityCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: ActivityCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: ActivityUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  connect?: ActivityWhereUniqueInput[] | null;
  set?: ActivityWhereUniqueInput[] | null;
  disconnect?: ActivityWhereUniqueInput[] | null;
  delete?: ActivityWhereUniqueInput[] | null;
  update?: ActivityUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: ActivityUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: ActivityScalarWhereInput[] | null;
}

export interface ActivityUpdateWithWhereUniqueWithoutAppointmentInput {
  where: ActivityWhereUniqueInput;
  data: ActivityUpdateWithoutAppointmentInput;
}

export interface ActivityUpdateWithoutAppointmentInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  source?: NullableStringFieldUpdateOperationsInput | null;
  meta?: any | null;
}

export interface ActivityUpsertWithWhereUniqueWithoutAppointmentInput {
  where: ActivityWhereUniqueInput;
  update: ActivityUpdateWithoutAppointmentInput;
  create: ActivityCreateWithoutAppointmentInput;
}

export interface ActivityWhereInput {
  AND?: ActivityWhereInput[] | null;
  OR?: ActivityWhereInput[] | null;
  NOT?: ActivityWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  type?: StringNullableFilter | null;
  description?: StringNullableFilter | null;
  source?: StringNullableFilter | null;
  meta?: JsonNullableFilter | null;
  appointment?: AppointmentListRelationFilter | null;
}

export interface ActivityWhereUniqueInput {
  id?: number | null;
}

export interface AppKeyCreateManySalesTeamInput {
  id?: number | null;
  podioAppId: string;
  podioAppToken?: string | null;
  podioAppName?: string | null;
  name?: string | null;
  modelName?: string | null;
  team: team_type;
  enabled?: boolean | null;
  notes?: string | null;
}

export interface AppKeyCreateManySalesTeamInputEnvelope {
  data?: AppKeyCreateManySalesTeamInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface AppKeyCreateNestedManyWithoutSalesTeamInput {
  create?: AppKeyCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: AppKeyCreateOrConnectWithoutSalesTeamInput[] | null;
  createMany?: AppKeyCreateManySalesTeamInputEnvelope | null;
  connect?: AppKeyWhereUniqueInput[] | null;
}

export interface AppKeyCreateNestedOneWithoutFieldKeysInput {
  create?: AppKeyCreateWithoutFieldKeysInput | null;
  connectOrCreate?: AppKeyCreateOrConnectWithoutFieldKeysInput | null;
  connect?: AppKeyWhereUniqueInput | null;
}

export interface AppKeyCreateOrConnectWithoutFieldKeysInput {
  where: AppKeyWhereUniqueInput;
  create: AppKeyCreateWithoutFieldKeysInput;
}

export interface AppKeyCreateOrConnectWithoutSalesTeamInput {
  where: AppKeyWhereUniqueInput;
  create: AppKeyCreateWithoutSalesTeamInput;
}

export interface AppKeyCreateWithoutFieldKeysInput {
  podioAppId: string;
  podioAppToken?: string | null;
  podioAppName?: string | null;
  name?: string | null;
  modelName?: string | null;
  team: team_type;
  enabled?: boolean | null;
  notes?: string | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutAppKeysInput | null;
}

export interface AppKeyCreateWithoutSalesTeamInput {
  podioAppId: string;
  podioAppToken?: string | null;
  podioAppName?: string | null;
  name?: string | null;
  modelName?: string | null;
  team: team_type;
  enabled?: boolean | null;
  notes?: string | null;
  fieldKeys?: FieldKeyCreateNestedManyWithoutAppKeyInput | null;
}

export interface AppKeyListRelationFilter {
  every?: AppKeyWhereInput | null;
  some?: AppKeyWhereInput | null;
  none?: AppKeyWhereInput | null;
}

export interface AppKeyScalarWhereInput {
  AND?: AppKeyScalarWhereInput[] | null;
  OR?: AppKeyScalarWhereInput[] | null;
  NOT?: AppKeyScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioAppId?: StringFilter | null;
  podioAppToken?: StringNullableFilter | null;
  podioAppName?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  modelName?: StringNullableFilter | null;
  team?: Enumteam_typeFilter | null;
  salesTeamId?: IntNullableFilter | null;
  enabled?: BoolNullableFilter | null;
  notes?: StringNullableFilter | null;
}

export interface AppKeyUpdateManyMutationInput {
  podioAppId?: StringFieldUpdateOperationsInput | null;
  podioAppToken?: NullableStringFieldUpdateOperationsInput | null;
  podioAppName?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  modelName?: NullableStringFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
}

export interface AppKeyUpdateManyWithWhereWithoutSalesTeamInput {
  where: AppKeyScalarWhereInput;
  data: AppKeyUpdateManyMutationInput;
}

export interface AppKeyUpdateManyWithoutSalesTeamInput {
  create?: AppKeyCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: AppKeyCreateOrConnectWithoutSalesTeamInput[] | null;
  upsert?: AppKeyUpsertWithWhereUniqueWithoutSalesTeamInput[] | null;
  createMany?: AppKeyCreateManySalesTeamInputEnvelope | null;
  connect?: AppKeyWhereUniqueInput[] | null;
  set?: AppKeyWhereUniqueInput[] | null;
  disconnect?: AppKeyWhereUniqueInput[] | null;
  delete?: AppKeyWhereUniqueInput[] | null;
  update?: AppKeyUpdateWithWhereUniqueWithoutSalesTeamInput[] | null;
  updateMany?: AppKeyUpdateManyWithWhereWithoutSalesTeamInput[] | null;
  deleteMany?: AppKeyScalarWhereInput[] | null;
}

export interface AppKeyUpdateOneRequiredWithoutFieldKeysInput {
  create?: AppKeyCreateWithoutFieldKeysInput | null;
  connectOrCreate?: AppKeyCreateOrConnectWithoutFieldKeysInput | null;
  upsert?: AppKeyUpsertWithoutFieldKeysInput | null;
  connect?: AppKeyWhereUniqueInput | null;
  update?: AppKeyUpdateWithoutFieldKeysInput | null;
}

export interface AppKeyUpdateWithWhereUniqueWithoutSalesTeamInput {
  where: AppKeyWhereUniqueInput;
  data: AppKeyUpdateWithoutSalesTeamInput;
}

export interface AppKeyUpdateWithoutFieldKeysInput {
  podioAppId?: StringFieldUpdateOperationsInput | null;
  podioAppToken?: NullableStringFieldUpdateOperationsInput | null;
  podioAppName?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  modelName?: NullableStringFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutAppKeysInput | null;
}

export interface AppKeyUpdateWithoutSalesTeamInput {
  podioAppId?: StringFieldUpdateOperationsInput | null;
  podioAppToken?: NullableStringFieldUpdateOperationsInput | null;
  podioAppName?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  modelName?: NullableStringFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  fieldKeys?: FieldKeyUpdateManyWithoutAppKeyInput | null;
}

export interface AppKeyUpsertWithWhereUniqueWithoutSalesTeamInput {
  where: AppKeyWhereUniqueInput;
  update: AppKeyUpdateWithoutSalesTeamInput;
  create: AppKeyCreateWithoutSalesTeamInput;
}

export interface AppKeyUpsertWithoutFieldKeysInput {
  update: AppKeyUpdateWithoutFieldKeysInput;
  create: AppKeyCreateWithoutFieldKeysInput;
}

export interface AppKeyWhereInput {
  AND?: AppKeyWhereInput[] | null;
  OR?: AppKeyWhereInput[] | null;
  NOT?: AppKeyWhereInput[] | null;
  id?: IntFilter | null;
  podioAppId?: StringFilter | null;
  podioAppToken?: StringNullableFilter | null;
  podioAppName?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  modelName?: StringNullableFilter | null;
  team?: Enumteam_typeFilter | null;
  salesTeamId?: IntNullableFilter | null;
  salesTeam?: SalesTeamWhereInput | null;
  fieldKeys?: FieldKeyListRelationFilter | null;
  enabled?: BoolNullableFilter | null;
  notes?: StringNullableFilter | null;
}

export interface AppKeyWhereUniqueInput {
  id?: number | null;
  podioAppId?: string | null;
  podioAppToken?: string | null;
}

export interface AppointmentCreateManyAssignedToInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  leadId?: number | null;
  contactId?: number | null;
  setterId?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  salesEmployeeId_Setter?: number | null;
}

export interface AppointmentCreateManyAssignedToInputEnvelope {
  data?: AppointmentCreateManyAssignedToInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManyContactInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  leadId?: number | null;
  salesRepId?: number | null;
  setterId?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  salesEmployeeId_Setter?: number | null;
}

export interface AppointmentCreateManyContactInputEnvelope {
  data?: AppointmentCreateManyContactInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManyLeadInfoInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  contactId?: number | null;
  salesRepId?: number | null;
  setterId?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  salesEmployeeId_Setter?: number | null;
}

export interface AppointmentCreateManyLeadInfoInputEnvelope {
  data?: AppointmentCreateManyLeadInfoInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  leadId?: number | null;
  contactId?: number | null;
  salesRepId?: number | null;
  setterId?: number | null;
  salesEmployeeId_Setter?: number | null;
}

export interface AppointmentCreateManySalesRepInputEnvelope {
  data?: AppointmentCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManySetterInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  leadId?: number | null;
  contactId?: number | null;
  salesRepId?: number | null;
  setterId?: number | null;
  salesEmployeeId_SalesRep?: number | null;
}

export interface AppointmentCreateManySetterInputEnvelope {
  data?: AppointmentCreateManySetterInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateNestedManyWithoutAssignedToInput {
  create?: AppointmentCreateWithoutAssignedToInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAssignedToInput[] | null;
  createMany?: AppointmentCreateManyAssignedToInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutContactInput {
  create?: AppointmentCreateWithoutContactInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutContactInput[] | null;
  createMany?: AppointmentCreateManyContactInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutLeadInfoInput {
  create?: AppointmentCreateWithoutLeadInfoInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutLeadInfoInput[] | null;
  createMany?: AppointmentCreateManyLeadInfoInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutSalesRepInput {
  create?: AppointmentCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: AppointmentCreateManySalesRepInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutSetterInput {
  create?: AppointmentCreateWithoutSetterInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutSetterInput[] | null;
  createMany?: AppointmentCreateManySetterInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedOneWithoutDealInput {
  create?: AppointmentCreateWithoutDealInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutDealInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateOrConnectWithoutAssignedToInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutAssignedToInput;
}

export interface AppointmentCreateOrConnectWithoutContactInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutContactInput;
}

export interface AppointmentCreateOrConnectWithoutDealInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutDealInput;
}

export interface AppointmentCreateOrConnectWithoutLeadInfoInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutLeadInfoInput;
}

export interface AppointmentCreateOrConnectWithoutSalesRepInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutSalesRepInput;
}

export interface AppointmentCreateOrConnectWithoutSetterInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutSetterInput;
}

export interface AppointmentCreateWithoutAssignedToInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  leadInfo?: LeadCreateNestedOneWithoutAppointmentInput | null;
  contact?: ContactCreateNestedOneWithoutAppointmentInput | null;
  setter?: SetterCreateNestedOneWithoutAppointmentInput | null;
  activity?: ActivityCreateNestedManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutSetAppointmentsInput | null;
  Deal?: DealCreateNestedOneWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutContactInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  assignedTo?: SalesRepCreateNestedOneWithoutAppointmentInput | null;
  leadInfo?: LeadCreateNestedOneWithoutAppointmentInput | null;
  setter?: SetterCreateNestedOneWithoutAppointmentInput | null;
  activity?: ActivityCreateNestedManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutSetAppointmentsInput | null;
  Deal?: DealCreateNestedOneWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutDealInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  assignedTo?: SalesRepCreateNestedOneWithoutAppointmentInput | null;
  leadInfo?: LeadCreateNestedOneWithoutAppointmentInput | null;
  contact?: ContactCreateNestedOneWithoutAppointmentInput | null;
  setter?: SetterCreateNestedOneWithoutAppointmentInput | null;
  activity?: ActivityCreateNestedManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutSetAppointmentsInput | null;
}

export interface AppointmentCreateWithoutLeadInfoInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  assignedTo?: SalesRepCreateNestedOneWithoutAppointmentInput | null;
  contact?: ContactCreateNestedOneWithoutAppointmentInput | null;
  setter?: SetterCreateNestedOneWithoutAppointmentInput | null;
  activity?: ActivityCreateNestedManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutSetAppointmentsInput | null;
  Deal?: DealCreateNestedOneWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutSalesRepInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  assignedTo?: SalesRepCreateNestedOneWithoutAppointmentInput | null;
  leadInfo?: LeadCreateNestedOneWithoutAppointmentInput | null;
  contact?: ContactCreateNestedOneWithoutAppointmentInput | null;
  setter?: SetterCreateNestedOneWithoutAppointmentInput | null;
  activity?: ActivityCreateNestedManyWithoutAppointmentInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutSetAppointmentsInput | null;
  Deal?: DealCreateNestedOneWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutSetterInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bookingRef?: string | null;
  startDate?: any | null;
  team?: string | null;
  title?: string | null;
  summary?: string | null;
  notes?: string | null;
  status?: string | null;
  provider?: string | null;
  assignedTo?: SalesRepCreateNestedOneWithoutAppointmentInput | null;
  leadInfo?: LeadCreateNestedOneWithoutAppointmentInput | null;
  contact?: ContactCreateNestedOneWithoutAppointmentInput | null;
  setter?: SetterCreateNestedOneWithoutAppointmentInput | null;
  activity?: ActivityCreateNestedManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutAssignedAppointmentsInput | null;
  Deal?: DealCreateNestedOneWithoutAppointmentInput | null;
}

export interface AppointmentListRelationFilter {
  every?: AppointmentWhereInput | null;
  some?: AppointmentWhereInput | null;
  none?: AppointmentWhereInput | null;
}

export interface AppointmentScalarWhereInput {
  AND?: AppointmentScalarWhereInput[] | null;
  OR?: AppointmentScalarWhereInput[] | null;
  NOT?: AppointmentScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  bookingRef?: StringNullableFilter | null;
  startDate?: DateTimeNullableFilter | null;
  team?: StringNullableFilter | null;
  title?: StringNullableFilter | null;
  summary?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  status?: StringNullableFilter | null;
  provider?: StringNullableFilter | null;
  leadId?: IntNullableFilter | null;
  contactId?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  setterId?: IntNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
}

export interface AppointmentUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bookingRef?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  summary?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
}

export interface AppointmentUpdateManyWithWhereWithoutAssignedToInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutContactInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutLeadInfoInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutSalesRepInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutSetterInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithoutAssignedToInput {
  create?: AppointmentCreateWithoutAssignedToInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAssignedToInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutAssignedToInput[] | null;
  createMany?: AppointmentCreateManyAssignedToInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutAssignedToInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutAssignedToInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutContactInput {
  create?: AppointmentCreateWithoutContactInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutContactInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutContactInput[] | null;
  createMany?: AppointmentCreateManyContactInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutContactInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutContactInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutLeadInfoInput {
  create?: AppointmentCreateWithoutLeadInfoInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutLeadInfoInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutLeadInfoInput[] | null;
  createMany?: AppointmentCreateManyLeadInfoInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutLeadInfoInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutLeadInfoInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutSalesRepInput {
  create?: AppointmentCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: AppointmentCreateManySalesRepInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutSetterInput {
  create?: AppointmentCreateWithoutSetterInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutSetterInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutSetterInput[] | null;
  createMany?: AppointmentCreateManySetterInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutSetterInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutSetterInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateOneWithoutDealInput {
  create?: AppointmentCreateWithoutDealInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutDealInput | null;
  upsert?: AppointmentUpsertWithoutDealInput | null;
  connect?: AppointmentWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: AppointmentUpdateWithoutDealInput | null;
}

export interface AppointmentUpdateWithWhereUniqueWithoutAssignedToInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutAssignedToInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutContactInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutContactInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutLeadInfoInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutLeadInfoInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutSalesRepInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutSalesRepInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutSetterInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutSetterInput;
}

export interface AppointmentUpdateWithoutAssignedToInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bookingRef?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  summary?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  leadInfo?: LeadUpdateOneWithoutAppointmentInput | null;
  contact?: ContactUpdateOneWithoutAppointmentInput | null;
  setter?: SetterUpdateOneWithoutAppointmentInput | null;
  activity?: ActivityUpdateManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutSetAppointmentsInput | null;
  Deal?: DealUpdateOneWithoutAppointmentInput | null;
}

export interface AppointmentUpdateWithoutContactInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bookingRef?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  summary?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  assignedTo?: SalesRepUpdateOneWithoutAppointmentInput | null;
  leadInfo?: LeadUpdateOneWithoutAppointmentInput | null;
  setter?: SetterUpdateOneWithoutAppointmentInput | null;
  activity?: ActivityUpdateManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutSetAppointmentsInput | null;
  Deal?: DealUpdateOneWithoutAppointmentInput | null;
}

export interface AppointmentUpdateWithoutDealInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bookingRef?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  summary?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  assignedTo?: SalesRepUpdateOneWithoutAppointmentInput | null;
  leadInfo?: LeadUpdateOneWithoutAppointmentInput | null;
  contact?: ContactUpdateOneWithoutAppointmentInput | null;
  setter?: SetterUpdateOneWithoutAppointmentInput | null;
  activity?: ActivityUpdateManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutSetAppointmentsInput | null;
}

export interface AppointmentUpdateWithoutLeadInfoInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bookingRef?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  summary?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  assignedTo?: SalesRepUpdateOneWithoutAppointmentInput | null;
  contact?: ContactUpdateOneWithoutAppointmentInput | null;
  setter?: SetterUpdateOneWithoutAppointmentInput | null;
  activity?: ActivityUpdateManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutAssignedAppointmentsInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutSetAppointmentsInput | null;
  Deal?: DealUpdateOneWithoutAppointmentInput | null;
}

export interface AppointmentUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bookingRef?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  summary?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  assignedTo?: SalesRepUpdateOneWithoutAppointmentInput | null;
  leadInfo?: LeadUpdateOneWithoutAppointmentInput | null;
  contact?: ContactUpdateOneWithoutAppointmentInput | null;
  setter?: SetterUpdateOneWithoutAppointmentInput | null;
  activity?: ActivityUpdateManyWithoutAppointmentInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutSetAppointmentsInput | null;
  Deal?: DealUpdateOneWithoutAppointmentInput | null;
}

export interface AppointmentUpdateWithoutSetterInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bookingRef?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  summary?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  assignedTo?: SalesRepUpdateOneWithoutAppointmentInput | null;
  leadInfo?: LeadUpdateOneWithoutAppointmentInput | null;
  contact?: ContactUpdateOneWithoutAppointmentInput | null;
  setter?: SetterUpdateOneWithoutAppointmentInput | null;
  activity?: ActivityUpdateManyWithoutAppointmentInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutAssignedAppointmentsInput | null;
  Deal?: DealUpdateOneWithoutAppointmentInput | null;
}

export interface AppointmentUpsertWithWhereUniqueWithoutAssignedToInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutAssignedToInput;
  create: AppointmentCreateWithoutAssignedToInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutContactInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutContactInput;
  create: AppointmentCreateWithoutContactInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutLeadInfoInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutLeadInfoInput;
  create: AppointmentCreateWithoutLeadInfoInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutSalesRepInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutSalesRepInput;
  create: AppointmentCreateWithoutSalesRepInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutSetterInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutSetterInput;
  create: AppointmentCreateWithoutSetterInput;
}

export interface AppointmentUpsertWithoutDealInput {
  update: AppointmentUpdateWithoutDealInput;
  create: AppointmentCreateWithoutDealInput;
}

export interface AppointmentWhereInput {
  AND?: AppointmentWhereInput[] | null;
  OR?: AppointmentWhereInput[] | null;
  NOT?: AppointmentWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  bookingRef?: StringNullableFilter | null;
  startDate?: DateTimeNullableFilter | null;
  team?: StringNullableFilter | null;
  title?: StringNullableFilter | null;
  summary?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  status?: StringNullableFilter | null;
  provider?: StringNullableFilter | null;
  assignedTo?: SalesRepWhereInput | null;
  leadInfo?: LeadWhereInput | null;
  contact?: ContactWhereInput | null;
  setter?: SetterWhereInput | null;
  activity?: ActivityListRelationFilter | null;
  leadId?: IntNullableFilter | null;
  contactId?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  setterId?: IntNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  Setter?: SalesEmployeeWhereInput | null;
  Deal?: DealWhereInput | null;
}

export interface AppointmentWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  leadId?: number | null;
}

export interface BoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface ContactCreateManySalesRepInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  salesEmployeeId_Setter?: number | null;
  region_?: ContactCreateManyregion_Input | null;
  leadSourceType_?: ContactCreateManyleadSourceType_Input | null;
}

export interface ContactCreateManySalesRepInputEnvelope {
  data?: ContactCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface ContactCreateManySetterInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  salesEmployeeId_SalesRep?: number | null;
  region_?: ContactCreateManyregion_Input | null;
  leadSourceType_?: ContactCreateManyleadSourceType_Input | null;
}

export interface ContactCreateManySetterInputEnvelope {
  data?: ContactCreateManySetterInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface ContactCreateManyleadSourceType_Input {
  set?: string[] | null;
}

export interface ContactCreateManyregion_Input {
  set?: string[] | null;
}

export interface ContactCreateNestedManyWithoutSalesRepInput {
  create?: ContactCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: ContactCreateManySalesRepInputEnvelope | null;
  connect?: ContactWhereUniqueInput[] | null;
}

export interface ContactCreateNestedManyWithoutSetterInput {
  create?: ContactCreateWithoutSetterInput[] | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSetterInput[] | null;
  createMany?: ContactCreateManySetterInputEnvelope | null;
  connect?: ContactWhereUniqueInput[] | null;
}

export interface ContactCreateNestedOneWithoutAppointmentInput {
  create?: ContactCreateWithoutAppointmentInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutAppointmentInput | null;
  connect?: ContactWhereUniqueInput | null;
}

export interface ContactCreateNestedOneWithoutCustomerInput {
  create?: ContactCreateWithoutCustomerInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutCustomerInput | null;
  connect?: ContactWhereUniqueInput | null;
}

export interface ContactCreateNestedOneWithoutLeadInput {
  create?: ContactCreateWithoutLeadInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutLeadInput | null;
  connect?: ContactWhereUniqueInput | null;
}

export interface ContactCreateNestedOneWithoutMasterCustomerInput {
  create?: ContactCreateWithoutMasterCustomerInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutMasterCustomerInput | null;
  connect?: ContactWhereUniqueInput | null;
}

export interface ContactCreateNestedOneWithoutSalesRepInput {
  create?: ContactCreateWithoutSalesRepInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSalesRepInput | null;
  connect?: ContactWhereUniqueInput | null;
}

export interface ContactCreateNestedOneWithoutSetterInput {
  create?: ContactCreateWithoutSetterInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSetterInput | null;
  connect?: ContactWhereUniqueInput | null;
}

export interface ContactCreateOrConnectWithoutAppointmentInput {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutAppointmentInput;
}

export interface ContactCreateOrConnectWithoutCustomerInput {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutCustomerInput;
}

export interface ContactCreateOrConnectWithoutLeadInput {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutLeadInput;
}

export interface ContactCreateOrConnectWithoutMasterCustomerInput {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutMasterCustomerInput;
}

export interface ContactCreateOrConnectWithoutSalesRepInput {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutSalesRepInput;
}

export interface ContactCreateOrConnectWithoutSetterInput {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutSetterInput;
}

export interface ContactCreateWithoutAppointmentInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  region_?: ContactCreateregion_Input | null;
  leadSourceType_?: ContactCreateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutContactsAsSetterInput | null;
  customer?: CustomerCreateNestedManyWithoutContactInput | null;
  salesRep?: SalesRepCreateNestedManyWithoutContactInput | null;
  setter?: SetterCreateNestedManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerCreateNestedManyWithoutContactInput | null;
  Lead?: LeadCreateNestedManyWithoutContactInput | null;
}

export interface ContactCreateWithoutCustomerInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  region_?: ContactCreateregion_Input | null;
  leadSourceType_?: ContactCreateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutContactsAsSetterInput | null;
  salesRep?: SalesRepCreateNestedManyWithoutContactInput | null;
  setter?: SetterCreateNestedManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerCreateNestedManyWithoutContactInput | null;
  Lead?: LeadCreateNestedManyWithoutContactInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutContactInput | null;
}

export interface ContactCreateWithoutLeadInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  region_?: ContactCreateregion_Input | null;
  leadSourceType_?: ContactCreateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutContactsAsSetterInput | null;
  customer?: CustomerCreateNestedManyWithoutContactInput | null;
  salesRep?: SalesRepCreateNestedManyWithoutContactInput | null;
  setter?: SetterCreateNestedManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerCreateNestedManyWithoutContactInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutContactInput | null;
}

export interface ContactCreateWithoutMasterCustomerInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  region_?: ContactCreateregion_Input | null;
  leadSourceType_?: ContactCreateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutContactsAsSetterInput | null;
  customer?: CustomerCreateNestedManyWithoutContactInput | null;
  salesRep?: SalesRepCreateNestedManyWithoutContactInput | null;
  setter?: SetterCreateNestedManyWithoutContactInput | null;
  Lead?: LeadCreateNestedManyWithoutContactInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutContactInput | null;
}

export interface ContactCreateWithoutSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  region_?: ContactCreateregion_Input | null;
  leadSourceType_?: ContactCreateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutContactsAsSetterInput | null;
  customer?: CustomerCreateNestedManyWithoutContactInput | null;
  setter?: SetterCreateNestedManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerCreateNestedManyWithoutContactInput | null;
  Lead?: LeadCreateNestedManyWithoutContactInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutContactInput | null;
}

export interface ContactCreateWithoutSetterInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phonePersonal?: string | null;
  phoneWork?: string | null;
  emailPersonal?: string | null;
  emailWork?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  notes?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  setterLeadPaidDate?: any | null;
  setterManagerPaidDate?: any | null;
  region_?: ContactCreateregion_Input | null;
  leadSourceType_?: ContactCreateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutContactsAsSalesRepInput | null;
  customer?: CustomerCreateNestedManyWithoutContactInput | null;
  salesRep?: SalesRepCreateNestedManyWithoutContactInput | null;
  setter?: SetterCreateNestedManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerCreateNestedManyWithoutContactInput | null;
  Lead?: LeadCreateNestedManyWithoutContactInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutContactInput | null;
}

export interface ContactCreateleadSourceType_Input {
  set?: string[] | null;
}

export interface ContactCreateregion_Input {
  set?: string[] | null;
}

export interface ContactListRelationFilter {
  every?: ContactWhereInput | null;
  some?: ContactWhereInput | null;
  none?: ContactWhereInput | null;
}

export interface ContactScalarWhereInput {
  AND?: ContactScalarWhereInput[] | null;
  OR?: ContactScalarWhereInput[] | null;
  NOT?: ContactScalarWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  team?: StringNullableFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  name?: StringNullableFilter | null;
  firstName?: StringNullableFilter | null;
  lastName?: StringNullableFilter | null;
  phonePersonal?: StringNullableFilter | null;
  phoneWork?: StringNullableFilter | null;
  emailPersonal?: StringNullableFilter | null;
  emailWork?: StringNullableFilter | null;
  formattedAddress?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  state?: StringNullableFilter | null;
  country?: StringNullableFilter | null;
  postalCode?: StringNullableFilter | null;
  lat?: DecimalNullableFilter | null;
  lng?: DecimalNullableFilter | null;
  notes?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  region_?: StringNullableListFilter | null;
  leadSourceType?: StringNullableFilter | null;
  leadSourceType_?: StringNullableListFilter | null;
  setterLeadPaidDate?: DateTimeNullableFilter | null;
  setterManagerPaidDate?: DateTimeNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
}

export interface ContactUpdateManyMutationInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phonePersonal?: NullableStringFieldUpdateOperationsInput | null;
  phoneWork?: NullableStringFieldUpdateOperationsInput | null;
  emailPersonal?: NullableStringFieldUpdateOperationsInput | null;
  emailWork?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  setterLeadPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterManagerPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: ContactUpdateregion_Input | null;
  leadSourceType_?: ContactUpdateleadSourceType_Input | null;
}

export interface ContactUpdateManyWithWhereWithoutSalesRepInput {
  where: ContactScalarWhereInput;
  data: ContactUpdateManyMutationInput;
}

export interface ContactUpdateManyWithWhereWithoutSetterInput {
  where: ContactScalarWhereInput;
  data: ContactUpdateManyMutationInput;
}

export interface ContactUpdateManyWithoutSalesRepInput {
  create?: ContactCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: ContactUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: ContactCreateManySalesRepInputEnvelope | null;
  connect?: ContactWhereUniqueInput[] | null;
  set?: ContactWhereUniqueInput[] | null;
  disconnect?: ContactWhereUniqueInput[] | null;
  delete?: ContactWhereUniqueInput[] | null;
  update?: ContactUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: ContactUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: ContactScalarWhereInput[] | null;
}

export interface ContactUpdateManyWithoutSetterInput {
  create?: ContactCreateWithoutSetterInput[] | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSetterInput[] | null;
  upsert?: ContactUpsertWithWhereUniqueWithoutSetterInput[] | null;
  createMany?: ContactCreateManySetterInputEnvelope | null;
  connect?: ContactWhereUniqueInput[] | null;
  set?: ContactWhereUniqueInput[] | null;
  disconnect?: ContactWhereUniqueInput[] | null;
  delete?: ContactWhereUniqueInput[] | null;
  update?: ContactUpdateWithWhereUniqueWithoutSetterInput[] | null;
  updateMany?: ContactUpdateManyWithWhereWithoutSetterInput[] | null;
  deleteMany?: ContactScalarWhereInput[] | null;
}

export interface ContactUpdateOneWithoutAppointmentInput {
  create?: ContactCreateWithoutAppointmentInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutAppointmentInput | null;
  upsert?: ContactUpsertWithoutAppointmentInput | null;
  connect?: ContactWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ContactUpdateWithoutAppointmentInput | null;
}

export interface ContactUpdateOneWithoutCustomerInput {
  create?: ContactCreateWithoutCustomerInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutCustomerInput | null;
  upsert?: ContactUpsertWithoutCustomerInput | null;
  connect?: ContactWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ContactUpdateWithoutCustomerInput | null;
}

export interface ContactUpdateOneWithoutLeadInput {
  create?: ContactCreateWithoutLeadInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutLeadInput | null;
  upsert?: ContactUpsertWithoutLeadInput | null;
  connect?: ContactWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ContactUpdateWithoutLeadInput | null;
}

export interface ContactUpdateOneWithoutMasterCustomerInput {
  create?: ContactCreateWithoutMasterCustomerInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutMasterCustomerInput | null;
  upsert?: ContactUpsertWithoutMasterCustomerInput | null;
  connect?: ContactWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ContactUpdateWithoutMasterCustomerInput | null;
}

export interface ContactUpdateOneWithoutSalesRepInput {
  create?: ContactCreateWithoutSalesRepInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSalesRepInput | null;
  upsert?: ContactUpsertWithoutSalesRepInput | null;
  connect?: ContactWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ContactUpdateWithoutSalesRepInput | null;
}

export interface ContactUpdateOneWithoutSetterInput {
  create?: ContactCreateWithoutSetterInput | null;
  connectOrCreate?: ContactCreateOrConnectWithoutSetterInput | null;
  upsert?: ContactUpsertWithoutSetterInput | null;
  connect?: ContactWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ContactUpdateWithoutSetterInput | null;
}

export interface ContactUpdateWithWhereUniqueWithoutSalesRepInput {
  where: ContactWhereUniqueInput;
  data: ContactUpdateWithoutSalesRepInput;
}

export interface ContactUpdateWithWhereUniqueWithoutSetterInput {
  where: ContactWhereUniqueInput;
  data: ContactUpdateWithoutSetterInput;
}

export interface ContactUpdateWithoutAppointmentInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phonePersonal?: NullableStringFieldUpdateOperationsInput | null;
  phoneWork?: NullableStringFieldUpdateOperationsInput | null;
  emailPersonal?: NullableStringFieldUpdateOperationsInput | null;
  emailWork?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  setterLeadPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterManagerPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: ContactUpdateregion_Input | null;
  leadSourceType_?: ContactUpdateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutContactsAsSetterInput | null;
  customer?: CustomerUpdateManyWithoutContactInput | null;
  salesRep?: SalesRepUpdateManyWithoutContactInput | null;
  setter?: SetterUpdateManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerUpdateManyWithoutContactInput | null;
  Lead?: LeadUpdateManyWithoutContactInput | null;
}

export interface ContactUpdateWithoutCustomerInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phonePersonal?: NullableStringFieldUpdateOperationsInput | null;
  phoneWork?: NullableStringFieldUpdateOperationsInput | null;
  emailPersonal?: NullableStringFieldUpdateOperationsInput | null;
  emailWork?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  setterLeadPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterManagerPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: ContactUpdateregion_Input | null;
  leadSourceType_?: ContactUpdateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutContactsAsSetterInput | null;
  salesRep?: SalesRepUpdateManyWithoutContactInput | null;
  setter?: SetterUpdateManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerUpdateManyWithoutContactInput | null;
  Lead?: LeadUpdateManyWithoutContactInput | null;
  Appointment?: AppointmentUpdateManyWithoutContactInput | null;
}

export interface ContactUpdateWithoutLeadInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phonePersonal?: NullableStringFieldUpdateOperationsInput | null;
  phoneWork?: NullableStringFieldUpdateOperationsInput | null;
  emailPersonal?: NullableStringFieldUpdateOperationsInput | null;
  emailWork?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  setterLeadPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterManagerPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: ContactUpdateregion_Input | null;
  leadSourceType_?: ContactUpdateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutContactsAsSetterInput | null;
  customer?: CustomerUpdateManyWithoutContactInput | null;
  salesRep?: SalesRepUpdateManyWithoutContactInput | null;
  setter?: SetterUpdateManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerUpdateManyWithoutContactInput | null;
  Appointment?: AppointmentUpdateManyWithoutContactInput | null;
}

export interface ContactUpdateWithoutMasterCustomerInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phonePersonal?: NullableStringFieldUpdateOperationsInput | null;
  phoneWork?: NullableStringFieldUpdateOperationsInput | null;
  emailPersonal?: NullableStringFieldUpdateOperationsInput | null;
  emailWork?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  setterLeadPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterManagerPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: ContactUpdateregion_Input | null;
  leadSourceType_?: ContactUpdateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutContactsAsSetterInput | null;
  customer?: CustomerUpdateManyWithoutContactInput | null;
  salesRep?: SalesRepUpdateManyWithoutContactInput | null;
  setter?: SetterUpdateManyWithoutContactInput | null;
  Lead?: LeadUpdateManyWithoutContactInput | null;
  Appointment?: AppointmentUpdateManyWithoutContactInput | null;
}

export interface ContactUpdateWithoutSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phonePersonal?: NullableStringFieldUpdateOperationsInput | null;
  phoneWork?: NullableStringFieldUpdateOperationsInput | null;
  emailPersonal?: NullableStringFieldUpdateOperationsInput | null;
  emailWork?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  setterLeadPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterManagerPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: ContactUpdateregion_Input | null;
  leadSourceType_?: ContactUpdateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutContactsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutContactsAsSetterInput | null;
  customer?: CustomerUpdateManyWithoutContactInput | null;
  setter?: SetterUpdateManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerUpdateManyWithoutContactInput | null;
  Lead?: LeadUpdateManyWithoutContactInput | null;
  Appointment?: AppointmentUpdateManyWithoutContactInput | null;
}

export interface ContactUpdateWithoutSetterInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phonePersonal?: NullableStringFieldUpdateOperationsInput | null;
  phoneWork?: NullableStringFieldUpdateOperationsInput | null;
  emailPersonal?: NullableStringFieldUpdateOperationsInput | null;
  emailWork?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  setterLeadPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterManagerPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: ContactUpdateregion_Input | null;
  leadSourceType_?: ContactUpdateleadSourceType_Input | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutContactsAsSalesRepInput | null;
  customer?: CustomerUpdateManyWithoutContactInput | null;
  salesRep?: SalesRepUpdateManyWithoutContactInput | null;
  setter?: SetterUpdateManyWithoutContactInput | null;
  MasterCustomer?: MasterCustomerUpdateManyWithoutContactInput | null;
  Lead?: LeadUpdateManyWithoutContactInput | null;
  Appointment?: AppointmentUpdateManyWithoutContactInput | null;
}

export interface ContactUpdateleadSourceType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ContactUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ContactUpsertWithWhereUniqueWithoutSalesRepInput {
  where: ContactWhereUniqueInput;
  update: ContactUpdateWithoutSalesRepInput;
  create: ContactCreateWithoutSalesRepInput;
}

export interface ContactUpsertWithWhereUniqueWithoutSetterInput {
  where: ContactWhereUniqueInput;
  update: ContactUpdateWithoutSetterInput;
  create: ContactCreateWithoutSetterInput;
}

export interface ContactUpsertWithoutAppointmentInput {
  update: ContactUpdateWithoutAppointmentInput;
  create: ContactCreateWithoutAppointmentInput;
}

export interface ContactUpsertWithoutCustomerInput {
  update: ContactUpdateWithoutCustomerInput;
  create: ContactCreateWithoutCustomerInput;
}

export interface ContactUpsertWithoutLeadInput {
  update: ContactUpdateWithoutLeadInput;
  create: ContactCreateWithoutLeadInput;
}

export interface ContactUpsertWithoutMasterCustomerInput {
  update: ContactUpdateWithoutMasterCustomerInput;
  create: ContactCreateWithoutMasterCustomerInput;
}

export interface ContactUpsertWithoutSalesRepInput {
  update: ContactUpdateWithoutSalesRepInput;
  create: ContactCreateWithoutSalesRepInput;
}

export interface ContactUpsertWithoutSetterInput {
  update: ContactUpdateWithoutSetterInput;
  create: ContactCreateWithoutSetterInput;
}

export interface ContactWhereInput {
  AND?: ContactWhereInput[] | null;
  OR?: ContactWhereInput[] | null;
  NOT?: ContactWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  team?: StringNullableFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  name?: StringNullableFilter | null;
  firstName?: StringNullableFilter | null;
  lastName?: StringNullableFilter | null;
  phonePersonal?: StringNullableFilter | null;
  phoneWork?: StringNullableFilter | null;
  emailPersonal?: StringNullableFilter | null;
  emailWork?: StringNullableFilter | null;
  formattedAddress?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  state?: StringNullableFilter | null;
  country?: StringNullableFilter | null;
  postalCode?: StringNullableFilter | null;
  lat?: DecimalNullableFilter | null;
  lng?: DecimalNullableFilter | null;
  notes?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  region_?: StringNullableListFilter | null;
  leadSourceType?: StringNullableFilter | null;
  leadSourceType_?: StringNullableListFilter | null;
  setterLeadPaidDate?: DateTimeNullableFilter | null;
  setterManagerPaidDate?: DateTimeNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  Setter?: SalesEmployeeWhereInput | null;
  customer?: CustomerListRelationFilter | null;
  salesRep?: SalesRepListRelationFilter | null;
  setter?: SetterListRelationFilter | null;
  MasterCustomer?: MasterCustomerListRelationFilter | null;
  Lead?: LeadListRelationFilter | null;
  Appointment?: AppointmentListRelationFilter | null;
}

export interface ContactWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
}

export interface CrumbCreateManySetterInput {
  id?: number | null;
  lat: number;
  lng: number;
  timeStamp: any;
  timeSheetId: number;
}

export interface CrumbCreateManySetterInputEnvelope {
  data?: CrumbCreateManySetterInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CrumbCreateManyTimeSheetInput {
  id?: number | null;
  lat: number;
  lng: number;
  timeStamp: any;
  setterId?: number | null;
}

export interface CrumbCreateManyTimeSheetInputEnvelope {
  data?: CrumbCreateManyTimeSheetInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CrumbCreateNestedManyWithoutSetterInput {
  create?: CrumbCreateWithoutSetterInput[] | null;
  connectOrCreate?: CrumbCreateOrConnectWithoutSetterInput[] | null;
  createMany?: CrumbCreateManySetterInputEnvelope | null;
  connect?: CrumbWhereUniqueInput[] | null;
}

export interface CrumbCreateNestedManyWithoutTimeSheetInput {
  create?: CrumbCreateWithoutTimeSheetInput[] | null;
  connectOrCreate?: CrumbCreateOrConnectWithoutTimeSheetInput[] | null;
  createMany?: CrumbCreateManyTimeSheetInputEnvelope | null;
  connect?: CrumbWhereUniqueInput[] | null;
}

export interface CrumbCreateOrConnectWithoutSetterInput {
  where: CrumbWhereUniqueInput;
  create: CrumbCreateWithoutSetterInput;
}

export interface CrumbCreateOrConnectWithoutTimeSheetInput {
  where: CrumbWhereUniqueInput;
  create: CrumbCreateWithoutTimeSheetInput;
}

export interface CrumbCreateWithoutSetterInput {
  lat: number;
  lng: number;
  timeStamp: any;
  timeSheet: TimeSheetCreateNestedOneWithoutCrumbsInput;
}

export interface CrumbCreateWithoutTimeSheetInput {
  lat: number;
  lng: number;
  timeStamp: any;
  setter?: SetterCreateNestedOneWithoutCrumbInput | null;
}

export interface CrumbListRelationFilter {
  every?: CrumbWhereInput | null;
  some?: CrumbWhereInput | null;
  none?: CrumbWhereInput | null;
}

export interface CrumbScalarWhereInput {
  AND?: CrumbScalarWhereInput[] | null;
  OR?: CrumbScalarWhereInput[] | null;
  NOT?: CrumbScalarWhereInput[] | null;
  id?: IntFilter | null;
  lat?: FloatFilter | null;
  lng?: FloatFilter | null;
  timeStamp?: DateTimeFilter | null;
  setterId?: IntNullableFilter | null;
  timeSheetId?: IntFilter | null;
}

export interface CrumbUpdateManyMutationInput {
  lat?: FloatFieldUpdateOperationsInput | null;
  lng?: FloatFieldUpdateOperationsInput | null;
  timeStamp?: DateTimeFieldUpdateOperationsInput | null;
}

export interface CrumbUpdateManyWithWhereWithoutSetterInput {
  where: CrumbScalarWhereInput;
  data: CrumbUpdateManyMutationInput;
}

export interface CrumbUpdateManyWithWhereWithoutTimeSheetInput {
  where: CrumbScalarWhereInput;
  data: CrumbUpdateManyMutationInput;
}

export interface CrumbUpdateManyWithoutSetterInput {
  create?: CrumbCreateWithoutSetterInput[] | null;
  connectOrCreate?: CrumbCreateOrConnectWithoutSetterInput[] | null;
  upsert?: CrumbUpsertWithWhereUniqueWithoutSetterInput[] | null;
  createMany?: CrumbCreateManySetterInputEnvelope | null;
  connect?: CrumbWhereUniqueInput[] | null;
  set?: CrumbWhereUniqueInput[] | null;
  disconnect?: CrumbWhereUniqueInput[] | null;
  delete?: CrumbWhereUniqueInput[] | null;
  update?: CrumbUpdateWithWhereUniqueWithoutSetterInput[] | null;
  updateMany?: CrumbUpdateManyWithWhereWithoutSetterInput[] | null;
  deleteMany?: CrumbScalarWhereInput[] | null;
}

export interface CrumbUpdateManyWithoutTimeSheetInput {
  create?: CrumbCreateWithoutTimeSheetInput[] | null;
  connectOrCreate?: CrumbCreateOrConnectWithoutTimeSheetInput[] | null;
  upsert?: CrumbUpsertWithWhereUniqueWithoutTimeSheetInput[] | null;
  createMany?: CrumbCreateManyTimeSheetInputEnvelope | null;
  connect?: CrumbWhereUniqueInput[] | null;
  set?: CrumbWhereUniqueInput[] | null;
  disconnect?: CrumbWhereUniqueInput[] | null;
  delete?: CrumbWhereUniqueInput[] | null;
  update?: CrumbUpdateWithWhereUniqueWithoutTimeSheetInput[] | null;
  updateMany?: CrumbUpdateManyWithWhereWithoutTimeSheetInput[] | null;
  deleteMany?: CrumbScalarWhereInput[] | null;
}

export interface CrumbUpdateWithWhereUniqueWithoutSetterInput {
  where: CrumbWhereUniqueInput;
  data: CrumbUpdateWithoutSetterInput;
}

export interface CrumbUpdateWithWhereUniqueWithoutTimeSheetInput {
  where: CrumbWhereUniqueInput;
  data: CrumbUpdateWithoutTimeSheetInput;
}

export interface CrumbUpdateWithoutSetterInput {
  lat?: FloatFieldUpdateOperationsInput | null;
  lng?: FloatFieldUpdateOperationsInput | null;
  timeStamp?: DateTimeFieldUpdateOperationsInput | null;
  timeSheet?: TimeSheetUpdateOneRequiredWithoutCrumbsInput | null;
}

export interface CrumbUpdateWithoutTimeSheetInput {
  lat?: FloatFieldUpdateOperationsInput | null;
  lng?: FloatFieldUpdateOperationsInput | null;
  timeStamp?: DateTimeFieldUpdateOperationsInput | null;
  setter?: SetterUpdateOneWithoutCrumbInput | null;
}

export interface CrumbUpsertWithWhereUniqueWithoutSetterInput {
  where: CrumbWhereUniqueInput;
  update: CrumbUpdateWithoutSetterInput;
  create: CrumbCreateWithoutSetterInput;
}

export interface CrumbUpsertWithWhereUniqueWithoutTimeSheetInput {
  where: CrumbWhereUniqueInput;
  update: CrumbUpdateWithoutTimeSheetInput;
  create: CrumbCreateWithoutTimeSheetInput;
}

export interface CrumbWhereInput {
  AND?: CrumbWhereInput[] | null;
  OR?: CrumbWhereInput[] | null;
  NOT?: CrumbWhereInput[] | null;
  id?: IntFilter | null;
  setter?: SetterWhereInput | null;
  timeSheet?: TimeSheetWhereInput | null;
  lat?: FloatFilter | null;
  lng?: FloatFilter | null;
  timeStamp?: DateTimeFilter | null;
  setterId?: IntNullableFilter | null;
  timeSheetId?: IntFilter | null;
}

export interface CrumbWhereUniqueInput {
  id?: number | null;
}

export interface CustomerCreateManyContactInput {
  id?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  addressCity?: string | null;
  mapInSync?: boolean | null;
  addressStreetAddress?: string | null;
  addressStreetNumber?: string | null;
  addressCountry?: string | null;
  addressStreetName?: string | null;
  addressState?: string | null;
  addressPostalCode?: string | null;
  lat?: number | null;
  lng?: number | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  region?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  closer?: string | null;
  team?: team_type | null;
  locationId?: number | null;
  fullName?: string | null;
  masterCustomerId?: number | null;
}

export interface CustomerCreateManyContactInputEnvelope {
  data?: CustomerCreateManyContactInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CustomerCreateManyLocationInput {
  id?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  addressCity?: string | null;
  mapInSync?: boolean | null;
  addressStreetAddress?: string | null;
  addressStreetNumber?: string | null;
  addressCountry?: string | null;
  addressStreetName?: string | null;
  addressState?: string | null;
  addressPostalCode?: string | null;
  lat?: number | null;
  lng?: number | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  region?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  closer?: string | null;
  team?: team_type | null;
  contactId?: number | null;
  fullName?: string | null;
  masterCustomerId?: number | null;
}

export interface CustomerCreateManyLocationInputEnvelope {
  data?: CustomerCreateManyLocationInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CustomerCreateNestedManyWithoutContactInput {
  create?: CustomerCreateWithoutContactInput[] | null;
  connectOrCreate?: CustomerCreateOrConnectWithoutContactInput[] | null;
  createMany?: CustomerCreateManyContactInputEnvelope | null;
  connect?: CustomerWhereUniqueInput[] | null;
}

export interface CustomerCreateNestedManyWithoutLocationInput {
  create?: CustomerCreateWithoutLocationInput[] | null;
  connectOrCreate?: CustomerCreateOrConnectWithoutLocationInput[] | null;
  createMany?: CustomerCreateManyLocationInputEnvelope | null;
  connect?: CustomerWhereUniqueInput[] | null;
}

export interface CustomerCreateNestedOneWithoutMasterCustomerInput {
  create?: CustomerCreateWithoutMasterCustomerInput | null;
  connectOrCreate?: CustomerCreateOrConnectWithoutMasterCustomerInput | null;
  connect?: CustomerWhereUniqueInput | null;
}

export interface CustomerCreateOrConnectWithoutContactInput {
  where: CustomerWhereUniqueInput;
  create: CustomerCreateWithoutContactInput;
}

export interface CustomerCreateOrConnectWithoutLocationInput {
  where: CustomerWhereUniqueInput;
  create: CustomerCreateWithoutLocationInput;
}

export interface CustomerCreateOrConnectWithoutMasterCustomerInput {
  where: CustomerWhereUniqueInput;
  create: CustomerCreateWithoutMasterCustomerInput;
}

export interface CustomerCreateWithoutContactInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  addressCity?: string | null;
  mapInSync?: boolean | null;
  addressStreetAddress?: string | null;
  addressStreetNumber?: string | null;
  addressCountry?: string | null;
  addressStreetName?: string | null;
  addressState?: string | null;
  addressPostalCode?: string | null;
  lat?: number | null;
  lng?: number | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  region?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  closer?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  location?: LocationCreateNestedOneWithoutCustomerInput | null;
  masterCustomer?: MasterCustomerCreateNestedOneWithoutCustomerInput | null;
}

export interface CustomerCreateWithoutLocationInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  addressCity?: string | null;
  mapInSync?: boolean | null;
  addressStreetAddress?: string | null;
  addressStreetNumber?: string | null;
  addressCountry?: string | null;
  addressStreetName?: string | null;
  addressState?: string | null;
  addressPostalCode?: string | null;
  lat?: number | null;
  lng?: number | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  region?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  closer?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  contact?: ContactCreateNestedOneWithoutCustomerInput | null;
  masterCustomer?: MasterCustomerCreateNestedOneWithoutCustomerInput | null;
}

export interface CustomerCreateWithoutMasterCustomerInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  addressCity?: string | null;
  mapInSync?: boolean | null;
  addressStreetAddress?: string | null;
  addressStreetNumber?: string | null;
  addressCountry?: string | null;
  addressStreetName?: string | null;
  addressState?: string | null;
  addressPostalCode?: string | null;
  lat?: number | null;
  lng?: number | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  region?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  closer?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  contact?: ContactCreateNestedOneWithoutCustomerInput | null;
  location?: LocationCreateNestedOneWithoutCustomerInput | null;
}

export interface CustomerListRelationFilter {
  every?: CustomerWhereInput | null;
  some?: CustomerWhereInput | null;
  none?: CustomerWhereInput | null;
}

export interface CustomerReportFilter {
  fullName?: string | null;
  leadSourceType?: (string | null)[] | null;
  team?: string | null;
  region?: string | null;
  dateLeadSubmitted?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  fluentApprovedDate?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  dateRange?: DateTimeNullableFilter | null;
  pipelineKey?: pipelineDateKeys | null;
  pipelineKeys?: (pipelineDateKeys | null)[] | null;
  period?: periodKeys | null;
  cohort?: boolean | null;
}

export interface CustomerReportOrderBy {
  fullName?: SortOrder | null;
  team?: SortOrder | null;
  region?: SortOrder | null;
  dateLeadSubmitted?: SortOrder | null;
  outcome?: SortOrder | null;
  contractSignedDate?: SortOrder | null;
  fluentApprovedDate?: SortOrder | null;
  installCompleteDate?: SortOrder | null;
}

export interface CustomerReportPaginate {
  take?: number | null;
  skip?: number | null;
}

export interface CustomerScalarWhereInput {
  AND?: CustomerScalarWhereInput[] | null;
  OR?: CustomerScalarWhereInput[] | null;
  NOT?: CustomerScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  addressCity?: StringNullableFilter | null;
  mapInSync?: BoolNullableFilter | null;
  addressStreetAddress?: StringNullableFilter | null;
  addressStreetNumber?: StringNullableFilter | null;
  addressCountry?: StringNullableFilter | null;
  addressStreetName?: StringNullableFilter | null;
  addressState?: StringNullableFilter | null;
  addressPostalCode?: StringNullableFilter | null;
  lat?: IntNullableFilter | null;
  lng?: IntNullableFilter | null;
  formattedAddress?: StringNullableFilter | null;
  showAddress?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  customerPhone?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  closer?: StringNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  locationId?: IntNullableFilter | null;
  contactId?: IntNullableFilter | null;
  fullName?: StringNullableFilter | null;
  masterCustomerId?: IntNullableFilter | null;
}

export interface CustomerUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  addressCity?: NullableStringFieldUpdateOperationsInput | null;
  mapInSync?: NullableBoolFieldUpdateOperationsInput | null;
  addressStreetAddress?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetNumber?: NullableStringFieldUpdateOperationsInput | null;
  addressCountry?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetName?: NullableStringFieldUpdateOperationsInput | null;
  addressState?: NullableStringFieldUpdateOperationsInput | null;
  addressPostalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableIntFieldUpdateOperationsInput | null;
  lng?: NullableIntFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  showAddress?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  closer?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
}

export interface CustomerUpdateManyWithWhereWithoutContactInput {
  where: CustomerScalarWhereInput;
  data: CustomerUpdateManyMutationInput;
}

export interface CustomerUpdateManyWithWhereWithoutLocationInput {
  where: CustomerScalarWhereInput;
  data: CustomerUpdateManyMutationInput;
}

export interface CustomerUpdateManyWithoutContactInput {
  create?: CustomerCreateWithoutContactInput[] | null;
  connectOrCreate?: CustomerCreateOrConnectWithoutContactInput[] | null;
  upsert?: CustomerUpsertWithWhereUniqueWithoutContactInput[] | null;
  createMany?: CustomerCreateManyContactInputEnvelope | null;
  connect?: CustomerWhereUniqueInput[] | null;
  set?: CustomerWhereUniqueInput[] | null;
  disconnect?: CustomerWhereUniqueInput[] | null;
  delete?: CustomerWhereUniqueInput[] | null;
  update?: CustomerUpdateWithWhereUniqueWithoutContactInput[] | null;
  updateMany?: CustomerUpdateManyWithWhereWithoutContactInput[] | null;
  deleteMany?: CustomerScalarWhereInput[] | null;
}

export interface CustomerUpdateManyWithoutLocationInput {
  create?: CustomerCreateWithoutLocationInput[] | null;
  connectOrCreate?: CustomerCreateOrConnectWithoutLocationInput[] | null;
  upsert?: CustomerUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: CustomerCreateManyLocationInputEnvelope | null;
  connect?: CustomerWhereUniqueInput[] | null;
  set?: CustomerWhereUniqueInput[] | null;
  disconnect?: CustomerWhereUniqueInput[] | null;
  delete?: CustomerWhereUniqueInput[] | null;
  update?: CustomerUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: CustomerUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: CustomerScalarWhereInput[] | null;
}

export interface CustomerUpdateOneWithoutMasterCustomerInput {
  create?: CustomerCreateWithoutMasterCustomerInput | null;
  connectOrCreate?: CustomerCreateOrConnectWithoutMasterCustomerInput | null;
  upsert?: CustomerUpsertWithoutMasterCustomerInput | null;
  connect?: CustomerWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CustomerUpdateWithoutMasterCustomerInput | null;
}

export interface CustomerUpdateWithWhereUniqueWithoutContactInput {
  where: CustomerWhereUniqueInput;
  data: CustomerUpdateWithoutContactInput;
}

export interface CustomerUpdateWithWhereUniqueWithoutLocationInput {
  where: CustomerWhereUniqueInput;
  data: CustomerUpdateWithoutLocationInput;
}

export interface CustomerUpdateWithoutContactInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  addressCity?: NullableStringFieldUpdateOperationsInput | null;
  mapInSync?: NullableBoolFieldUpdateOperationsInput | null;
  addressStreetAddress?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetNumber?: NullableStringFieldUpdateOperationsInput | null;
  addressCountry?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetName?: NullableStringFieldUpdateOperationsInput | null;
  addressState?: NullableStringFieldUpdateOperationsInput | null;
  addressPostalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableIntFieldUpdateOperationsInput | null;
  lng?: NullableIntFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  showAddress?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  closer?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneWithoutCustomerInput | null;
  masterCustomer?: MasterCustomerUpdateOneWithoutCustomerInput | null;
}

export interface CustomerUpdateWithoutLocationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  addressCity?: NullableStringFieldUpdateOperationsInput | null;
  mapInSync?: NullableBoolFieldUpdateOperationsInput | null;
  addressStreetAddress?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetNumber?: NullableStringFieldUpdateOperationsInput | null;
  addressCountry?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetName?: NullableStringFieldUpdateOperationsInput | null;
  addressState?: NullableStringFieldUpdateOperationsInput | null;
  addressPostalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableIntFieldUpdateOperationsInput | null;
  lng?: NullableIntFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  showAddress?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  closer?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  contact?: ContactUpdateOneWithoutCustomerInput | null;
  masterCustomer?: MasterCustomerUpdateOneWithoutCustomerInput | null;
}

export interface CustomerUpdateWithoutMasterCustomerInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  addressCity?: NullableStringFieldUpdateOperationsInput | null;
  mapInSync?: NullableBoolFieldUpdateOperationsInput | null;
  addressStreetAddress?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetNumber?: NullableStringFieldUpdateOperationsInput | null;
  addressCountry?: NullableStringFieldUpdateOperationsInput | null;
  addressStreetName?: NullableStringFieldUpdateOperationsInput | null;
  addressState?: NullableStringFieldUpdateOperationsInput | null;
  addressPostalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableIntFieldUpdateOperationsInput | null;
  lng?: NullableIntFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  showAddress?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  closer?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  contact?: ContactUpdateOneWithoutCustomerInput | null;
  location?: LocationUpdateOneWithoutCustomerInput | null;
}

export interface CustomerUpsertWithWhereUniqueWithoutContactInput {
  where: CustomerWhereUniqueInput;
  update: CustomerUpdateWithoutContactInput;
  create: CustomerCreateWithoutContactInput;
}

export interface CustomerUpsertWithWhereUniqueWithoutLocationInput {
  where: CustomerWhereUniqueInput;
  update: CustomerUpdateWithoutLocationInput;
  create: CustomerCreateWithoutLocationInput;
}

export interface CustomerUpsertWithoutMasterCustomerInput {
  update: CustomerUpdateWithoutMasterCustomerInput;
  create: CustomerCreateWithoutMasterCustomerInput;
}

export interface CustomerWhereInput {
  AND?: CustomerWhereInput[] | null;
  OR?: CustomerWhereInput[] | null;
  NOT?: CustomerWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  addressCity?: StringNullableFilter | null;
  mapInSync?: BoolNullableFilter | null;
  addressStreetAddress?: StringNullableFilter | null;
  addressStreetNumber?: StringNullableFilter | null;
  addressCountry?: StringNullableFilter | null;
  addressStreetName?: StringNullableFilter | null;
  addressState?: StringNullableFilter | null;
  addressPostalCode?: StringNullableFilter | null;
  lat?: IntNullableFilter | null;
  lng?: IntNullableFilter | null;
  formattedAddress?: StringNullableFilter | null;
  showAddress?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  customerPhone?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  closer?: StringNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  locationId?: IntNullableFilter | null;
  contactId?: IntNullableFilter | null;
  fullName?: StringNullableFilter | null;
  masterCustomerId?: IntNullableFilter | null;
  contact?: ContactWhereInput | null;
  location?: LocationWhereInput | null;
  masterCustomer?: MasterCustomerWhereInput | null;
}

export interface CustomerWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  masterCustomerId?: number | null;
}

export interface DateTimeFieldUpdateOperationsInput {
  set?: any | null;
}

export interface DateTimeFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeFilter | null;
}

export interface DateTimeNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
}

export interface DealCreateManyLeadInput {
  id?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  salesEmployeeId_SalesRep?: number | null;
  salesEmployeeId_Setter?: number | null;
  appointmentId?: number | null;
  proposalId?: number | null;
  outcome_?: DealCreateManyoutcome_Input | null;
  pitchPay_?: DealCreateManypitchPay_Input | null;
}

export interface DealCreateManyLeadInputEnvelope {
  data?: DealCreateManyLeadInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface DealCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  salesEmployeeId_Setter?: number | null;
  leadId?: number | null;
  appointmentId?: number | null;
  proposalId?: number | null;
  outcome_?: DealCreateManyoutcome_Input | null;
  pitchPay_?: DealCreateManypitchPay_Input | null;
}

export interface DealCreateManySalesRepInputEnvelope {
  data?: DealCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface DealCreateManySetterInput {
  id?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  salesEmployeeId_SalesRep?: number | null;
  leadId?: number | null;
  appointmentId?: number | null;
  proposalId?: number | null;
  outcome_?: DealCreateManyoutcome_Input | null;
  pitchPay_?: DealCreateManypitchPay_Input | null;
}

export interface DealCreateManySetterInputEnvelope {
  data?: DealCreateManySetterInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface DealCreateManyoutcome_Input {
  set?: string[] | null;
}

export interface DealCreateManypitchPay_Input {
  set?: string[] | null;
}

export interface DealCreateNestedManyWithoutLeadInput {
  create?: DealCreateWithoutLeadInput[] | null;
  connectOrCreate?: DealCreateOrConnectWithoutLeadInput[] | null;
  createMany?: DealCreateManyLeadInputEnvelope | null;
  connect?: DealWhereUniqueInput[] | null;
}

export interface DealCreateNestedManyWithoutSalesRepInput {
  create?: DealCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: DealCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: DealCreateManySalesRepInputEnvelope | null;
  connect?: DealWhereUniqueInput[] | null;
}

export interface DealCreateNestedManyWithoutSetterInput {
  create?: DealCreateWithoutSetterInput[] | null;
  connectOrCreate?: DealCreateOrConnectWithoutSetterInput[] | null;
  createMany?: DealCreateManySetterInputEnvelope | null;
  connect?: DealWhereUniqueInput[] | null;
}

export interface DealCreateNestedOneWithoutAppointmentInput {
  create?: DealCreateWithoutAppointmentInput | null;
  connectOrCreate?: DealCreateOrConnectWithoutAppointmentInput | null;
  connect?: DealWhereUniqueInput | null;
}

export interface DealCreateNestedOneWithoutProposalInput {
  create?: DealCreateWithoutProposalInput | null;
  connectOrCreate?: DealCreateOrConnectWithoutProposalInput | null;
  connect?: DealWhereUniqueInput | null;
}

export interface DealCreateOrConnectWithoutAppointmentInput {
  where: DealWhereUniqueInput;
  create: DealCreateWithoutAppointmentInput;
}

export interface DealCreateOrConnectWithoutLeadInput {
  where: DealWhereUniqueInput;
  create: DealCreateWithoutLeadInput;
}

export interface DealCreateOrConnectWithoutProposalInput {
  where: DealWhereUniqueInput;
  create: DealCreateWithoutProposalInput;
}

export interface DealCreateOrConnectWithoutSalesRepInput {
  where: DealWhereUniqueInput;
  create: DealCreateWithoutSalesRepInput;
}

export interface DealCreateOrConnectWithoutSetterInput {
  where: DealWhereUniqueInput;
  create: DealCreateWithoutSetterInput;
}

export interface DealCreateWithoutAppointmentInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  outcome_?: DealCreateoutcome_Input | null;
  pitchPay_?: DealCreatepitchPay_Input | null;
  proposal?: ProposalCreateNestedOneWithoutLinkToDealInput | null;
  lead?: LeadCreateNestedOneWithoutDealInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutDealsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutDealsAsSetterInput | null;
}

export interface DealCreateWithoutLeadInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  outcome_?: DealCreateoutcome_Input | null;
  pitchPay_?: DealCreatepitchPay_Input | null;
  appointment?: AppointmentCreateNestedOneWithoutDealInput | null;
  proposal?: ProposalCreateNestedOneWithoutLinkToDealInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutDealsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutDealsAsSetterInput | null;
}

export interface DealCreateWithoutProposalInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  outcome_?: DealCreateoutcome_Input | null;
  pitchPay_?: DealCreatepitchPay_Input | null;
  appointment?: AppointmentCreateNestedOneWithoutDealInput | null;
  lead?: LeadCreateNestedOneWithoutDealInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutDealsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutDealsAsSetterInput | null;
}

export interface DealCreateWithoutSalesRepInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  outcome_?: DealCreateoutcome_Input | null;
  pitchPay_?: DealCreatepitchPay_Input | null;
  appointment?: AppointmentCreateNestedOneWithoutDealInput | null;
  proposal?: ProposalCreateNestedOneWithoutLinkToDealInput | null;
  lead?: LeadCreateNestedOneWithoutDealInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutDealsAsSetterInput | null;
}

export interface DealCreateWithoutSetterInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  outcome?: string | null;
  pitchPay?: string | null;
  explainOutcome?: string | null;
  dealNotes?: string | null;
  dateOutcomeUpdated?: any | null;
  outcome_?: DealCreateoutcome_Input | null;
  pitchPay_?: DealCreatepitchPay_Input | null;
  appointment?: AppointmentCreateNestedOneWithoutDealInput | null;
  proposal?: ProposalCreateNestedOneWithoutLinkToDealInput | null;
  lead?: LeadCreateNestedOneWithoutDealInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutDealsAsSalesRepInput | null;
}

export interface DealCreateoutcome_Input {
  set?: string[] | null;
}

export interface DealCreatepitchPay_Input {
  set?: string[] | null;
}

export interface DealListRelationFilter {
  every?: DealWhereInput | null;
  some?: DealWhereInput | null;
  none?: DealWhereInput | null;
}

export interface DealScalarWhereInput {
  AND?: DealScalarWhereInput[] | null;
  OR?: DealScalarWhereInput[] | null;
  NOT?: DealScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  team?: StringNullableFilter | null;
  outcome_?: StringNullableListFilter | null;
  outcome?: StringNullableFilter | null;
  pitchPay_?: StringNullableListFilter | null;
  pitchPay?: StringNullableFilter | null;
  explainOutcome?: StringNullableFilter | null;
  dealNotes?: StringNullableFilter | null;
  dateOutcomeUpdated?: DateTimeNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
  leadId?: IntNullableFilter | null;
  appointmentId?: IntNullableFilter | null;
  proposalId?: IntNullableFilter | null;
}

export interface DealUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  pitchPay?: NullableStringFieldUpdateOperationsInput | null;
  explainOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dealNotes?: NullableStringFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome_?: DealUpdateoutcome_Input | null;
  pitchPay_?: DealUpdatepitchPay_Input | null;
}

export interface DealUpdateManyWithWhereWithoutLeadInput {
  where: DealScalarWhereInput;
  data: DealUpdateManyMutationInput;
}

export interface DealUpdateManyWithWhereWithoutSalesRepInput {
  where: DealScalarWhereInput;
  data: DealUpdateManyMutationInput;
}

export interface DealUpdateManyWithWhereWithoutSetterInput {
  where: DealScalarWhereInput;
  data: DealUpdateManyMutationInput;
}

export interface DealUpdateManyWithoutLeadInput {
  create?: DealCreateWithoutLeadInput[] | null;
  connectOrCreate?: DealCreateOrConnectWithoutLeadInput[] | null;
  upsert?: DealUpsertWithWhereUniqueWithoutLeadInput[] | null;
  createMany?: DealCreateManyLeadInputEnvelope | null;
  connect?: DealWhereUniqueInput[] | null;
  set?: DealWhereUniqueInput[] | null;
  disconnect?: DealWhereUniqueInput[] | null;
  delete?: DealWhereUniqueInput[] | null;
  update?: DealUpdateWithWhereUniqueWithoutLeadInput[] | null;
  updateMany?: DealUpdateManyWithWhereWithoutLeadInput[] | null;
  deleteMany?: DealScalarWhereInput[] | null;
}

export interface DealUpdateManyWithoutSalesRepInput {
  create?: DealCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: DealCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: DealUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: DealCreateManySalesRepInputEnvelope | null;
  connect?: DealWhereUniqueInput[] | null;
  set?: DealWhereUniqueInput[] | null;
  disconnect?: DealWhereUniqueInput[] | null;
  delete?: DealWhereUniqueInput[] | null;
  update?: DealUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: DealUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: DealScalarWhereInput[] | null;
}

export interface DealUpdateManyWithoutSetterInput {
  create?: DealCreateWithoutSetterInput[] | null;
  connectOrCreate?: DealCreateOrConnectWithoutSetterInput[] | null;
  upsert?: DealUpsertWithWhereUniqueWithoutSetterInput[] | null;
  createMany?: DealCreateManySetterInputEnvelope | null;
  connect?: DealWhereUniqueInput[] | null;
  set?: DealWhereUniqueInput[] | null;
  disconnect?: DealWhereUniqueInput[] | null;
  delete?: DealWhereUniqueInput[] | null;
  update?: DealUpdateWithWhereUniqueWithoutSetterInput[] | null;
  updateMany?: DealUpdateManyWithWhereWithoutSetterInput[] | null;
  deleteMany?: DealScalarWhereInput[] | null;
}

export interface DealUpdateOneWithoutAppointmentInput {
  create?: DealCreateWithoutAppointmentInput | null;
  connectOrCreate?: DealCreateOrConnectWithoutAppointmentInput | null;
  upsert?: DealUpsertWithoutAppointmentInput | null;
  connect?: DealWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: DealUpdateWithoutAppointmentInput | null;
}

export interface DealUpdateOneWithoutProposalInput {
  create?: DealCreateWithoutProposalInput | null;
  connectOrCreate?: DealCreateOrConnectWithoutProposalInput | null;
  upsert?: DealUpsertWithoutProposalInput | null;
  connect?: DealWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: DealUpdateWithoutProposalInput | null;
}

export interface DealUpdateWithWhereUniqueWithoutLeadInput {
  where: DealWhereUniqueInput;
  data: DealUpdateWithoutLeadInput;
}

export interface DealUpdateWithWhereUniqueWithoutSalesRepInput {
  where: DealWhereUniqueInput;
  data: DealUpdateWithoutSalesRepInput;
}

export interface DealUpdateWithWhereUniqueWithoutSetterInput {
  where: DealWhereUniqueInput;
  data: DealUpdateWithoutSetterInput;
}

export interface DealUpdateWithoutAppointmentInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  pitchPay?: NullableStringFieldUpdateOperationsInput | null;
  explainOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dealNotes?: NullableStringFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome_?: DealUpdateoutcome_Input | null;
  pitchPay_?: DealUpdatepitchPay_Input | null;
  proposal?: ProposalUpdateOneWithoutLinkToDealInput | null;
  lead?: LeadUpdateOneWithoutDealInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutDealsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutDealsAsSetterInput | null;
}

export interface DealUpdateWithoutLeadInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  pitchPay?: NullableStringFieldUpdateOperationsInput | null;
  explainOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dealNotes?: NullableStringFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome_?: DealUpdateoutcome_Input | null;
  pitchPay_?: DealUpdatepitchPay_Input | null;
  appointment?: AppointmentUpdateOneWithoutDealInput | null;
  proposal?: ProposalUpdateOneWithoutLinkToDealInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutDealsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutDealsAsSetterInput | null;
}

export interface DealUpdateWithoutProposalInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  pitchPay?: NullableStringFieldUpdateOperationsInput | null;
  explainOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dealNotes?: NullableStringFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome_?: DealUpdateoutcome_Input | null;
  pitchPay_?: DealUpdatepitchPay_Input | null;
  appointment?: AppointmentUpdateOneWithoutDealInput | null;
  lead?: LeadUpdateOneWithoutDealInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutDealsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutDealsAsSetterInput | null;
}

export interface DealUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  pitchPay?: NullableStringFieldUpdateOperationsInput | null;
  explainOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dealNotes?: NullableStringFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome_?: DealUpdateoutcome_Input | null;
  pitchPay_?: DealUpdatepitchPay_Input | null;
  appointment?: AppointmentUpdateOneWithoutDealInput | null;
  proposal?: ProposalUpdateOneWithoutLinkToDealInput | null;
  lead?: LeadUpdateOneWithoutDealInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutDealsAsSetterInput | null;
}

export interface DealUpdateWithoutSetterInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  pitchPay?: NullableStringFieldUpdateOperationsInput | null;
  explainOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dealNotes?: NullableStringFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome_?: DealUpdateoutcome_Input | null;
  pitchPay_?: DealUpdatepitchPay_Input | null;
  appointment?: AppointmentUpdateOneWithoutDealInput | null;
  proposal?: ProposalUpdateOneWithoutLinkToDealInput | null;
  lead?: LeadUpdateOneWithoutDealInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutDealsAsSalesRepInput | null;
}

export interface DealUpdateoutcome_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DealUpdatepitchPay_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DealUpsertWithWhereUniqueWithoutLeadInput {
  where: DealWhereUniqueInput;
  update: DealUpdateWithoutLeadInput;
  create: DealCreateWithoutLeadInput;
}

export interface DealUpsertWithWhereUniqueWithoutSalesRepInput {
  where: DealWhereUniqueInput;
  update: DealUpdateWithoutSalesRepInput;
  create: DealCreateWithoutSalesRepInput;
}

export interface DealUpsertWithWhereUniqueWithoutSetterInput {
  where: DealWhereUniqueInput;
  update: DealUpdateWithoutSetterInput;
  create: DealCreateWithoutSetterInput;
}

export interface DealUpsertWithoutAppointmentInput {
  update: DealUpdateWithoutAppointmentInput;
  create: DealCreateWithoutAppointmentInput;
}

export interface DealUpsertWithoutProposalInput {
  update: DealUpdateWithoutProposalInput;
  create: DealCreateWithoutProposalInput;
}

export interface DealWhereInput {
  AND?: DealWhereInput[] | null;
  OR?: DealWhereInput[] | null;
  NOT?: DealWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  team?: StringNullableFilter | null;
  outcome_?: StringNullableListFilter | null;
  outcome?: StringNullableFilter | null;
  pitchPay_?: StringNullableListFilter | null;
  pitchPay?: StringNullableFilter | null;
  explainOutcome?: StringNullableFilter | null;
  dealNotes?: StringNullableFilter | null;
  dateOutcomeUpdated?: DateTimeNullableFilter | null;
  appointment?: AppointmentWhereInput | null;
  proposal?: ProposalWhereInput | null;
  lead?: LeadWhereInput | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  Setter?: SalesEmployeeWhereInput | null;
  leadId?: IntNullableFilter | null;
  appointmentId?: IntNullableFilter | null;
  proposalId?: IntNullableFilter | null;
}

export interface DealWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  leadId?: number | null;
  appointmentId?: number | null;
  proposalId?: number | null;
}

export interface DecimalNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalNullableFilter | null;
}

export interface DesignCreateNestedOneWithoutLinkToPermittingBucketInput {
  create?: DesignCreateWithoutLinkToPermittingBucketInput | null;
  connectOrCreate?: DesignCreateOrConnectWithoutLinkToPermittingBucketInput | null;
  connect?: DesignWhereUniqueInput | null;
}

export interface DesignCreateNestedOneWithoutProjectInput {
  create?: DesignCreateWithoutProjectInput | null;
  connectOrCreate?: DesignCreateOrConnectWithoutProjectInput | null;
  connect?: DesignWhereUniqueInput | null;
}

export interface DesignCreateOrConnectWithoutLinkToPermittingBucketInput {
  where: DesignWhereUniqueInput;
  create: DesignCreateWithoutLinkToPermittingBucketInput;
}

export interface DesignCreateOrConnectWithoutProjectInput {
  where: DesignWhereUniqueInput;
  create: DesignCreateWithoutProjectInput;
}

export interface DesignCreateWithoutLinkToPermittingBucketInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  cadRequestedDate?: any | null;
  cadCompleteDate?: any | null;
  engineeringRequestedDate?: any | null;
  engineeringCompleteDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  cadCorrectionsRequestedDate?: any | null;
  cadCorrectionsReceivedDate?: any | null;
  customerAcknowledgementLetterSignedDate?: any | null;
  engineeringCorrectionsRequestedDate?: any | null;
  engineeringCorrectionsReceivedDate?: any | null;
  installPurchaseOrderCreatedDate?: any | null;
  installPurchaseOrderApprovalDate?: any | null;
  costAnalysisCompletedDate?: any | null;
  bucketActivationDate?: any | null;
  bucketCompletionDate?: any | null;
  designCompleteDate?: any | null;
  dateCommissionPmtsTriggered?: any | null;
  excelFileCreatedDate?: any | null;
  accountUpdate?: string | null;
  specialRequestsNotes?: string | null;
  numberOfArrays?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  changeOrderDescription?: string | null;
  notes?: string | null;
  descriptionOfDesignCorrections?: string | null;
  addersNotes?: string | null;
  bucketActivation_?: DesignCreatebucketActivation_Input | null;
  status_?: DesignCreatestatus_Input | null;
  nearMapUsed_?: DesignCreatenearMapUsed_Input | null;
  cadStatus_?: DesignCreatecadStatus_Input | null;
  assignedTo_?: DesignCreateassignedTo_Input | null;
  batteryPromised_?: DesignCreatebatteryPromised_Input | null;
  generatorPromised_?: DesignCreategeneratorPromised_Input | null;
  groundMount_?: DesignCreategroundMount_Input | null;
  engineeringStatus_?: DesignCreateengineeringStatus_Input | null;
  engStampsRequired_?: DesignCreateengStampsRequired_Input | null;
  changeOrder_?: DesignCreatechangeOrder_Input | null;
  permitCorrectionsNeeded_?: DesignCreatepermitCorrectionsNeeded_Input | null;
  correctionsType_?: DesignCreatecorrectionsType_Input | null;
  engineeringCorrectionType_?: DesignCreateengineeringCorrectionType_Input | null;
  stepsToComplete_?: DesignCreatestepsToComplete_Input | null;
  splitPay_?: DesignCreatesplitPay_Input | null;
  slaStatus_?: DesignCreateslaStatus_Input | null;
  bomCreated_?: DesignCreatebomCreated_Input | null;
  cadCorrections_?: DesignCreatecadCorrections_Input | null;
  cadMatchesOriginalProposal_?: DesignCreatecadMatchesOriginalProposal_Input | null;
  engineeringCorrections_?: DesignCreateengineeringCorrections_Input | null;
  mpuNeeded_?: DesignCreatempuNeeded_Input | null;
  createInstallerPurchaseOrder_?: DesignCreatecreateInstallerPurchaseOrder_Input | null;
  createBom_?: DesignCreatecreateBom_Input | null;
  createMpu_?: DesignCreatecreateMpu_Input | null;
  createPermit_?: DesignCreatecreatePermit_Input | null;
  createEquipment_?: DesignCreatecreateEquipment_Input | null;
  equipmentBucket_?: DesignCreateequipmentBucket_Input | null;
  mpuPromisedFromWc_?: DesignCreatempuPromisedFromWc_Input | null;
  project?: ProjectCreateNestedOneWithoutDesignInput | null;
}

export interface DesignCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  cadRequestedDate?: any | null;
  cadCompleteDate?: any | null;
  engineeringRequestedDate?: any | null;
  engineeringCompleteDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  cadCorrectionsRequestedDate?: any | null;
  cadCorrectionsReceivedDate?: any | null;
  customerAcknowledgementLetterSignedDate?: any | null;
  engineeringCorrectionsRequestedDate?: any | null;
  engineeringCorrectionsReceivedDate?: any | null;
  installPurchaseOrderCreatedDate?: any | null;
  installPurchaseOrderApprovalDate?: any | null;
  costAnalysisCompletedDate?: any | null;
  bucketActivationDate?: any | null;
  bucketCompletionDate?: any | null;
  designCompleteDate?: any | null;
  dateCommissionPmtsTriggered?: any | null;
  excelFileCreatedDate?: any | null;
  accountUpdate?: string | null;
  specialRequestsNotes?: string | null;
  numberOfArrays?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  changeOrderDescription?: string | null;
  notes?: string | null;
  descriptionOfDesignCorrections?: string | null;
  addersNotes?: string | null;
  bucketActivation_?: DesignCreatebucketActivation_Input | null;
  status_?: DesignCreatestatus_Input | null;
  nearMapUsed_?: DesignCreatenearMapUsed_Input | null;
  cadStatus_?: DesignCreatecadStatus_Input | null;
  assignedTo_?: DesignCreateassignedTo_Input | null;
  batteryPromised_?: DesignCreatebatteryPromised_Input | null;
  generatorPromised_?: DesignCreategeneratorPromised_Input | null;
  groundMount_?: DesignCreategroundMount_Input | null;
  engineeringStatus_?: DesignCreateengineeringStatus_Input | null;
  engStampsRequired_?: DesignCreateengStampsRequired_Input | null;
  changeOrder_?: DesignCreatechangeOrder_Input | null;
  permitCorrectionsNeeded_?: DesignCreatepermitCorrectionsNeeded_Input | null;
  correctionsType_?: DesignCreatecorrectionsType_Input | null;
  engineeringCorrectionType_?: DesignCreateengineeringCorrectionType_Input | null;
  stepsToComplete_?: DesignCreatestepsToComplete_Input | null;
  splitPay_?: DesignCreatesplitPay_Input | null;
  slaStatus_?: DesignCreateslaStatus_Input | null;
  bomCreated_?: DesignCreatebomCreated_Input | null;
  cadCorrections_?: DesignCreatecadCorrections_Input | null;
  cadMatchesOriginalProposal_?: DesignCreatecadMatchesOriginalProposal_Input | null;
  engineeringCorrections_?: DesignCreateengineeringCorrections_Input | null;
  mpuNeeded_?: DesignCreatempuNeeded_Input | null;
  createInstallerPurchaseOrder_?: DesignCreatecreateInstallerPurchaseOrder_Input | null;
  createBom_?: DesignCreatecreateBom_Input | null;
  createMpu_?: DesignCreatecreateMpu_Input | null;
  createPermit_?: DesignCreatecreatePermit_Input | null;
  createEquipment_?: DesignCreatecreateEquipment_Input | null;
  equipmentBucket_?: DesignCreateequipmentBucket_Input | null;
  mpuPromisedFromWc_?: DesignCreatempuPromisedFromWc_Input | null;
  linkToPermittingBucket?: PermitCreateNestedOneWithoutDesignInput | null;
}

export interface DesignCreateassignedTo_Input {
  set?: string[] | null;
}

export interface DesignCreatebatteryPromised_Input {
  set?: string[] | null;
}

export interface DesignCreatebomCreated_Input {
  set?: string[] | null;
}

export interface DesignCreatebucketActivation_Input {
  set?: string[] | null;
}

export interface DesignCreatecadCorrections_Input {
  set?: string[] | null;
}

export interface DesignCreatecadMatchesOriginalProposal_Input {
  set?: string[] | null;
}

export interface DesignCreatecadStatus_Input {
  set?: string[] | null;
}

export interface DesignCreatechangeOrder_Input {
  set?: string[] | null;
}

export interface DesignCreatecorrectionsType_Input {
  set?: string[] | null;
}

export interface DesignCreatecreateBom_Input {
  set?: string[] | null;
}

export interface DesignCreatecreateEquipment_Input {
  set?: string[] | null;
}

export interface DesignCreatecreateInstallerPurchaseOrder_Input {
  set?: string[] | null;
}

export interface DesignCreatecreateMpu_Input {
  set?: string[] | null;
}

export interface DesignCreatecreatePermit_Input {
  set?: string[] | null;
}

export interface DesignCreateengStampsRequired_Input {
  set?: string[] | null;
}

export interface DesignCreateengineeringCorrectionType_Input {
  set?: string[] | null;
}

export interface DesignCreateengineeringCorrections_Input {
  set?: string[] | null;
}

export interface DesignCreateengineeringStatus_Input {
  set?: string[] | null;
}

export interface DesignCreateequipmentBucket_Input {
  set?: string[] | null;
}

export interface DesignCreategeneratorPromised_Input {
  set?: string[] | null;
}

export interface DesignCreategroundMount_Input {
  set?: string[] | null;
}

export interface DesignCreatempuNeeded_Input {
  set?: string[] | null;
}

export interface DesignCreatempuPromisedFromWc_Input {
  set?: string[] | null;
}

export interface DesignCreatenearMapUsed_Input {
  set?: string[] | null;
}

export interface DesignCreatepermitCorrectionsNeeded_Input {
  set?: string[] | null;
}

export interface DesignCreateslaStatus_Input {
  set?: string[] | null;
}

export interface DesignCreatesplitPay_Input {
  set?: string[] | null;
}

export interface DesignCreatestatus_Input {
  set?: string[] | null;
}

export interface DesignCreatestepsToComplete_Input {
  set?: string[] | null;
}

export interface DesignUpdateOneWithoutLinkToPermittingBucketInput {
  create?: DesignCreateWithoutLinkToPermittingBucketInput | null;
  connectOrCreate?: DesignCreateOrConnectWithoutLinkToPermittingBucketInput | null;
  upsert?: DesignUpsertWithoutLinkToPermittingBucketInput | null;
  connect?: DesignWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: DesignUpdateWithoutLinkToPermittingBucketInput | null;
}

export interface DesignUpdateOneWithoutProjectInput {
  create?: DesignCreateWithoutProjectInput | null;
  connectOrCreate?: DesignCreateOrConnectWithoutProjectInput | null;
  upsert?: DesignUpsertWithoutProjectInput | null;
  connect?: DesignWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: DesignUpdateWithoutProjectInput | null;
}

export interface DesignUpdateWithoutLinkToPermittingBucketInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerAcknowledgementLetterSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCorrectionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCorrectionsReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installPurchaseOrderCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installPurchaseOrderApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  costAnalysisCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  designCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCommissionPmtsTriggered?: NullableDateTimeFieldUpdateOperationsInput | null;
  excelFileCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  numberOfArrays?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  addersNotes?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: DesignUpdatebucketActivation_Input | null;
  status_?: DesignUpdatestatus_Input | null;
  nearMapUsed_?: DesignUpdatenearMapUsed_Input | null;
  cadStatus_?: DesignUpdatecadStatus_Input | null;
  assignedTo_?: DesignUpdateassignedTo_Input | null;
  batteryPromised_?: DesignUpdatebatteryPromised_Input | null;
  generatorPromised_?: DesignUpdategeneratorPromised_Input | null;
  groundMount_?: DesignUpdategroundMount_Input | null;
  engineeringStatus_?: DesignUpdateengineeringStatus_Input | null;
  engStampsRequired_?: DesignUpdateengStampsRequired_Input | null;
  changeOrder_?: DesignUpdatechangeOrder_Input | null;
  permitCorrectionsNeeded_?: DesignUpdatepermitCorrectionsNeeded_Input | null;
  correctionsType_?: DesignUpdatecorrectionsType_Input | null;
  engineeringCorrectionType_?: DesignUpdateengineeringCorrectionType_Input | null;
  stepsToComplete_?: DesignUpdatestepsToComplete_Input | null;
  splitPay_?: DesignUpdatesplitPay_Input | null;
  slaStatus_?: DesignUpdateslaStatus_Input | null;
  bomCreated_?: DesignUpdatebomCreated_Input | null;
  cadCorrections_?: DesignUpdatecadCorrections_Input | null;
  cadMatchesOriginalProposal_?: DesignUpdatecadMatchesOriginalProposal_Input | null;
  engineeringCorrections_?: DesignUpdateengineeringCorrections_Input | null;
  mpuNeeded_?: DesignUpdatempuNeeded_Input | null;
  createInstallerPurchaseOrder_?: DesignUpdatecreateInstallerPurchaseOrder_Input | null;
  createBom_?: DesignUpdatecreateBom_Input | null;
  createMpu_?: DesignUpdatecreateMpu_Input | null;
  createPermit_?: DesignUpdatecreatePermit_Input | null;
  createEquipment_?: DesignUpdatecreateEquipment_Input | null;
  equipmentBucket_?: DesignUpdateequipmentBucket_Input | null;
  mpuPromisedFromWc_?: DesignUpdatempuPromisedFromWc_Input | null;
  project?: ProjectUpdateOneWithoutDesignInput | null;
}

export interface DesignUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerAcknowledgementLetterSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCorrectionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCorrectionsReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installPurchaseOrderCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installPurchaseOrderApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  costAnalysisCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  designCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCommissionPmtsTriggered?: NullableDateTimeFieldUpdateOperationsInput | null;
  excelFileCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  numberOfArrays?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  addersNotes?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: DesignUpdatebucketActivation_Input | null;
  status_?: DesignUpdatestatus_Input | null;
  nearMapUsed_?: DesignUpdatenearMapUsed_Input | null;
  cadStatus_?: DesignUpdatecadStatus_Input | null;
  assignedTo_?: DesignUpdateassignedTo_Input | null;
  batteryPromised_?: DesignUpdatebatteryPromised_Input | null;
  generatorPromised_?: DesignUpdategeneratorPromised_Input | null;
  groundMount_?: DesignUpdategroundMount_Input | null;
  engineeringStatus_?: DesignUpdateengineeringStatus_Input | null;
  engStampsRequired_?: DesignUpdateengStampsRequired_Input | null;
  changeOrder_?: DesignUpdatechangeOrder_Input | null;
  permitCorrectionsNeeded_?: DesignUpdatepermitCorrectionsNeeded_Input | null;
  correctionsType_?: DesignUpdatecorrectionsType_Input | null;
  engineeringCorrectionType_?: DesignUpdateengineeringCorrectionType_Input | null;
  stepsToComplete_?: DesignUpdatestepsToComplete_Input | null;
  splitPay_?: DesignUpdatesplitPay_Input | null;
  slaStatus_?: DesignUpdateslaStatus_Input | null;
  bomCreated_?: DesignUpdatebomCreated_Input | null;
  cadCorrections_?: DesignUpdatecadCorrections_Input | null;
  cadMatchesOriginalProposal_?: DesignUpdatecadMatchesOriginalProposal_Input | null;
  engineeringCorrections_?: DesignUpdateengineeringCorrections_Input | null;
  mpuNeeded_?: DesignUpdatempuNeeded_Input | null;
  createInstallerPurchaseOrder_?: DesignUpdatecreateInstallerPurchaseOrder_Input | null;
  createBom_?: DesignUpdatecreateBom_Input | null;
  createMpu_?: DesignUpdatecreateMpu_Input | null;
  createPermit_?: DesignUpdatecreatePermit_Input | null;
  createEquipment_?: DesignUpdatecreateEquipment_Input | null;
  equipmentBucket_?: DesignUpdateequipmentBucket_Input | null;
  mpuPromisedFromWc_?: DesignUpdatempuPromisedFromWc_Input | null;
  linkToPermittingBucket?: PermitUpdateOneWithoutDesignInput | null;
}

export interface DesignUpdateassignedTo_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatebatteryPromised_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatebomCreated_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatebucketActivation_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecadCorrections_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecadMatchesOriginalProposal_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecadStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatechangeOrder_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecorrectionsType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecreateBom_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecreateEquipment_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecreateInstallerPurchaseOrder_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecreateMpu_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatecreatePermit_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdateengStampsRequired_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdateengineeringCorrectionType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdateengineeringCorrections_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdateengineeringStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdateequipmentBucket_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdategeneratorPromised_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdategroundMount_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatempuNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatempuPromisedFromWc_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatenearMapUsed_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatepermitCorrectionsNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdateslaStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatesplitPay_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpdatestepsToComplete_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface DesignUpsertWithoutLinkToPermittingBucketInput {
  update: DesignUpdateWithoutLinkToPermittingBucketInput;
  create: DesignCreateWithoutLinkToPermittingBucketInput;
}

export interface DesignUpsertWithoutProjectInput {
  update: DesignUpdateWithoutProjectInput;
  create: DesignCreateWithoutProjectInput;
}

export interface DesignWhereInput {
  AND?: DesignWhereInput[] | null;
  OR?: DesignWhereInput[] | null;
  NOT?: DesignWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  cadRequestedDate?: DateTimeNullableFilter | null;
  cadCompleteDate?: DateTimeNullableFilter | null;
  engineeringRequestedDate?: DateTimeNullableFilter | null;
  engineeringCompleteDate?: DateTimeNullableFilter | null;
  correctionsRequestedDate?: DateTimeNullableFilter | null;
  correctionsCompletedDate?: DateTimeNullableFilter | null;
  cadCorrectionsRequestedDate?: DateTimeNullableFilter | null;
  cadCorrectionsReceivedDate?: DateTimeNullableFilter | null;
  customerAcknowledgementLetterSignedDate?: DateTimeNullableFilter | null;
  engineeringCorrectionsRequestedDate?: DateTimeNullableFilter | null;
  engineeringCorrectionsReceivedDate?: DateTimeNullableFilter | null;
  installPurchaseOrderCreatedDate?: DateTimeNullableFilter | null;
  installPurchaseOrderApprovalDate?: DateTimeNullableFilter | null;
  costAnalysisCompletedDate?: DateTimeNullableFilter | null;
  bucketActivationDate?: DateTimeNullableFilter | null;
  bucketCompletionDate?: DateTimeNullableFilter | null;
  designCompleteDate?: DateTimeNullableFilter | null;
  dateCommissionPmtsTriggered?: DateTimeNullableFilter | null;
  excelFileCreatedDate?: DateTimeNullableFilter | null;
  bucketActivation_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  nearMapUsed_?: StringNullableListFilter | null;
  cadStatus_?: StringNullableListFilter | null;
  assignedTo_?: StringNullableListFilter | null;
  batteryPromised_?: StringNullableListFilter | null;
  generatorPromised_?: StringNullableListFilter | null;
  groundMount_?: StringNullableListFilter | null;
  engineeringStatus_?: StringNullableListFilter | null;
  engStampsRequired_?: StringNullableListFilter | null;
  changeOrder_?: StringNullableListFilter | null;
  permitCorrectionsNeeded_?: StringNullableListFilter | null;
  correctionsType_?: StringNullableListFilter | null;
  engineeringCorrectionType_?: StringNullableListFilter | null;
  stepsToComplete_?: StringNullableListFilter | null;
  splitPay_?: StringNullableListFilter | null;
  slaStatus_?: StringNullableListFilter | null;
  bomCreated_?: StringNullableListFilter | null;
  cadCorrections_?: StringNullableListFilter | null;
  cadMatchesOriginalProposal_?: StringNullableListFilter | null;
  engineeringCorrections_?: StringNullableListFilter | null;
  mpuNeeded_?: StringNullableListFilter | null;
  createInstallerPurchaseOrder_?: StringNullableListFilter | null;
  createBom_?: StringNullableListFilter | null;
  createMpu_?: StringNullableListFilter | null;
  createPermit_?: StringNullableListFilter | null;
  createEquipment_?: StringNullableListFilter | null;
  equipmentBucket_?: StringNullableListFilter | null;
  mpuPromisedFromWc_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  specialRequestsNotes?: StringNullableFilter | null;
  numberOfArrays?: IntNullableFilter | null;
  trenchingRawGroundFootage?: IntNullableFilter | null;
  trenchingConcreteGroundFootage?: IntNullableFilter | null;
  changeOrderDescription?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  descriptionOfDesignCorrections?: StringNullableFilter | null;
  addersNotes?: StringNullableFilter | null;
  project?: ProjectWhereInput | null;
  linkToPermittingBucket?: PermitWhereInput | null;
  permitId?: IntNullableFilter | null;
}

export interface DesignWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  permitId?: number | null;
}

export interface EnumASSIGNMENT_TYPEFieldUpdateOperationsInput {
  set?: ASSIGNMENT_TYPE | null;
}

export interface EnumASSIGNMENT_TYPEFilter {
  equals?: ASSIGNMENT_TYPE | null;
  in?: ASSIGNMENT_TYPE[] | null;
  notIn?: ASSIGNMENT_TYPE[] | null;
  not?: NestedEnumASSIGNMENT_TYPEFilter | null;
}

export interface EnumEliminationTypeFieldUpdateOperationsInput {
  set?: EliminationType | null;
}

export interface EnumEliminationTypeFilter {
  equals?: EliminationType | null;
  in?: EliminationType[] | null;
  notIn?: EliminationType[] | null;
  not?: NestedEnumEliminationTypeFilter | null;
}

export interface EnumFieldKeyRelationTypeNullableFilter {
  equals?: FieldKeyRelationType | null;
  in?: FieldKeyRelationType[] | null;
  notIn?: FieldKeyRelationType[] | null;
  not?: NestedEnumFieldKeyRelationTypeNullableFilter | null;
}

export interface EnumGENERATED_TYPEFieldUpdateOperationsInput {
  set?: GENERATED_TYPE | null;
}

export interface EnumGENERATED_TYPEFilter {
  equals?: GENERATED_TYPE | null;
  in?: GENERATED_TYPE[] | null;
  notIn?: GENERATED_TYPE[] | null;
  not?: NestedEnumGENERATED_TYPEFilter | null;
}

export interface EnumSTATUS_TYPEFieldUpdateOperationsInput {
  set?: STATUS_TYPE | null;
}

export interface EnumSTATUS_TYPEFilter {
  equals?: STATUS_TYPE | null;
  in?: STATUS_TYPE[] | null;
  notIn?: STATUS_TYPE[] | null;
  not?: NestedEnumSTATUS_TYPEFilter | null;
}

export interface EnumTimeSheetStatusNullableFilter {
  equals?: TimeSheetStatus | null;
  in?: TimeSheetStatus[] | null;
  notIn?: TimeSheetStatus[] | null;
  not?: NestedEnumTimeSheetStatusNullableFilter | null;
}

export interface Enumteam_typeFieldUpdateOperationsInput {
  set?: team_type | null;
}

export interface Enumteam_typeFilter {
  equals?: team_type | null;
  in?: team_type[] | null;
  notIn?: team_type[] | null;
  not?: NestedEnumteam_typeFilter | null;
}

export interface Enumteam_typeNullableFilter {
  equals?: team_type | null;
  in?: team_type[] | null;
  notIn?: team_type[] | null;
  not?: NestedEnumteam_typeNullableFilter | null;
}

export interface Enumuser_roleNullableFilter {
  equals?: user_role | null;
  in?: user_role[] | null;
  notIn?: user_role[] | null;
  not?: NestedEnumuser_roleNullableFilter | null;
}

export interface FieldKeyCreateInput {
  nodeName: string;
  podioFieldId: number;
  externalId?: string | null;
  enabled?: boolean | null;
  type?: string | null;
  notes?: string | null;
  relationType?: FieldKeyRelationType | null;
  appKey: AppKeyCreateNestedOneWithoutFieldKeysInput;
}

export interface FieldKeyCreateManyAppKeyInput {
  id?: number | null;
  nodeName: string;
  podioFieldId: number;
  externalId?: string | null;
  enabled?: boolean | null;
  type?: string | null;
  notes?: string | null;
  relationType?: FieldKeyRelationType | null;
}

export interface FieldKeyCreateManyAppKeyInputEnvelope {
  data?: FieldKeyCreateManyAppKeyInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface FieldKeyCreateNestedManyWithoutAppKeyInput {
  create?: FieldKeyCreateWithoutAppKeyInput[] | null;
  connectOrCreate?: FieldKeyCreateOrConnectWithoutAppKeyInput[] | null;
  createMany?: FieldKeyCreateManyAppKeyInputEnvelope | null;
  connect?: FieldKeyWhereUniqueInput[] | null;
}

export interface FieldKeyCreateOrConnectWithoutAppKeyInput {
  where: FieldKeyWhereUniqueInput;
  create: FieldKeyCreateWithoutAppKeyInput;
}

export interface FieldKeyCreateWithoutAppKeyInput {
  nodeName: string;
  podioFieldId: number;
  externalId?: string | null;
  enabled?: boolean | null;
  type?: string | null;
  notes?: string | null;
  relationType?: FieldKeyRelationType | null;
}

export interface FieldKeyListRelationFilter {
  every?: FieldKeyWhereInput | null;
  some?: FieldKeyWhereInput | null;
  none?: FieldKeyWhereInput | null;
}

export interface FieldKeyScalarWhereInput {
  AND?: FieldKeyScalarWhereInput[] | null;
  OR?: FieldKeyScalarWhereInput[] | null;
  NOT?: FieldKeyScalarWhereInput[] | null;
  id?: IntFilter | null;
  nodeName?: StringFilter | null;
  podioFieldId?: IntFilter | null;
  externalId?: StringNullableFilter | null;
  appKeyId?: IntFilter | null;
  enabled?: BoolNullableFilter | null;
  type?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  relationType?: EnumFieldKeyRelationTypeNullableFilter | null;
}

export interface FieldKeyUpdateInput {
  nodeName?: StringFieldUpdateOperationsInput | null;
  podioFieldId?: IntFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  relationType?: NullableEnumFieldKeyRelationTypeFieldUpdateOperationsInput | null;
  appKey?: AppKeyUpdateOneRequiredWithoutFieldKeysInput | null;
}

export interface FieldKeyUpdateManyMutationInput {
  nodeName?: StringFieldUpdateOperationsInput | null;
  podioFieldId?: IntFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  relationType?: NullableEnumFieldKeyRelationTypeFieldUpdateOperationsInput | null;
}

export interface FieldKeyUpdateManyWithWhereWithoutAppKeyInput {
  where: FieldKeyScalarWhereInput;
  data: FieldKeyUpdateManyMutationInput;
}

export interface FieldKeyUpdateManyWithoutAppKeyInput {
  create?: FieldKeyCreateWithoutAppKeyInput[] | null;
  connectOrCreate?: FieldKeyCreateOrConnectWithoutAppKeyInput[] | null;
  upsert?: FieldKeyUpsertWithWhereUniqueWithoutAppKeyInput[] | null;
  createMany?: FieldKeyCreateManyAppKeyInputEnvelope | null;
  connect?: FieldKeyWhereUniqueInput[] | null;
  set?: FieldKeyWhereUniqueInput[] | null;
  disconnect?: FieldKeyWhereUniqueInput[] | null;
  delete?: FieldKeyWhereUniqueInput[] | null;
  update?: FieldKeyUpdateWithWhereUniqueWithoutAppKeyInput[] | null;
  updateMany?: FieldKeyUpdateManyWithWhereWithoutAppKeyInput[] | null;
  deleteMany?: FieldKeyScalarWhereInput[] | null;
}

export interface FieldKeyUpdateWithWhereUniqueWithoutAppKeyInput {
  where: FieldKeyWhereUniqueInput;
  data: FieldKeyUpdateWithoutAppKeyInput;
}

export interface FieldKeyUpdateWithoutAppKeyInput {
  nodeName?: StringFieldUpdateOperationsInput | null;
  podioFieldId?: IntFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  relationType?: NullableEnumFieldKeyRelationTypeFieldUpdateOperationsInput | null;
}

export interface FieldKeyUpsertWithWhereUniqueWithoutAppKeyInput {
  where: FieldKeyWhereUniqueInput;
  update: FieldKeyUpdateWithoutAppKeyInput;
  create: FieldKeyCreateWithoutAppKeyInput;
}

export interface FieldKeyWhereInput {
  AND?: FieldKeyWhereInput[] | null;
  OR?: FieldKeyWhereInput[] | null;
  NOT?: FieldKeyWhereInput[] | null;
  id?: IntFilter | null;
  nodeName?: StringFilter | null;
  podioFieldId?: IntFilter | null;
  externalId?: StringNullableFilter | null;
  appKeyId?: IntFilter | null;
  appKey?: AppKeyWhereInput | null;
  enabled?: BoolNullableFilter | null;
  type?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  relationType?: EnumFieldKeyRelationTypeNullableFilter | null;
}

export interface FieldKeyWhereUniqueInput {
  id?: number | null;
  podioFieldId?: number | null;
}

export interface FloatFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface FloatFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatFilter | null;
}

export interface FloatNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatNullableFilter | null;
}

export interface FundingCreateManyInstallPageInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  noticeOfIntentTimestamp?: any | null;
  lienFiledForeclosureTimestamp?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  wcBucketCompletionDate?: any | null;
  scheduledInstallDate?: any | null;
  installEstimatedCompletionDate?: any | null;
  modulesInstalledDate?: any | null;
  electricalCompletedDate?: any | null;
  installCompletionDate?: any | null;
  m1SubmittedDate?: any | null;
  m1ApprovedDate?: any | null;
  m1ReceivedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  ptoSubmittedToFinanceCompanyDate?: any | null;
  m3ReceivedDate?: any | null;
  engineeringCompleteDate?: any | null;
  fundingProjectionReportDate?: any | null;
  accountUpdate?: string | null;
  reasonForHold?: string | null;
  notes?: string | null;
  totalOffset?: number | null;
  loanPortalEquipmentCost?: number | null;
  n18MonthCoverageAmount?: number | null;
  changeOrderDescription?: string | null;
  m1DisbursedAmount?: number | null;
  projectionNotes?: string | null;
  customerName?: string | null;
  equipmentInvoicePrice?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  salesRepId?: number | null;
  proposalId?: number | null;
  region_?: FundingCreateManyregion_Input | null;
  loanDocsSigned_?: FundingCreateManyloanDocsSigned_Input | null;
  status_?: FundingCreateManystatus_Input | null;
  filePreliminaryNotice_?: FundingCreateManyfilePreliminaryNotice_Input | null;
  lien_?: FundingCreateManylien_Input | null;
  batteryPromised_?: FundingCreateManybatteryPromised_Input | null;
  splitPay_?: FundingCreateManysplitPay_Input | null;
  ntpApprovalOverride_?: FundingCreateManyntpApprovalOverride_Input | null;
  stipulations_?: FundingCreateManystipulations_Input | null;
  changeOrder_?: FundingCreateManychangeOrder_Input | null;
  cashPayment1_?: FundingCreateManycashPayment1_Input | null;
  cashPayment2_?: FundingCreateManycashPayment2_Input | null;
  cashPayment3_?: FundingCreateManycashPayment3_Input | null;
  reasonNotSubmitted_?: FundingCreateManyreasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingCreateManyincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingCreateManyfundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingCreateManycontractUploadedToDropbox_Input | null;
  updated_?: FundingCreateManyupdated_Input | null;
  inProjectionsReport_?: FundingCreateManyinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingCreateManyinCurrentWeeksProjections_Input | null;
}

export interface FundingCreateManyInstallPageInputEnvelope {
  data?: FundingCreateManyInstallPageInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface FundingCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  noticeOfIntentTimestamp?: any | null;
  lienFiledForeclosureTimestamp?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  wcBucketCompletionDate?: any | null;
  scheduledInstallDate?: any | null;
  installEstimatedCompletionDate?: any | null;
  modulesInstalledDate?: any | null;
  electricalCompletedDate?: any | null;
  installCompletionDate?: any | null;
  m1SubmittedDate?: any | null;
  m1ApprovedDate?: any | null;
  m1ReceivedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  ptoSubmittedToFinanceCompanyDate?: any | null;
  m3ReceivedDate?: any | null;
  engineeringCompleteDate?: any | null;
  fundingProjectionReportDate?: any | null;
  accountUpdate?: string | null;
  reasonForHold?: string | null;
  notes?: string | null;
  totalOffset?: number | null;
  loanPortalEquipmentCost?: number | null;
  n18MonthCoverageAmount?: number | null;
  changeOrderDescription?: string | null;
  m1DisbursedAmount?: number | null;
  projectionNotes?: string | null;
  customerName?: string | null;
  equipmentInvoicePrice?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  proposalId?: number | null;
  installId?: number | null;
  region_?: FundingCreateManyregion_Input | null;
  loanDocsSigned_?: FundingCreateManyloanDocsSigned_Input | null;
  status_?: FundingCreateManystatus_Input | null;
  filePreliminaryNotice_?: FundingCreateManyfilePreliminaryNotice_Input | null;
  lien_?: FundingCreateManylien_Input | null;
  batteryPromised_?: FundingCreateManybatteryPromised_Input | null;
  splitPay_?: FundingCreateManysplitPay_Input | null;
  ntpApprovalOverride_?: FundingCreateManyntpApprovalOverride_Input | null;
  stipulations_?: FundingCreateManystipulations_Input | null;
  changeOrder_?: FundingCreateManychangeOrder_Input | null;
  cashPayment1_?: FundingCreateManycashPayment1_Input | null;
  cashPayment2_?: FundingCreateManycashPayment2_Input | null;
  cashPayment3_?: FundingCreateManycashPayment3_Input | null;
  reasonNotSubmitted_?: FundingCreateManyreasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingCreateManyincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingCreateManyfundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingCreateManycontractUploadedToDropbox_Input | null;
  updated_?: FundingCreateManyupdated_Input | null;
  inProjectionsReport_?: FundingCreateManyinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingCreateManyinCurrentWeeksProjections_Input | null;
}

export interface FundingCreateManySalesRepInputEnvelope {
  data?: FundingCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface FundingCreateManybatteryPromised_Input {
  set?: string[] | null;
}

export interface FundingCreateManycashPayment1_Input {
  set?: string[] | null;
}

export interface FundingCreateManycashPayment2_Input {
  set?: string[] | null;
}

export interface FundingCreateManycashPayment3_Input {
  set?: string[] | null;
}

export interface FundingCreateManychangeOrder_Input {
  set?: string[] | null;
}

export interface FundingCreateManycontractUploadedToDropbox_Input {
  set?: string[] | null;
}

export interface FundingCreateManyfilePreliminaryNotice_Input {
  set?: string[] | null;
}

export interface FundingCreateManyfundingCloseout_Input {
  set?: string[] | null;
}

export interface FundingCreateManyinCurrentWeeksProjections_Input {
  set?: string[] | null;
}

export interface FundingCreateManyinProjectionsReport_Input {
  set?: string[] | null;
}

export interface FundingCreateManyincludeProjectionCalculations_Input {
  set?: string[] | null;
}

export interface FundingCreateManylien_Input {
  set?: string[] | null;
}

export interface FundingCreateManyloanDocsSigned_Input {
  set?: string[] | null;
}

export interface FundingCreateManyntpApprovalOverride_Input {
  set?: string[] | null;
}

export interface FundingCreateManyreasonNotSubmitted_Input {
  set?: string[] | null;
}

export interface FundingCreateManyregion_Input {
  set?: string[] | null;
}

export interface FundingCreateManysplitPay_Input {
  set?: string[] | null;
}

export interface FundingCreateManystatus_Input {
  set?: string[] | null;
}

export interface FundingCreateManystipulations_Input {
  set?: string[] | null;
}

export interface FundingCreateManyupdated_Input {
  set?: string[] | null;
}

export interface FundingCreateNestedManyWithoutInstallPageInput {
  create?: FundingCreateWithoutInstallPageInput[] | null;
  connectOrCreate?: FundingCreateOrConnectWithoutInstallPageInput[] | null;
  createMany?: FundingCreateManyInstallPageInputEnvelope | null;
  connect?: FundingWhereUniqueInput[] | null;
}

export interface FundingCreateNestedManyWithoutSalesRepInput {
  create?: FundingCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: FundingCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: FundingCreateManySalesRepInputEnvelope | null;
  connect?: FundingWhereUniqueInput[] | null;
}

export interface FundingCreateNestedOneWithoutProjectInput {
  create?: FundingCreateWithoutProjectInput | null;
  connectOrCreate?: FundingCreateOrConnectWithoutProjectInput | null;
  connect?: FundingWhereUniqueInput | null;
}

export interface FundingCreateNestedOneWithoutProposalInput {
  create?: FundingCreateWithoutProposalInput | null;
  connectOrCreate?: FundingCreateOrConnectWithoutProposalInput | null;
  connect?: FundingWhereUniqueInput | null;
}

export interface FundingCreateOrConnectWithoutInstallPageInput {
  where: FundingWhereUniqueInput;
  create: FundingCreateWithoutInstallPageInput;
}

export interface FundingCreateOrConnectWithoutProjectInput {
  where: FundingWhereUniqueInput;
  create: FundingCreateWithoutProjectInput;
}

export interface FundingCreateOrConnectWithoutProposalInput {
  where: FundingWhereUniqueInput;
  create: FundingCreateWithoutProposalInput;
}

export interface FundingCreateOrConnectWithoutSalesRepInput {
  where: FundingWhereUniqueInput;
  create: FundingCreateWithoutSalesRepInput;
}

export interface FundingCreateWithoutInstallPageInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  noticeOfIntentTimestamp?: any | null;
  lienFiledForeclosureTimestamp?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  wcBucketCompletionDate?: any | null;
  scheduledInstallDate?: any | null;
  installEstimatedCompletionDate?: any | null;
  modulesInstalledDate?: any | null;
  electricalCompletedDate?: any | null;
  installCompletionDate?: any | null;
  m1SubmittedDate?: any | null;
  m1ApprovedDate?: any | null;
  m1ReceivedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  ptoSubmittedToFinanceCompanyDate?: any | null;
  m3ReceivedDate?: any | null;
  engineeringCompleteDate?: any | null;
  fundingProjectionReportDate?: any | null;
  accountUpdate?: string | null;
  reasonForHold?: string | null;
  notes?: string | null;
  totalOffset?: number | null;
  loanPortalEquipmentCost?: number | null;
  n18MonthCoverageAmount?: number | null;
  changeOrderDescription?: string | null;
  m1DisbursedAmount?: number | null;
  projectionNotes?: string | null;
  customerName?: string | null;
  equipmentInvoicePrice?: number | null;
  region_?: FundingCreateregion_Input | null;
  loanDocsSigned_?: FundingCreateloanDocsSigned_Input | null;
  status_?: FundingCreatestatus_Input | null;
  filePreliminaryNotice_?: FundingCreatefilePreliminaryNotice_Input | null;
  lien_?: FundingCreatelien_Input | null;
  batteryPromised_?: FundingCreatebatteryPromised_Input | null;
  splitPay_?: FundingCreatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingCreatentpApprovalOverride_Input | null;
  stipulations_?: FundingCreatestipulations_Input | null;
  changeOrder_?: FundingCreatechangeOrder_Input | null;
  cashPayment1_?: FundingCreatecashPayment1_Input | null;
  cashPayment2_?: FundingCreatecashPayment2_Input | null;
  cashPayment3_?: FundingCreatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingCreatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingCreateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingCreatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingCreatecontractUploadedToDropbox_Input | null;
  updated_?: FundingCreateupdated_Input | null;
  inProjectionsReport_?: FundingCreateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingCreateinCurrentWeeksProjections_Input | null;
  project?: ProjectCreateNestedOneWithoutFundingInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutFundingAsSalesRepInput | null;
  proposal?: ProposalCreateNestedOneWithoutFundingInput | null;
}

export interface FundingCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  noticeOfIntentTimestamp?: any | null;
  lienFiledForeclosureTimestamp?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  wcBucketCompletionDate?: any | null;
  scheduledInstallDate?: any | null;
  installEstimatedCompletionDate?: any | null;
  modulesInstalledDate?: any | null;
  electricalCompletedDate?: any | null;
  installCompletionDate?: any | null;
  m1SubmittedDate?: any | null;
  m1ApprovedDate?: any | null;
  m1ReceivedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  ptoSubmittedToFinanceCompanyDate?: any | null;
  m3ReceivedDate?: any | null;
  engineeringCompleteDate?: any | null;
  fundingProjectionReportDate?: any | null;
  accountUpdate?: string | null;
  reasonForHold?: string | null;
  notes?: string | null;
  totalOffset?: number | null;
  loanPortalEquipmentCost?: number | null;
  n18MonthCoverageAmount?: number | null;
  changeOrderDescription?: string | null;
  m1DisbursedAmount?: number | null;
  projectionNotes?: string | null;
  customerName?: string | null;
  equipmentInvoicePrice?: number | null;
  region_?: FundingCreateregion_Input | null;
  loanDocsSigned_?: FundingCreateloanDocsSigned_Input | null;
  status_?: FundingCreatestatus_Input | null;
  filePreliminaryNotice_?: FundingCreatefilePreliminaryNotice_Input | null;
  lien_?: FundingCreatelien_Input | null;
  batteryPromised_?: FundingCreatebatteryPromised_Input | null;
  splitPay_?: FundingCreatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingCreatentpApprovalOverride_Input | null;
  stipulations_?: FundingCreatestipulations_Input | null;
  changeOrder_?: FundingCreatechangeOrder_Input | null;
  cashPayment1_?: FundingCreatecashPayment1_Input | null;
  cashPayment2_?: FundingCreatecashPayment2_Input | null;
  cashPayment3_?: FundingCreatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingCreatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingCreateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingCreatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingCreatecontractUploadedToDropbox_Input | null;
  updated_?: FundingCreateupdated_Input | null;
  inProjectionsReport_?: FundingCreateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingCreateinCurrentWeeksProjections_Input | null;
  salesRep?: SalesRepCreateNestedOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutFundingAsSalesRepInput | null;
  proposal?: ProposalCreateNestedOneWithoutFundingInput | null;
  installPage?: InstallCreateNestedOneWithoutFundingInput | null;
}

export interface FundingCreateWithoutProposalInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  noticeOfIntentTimestamp?: any | null;
  lienFiledForeclosureTimestamp?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  wcBucketCompletionDate?: any | null;
  scheduledInstallDate?: any | null;
  installEstimatedCompletionDate?: any | null;
  modulesInstalledDate?: any | null;
  electricalCompletedDate?: any | null;
  installCompletionDate?: any | null;
  m1SubmittedDate?: any | null;
  m1ApprovedDate?: any | null;
  m1ReceivedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  ptoSubmittedToFinanceCompanyDate?: any | null;
  m3ReceivedDate?: any | null;
  engineeringCompleteDate?: any | null;
  fundingProjectionReportDate?: any | null;
  accountUpdate?: string | null;
  reasonForHold?: string | null;
  notes?: string | null;
  totalOffset?: number | null;
  loanPortalEquipmentCost?: number | null;
  n18MonthCoverageAmount?: number | null;
  changeOrderDescription?: string | null;
  m1DisbursedAmount?: number | null;
  projectionNotes?: string | null;
  customerName?: string | null;
  equipmentInvoicePrice?: number | null;
  region_?: FundingCreateregion_Input | null;
  loanDocsSigned_?: FundingCreateloanDocsSigned_Input | null;
  status_?: FundingCreatestatus_Input | null;
  filePreliminaryNotice_?: FundingCreatefilePreliminaryNotice_Input | null;
  lien_?: FundingCreatelien_Input | null;
  batteryPromised_?: FundingCreatebatteryPromised_Input | null;
  splitPay_?: FundingCreatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingCreatentpApprovalOverride_Input | null;
  stipulations_?: FundingCreatestipulations_Input | null;
  changeOrder_?: FundingCreatechangeOrder_Input | null;
  cashPayment1_?: FundingCreatecashPayment1_Input | null;
  cashPayment2_?: FundingCreatecashPayment2_Input | null;
  cashPayment3_?: FundingCreatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingCreatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingCreateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingCreatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingCreatecontractUploadedToDropbox_Input | null;
  updated_?: FundingCreateupdated_Input | null;
  inProjectionsReport_?: FundingCreateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingCreateinCurrentWeeksProjections_Input | null;
  project?: ProjectCreateNestedOneWithoutFundingInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutFundingAsSalesRepInput | null;
  installPage?: InstallCreateNestedOneWithoutFundingInput | null;
}

export interface FundingCreateWithoutSalesRepInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  noticeOfIntentTimestamp?: any | null;
  lienFiledForeclosureTimestamp?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  wcBucketCompletionDate?: any | null;
  scheduledInstallDate?: any | null;
  installEstimatedCompletionDate?: any | null;
  modulesInstalledDate?: any | null;
  electricalCompletedDate?: any | null;
  installCompletionDate?: any | null;
  m1SubmittedDate?: any | null;
  m1ApprovedDate?: any | null;
  m1ReceivedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  ptoSubmittedToFinanceCompanyDate?: any | null;
  m3ReceivedDate?: any | null;
  engineeringCompleteDate?: any | null;
  fundingProjectionReportDate?: any | null;
  accountUpdate?: string | null;
  reasonForHold?: string | null;
  notes?: string | null;
  totalOffset?: number | null;
  loanPortalEquipmentCost?: number | null;
  n18MonthCoverageAmount?: number | null;
  changeOrderDescription?: string | null;
  m1DisbursedAmount?: number | null;
  projectionNotes?: string | null;
  customerName?: string | null;
  equipmentInvoicePrice?: number | null;
  region_?: FundingCreateregion_Input | null;
  loanDocsSigned_?: FundingCreateloanDocsSigned_Input | null;
  status_?: FundingCreatestatus_Input | null;
  filePreliminaryNotice_?: FundingCreatefilePreliminaryNotice_Input | null;
  lien_?: FundingCreatelien_Input | null;
  batteryPromised_?: FundingCreatebatteryPromised_Input | null;
  splitPay_?: FundingCreatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingCreatentpApprovalOverride_Input | null;
  stipulations_?: FundingCreatestipulations_Input | null;
  changeOrder_?: FundingCreatechangeOrder_Input | null;
  cashPayment1_?: FundingCreatecashPayment1_Input | null;
  cashPayment2_?: FundingCreatecashPayment2_Input | null;
  cashPayment3_?: FundingCreatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingCreatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingCreateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingCreatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingCreatecontractUploadedToDropbox_Input | null;
  updated_?: FundingCreateupdated_Input | null;
  inProjectionsReport_?: FundingCreateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingCreateinCurrentWeeksProjections_Input | null;
  project?: ProjectCreateNestedOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutFundingAsSalesRepInput | null;
  proposal?: ProposalCreateNestedOneWithoutFundingInput | null;
  installPage?: InstallCreateNestedOneWithoutFundingInput | null;
}

export interface FundingCreatebatteryPromised_Input {
  set?: string[] | null;
}

export interface FundingCreatecashPayment1_Input {
  set?: string[] | null;
}

export interface FundingCreatecashPayment2_Input {
  set?: string[] | null;
}

export interface FundingCreatecashPayment3_Input {
  set?: string[] | null;
}

export interface FundingCreatechangeOrder_Input {
  set?: string[] | null;
}

export interface FundingCreatecontractUploadedToDropbox_Input {
  set?: string[] | null;
}

export interface FundingCreatefilePreliminaryNotice_Input {
  set?: string[] | null;
}

export interface FundingCreatefundingCloseout_Input {
  set?: string[] | null;
}

export interface FundingCreateinCurrentWeeksProjections_Input {
  set?: string[] | null;
}

export interface FundingCreateinProjectionsReport_Input {
  set?: string[] | null;
}

export interface FundingCreateincludeProjectionCalculations_Input {
  set?: string[] | null;
}

export interface FundingCreatelien_Input {
  set?: string[] | null;
}

export interface FundingCreateloanDocsSigned_Input {
  set?: string[] | null;
}

export interface FundingCreatentpApprovalOverride_Input {
  set?: string[] | null;
}

export interface FundingCreatereasonNotSubmitted_Input {
  set?: string[] | null;
}

export interface FundingCreateregion_Input {
  set?: string[] | null;
}

export interface FundingCreatesplitPay_Input {
  set?: string[] | null;
}

export interface FundingCreatestatus_Input {
  set?: string[] | null;
}

export interface FundingCreatestipulations_Input {
  set?: string[] | null;
}

export interface FundingCreateupdated_Input {
  set?: string[] | null;
}

export interface FundingListRelationFilter {
  every?: FundingWhereInput | null;
  some?: FundingWhereInput | null;
  none?: FundingWhereInput | null;
}

export interface FundingScalarWhereInput {
  AND?: FundingScalarWhereInput[] | null;
  OR?: FundingScalarWhereInput[] | null;
  NOT?: FundingScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  loanDocsSignedDate?: DateTimeNullableFilter | null;
  creditExpirationDate?: DateTimeNullableFilter | null;
  noticeOfIntentTimestamp?: DateTimeNullableFilter | null;
  lienFiledForeclosureTimestamp?: DateTimeNullableFilter | null;
  ntpSubmittedDate?: DateTimeNullableFilter | null;
  ntpApprovedDate?: DateTimeNullableFilter | null;
  wcBucketCompletionDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  installEstimatedCompletionDate?: DateTimeNullableFilter | null;
  modulesInstalledDate?: DateTimeNullableFilter | null;
  electricalCompletedDate?: DateTimeNullableFilter | null;
  installCompletionDate?: DateTimeNullableFilter | null;
  m1SubmittedDate?: DateTimeNullableFilter | null;
  m1ApprovedDate?: DateTimeNullableFilter | null;
  m1ReceivedDate?: DateTimeNullableFilter | null;
  ptoSubmittedDate?: DateTimeNullableFilter | null;
  ptoReceivedDate?: DateTimeNullableFilter | null;
  ptoSubmittedToFinanceCompanyDate?: DateTimeNullableFilter | null;
  m3ReceivedDate?: DateTimeNullableFilter | null;
  engineeringCompleteDate?: DateTimeNullableFilter | null;
  fundingProjectionReportDate?: DateTimeNullableFilter | null;
  region_?: StringNullableListFilter | null;
  loanDocsSigned_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  filePreliminaryNotice_?: StringNullableListFilter | null;
  lien_?: StringNullableListFilter | null;
  batteryPromised_?: StringNullableListFilter | null;
  splitPay_?: StringNullableListFilter | null;
  ntpApprovalOverride_?: StringNullableListFilter | null;
  stipulations_?: StringNullableListFilter | null;
  changeOrder_?: StringNullableListFilter | null;
  cashPayment1_?: StringNullableListFilter | null;
  cashPayment2_?: StringNullableListFilter | null;
  cashPayment3_?: StringNullableListFilter | null;
  reasonNotSubmitted_?: StringNullableListFilter | null;
  includeProjectionCalculations_?: StringNullableListFilter | null;
  fundingCloseout_?: StringNullableListFilter | null;
  contractUploadedToDropbox_?: StringNullableListFilter | null;
  updated_?: StringNullableListFilter | null;
  inProjectionsReport_?: StringNullableListFilter | null;
  inCurrentWeeksProjections_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  reasonForHold?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  totalOffset?: IntNullableFilter | null;
  loanPortalEquipmentCost?: FloatNullableFilter | null;
  n18MonthCoverageAmount?: FloatNullableFilter | null;
  changeOrderDescription?: StringNullableFilter | null;
  m1DisbursedAmount?: FloatNullableFilter | null;
  projectionNotes?: StringNullableFilter | null;
  customerName?: StringNullableFilter | null;
  equipmentInvoicePrice?: FloatNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  proposalId?: IntNullableFilter | null;
  installId?: IntNullableFilter | null;
}

export interface FundingUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  noticeOfIntentTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  lienFiledForeclosureTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  wcBucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installEstimatedCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstalledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedToFinanceCompanyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m3ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fundingProjectionReportDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  loanPortalEquipmentCost?: NullableFloatFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  m1DisbursedAmount?: NullableFloatFieldUpdateOperationsInput | null;
  projectionNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  equipmentInvoicePrice?: NullableFloatFieldUpdateOperationsInput | null;
  region_?: FundingUpdateregion_Input | null;
  loanDocsSigned_?: FundingUpdateloanDocsSigned_Input | null;
  status_?: FundingUpdatestatus_Input | null;
  filePreliminaryNotice_?: FundingUpdatefilePreliminaryNotice_Input | null;
  lien_?: FundingUpdatelien_Input | null;
  batteryPromised_?: FundingUpdatebatteryPromised_Input | null;
  splitPay_?: FundingUpdatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingUpdatentpApprovalOverride_Input | null;
  stipulations_?: FundingUpdatestipulations_Input | null;
  changeOrder_?: FundingUpdatechangeOrder_Input | null;
  cashPayment1_?: FundingUpdatecashPayment1_Input | null;
  cashPayment2_?: FundingUpdatecashPayment2_Input | null;
  cashPayment3_?: FundingUpdatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingUpdatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingUpdateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingUpdatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingUpdatecontractUploadedToDropbox_Input | null;
  updated_?: FundingUpdateupdated_Input | null;
  inProjectionsReport_?: FundingUpdateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingUpdateinCurrentWeeksProjections_Input | null;
}

export interface FundingUpdateManyWithWhereWithoutInstallPageInput {
  where: FundingScalarWhereInput;
  data: FundingUpdateManyMutationInput;
}

export interface FundingUpdateManyWithWhereWithoutSalesRepInput {
  where: FundingScalarWhereInput;
  data: FundingUpdateManyMutationInput;
}

export interface FundingUpdateManyWithoutInstallPageInput {
  create?: FundingCreateWithoutInstallPageInput[] | null;
  connectOrCreate?: FundingCreateOrConnectWithoutInstallPageInput[] | null;
  upsert?: FundingUpsertWithWhereUniqueWithoutInstallPageInput[] | null;
  createMany?: FundingCreateManyInstallPageInputEnvelope | null;
  connect?: FundingWhereUniqueInput[] | null;
  set?: FundingWhereUniqueInput[] | null;
  disconnect?: FundingWhereUniqueInput[] | null;
  delete?: FundingWhereUniqueInput[] | null;
  update?: FundingUpdateWithWhereUniqueWithoutInstallPageInput[] | null;
  updateMany?: FundingUpdateManyWithWhereWithoutInstallPageInput[] | null;
  deleteMany?: FundingScalarWhereInput[] | null;
}

export interface FundingUpdateManyWithoutSalesRepInput {
  create?: FundingCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: FundingCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: FundingUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: FundingCreateManySalesRepInputEnvelope | null;
  connect?: FundingWhereUniqueInput[] | null;
  set?: FundingWhereUniqueInput[] | null;
  disconnect?: FundingWhereUniqueInput[] | null;
  delete?: FundingWhereUniqueInput[] | null;
  update?: FundingUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: FundingUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: FundingScalarWhereInput[] | null;
}

export interface FundingUpdateOneWithoutProjectInput {
  create?: FundingCreateWithoutProjectInput | null;
  connectOrCreate?: FundingCreateOrConnectWithoutProjectInput | null;
  upsert?: FundingUpsertWithoutProjectInput | null;
  connect?: FundingWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: FundingUpdateWithoutProjectInput | null;
}

export interface FundingUpdateOneWithoutProposalInput {
  create?: FundingCreateWithoutProposalInput | null;
  connectOrCreate?: FundingCreateOrConnectWithoutProposalInput | null;
  upsert?: FundingUpsertWithoutProposalInput | null;
  connect?: FundingWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: FundingUpdateWithoutProposalInput | null;
}

export interface FundingUpdateWithWhereUniqueWithoutInstallPageInput {
  where: FundingWhereUniqueInput;
  data: FundingUpdateWithoutInstallPageInput;
}

export interface FundingUpdateWithWhereUniqueWithoutSalesRepInput {
  where: FundingWhereUniqueInput;
  data: FundingUpdateWithoutSalesRepInput;
}

export interface FundingUpdateWithoutInstallPageInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  noticeOfIntentTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  lienFiledForeclosureTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  wcBucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installEstimatedCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstalledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedToFinanceCompanyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m3ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fundingProjectionReportDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  loanPortalEquipmentCost?: NullableFloatFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  m1DisbursedAmount?: NullableFloatFieldUpdateOperationsInput | null;
  projectionNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  equipmentInvoicePrice?: NullableFloatFieldUpdateOperationsInput | null;
  region_?: FundingUpdateregion_Input | null;
  loanDocsSigned_?: FundingUpdateloanDocsSigned_Input | null;
  status_?: FundingUpdatestatus_Input | null;
  filePreliminaryNotice_?: FundingUpdatefilePreliminaryNotice_Input | null;
  lien_?: FundingUpdatelien_Input | null;
  batteryPromised_?: FundingUpdatebatteryPromised_Input | null;
  splitPay_?: FundingUpdatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingUpdatentpApprovalOverride_Input | null;
  stipulations_?: FundingUpdatestipulations_Input | null;
  changeOrder_?: FundingUpdatechangeOrder_Input | null;
  cashPayment1_?: FundingUpdatecashPayment1_Input | null;
  cashPayment2_?: FundingUpdatecashPayment2_Input | null;
  cashPayment3_?: FundingUpdatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingUpdatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingUpdateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingUpdatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingUpdatecontractUploadedToDropbox_Input | null;
  updated_?: FundingUpdateupdated_Input | null;
  inProjectionsReport_?: FundingUpdateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingUpdateinCurrentWeeksProjections_Input | null;
  project?: ProjectUpdateOneWithoutFundingInput | null;
  salesRep?: SalesRepUpdateOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutFundingAsSalesRepInput | null;
  proposal?: ProposalUpdateOneWithoutFundingInput | null;
}

export interface FundingUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  noticeOfIntentTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  lienFiledForeclosureTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  wcBucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installEstimatedCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstalledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedToFinanceCompanyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m3ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fundingProjectionReportDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  loanPortalEquipmentCost?: NullableFloatFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  m1DisbursedAmount?: NullableFloatFieldUpdateOperationsInput | null;
  projectionNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  equipmentInvoicePrice?: NullableFloatFieldUpdateOperationsInput | null;
  region_?: FundingUpdateregion_Input | null;
  loanDocsSigned_?: FundingUpdateloanDocsSigned_Input | null;
  status_?: FundingUpdatestatus_Input | null;
  filePreliminaryNotice_?: FundingUpdatefilePreliminaryNotice_Input | null;
  lien_?: FundingUpdatelien_Input | null;
  batteryPromised_?: FundingUpdatebatteryPromised_Input | null;
  splitPay_?: FundingUpdatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingUpdatentpApprovalOverride_Input | null;
  stipulations_?: FundingUpdatestipulations_Input | null;
  changeOrder_?: FundingUpdatechangeOrder_Input | null;
  cashPayment1_?: FundingUpdatecashPayment1_Input | null;
  cashPayment2_?: FundingUpdatecashPayment2_Input | null;
  cashPayment3_?: FundingUpdatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingUpdatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingUpdateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingUpdatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingUpdatecontractUploadedToDropbox_Input | null;
  updated_?: FundingUpdateupdated_Input | null;
  inProjectionsReport_?: FundingUpdateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingUpdateinCurrentWeeksProjections_Input | null;
  salesRep?: SalesRepUpdateOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutFundingAsSalesRepInput | null;
  proposal?: ProposalUpdateOneWithoutFundingInput | null;
  installPage?: InstallUpdateOneWithoutFundingInput | null;
}

export interface FundingUpdateWithoutProposalInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  noticeOfIntentTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  lienFiledForeclosureTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  wcBucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installEstimatedCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstalledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedToFinanceCompanyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m3ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fundingProjectionReportDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  loanPortalEquipmentCost?: NullableFloatFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  m1DisbursedAmount?: NullableFloatFieldUpdateOperationsInput | null;
  projectionNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  equipmentInvoicePrice?: NullableFloatFieldUpdateOperationsInput | null;
  region_?: FundingUpdateregion_Input | null;
  loanDocsSigned_?: FundingUpdateloanDocsSigned_Input | null;
  status_?: FundingUpdatestatus_Input | null;
  filePreliminaryNotice_?: FundingUpdatefilePreliminaryNotice_Input | null;
  lien_?: FundingUpdatelien_Input | null;
  batteryPromised_?: FundingUpdatebatteryPromised_Input | null;
  splitPay_?: FundingUpdatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingUpdatentpApprovalOverride_Input | null;
  stipulations_?: FundingUpdatestipulations_Input | null;
  changeOrder_?: FundingUpdatechangeOrder_Input | null;
  cashPayment1_?: FundingUpdatecashPayment1_Input | null;
  cashPayment2_?: FundingUpdatecashPayment2_Input | null;
  cashPayment3_?: FundingUpdatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingUpdatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingUpdateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingUpdatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingUpdatecontractUploadedToDropbox_Input | null;
  updated_?: FundingUpdateupdated_Input | null;
  inProjectionsReport_?: FundingUpdateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingUpdateinCurrentWeeksProjections_Input | null;
  project?: ProjectUpdateOneWithoutFundingInput | null;
  salesRep?: SalesRepUpdateOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutFundingAsSalesRepInput | null;
  installPage?: InstallUpdateOneWithoutFundingInput | null;
}

export interface FundingUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  noticeOfIntentTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  lienFiledForeclosureTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  wcBucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installEstimatedCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstalledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedToFinanceCompanyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m3ReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  engineeringCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fundingProjectionReportDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  loanPortalEquipmentCost?: NullableFloatFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  m1DisbursedAmount?: NullableFloatFieldUpdateOperationsInput | null;
  projectionNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  equipmentInvoicePrice?: NullableFloatFieldUpdateOperationsInput | null;
  region_?: FundingUpdateregion_Input | null;
  loanDocsSigned_?: FundingUpdateloanDocsSigned_Input | null;
  status_?: FundingUpdatestatus_Input | null;
  filePreliminaryNotice_?: FundingUpdatefilePreliminaryNotice_Input | null;
  lien_?: FundingUpdatelien_Input | null;
  batteryPromised_?: FundingUpdatebatteryPromised_Input | null;
  splitPay_?: FundingUpdatesplitPay_Input | null;
  ntpApprovalOverride_?: FundingUpdatentpApprovalOverride_Input | null;
  stipulations_?: FundingUpdatestipulations_Input | null;
  changeOrder_?: FundingUpdatechangeOrder_Input | null;
  cashPayment1_?: FundingUpdatecashPayment1_Input | null;
  cashPayment2_?: FundingUpdatecashPayment2_Input | null;
  cashPayment3_?: FundingUpdatecashPayment3_Input | null;
  reasonNotSubmitted_?: FundingUpdatereasonNotSubmitted_Input | null;
  includeProjectionCalculations_?: FundingUpdateincludeProjectionCalculations_Input | null;
  fundingCloseout_?: FundingUpdatefundingCloseout_Input | null;
  contractUploadedToDropbox_?: FundingUpdatecontractUploadedToDropbox_Input | null;
  updated_?: FundingUpdateupdated_Input | null;
  inProjectionsReport_?: FundingUpdateinProjectionsReport_Input | null;
  inCurrentWeeksProjections_?: FundingUpdateinCurrentWeeksProjections_Input | null;
  project?: ProjectUpdateOneWithoutFundingInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutFundingAsSalesRepInput | null;
  proposal?: ProposalUpdateOneWithoutFundingInput | null;
  installPage?: InstallUpdateOneWithoutFundingInput | null;
}

export interface FundingUpdatebatteryPromised_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatecashPayment1_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatecashPayment2_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatecashPayment3_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatechangeOrder_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatecontractUploadedToDropbox_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatefilePreliminaryNotice_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatefundingCloseout_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdateinCurrentWeeksProjections_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdateinProjectionsReport_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdateincludeProjectionCalculations_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatelien_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdateloanDocsSigned_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatentpApprovalOverride_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatereasonNotSubmitted_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatesplitPay_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdatestipulations_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpdateupdated_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface FundingUpsertWithWhereUniqueWithoutInstallPageInput {
  where: FundingWhereUniqueInput;
  update: FundingUpdateWithoutInstallPageInput;
  create: FundingCreateWithoutInstallPageInput;
}

export interface FundingUpsertWithWhereUniqueWithoutSalesRepInput {
  where: FundingWhereUniqueInput;
  update: FundingUpdateWithoutSalesRepInput;
  create: FundingCreateWithoutSalesRepInput;
}

export interface FundingUpsertWithoutProjectInput {
  update: FundingUpdateWithoutProjectInput;
  create: FundingCreateWithoutProjectInput;
}

export interface FundingUpsertWithoutProposalInput {
  update: FundingUpdateWithoutProposalInput;
  create: FundingCreateWithoutProposalInput;
}

export interface FundingWhereInput {
  AND?: FundingWhereInput[] | null;
  OR?: FundingWhereInput[] | null;
  NOT?: FundingWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  loanDocsSignedDate?: DateTimeNullableFilter | null;
  creditExpirationDate?: DateTimeNullableFilter | null;
  noticeOfIntentTimestamp?: DateTimeNullableFilter | null;
  lienFiledForeclosureTimestamp?: DateTimeNullableFilter | null;
  ntpSubmittedDate?: DateTimeNullableFilter | null;
  ntpApprovedDate?: DateTimeNullableFilter | null;
  wcBucketCompletionDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  installEstimatedCompletionDate?: DateTimeNullableFilter | null;
  modulesInstalledDate?: DateTimeNullableFilter | null;
  electricalCompletedDate?: DateTimeNullableFilter | null;
  installCompletionDate?: DateTimeNullableFilter | null;
  m1SubmittedDate?: DateTimeNullableFilter | null;
  m1ApprovedDate?: DateTimeNullableFilter | null;
  m1ReceivedDate?: DateTimeNullableFilter | null;
  ptoSubmittedDate?: DateTimeNullableFilter | null;
  ptoReceivedDate?: DateTimeNullableFilter | null;
  ptoSubmittedToFinanceCompanyDate?: DateTimeNullableFilter | null;
  m3ReceivedDate?: DateTimeNullableFilter | null;
  engineeringCompleteDate?: DateTimeNullableFilter | null;
  fundingProjectionReportDate?: DateTimeNullableFilter | null;
  region_?: StringNullableListFilter | null;
  loanDocsSigned_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  filePreliminaryNotice_?: StringNullableListFilter | null;
  lien_?: StringNullableListFilter | null;
  batteryPromised_?: StringNullableListFilter | null;
  splitPay_?: StringNullableListFilter | null;
  ntpApprovalOverride_?: StringNullableListFilter | null;
  stipulations_?: StringNullableListFilter | null;
  changeOrder_?: StringNullableListFilter | null;
  cashPayment1_?: StringNullableListFilter | null;
  cashPayment2_?: StringNullableListFilter | null;
  cashPayment3_?: StringNullableListFilter | null;
  reasonNotSubmitted_?: StringNullableListFilter | null;
  includeProjectionCalculations_?: StringNullableListFilter | null;
  fundingCloseout_?: StringNullableListFilter | null;
  contractUploadedToDropbox_?: StringNullableListFilter | null;
  updated_?: StringNullableListFilter | null;
  inProjectionsReport_?: StringNullableListFilter | null;
  inCurrentWeeksProjections_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  reasonForHold?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  totalOffset?: IntNullableFilter | null;
  loanPortalEquipmentCost?: FloatNullableFilter | null;
  n18MonthCoverageAmount?: FloatNullableFilter | null;
  changeOrderDescription?: StringNullableFilter | null;
  m1DisbursedAmount?: FloatNullableFilter | null;
  projectionNotes?: StringNullableFilter | null;
  customerName?: StringNullableFilter | null;
  equipmentInvoicePrice?: FloatNullableFilter | null;
  project?: ProjectWhereInput | null;
  salesRep?: SalesRepWhereInput | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  proposal?: ProposalWhereInput | null;
  installPage?: InstallWhereInput | null;
  salesRepId?: IntNullableFilter | null;
  proposalId?: IntNullableFilter | null;
  installId?: IntNullableFilter | null;
}

export interface FundingWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  proposalId?: number | null;
}

export interface GeneratedLeadCreateInput {
  id: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  status?: STATUS_TYPE | null;
  message?: string | null;
  type: GENERATED_TYPE;
  startTime: any;
  endTime: any;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  submittedBy: string;
  referral?: boolean | null;
  referralinfo?: string | null;
  notes?: string | null;
  assignedTo?: SalesRepCreateNestedOneWithoutAssignedLeadsInput | null;
  office?: OfficeCreateNestedOneWithoutUnassignedLeadsInput | null;
}

export interface GeneratedLeadCreateManyAssignedToInput {
  id: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  status?: STATUS_TYPE | null;
  message?: string | null;
  officeId?: number | null;
  type: GENERATED_TYPE;
  startTime: any;
  endTime: any;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  submittedBy: string;
  referral?: boolean | null;
  referralinfo?: string | null;
  notes?: string | null;
}

export interface GeneratedLeadCreateManyAssignedToInputEnvelope {
  data?: GeneratedLeadCreateManyAssignedToInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface GeneratedLeadCreateManyOfficeInput {
  id: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  status?: STATUS_TYPE | null;
  message?: string | null;
  type: GENERATED_TYPE;
  startTime: any;
  endTime: any;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  submittedBy: string;
  referral?: boolean | null;
  referralinfo?: string | null;
  notes?: string | null;
  salesRepId?: number | null;
}

export interface GeneratedLeadCreateManyOfficeInputEnvelope {
  data?: GeneratedLeadCreateManyOfficeInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface GeneratedLeadCreateNestedManyWithoutAssignedToInput {
  create?: GeneratedLeadCreateWithoutAssignedToInput[] | null;
  connectOrCreate?: GeneratedLeadCreateOrConnectWithoutAssignedToInput[] | null;
  createMany?: GeneratedLeadCreateManyAssignedToInputEnvelope | null;
  connect?: GeneratedLeadWhereUniqueInput[] | null;
}

export interface GeneratedLeadCreateNestedManyWithoutOfficeInput {
  create?: GeneratedLeadCreateWithoutOfficeInput[] | null;
  connectOrCreate?: GeneratedLeadCreateOrConnectWithoutOfficeInput[] | null;
  createMany?: GeneratedLeadCreateManyOfficeInputEnvelope | null;
  connect?: GeneratedLeadWhereUniqueInput[] | null;
}

export interface GeneratedLeadCreateOrConnectWithoutAssignedToInput {
  where: GeneratedLeadWhereUniqueInput;
  create: GeneratedLeadCreateWithoutAssignedToInput;
}

export interface GeneratedLeadCreateOrConnectWithoutOfficeInput {
  where: GeneratedLeadWhereUniqueInput;
  create: GeneratedLeadCreateWithoutOfficeInput;
}

export interface GeneratedLeadCreateWithoutAssignedToInput {
  id: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  status?: STATUS_TYPE | null;
  message?: string | null;
  type: GENERATED_TYPE;
  startTime: any;
  endTime: any;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  submittedBy: string;
  referral?: boolean | null;
  referralinfo?: string | null;
  notes?: string | null;
  office?: OfficeCreateNestedOneWithoutUnassignedLeadsInput | null;
}

export interface GeneratedLeadCreateWithoutOfficeInput {
  id: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  status?: STATUS_TYPE | null;
  message?: string | null;
  type: GENERATED_TYPE;
  startTime: any;
  endTime: any;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  submittedBy: string;
  referral?: boolean | null;
  referralinfo?: string | null;
  notes?: string | null;
  assignedTo?: SalesRepCreateNestedOneWithoutAssignedLeadsInput | null;
}

export interface GeneratedLeadListRelationFilter {
  every?: GeneratedLeadWhereInput | null;
  some?: GeneratedLeadWhereInput | null;
  none?: GeneratedLeadWhereInput | null;
}

export interface GeneratedLeadScalarWhereInput {
  AND?: GeneratedLeadScalarWhereInput[] | null;
  OR?: GeneratedLeadScalarWhereInput[] | null;
  NOT?: GeneratedLeadScalarWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  status?: EnumSTATUS_TYPEFilter | null;
  message?: StringNullableFilter | null;
  officeId?: IntNullableFilter | null;
  type?: EnumGENERATED_TYPEFilter | null;
  startTime?: DateTimeFilter | null;
  endTime?: DateTimeFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  email?: StringFilter | null;
  address?: StringFilter | null;
  city?: StringFilter | null;
  state?: StringFilter | null;
  zip?: StringFilter | null;
  phone?: StringFilter | null;
  submittedBy?: StringFilter | null;
  referral?: BoolNullableFilter | null;
  referralinfo?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
}

export interface GeneratedLeadUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumSTATUS_TYPEFieldUpdateOperationsInput | null;
  message?: NullableStringFieldUpdateOperationsInput | null;
  type?: EnumGENERATED_TYPEFieldUpdateOperationsInput | null;
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  address?: StringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  zip?: StringFieldUpdateOperationsInput | null;
  phone?: StringFieldUpdateOperationsInput | null;
  submittedBy?: StringFieldUpdateOperationsInput | null;
  referral?: NullableBoolFieldUpdateOperationsInput | null;
  referralinfo?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
}

export interface GeneratedLeadUpdateManyWithWhereWithoutAssignedToInput {
  where: GeneratedLeadScalarWhereInput;
  data: GeneratedLeadUpdateManyMutationInput;
}

export interface GeneratedLeadUpdateManyWithWhereWithoutOfficeInput {
  where: GeneratedLeadScalarWhereInput;
  data: GeneratedLeadUpdateManyMutationInput;
}

export interface GeneratedLeadUpdateManyWithoutAssignedToInput {
  create?: GeneratedLeadCreateWithoutAssignedToInput[] | null;
  connectOrCreate?: GeneratedLeadCreateOrConnectWithoutAssignedToInput[] | null;
  upsert?: GeneratedLeadUpsertWithWhereUniqueWithoutAssignedToInput[] | null;
  createMany?: GeneratedLeadCreateManyAssignedToInputEnvelope | null;
  connect?: GeneratedLeadWhereUniqueInput[] | null;
  set?: GeneratedLeadWhereUniqueInput[] | null;
  disconnect?: GeneratedLeadWhereUniqueInput[] | null;
  delete?: GeneratedLeadWhereUniqueInput[] | null;
  update?: GeneratedLeadUpdateWithWhereUniqueWithoutAssignedToInput[] | null;
  updateMany?: GeneratedLeadUpdateManyWithWhereWithoutAssignedToInput[] | null;
  deleteMany?: GeneratedLeadScalarWhereInput[] | null;
}

export interface GeneratedLeadUpdateManyWithoutOfficeInput {
  create?: GeneratedLeadCreateWithoutOfficeInput[] | null;
  connectOrCreate?: GeneratedLeadCreateOrConnectWithoutOfficeInput[] | null;
  upsert?: GeneratedLeadUpsertWithWhereUniqueWithoutOfficeInput[] | null;
  createMany?: GeneratedLeadCreateManyOfficeInputEnvelope | null;
  connect?: GeneratedLeadWhereUniqueInput[] | null;
  set?: GeneratedLeadWhereUniqueInput[] | null;
  disconnect?: GeneratedLeadWhereUniqueInput[] | null;
  delete?: GeneratedLeadWhereUniqueInput[] | null;
  update?: GeneratedLeadUpdateWithWhereUniqueWithoutOfficeInput[] | null;
  updateMany?: GeneratedLeadUpdateManyWithWhereWithoutOfficeInput[] | null;
  deleteMany?: GeneratedLeadScalarWhereInput[] | null;
}

export interface GeneratedLeadUpdateWithWhereUniqueWithoutAssignedToInput {
  where: GeneratedLeadWhereUniqueInput;
  data: GeneratedLeadUpdateWithoutAssignedToInput;
}

export interface GeneratedLeadUpdateWithWhereUniqueWithoutOfficeInput {
  where: GeneratedLeadWhereUniqueInput;
  data: GeneratedLeadUpdateWithoutOfficeInput;
}

export interface GeneratedLeadUpdateWithoutAssignedToInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumSTATUS_TYPEFieldUpdateOperationsInput | null;
  message?: NullableStringFieldUpdateOperationsInput | null;
  type?: EnumGENERATED_TYPEFieldUpdateOperationsInput | null;
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  address?: StringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  zip?: StringFieldUpdateOperationsInput | null;
  phone?: StringFieldUpdateOperationsInput | null;
  submittedBy?: StringFieldUpdateOperationsInput | null;
  referral?: NullableBoolFieldUpdateOperationsInput | null;
  referralinfo?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  office?: OfficeUpdateOneWithoutUnassignedLeadsInput | null;
}

export interface GeneratedLeadUpdateWithoutOfficeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumSTATUS_TYPEFieldUpdateOperationsInput | null;
  message?: NullableStringFieldUpdateOperationsInput | null;
  type?: EnumGENERATED_TYPEFieldUpdateOperationsInput | null;
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  address?: StringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  zip?: StringFieldUpdateOperationsInput | null;
  phone?: StringFieldUpdateOperationsInput | null;
  submittedBy?: StringFieldUpdateOperationsInput | null;
  referral?: NullableBoolFieldUpdateOperationsInput | null;
  referralinfo?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  assignedTo?: SalesRepUpdateOneWithoutAssignedLeadsInput | null;
}

export interface GeneratedLeadUpsertWithWhereUniqueWithoutAssignedToInput {
  where: GeneratedLeadWhereUniqueInput;
  update: GeneratedLeadUpdateWithoutAssignedToInput;
  create: GeneratedLeadCreateWithoutAssignedToInput;
}

export interface GeneratedLeadUpsertWithWhereUniqueWithoutOfficeInput {
  where: GeneratedLeadWhereUniqueInput;
  update: GeneratedLeadUpdateWithoutOfficeInput;
  create: GeneratedLeadCreateWithoutOfficeInput;
}

export interface GeneratedLeadWhereInput {
  AND?: GeneratedLeadWhereInput[] | null;
  OR?: GeneratedLeadWhereInput[] | null;
  NOT?: GeneratedLeadWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  status?: EnumSTATUS_TYPEFilter | null;
  message?: StringNullableFilter | null;
  officeId?: IntNullableFilter | null;
  type?: EnumGENERATED_TYPEFilter | null;
  startTime?: DateTimeFilter | null;
  endTime?: DateTimeFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  email?: StringFilter | null;
  address?: StringFilter | null;
  city?: StringFilter | null;
  state?: StringFilter | null;
  zip?: StringFilter | null;
  phone?: StringFilter | null;
  submittedBy?: StringFilter | null;
  referral?: BoolNullableFilter | null;
  referralinfo?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  assignedTo?: SalesRepWhereInput | null;
  office?: OfficeWhereInput | null;
}

export interface GeneratedLeadWhereUniqueInput {
  id?: string | null;
}

export interface HOACreateNestedOneWithoutProjectInput {
  create?: HOACreateWithoutProjectInput | null;
  connectOrCreate?: HOACreateOrConnectWithoutProjectInput | null;
  connect?: HOAWhereUniqueInput | null;
}

export interface HOACreateNestedOneWithoutWelcomeCallInput {
  create?: HOACreateWithoutWelcomeCallInput | null;
  connectOrCreate?: HOACreateOrConnectWithoutWelcomeCallInput | null;
  connect?: HOAWhereUniqueInput | null;
}

export interface HOACreateOrConnectWithoutProjectInput {
  where: HOAWhereUniqueInput;
  create: HOACreateWithoutProjectInput;
}

export interface HOACreateOrConnectWithoutWelcomeCallInput {
  where: HOAWhereUniqueInput;
  create: HOACreateWithoutWelcomeCallInput;
}

export interface HOACreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  accountUpdate?: string | null;
  hoaRejectionNotes?: string | null;
  hoaInfo?: string | null;
  hoaFormsRequestedDate?: any | null;
  hoaFormsReceivedDate?: any | null;
  hoaFormsFilledOutDate?: any | null;
  formsSentForHoaSignaturesDate?: any | null;
  hoaApplicationSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  scheduledInstallDate?: any | null;
  descriptionOfDesignCorrections?: string | null;
  requestDesignCorrections?: string | null;
  correctionRequstedDate?: any | null;
  correctionsCompleteDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  installCompleteDate?: any | null;
  bucketCompleteDate?: any | null;
  followUpDate?: any | null;
  region_?: HOACreateregion_Input | null;
  status_?: HOACreatestatus_Input | null;
  stepsToComplete_?: HOACreatestepsToComplete_Input | null;
  hoaNeeded_?: HOACreatehoaNeeded_Input | null;
  hoaRejection_?: HOACreatehoaRejection_Input | null;
  customerSignaturesNeeded_?: HOACreatecustomerSignaturesNeeded_Input | null;
  customerRepToSubmitApplication_?: HOACreatecustomerRepToSubmitApplication_Input | null;
  requestDesignCorrections_?: HOACreaterequestDesignCorrections_Input | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutHoaInput | null;
}

export interface HOACreateWithoutWelcomeCallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  accountUpdate?: string | null;
  hoaRejectionNotes?: string | null;
  hoaInfo?: string | null;
  hoaFormsRequestedDate?: any | null;
  hoaFormsReceivedDate?: any | null;
  hoaFormsFilledOutDate?: any | null;
  formsSentForHoaSignaturesDate?: any | null;
  hoaApplicationSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  scheduledInstallDate?: any | null;
  descriptionOfDesignCorrections?: string | null;
  requestDesignCorrections?: string | null;
  correctionRequstedDate?: any | null;
  correctionsCompleteDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  installCompleteDate?: any | null;
  bucketCompleteDate?: any | null;
  followUpDate?: any | null;
  region_?: HOACreateregion_Input | null;
  status_?: HOACreatestatus_Input | null;
  stepsToComplete_?: HOACreatestepsToComplete_Input | null;
  hoaNeeded_?: HOACreatehoaNeeded_Input | null;
  hoaRejection_?: HOACreatehoaRejection_Input | null;
  customerSignaturesNeeded_?: HOACreatecustomerSignaturesNeeded_Input | null;
  customerRepToSubmitApplication_?: HOACreatecustomerRepToSubmitApplication_Input | null;
  requestDesignCorrections_?: HOACreaterequestDesignCorrections_Input | null;
  project?: ProjectCreateNestedOneWithoutHoaInput | null;
}

export interface HOACreatecustomerRepToSubmitApplication_Input {
  set?: string[] | null;
}

export interface HOACreatecustomerSignaturesNeeded_Input {
  set?: string[] | null;
}

export interface HOACreatehoaNeeded_Input {
  set?: string[] | null;
}

export interface HOACreatehoaRejection_Input {
  set?: string[] | null;
}

export interface HOACreateregion_Input {
  set?: string[] | null;
}

export interface HOACreaterequestDesignCorrections_Input {
  set?: string[] | null;
}

export interface HOACreatestatus_Input {
  set?: string[] | null;
}

export interface HOACreatestepsToComplete_Input {
  set?: string[] | null;
}

export interface HOAUpdateOneWithoutProjectInput {
  create?: HOACreateWithoutProjectInput | null;
  connectOrCreate?: HOACreateOrConnectWithoutProjectInput | null;
  upsert?: HOAUpsertWithoutProjectInput | null;
  connect?: HOAWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: HOAUpdateWithoutProjectInput | null;
}

export interface HOAUpdateOneWithoutWelcomeCallInput {
  create?: HOACreateWithoutWelcomeCallInput | null;
  connectOrCreate?: HOACreateOrConnectWithoutWelcomeCallInput | null;
  upsert?: HOAUpsertWithoutWelcomeCallInput | null;
  connect?: HOAWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: HOAUpdateWithoutWelcomeCallInput | null;
}

export interface HOAUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  hoaRejectionNotes?: NullableStringFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  hoaFormsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsFilledOutDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  formsSentForHoaSignaturesDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApplicationSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  requestDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  correctionRequstedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  followUpDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: HOAUpdateregion_Input | null;
  status_?: HOAUpdatestatus_Input | null;
  stepsToComplete_?: HOAUpdatestepsToComplete_Input | null;
  hoaNeeded_?: HOAUpdatehoaNeeded_Input | null;
  hoaRejection_?: HOAUpdatehoaRejection_Input | null;
  customerSignaturesNeeded_?: HOAUpdatecustomerSignaturesNeeded_Input | null;
  customerRepToSubmitApplication_?: HOAUpdatecustomerRepToSubmitApplication_Input | null;
  requestDesignCorrections_?: HOAUpdaterequestDesignCorrections_Input | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutHoaInput | null;
}

export interface HOAUpdateWithoutWelcomeCallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  hoaRejectionNotes?: NullableStringFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  hoaFormsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsFilledOutDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  formsSentForHoaSignaturesDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApplicationSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  requestDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  correctionRequstedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  followUpDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  region_?: HOAUpdateregion_Input | null;
  status_?: HOAUpdatestatus_Input | null;
  stepsToComplete_?: HOAUpdatestepsToComplete_Input | null;
  hoaNeeded_?: HOAUpdatehoaNeeded_Input | null;
  hoaRejection_?: HOAUpdatehoaRejection_Input | null;
  customerSignaturesNeeded_?: HOAUpdatecustomerSignaturesNeeded_Input | null;
  customerRepToSubmitApplication_?: HOAUpdatecustomerRepToSubmitApplication_Input | null;
  requestDesignCorrections_?: HOAUpdaterequestDesignCorrections_Input | null;
  project?: ProjectUpdateOneWithoutHoaInput | null;
}

export interface HOAUpdatecustomerRepToSubmitApplication_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpdatecustomerSignaturesNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpdatehoaNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpdatehoaRejection_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpdaterequestDesignCorrections_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpdatestepsToComplete_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface HOAUpsertWithoutProjectInput {
  update: HOAUpdateWithoutProjectInput;
  create: HOACreateWithoutProjectInput;
}

export interface HOAUpsertWithoutWelcomeCallInput {
  update: HOAUpdateWithoutWelcomeCallInput;
  create: HOACreateWithoutWelcomeCallInput;
}

export interface HOAWhereInput {
  AND?: HOAWhereInput[] | null;
  OR?: HOAWhereInput[] | null;
  NOT?: HOAWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  project?: ProjectWhereInput | null;
  region_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  stepsToComplete_?: StringNullableListFilter | null;
  hoaNeeded_?: StringNullableListFilter | null;
  hoaRejection_?: StringNullableListFilter | null;
  customerSignaturesNeeded_?: StringNullableListFilter | null;
  customerRepToSubmitApplication_?: StringNullableListFilter | null;
  requestDesignCorrections_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  hoaRejectionNotes?: StringNullableFilter | null;
  hoaInfo?: StringNullableFilter | null;
  hoaFormsRequestedDate?: DateTimeNullableFilter | null;
  hoaFormsReceivedDate?: DateTimeNullableFilter | null;
  hoaFormsFilledOutDate?: DateTimeNullableFilter | null;
  formsSentForHoaSignaturesDate?: DateTimeNullableFilter | null;
  hoaApplicationSubmittedDate?: DateTimeNullableFilter | null;
  hoaApprovedDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  descriptionOfDesignCorrections?: StringNullableFilter | null;
  requestDesignCorrections?: StringNullableFilter | null;
  correctionRequstedDate?: DateTimeNullableFilter | null;
  correctionsCompleteDate?: DateTimeNullableFilter | null;
  correctionsSubmittedDate?: DateTimeNullableFilter | null;
  correctionsApprovedDate?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  bucketCompleteDate?: DateTimeNullableFilter | null;
  followUpDate?: DateTimeNullableFilter | null;
  welcomeCallId?: IntNullableFilter | null;
  welcomeCall?: WelcomeCallWhereInput | null;
  projectId?: IntNullableFilter | null;
}

export interface HOAWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  welcomeCallId?: number | null;
  projectId?: number | null;
}

export interface InstallCreateNestedOneWithoutFundingInput {
  create?: InstallCreateWithoutFundingInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutFundingInput | null;
  connect?: InstallWhereUniqueInput | null;
}

export interface InstallCreateNestedOneWithoutInstallReadyInput {
  create?: InstallCreateWithoutInstallReadyInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutInstallReadyInput | null;
  connect?: InstallWhereUniqueInput | null;
}

export interface InstallCreateNestedOneWithoutInterconnectionPostInstallInput {
  create?: InstallCreateWithoutInterconnectionPostInstallInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutInterconnectionPostInstallInput | null;
  connect?: InstallWhereUniqueInput | null;
}

export interface InstallCreateNestedOneWithoutProjectInput {
  create?: InstallCreateWithoutProjectInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutProjectInput | null;
  connect?: InstallWhereUniqueInput | null;
}

export interface InstallCreateOrConnectWithoutFundingInput {
  where: InstallWhereUniqueInput;
  create: InstallCreateWithoutFundingInput;
}

export interface InstallCreateOrConnectWithoutInstallReadyInput {
  where: InstallWhereUniqueInput;
  create: InstallCreateWithoutInstallReadyInput;
}

export interface InstallCreateOrConnectWithoutInterconnectionPostInstallInput {
  where: InstallWhereUniqueInput;
  create: InstallCreateWithoutInterconnectionPostInstallInput;
}

export interface InstallCreateOrConnectWithoutProjectInput {
  where: InstallWhereUniqueInput;
  create: InstallCreateWithoutProjectInput;
}

export interface InstallCreateWithoutFundingInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  contractSignedDate?: any | null;
  utilityDocsSentDate?: any | null;
  commissionedDate?: any | null;
  permitApprovedDate?: any | null;
  installerSchedulingAttempt1?: any | null;
  installerSchedulingAttempt2?: any | null;
  installerSchedulingAttempt3?: any | null;
  customerSchedulingAttempt1Lvm?: any | null;
  customerSchedulingAttempt2Lvm?: any | null;
  customerSchedulingAttempt3Lvm?: any | null;
  mpuScheduledDate?: any | null;
  mpuCompleteDate?: any | null;
  roofScheduledDate?: any | null;
  scheduledInstallDate?: any | null;
  roughInspectionScheduledDate?: any | null;
  roughInspectionCompleteDate?: any | null;
  modulesInstallScheduledDate?: any | null;
  modulesInstallCompleteDate?: any | null;
  electricalWorkScheduledDate?: any | null;
  electricalWorkCompletedDate?: any | null;
  photoAuditFailureDate?: any | null;
  m1SubmittedDate?: any | null;
  requestedDeliveryDate?: any | null;
  equipmentOrderedDate?: any | null;
  equipmentDeliveryDate?: any | null;
  adderFormSignedDate?: any | null;
  scheduledServiceDate?: any | null;
  finalSolarInspectionDate?: any | null;
  ficUploadedDate?: any | null;
  inspectionFailedDate?: any | null;
  inspectionCompleteDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  nemApprovedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  timestampEmailToDistributor?: any | null;
  installCompleteDate?: any | null;
  hoaApprovedDate?: any | null;
  nemApprovedDate3?: any | null;
  accountUpdate?: string | null;
  customerCity?: string | null;
  reasonForHold?: string | null;
  specialInstallInstructionsRequests?: string | null;
  workOrderRef?: string | null;
  installerSchedulingNotes?: string | null;
  customerSchedulingNotes?: string | null;
  deliveryLocationNotes?: string | null;
  installerArrivalWindow?: string | null;
  descriptionOfElectricalNotYetCompleted?: string | null;
  missingPhotos?: string | null;
  reasonInspectionFailedOrConditionalPass?: string | null;
  missingIssues?: string | null;
  descriptionOfDesignCorrections?: string | null;
  ruleOfBackcharge?: string | null;
  costOfRepair?: number | null;
  totalCost?: number | null;
  description?: string | null;
  installerName?: string | null;
  siteCaptureInstallerId?: string | null;
  siteCaptureProjectId?: string | null;
  pathToDropboxParentFolder?: string | null;
  numberOfModules?: number | null;
  numberOfArrays?: number | null;
  installItemId?: string | null;
  bucketActivation_?: InstallCreatebucketActivation_Input | null;
  status_?: InstallCreatestatus_Input | null;
  stepsToComplete_?: InstallCreatestepsToComplete_Input | null;
  region_?: InstallCreateregion_Input | null;
  proceedWOutPermit_?: InstallCreateproceedWOutPermit_Input | null;
  calendarColor_?: InstallCreatecalendarColor_Input | null;
  textEmailSent_?: InstallCreatetextEmailSent_Input | null;
  textemailSent2_?: InstallCreatetextemailSent2_Input | null;
  textemailSent3_?: InstallCreatetextemailSent3_Input | null;
  roofComplete_?: InstallCreateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallCreatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallCreatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallCreatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallCreateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallCreatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallCreatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallCreatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallCreaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallCreatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallCreatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallCreateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallCreatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallCreatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallCreatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallCreatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallCreatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallCreateorganizeDropboxFolders_Input | null;
  project?: ProjectCreateNestedOneWithoutInstallInspectionInput | null;
  interconnectionPostInstall?: InterconnectionPostInstallCreateNestedOneWithoutLinkToInstallInput | null;
  InstallReady?: InstallReadyCreateNestedManyWithoutLinkToInstallInput | null;
}

export interface InstallCreateWithoutInstallReadyInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  contractSignedDate?: any | null;
  utilityDocsSentDate?: any | null;
  commissionedDate?: any | null;
  permitApprovedDate?: any | null;
  installerSchedulingAttempt1?: any | null;
  installerSchedulingAttempt2?: any | null;
  installerSchedulingAttempt3?: any | null;
  customerSchedulingAttempt1Lvm?: any | null;
  customerSchedulingAttempt2Lvm?: any | null;
  customerSchedulingAttempt3Lvm?: any | null;
  mpuScheduledDate?: any | null;
  mpuCompleteDate?: any | null;
  roofScheduledDate?: any | null;
  scheduledInstallDate?: any | null;
  roughInspectionScheduledDate?: any | null;
  roughInspectionCompleteDate?: any | null;
  modulesInstallScheduledDate?: any | null;
  modulesInstallCompleteDate?: any | null;
  electricalWorkScheduledDate?: any | null;
  electricalWorkCompletedDate?: any | null;
  photoAuditFailureDate?: any | null;
  m1SubmittedDate?: any | null;
  requestedDeliveryDate?: any | null;
  equipmentOrderedDate?: any | null;
  equipmentDeliveryDate?: any | null;
  adderFormSignedDate?: any | null;
  scheduledServiceDate?: any | null;
  finalSolarInspectionDate?: any | null;
  ficUploadedDate?: any | null;
  inspectionFailedDate?: any | null;
  inspectionCompleteDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  nemApprovedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  timestampEmailToDistributor?: any | null;
  installCompleteDate?: any | null;
  hoaApprovedDate?: any | null;
  nemApprovedDate3?: any | null;
  accountUpdate?: string | null;
  customerCity?: string | null;
  reasonForHold?: string | null;
  specialInstallInstructionsRequests?: string | null;
  workOrderRef?: string | null;
  installerSchedulingNotes?: string | null;
  customerSchedulingNotes?: string | null;
  deliveryLocationNotes?: string | null;
  installerArrivalWindow?: string | null;
  descriptionOfElectricalNotYetCompleted?: string | null;
  missingPhotos?: string | null;
  reasonInspectionFailedOrConditionalPass?: string | null;
  missingIssues?: string | null;
  descriptionOfDesignCorrections?: string | null;
  ruleOfBackcharge?: string | null;
  costOfRepair?: number | null;
  totalCost?: number | null;
  description?: string | null;
  installerName?: string | null;
  siteCaptureInstallerId?: string | null;
  siteCaptureProjectId?: string | null;
  pathToDropboxParentFolder?: string | null;
  numberOfModules?: number | null;
  numberOfArrays?: number | null;
  installItemId?: string | null;
  bucketActivation_?: InstallCreatebucketActivation_Input | null;
  status_?: InstallCreatestatus_Input | null;
  stepsToComplete_?: InstallCreatestepsToComplete_Input | null;
  region_?: InstallCreateregion_Input | null;
  proceedWOutPermit_?: InstallCreateproceedWOutPermit_Input | null;
  calendarColor_?: InstallCreatecalendarColor_Input | null;
  textEmailSent_?: InstallCreatetextEmailSent_Input | null;
  textemailSent2_?: InstallCreatetextemailSent2_Input | null;
  textemailSent3_?: InstallCreatetextemailSent3_Input | null;
  roofComplete_?: InstallCreateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallCreatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallCreatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallCreatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallCreateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallCreatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallCreatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallCreatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallCreaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallCreatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallCreatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallCreateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallCreatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallCreatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallCreatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallCreatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallCreatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallCreateorganizeDropboxFolders_Input | null;
  project?: ProjectCreateNestedOneWithoutInstallInspectionInput | null;
  interconnectionPostInstall?: InterconnectionPostInstallCreateNestedOneWithoutLinkToInstallInput | null;
  funding?: FundingCreateNestedManyWithoutInstallPageInput | null;
}

export interface InstallCreateWithoutInterconnectionPostInstallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  contractSignedDate?: any | null;
  utilityDocsSentDate?: any | null;
  commissionedDate?: any | null;
  permitApprovedDate?: any | null;
  installerSchedulingAttempt1?: any | null;
  installerSchedulingAttempt2?: any | null;
  installerSchedulingAttempt3?: any | null;
  customerSchedulingAttempt1Lvm?: any | null;
  customerSchedulingAttempt2Lvm?: any | null;
  customerSchedulingAttempt3Lvm?: any | null;
  mpuScheduledDate?: any | null;
  mpuCompleteDate?: any | null;
  roofScheduledDate?: any | null;
  scheduledInstallDate?: any | null;
  roughInspectionScheduledDate?: any | null;
  roughInspectionCompleteDate?: any | null;
  modulesInstallScheduledDate?: any | null;
  modulesInstallCompleteDate?: any | null;
  electricalWorkScheduledDate?: any | null;
  electricalWorkCompletedDate?: any | null;
  photoAuditFailureDate?: any | null;
  m1SubmittedDate?: any | null;
  requestedDeliveryDate?: any | null;
  equipmentOrderedDate?: any | null;
  equipmentDeliveryDate?: any | null;
  adderFormSignedDate?: any | null;
  scheduledServiceDate?: any | null;
  finalSolarInspectionDate?: any | null;
  ficUploadedDate?: any | null;
  inspectionFailedDate?: any | null;
  inspectionCompleteDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  nemApprovedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  timestampEmailToDistributor?: any | null;
  installCompleteDate?: any | null;
  hoaApprovedDate?: any | null;
  nemApprovedDate3?: any | null;
  accountUpdate?: string | null;
  customerCity?: string | null;
  reasonForHold?: string | null;
  specialInstallInstructionsRequests?: string | null;
  workOrderRef?: string | null;
  installerSchedulingNotes?: string | null;
  customerSchedulingNotes?: string | null;
  deliveryLocationNotes?: string | null;
  installerArrivalWindow?: string | null;
  descriptionOfElectricalNotYetCompleted?: string | null;
  missingPhotos?: string | null;
  reasonInspectionFailedOrConditionalPass?: string | null;
  missingIssues?: string | null;
  descriptionOfDesignCorrections?: string | null;
  ruleOfBackcharge?: string | null;
  costOfRepair?: number | null;
  totalCost?: number | null;
  description?: string | null;
  installerName?: string | null;
  siteCaptureInstallerId?: string | null;
  siteCaptureProjectId?: string | null;
  pathToDropboxParentFolder?: string | null;
  numberOfModules?: number | null;
  numberOfArrays?: number | null;
  installItemId?: string | null;
  bucketActivation_?: InstallCreatebucketActivation_Input | null;
  status_?: InstallCreatestatus_Input | null;
  stepsToComplete_?: InstallCreatestepsToComplete_Input | null;
  region_?: InstallCreateregion_Input | null;
  proceedWOutPermit_?: InstallCreateproceedWOutPermit_Input | null;
  calendarColor_?: InstallCreatecalendarColor_Input | null;
  textEmailSent_?: InstallCreatetextEmailSent_Input | null;
  textemailSent2_?: InstallCreatetextemailSent2_Input | null;
  textemailSent3_?: InstallCreatetextemailSent3_Input | null;
  roofComplete_?: InstallCreateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallCreatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallCreatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallCreatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallCreateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallCreatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallCreatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallCreatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallCreaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallCreatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallCreatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallCreateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallCreatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallCreatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallCreatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallCreatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallCreatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallCreateorganizeDropboxFolders_Input | null;
  project?: ProjectCreateNestedOneWithoutInstallInspectionInput | null;
  funding?: FundingCreateNestedManyWithoutInstallPageInput | null;
  InstallReady?: InstallReadyCreateNestedManyWithoutLinkToInstallInput | null;
}

export interface InstallCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  contractSignedDate?: any | null;
  utilityDocsSentDate?: any | null;
  commissionedDate?: any | null;
  permitApprovedDate?: any | null;
  installerSchedulingAttempt1?: any | null;
  installerSchedulingAttempt2?: any | null;
  installerSchedulingAttempt3?: any | null;
  customerSchedulingAttempt1Lvm?: any | null;
  customerSchedulingAttempt2Lvm?: any | null;
  customerSchedulingAttempt3Lvm?: any | null;
  mpuScheduledDate?: any | null;
  mpuCompleteDate?: any | null;
  roofScheduledDate?: any | null;
  scheduledInstallDate?: any | null;
  roughInspectionScheduledDate?: any | null;
  roughInspectionCompleteDate?: any | null;
  modulesInstallScheduledDate?: any | null;
  modulesInstallCompleteDate?: any | null;
  electricalWorkScheduledDate?: any | null;
  electricalWorkCompletedDate?: any | null;
  photoAuditFailureDate?: any | null;
  m1SubmittedDate?: any | null;
  requestedDeliveryDate?: any | null;
  equipmentOrderedDate?: any | null;
  equipmentDeliveryDate?: any | null;
  adderFormSignedDate?: any | null;
  scheduledServiceDate?: any | null;
  finalSolarInspectionDate?: any | null;
  ficUploadedDate?: any | null;
  inspectionFailedDate?: any | null;
  inspectionCompleteDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  nemApprovedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  timestampEmailToDistributor?: any | null;
  installCompleteDate?: any | null;
  hoaApprovedDate?: any | null;
  nemApprovedDate3?: any | null;
  accountUpdate?: string | null;
  customerCity?: string | null;
  reasonForHold?: string | null;
  specialInstallInstructionsRequests?: string | null;
  workOrderRef?: string | null;
  installerSchedulingNotes?: string | null;
  customerSchedulingNotes?: string | null;
  deliveryLocationNotes?: string | null;
  installerArrivalWindow?: string | null;
  descriptionOfElectricalNotYetCompleted?: string | null;
  missingPhotos?: string | null;
  reasonInspectionFailedOrConditionalPass?: string | null;
  missingIssues?: string | null;
  descriptionOfDesignCorrections?: string | null;
  ruleOfBackcharge?: string | null;
  costOfRepair?: number | null;
  totalCost?: number | null;
  description?: string | null;
  installerName?: string | null;
  siteCaptureInstallerId?: string | null;
  siteCaptureProjectId?: string | null;
  pathToDropboxParentFolder?: string | null;
  numberOfModules?: number | null;
  numberOfArrays?: number | null;
  installItemId?: string | null;
  bucketActivation_?: InstallCreatebucketActivation_Input | null;
  status_?: InstallCreatestatus_Input | null;
  stepsToComplete_?: InstallCreatestepsToComplete_Input | null;
  region_?: InstallCreateregion_Input | null;
  proceedWOutPermit_?: InstallCreateproceedWOutPermit_Input | null;
  calendarColor_?: InstallCreatecalendarColor_Input | null;
  textEmailSent_?: InstallCreatetextEmailSent_Input | null;
  textemailSent2_?: InstallCreatetextemailSent2_Input | null;
  textemailSent3_?: InstallCreatetextemailSent3_Input | null;
  roofComplete_?: InstallCreateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallCreatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallCreatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallCreatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallCreateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallCreatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallCreatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallCreatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallCreaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallCreatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallCreatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallCreateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallCreatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallCreatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallCreatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallCreatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallCreatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallCreateorganizeDropboxFolders_Input | null;
  interconnectionPostInstall?: InterconnectionPostInstallCreateNestedOneWithoutLinkToInstallInput | null;
  funding?: FundingCreateNestedManyWithoutInstallPageInput | null;
  InstallReady?: InstallReadyCreateNestedManyWithoutLinkToInstallInput | null;
}

export interface InstallCreateadderFormNeeded_Input {
  set?: string[] | null;
}

export interface InstallCreatebucketActivation_Input {
  set?: string[] | null;
}

export interface InstallCreatecalendarColor_Input {
  set?: string[] | null;
}

export interface InstallCreatecreateInstallerPurchaseOrder_Input {
  set?: string[] | null;
}

export interface InstallCreatecreateUtilityApprovalPto_Input {
  set?: string[] | null;
}

export interface InstallCreatedidInspectionPass_Input {
  set?: string[] | null;
}

export interface InstallCreatefailedInspectionOccurrences_Input {
  set?: string[] | null;
}

export interface InstallCreatefailureToUpdateTheCrmOccurrences_Input {
  set?: string[] | null;
}

export interface InstallCreateinstallScheduledDateChanged_Input {
  set?: string[] | null;
}

export interface InstallCreatemissingPhotosNotification_Input {
  set?: string[] | null;
}

export interface InstallCreatempuLinked_Input {
  set?: string[] | null;
}

export interface InstallCreatenoCallNoShowOccurrences_Input {
  set?: string[] | null;
}

export interface InstallCreateorganizeDropboxFolders_Input {
  set?: string[] | null;
}

export interface InstallCreatepermitCorrectionsNeeded_Input {
  set?: string[] | null;
}

export interface InstallCreatephotoAudit_Input {
  set?: string[] | null;
}

export interface InstallCreateproceedWOutPermit_Input {
  set?: string[] | null;
}

export interface InstallCreatereasonForBackcharge_Input {
  set?: string[] | null;
}

export interface InstallCreateregion_Input {
  set?: string[] | null;
}

export interface InstallCreaterequestDesignCorrections_Input {
  set?: string[] | null;
}

export interface InstallCreateroofComplete_Input {
  set?: string[] | null;
}

export interface InstallCreatescheduledInspectionConfirmedWithCustomer_Input {
  set?: string[] | null;
}

export interface InstallCreatescheduledInstallConfirmedWithCustomer_Input {
  set?: string[] | null;
}

export interface InstallCreatestatus_Input {
  set?: string[] | null;
}

export interface InstallCreatestepsToComplete_Input {
  set?: string[] | null;
}

export interface InstallCreatetextEmailSent_Input {
  set?: string[] | null;
}

export interface InstallCreatetextemailSent2_Input {
  set?: string[] | null;
}

export interface InstallCreatetextemailSent3_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateManyLinkToInstallInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  installReadyDate?: any | null;
  title?: string | null;
  masterCustomerId?: number | null;
  installReady_?: InstallReadyCreateManyinstallReady_Input | null;
  reRoof_?: InstallReadyCreateManyreRoof_Input | null;
  permit_?: InstallReadyCreateManypermit_Input | null;
  ntp_?: InstallReadyCreateManyntp_Input | null;
  utilityApproval_?: InstallReadyCreateManyutilityApproval_Input | null;
  credit_?: InstallReadyCreateManycredit_Input | null;
  hoa_?: InstallReadyCreateManyhoa_Input | null;
}

export interface InstallReadyCreateManyLinkToInstallInputEnvelope {
  data?: InstallReadyCreateManyLinkToInstallInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface InstallReadyCreateManyLinkToMasterListInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  installReadyDate?: any | null;
  title?: string | null;
  installId?: number | null;
  installReady_?: InstallReadyCreateManyinstallReady_Input | null;
  reRoof_?: InstallReadyCreateManyreRoof_Input | null;
  permit_?: InstallReadyCreateManypermit_Input | null;
  ntp_?: InstallReadyCreateManyntp_Input | null;
  utilityApproval_?: InstallReadyCreateManyutilityApproval_Input | null;
  credit_?: InstallReadyCreateManycredit_Input | null;
  hoa_?: InstallReadyCreateManyhoa_Input | null;
}

export interface InstallReadyCreateManyLinkToMasterListInputEnvelope {
  data?: InstallReadyCreateManyLinkToMasterListInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface InstallReadyCreateManycredit_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateManyhoa_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateManyinstallReady_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateManyntp_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateManypermit_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateManyreRoof_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateManyutilityApproval_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateNestedManyWithoutLinkToInstallInput {
  create?: InstallReadyCreateWithoutLinkToInstallInput[] | null;
  connectOrCreate?: InstallReadyCreateOrConnectWithoutLinkToInstallInput[] | null;
  createMany?: InstallReadyCreateManyLinkToInstallInputEnvelope | null;
  connect?: InstallReadyWhereUniqueInput[] | null;
}

export interface InstallReadyCreateNestedManyWithoutLinkToMasterListInput {
  create?: InstallReadyCreateWithoutLinkToMasterListInput[] | null;
  connectOrCreate?: InstallReadyCreateOrConnectWithoutLinkToMasterListInput[] | null;
  createMany?: InstallReadyCreateManyLinkToMasterListInputEnvelope | null;
  connect?: InstallReadyWhereUniqueInput[] | null;
}

export interface InstallReadyCreateNestedOneWithoutProjectInput {
  create?: InstallReadyCreateWithoutProjectInput | null;
  connectOrCreate?: InstallReadyCreateOrConnectWithoutProjectInput | null;
  connect?: InstallReadyWhereUniqueInput | null;
}

export interface InstallReadyCreateOrConnectWithoutLinkToInstallInput {
  where: InstallReadyWhereUniqueInput;
  create: InstallReadyCreateWithoutLinkToInstallInput;
}

export interface InstallReadyCreateOrConnectWithoutLinkToMasterListInput {
  where: InstallReadyWhereUniqueInput;
  create: InstallReadyCreateWithoutLinkToMasterListInput;
}

export interface InstallReadyCreateOrConnectWithoutProjectInput {
  where: InstallReadyWhereUniqueInput;
  create: InstallReadyCreateWithoutProjectInput;
}

export interface InstallReadyCreateWithoutLinkToInstallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  installReadyDate?: any | null;
  title?: string | null;
  installReady_?: InstallReadyCreateinstallReady_Input | null;
  reRoof_?: InstallReadyCreatereRoof_Input | null;
  permit_?: InstallReadyCreatepermit_Input | null;
  ntp_?: InstallReadyCreatentp_Input | null;
  utilityApproval_?: InstallReadyCreateutilityApproval_Input | null;
  credit_?: InstallReadyCreatecredit_Input | null;
  hoa_?: InstallReadyCreatehoa_Input | null;
  project?: ProjectCreateNestedOneWithoutInstallReadyInput | null;
  linkToMasterList?: MasterCustomerCreateNestedOneWithoutInstallReadyInput | null;
}

export interface InstallReadyCreateWithoutLinkToMasterListInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  installReadyDate?: any | null;
  title?: string | null;
  installReady_?: InstallReadyCreateinstallReady_Input | null;
  reRoof_?: InstallReadyCreatereRoof_Input | null;
  permit_?: InstallReadyCreatepermit_Input | null;
  ntp_?: InstallReadyCreatentp_Input | null;
  utilityApproval_?: InstallReadyCreateutilityApproval_Input | null;
  credit_?: InstallReadyCreatecredit_Input | null;
  hoa_?: InstallReadyCreatehoa_Input | null;
  project?: ProjectCreateNestedOneWithoutInstallReadyInput | null;
  linkToInstall?: InstallCreateNestedOneWithoutInstallReadyInput | null;
}

export interface InstallReadyCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  installReadyDate?: any | null;
  title?: string | null;
  installReady_?: InstallReadyCreateinstallReady_Input | null;
  reRoof_?: InstallReadyCreatereRoof_Input | null;
  permit_?: InstallReadyCreatepermit_Input | null;
  ntp_?: InstallReadyCreatentp_Input | null;
  utilityApproval_?: InstallReadyCreateutilityApproval_Input | null;
  credit_?: InstallReadyCreatecredit_Input | null;
  hoa_?: InstallReadyCreatehoa_Input | null;
  linkToInstall?: InstallCreateNestedOneWithoutInstallReadyInput | null;
  linkToMasterList?: MasterCustomerCreateNestedOneWithoutInstallReadyInput | null;
}

export interface InstallReadyCreatecredit_Input {
  set?: string[] | null;
}

export interface InstallReadyCreatehoa_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateinstallReady_Input {
  set?: string[] | null;
}

export interface InstallReadyCreatentp_Input {
  set?: string[] | null;
}

export interface InstallReadyCreatepermit_Input {
  set?: string[] | null;
}

export interface InstallReadyCreatereRoof_Input {
  set?: string[] | null;
}

export interface InstallReadyCreateutilityApproval_Input {
  set?: string[] | null;
}

export interface InstallReadyListRelationFilter {
  every?: InstallReadyWhereInput | null;
  some?: InstallReadyWhereInput | null;
  none?: InstallReadyWhereInput | null;
}

export interface InstallReadyScalarWhereInput {
  AND?: InstallReadyScalarWhereInput[] | null;
  OR?: InstallReadyScalarWhereInput[] | null;
  NOT?: InstallReadyScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  installReady_?: StringNullableListFilter | null;
  reRoof_?: StringNullableListFilter | null;
  permit_?: StringNullableListFilter | null;
  ntp_?: StringNullableListFilter | null;
  utilityApproval_?: StringNullableListFilter | null;
  credit_?: StringNullableListFilter | null;
  hoa_?: StringNullableListFilter | null;
  installReadyDate?: DateTimeNullableFilter | null;
  title?: StringNullableFilter | null;
  installId?: IntNullableFilter | null;
  masterCustomerId?: IntNullableFilter | null;
}

export interface InstallReadyUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  installReadyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  installReady_?: InstallReadyUpdateinstallReady_Input | null;
  reRoof_?: InstallReadyUpdatereRoof_Input | null;
  permit_?: InstallReadyUpdatepermit_Input | null;
  ntp_?: InstallReadyUpdatentp_Input | null;
  utilityApproval_?: InstallReadyUpdateutilityApproval_Input | null;
  credit_?: InstallReadyUpdatecredit_Input | null;
  hoa_?: InstallReadyUpdatehoa_Input | null;
}

export interface InstallReadyUpdateManyWithWhereWithoutLinkToInstallInput {
  where: InstallReadyScalarWhereInput;
  data: InstallReadyUpdateManyMutationInput;
}

export interface InstallReadyUpdateManyWithWhereWithoutLinkToMasterListInput {
  where: InstallReadyScalarWhereInput;
  data: InstallReadyUpdateManyMutationInput;
}

export interface InstallReadyUpdateManyWithoutLinkToInstallInput {
  create?: InstallReadyCreateWithoutLinkToInstallInput[] | null;
  connectOrCreate?: InstallReadyCreateOrConnectWithoutLinkToInstallInput[] | null;
  upsert?: InstallReadyUpsertWithWhereUniqueWithoutLinkToInstallInput[] | null;
  createMany?: InstallReadyCreateManyLinkToInstallInputEnvelope | null;
  connect?: InstallReadyWhereUniqueInput[] | null;
  set?: InstallReadyWhereUniqueInput[] | null;
  disconnect?: InstallReadyWhereUniqueInput[] | null;
  delete?: InstallReadyWhereUniqueInput[] | null;
  update?: InstallReadyUpdateWithWhereUniqueWithoutLinkToInstallInput[] | null;
  updateMany?: InstallReadyUpdateManyWithWhereWithoutLinkToInstallInput[] | null;
  deleteMany?: InstallReadyScalarWhereInput[] | null;
}

export interface InstallReadyUpdateManyWithoutLinkToMasterListInput {
  create?: InstallReadyCreateWithoutLinkToMasterListInput[] | null;
  connectOrCreate?: InstallReadyCreateOrConnectWithoutLinkToMasterListInput[] | null;
  upsert?: InstallReadyUpsertWithWhereUniqueWithoutLinkToMasterListInput[] | null;
  createMany?: InstallReadyCreateManyLinkToMasterListInputEnvelope | null;
  connect?: InstallReadyWhereUniqueInput[] | null;
  set?: InstallReadyWhereUniqueInput[] | null;
  disconnect?: InstallReadyWhereUniqueInput[] | null;
  delete?: InstallReadyWhereUniqueInput[] | null;
  update?: InstallReadyUpdateWithWhereUniqueWithoutLinkToMasterListInput[] | null;
  updateMany?: InstallReadyUpdateManyWithWhereWithoutLinkToMasterListInput[] | null;
  deleteMany?: InstallReadyScalarWhereInput[] | null;
}

export interface InstallReadyUpdateOneWithoutProjectInput {
  create?: InstallReadyCreateWithoutProjectInput | null;
  connectOrCreate?: InstallReadyCreateOrConnectWithoutProjectInput | null;
  upsert?: InstallReadyUpsertWithoutProjectInput | null;
  connect?: InstallReadyWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InstallReadyUpdateWithoutProjectInput | null;
}

export interface InstallReadyUpdateWithWhereUniqueWithoutLinkToInstallInput {
  where: InstallReadyWhereUniqueInput;
  data: InstallReadyUpdateWithoutLinkToInstallInput;
}

export interface InstallReadyUpdateWithWhereUniqueWithoutLinkToMasterListInput {
  where: InstallReadyWhereUniqueInput;
  data: InstallReadyUpdateWithoutLinkToMasterListInput;
}

export interface InstallReadyUpdateWithoutLinkToInstallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  installReadyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  installReady_?: InstallReadyUpdateinstallReady_Input | null;
  reRoof_?: InstallReadyUpdatereRoof_Input | null;
  permit_?: InstallReadyUpdatepermit_Input | null;
  ntp_?: InstallReadyUpdatentp_Input | null;
  utilityApproval_?: InstallReadyUpdateutilityApproval_Input | null;
  credit_?: InstallReadyUpdatecredit_Input | null;
  hoa_?: InstallReadyUpdatehoa_Input | null;
  project?: ProjectUpdateOneWithoutInstallReadyInput | null;
  linkToMasterList?: MasterCustomerUpdateOneWithoutInstallReadyInput | null;
}

export interface InstallReadyUpdateWithoutLinkToMasterListInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  installReadyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  installReady_?: InstallReadyUpdateinstallReady_Input | null;
  reRoof_?: InstallReadyUpdatereRoof_Input | null;
  permit_?: InstallReadyUpdatepermit_Input | null;
  ntp_?: InstallReadyUpdatentp_Input | null;
  utilityApproval_?: InstallReadyUpdateutilityApproval_Input | null;
  credit_?: InstallReadyUpdatecredit_Input | null;
  hoa_?: InstallReadyUpdatehoa_Input | null;
  project?: ProjectUpdateOneWithoutInstallReadyInput | null;
  linkToInstall?: InstallUpdateOneWithoutInstallReadyInput | null;
}

export interface InstallReadyUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  installReadyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  installReady_?: InstallReadyUpdateinstallReady_Input | null;
  reRoof_?: InstallReadyUpdatereRoof_Input | null;
  permit_?: InstallReadyUpdatepermit_Input | null;
  ntp_?: InstallReadyUpdatentp_Input | null;
  utilityApproval_?: InstallReadyUpdateutilityApproval_Input | null;
  credit_?: InstallReadyUpdatecredit_Input | null;
  hoa_?: InstallReadyUpdatehoa_Input | null;
  linkToInstall?: InstallUpdateOneWithoutInstallReadyInput | null;
  linkToMasterList?: MasterCustomerUpdateOneWithoutInstallReadyInput | null;
}

export interface InstallReadyUpdatecredit_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallReadyUpdatehoa_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallReadyUpdateinstallReady_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallReadyUpdatentp_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallReadyUpdatepermit_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallReadyUpdatereRoof_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallReadyUpdateutilityApproval_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallReadyUpsertWithWhereUniqueWithoutLinkToInstallInput {
  where: InstallReadyWhereUniqueInput;
  update: InstallReadyUpdateWithoutLinkToInstallInput;
  create: InstallReadyCreateWithoutLinkToInstallInput;
}

export interface InstallReadyUpsertWithWhereUniqueWithoutLinkToMasterListInput {
  where: InstallReadyWhereUniqueInput;
  update: InstallReadyUpdateWithoutLinkToMasterListInput;
  create: InstallReadyCreateWithoutLinkToMasterListInput;
}

export interface InstallReadyUpsertWithoutProjectInput {
  update: InstallReadyUpdateWithoutProjectInput;
  create: InstallReadyCreateWithoutProjectInput;
}

export interface InstallReadyWhereInput {
  AND?: InstallReadyWhereInput[] | null;
  OR?: InstallReadyWhereInput[] | null;
  NOT?: InstallReadyWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  installReady_?: StringNullableListFilter | null;
  reRoof_?: StringNullableListFilter | null;
  permit_?: StringNullableListFilter | null;
  ntp_?: StringNullableListFilter | null;
  utilityApproval_?: StringNullableListFilter | null;
  credit_?: StringNullableListFilter | null;
  hoa_?: StringNullableListFilter | null;
  installReadyDate?: DateTimeNullableFilter | null;
  title?: StringNullableFilter | null;
  project?: ProjectWhereInput | null;
  linkToInstall?: InstallWhereInput | null;
  linkToMasterList?: MasterCustomerWhereInput | null;
  installId?: IntNullableFilter | null;
  masterCustomerId?: IntNullableFilter | null;
}

export interface InstallReadyWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
}

export interface InstallUpdateOneWithoutFundingInput {
  create?: InstallCreateWithoutFundingInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutFundingInput | null;
  upsert?: InstallUpsertWithoutFundingInput | null;
  connect?: InstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InstallUpdateWithoutFundingInput | null;
}

export interface InstallUpdateOneWithoutInstallReadyInput {
  create?: InstallCreateWithoutInstallReadyInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutInstallReadyInput | null;
  upsert?: InstallUpsertWithoutInstallReadyInput | null;
  connect?: InstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InstallUpdateWithoutInstallReadyInput | null;
}

export interface InstallUpdateOneWithoutInterconnectionPostInstallInput {
  create?: InstallCreateWithoutInterconnectionPostInstallInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutInterconnectionPostInstallInput | null;
  upsert?: InstallUpsertWithoutInterconnectionPostInstallInput | null;
  connect?: InstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InstallUpdateWithoutInterconnectionPostInstallInput | null;
}

export interface InstallUpdateOneWithoutProjectInput {
  create?: InstallCreateWithoutProjectInput | null;
  connectOrCreate?: InstallCreateOrConnectWithoutProjectInput | null;
  upsert?: InstallUpsertWithoutProjectInput | null;
  connect?: InstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InstallUpdateWithoutProjectInput | null;
}

export interface InstallUpdateWithoutFundingInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  commissionedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt1?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt2?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt3?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt1Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt2Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt3Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roofScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  photoAuditFailureDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  requestedDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentOrderedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  adderFormSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledServiceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  finalSolarInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ficUploadedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionFailedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timestampEmailToDistributor?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate3?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  customerCity?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  specialInstallInstructionsRequests?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  installerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  deliveryLocationNotes?: NullableStringFieldUpdateOperationsInput | null;
  installerArrivalWindow?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfElectricalNotYetCompleted?: NullableStringFieldUpdateOperationsInput | null;
  missingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  reasonInspectionFailedOrConditionalPass?: NullableStringFieldUpdateOperationsInput | null;
  missingIssues?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  ruleOfBackcharge?: NullableStringFieldUpdateOperationsInput | null;
  costOfRepair?: NullableFloatFieldUpdateOperationsInput | null;
  totalCost?: NullableFloatFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  installerName?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureInstallerId?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureProjectId?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  numberOfArrays?: NullableIntFieldUpdateOperationsInput | null;
  installItemId?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: InstallUpdatebucketActivation_Input | null;
  status_?: InstallUpdatestatus_Input | null;
  stepsToComplete_?: InstallUpdatestepsToComplete_Input | null;
  region_?: InstallUpdateregion_Input | null;
  proceedWOutPermit_?: InstallUpdateproceedWOutPermit_Input | null;
  calendarColor_?: InstallUpdatecalendarColor_Input | null;
  textEmailSent_?: InstallUpdatetextEmailSent_Input | null;
  textemailSent2_?: InstallUpdatetextemailSent2_Input | null;
  textemailSent3_?: InstallUpdatetextemailSent3_Input | null;
  roofComplete_?: InstallUpdateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallUpdatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallUpdatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallUpdatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallUpdateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallUpdatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallUpdatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallUpdatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallUpdaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallUpdatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallUpdatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallUpdateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallUpdatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallUpdatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallUpdatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallUpdatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallUpdatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallUpdateorganizeDropboxFolders_Input | null;
  project?: ProjectUpdateOneWithoutInstallInspectionInput | null;
  interconnectionPostInstall?: InterconnectionPostInstallUpdateOneWithoutLinkToInstallInput | null;
  InstallReady?: InstallReadyUpdateManyWithoutLinkToInstallInput | null;
}

export interface InstallUpdateWithoutInstallReadyInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  commissionedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt1?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt2?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt3?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt1Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt2Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt3Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roofScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  photoAuditFailureDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  requestedDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentOrderedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  adderFormSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledServiceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  finalSolarInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ficUploadedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionFailedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timestampEmailToDistributor?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate3?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  customerCity?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  specialInstallInstructionsRequests?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  installerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  deliveryLocationNotes?: NullableStringFieldUpdateOperationsInput | null;
  installerArrivalWindow?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfElectricalNotYetCompleted?: NullableStringFieldUpdateOperationsInput | null;
  missingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  reasonInspectionFailedOrConditionalPass?: NullableStringFieldUpdateOperationsInput | null;
  missingIssues?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  ruleOfBackcharge?: NullableStringFieldUpdateOperationsInput | null;
  costOfRepair?: NullableFloatFieldUpdateOperationsInput | null;
  totalCost?: NullableFloatFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  installerName?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureInstallerId?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureProjectId?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  numberOfArrays?: NullableIntFieldUpdateOperationsInput | null;
  installItemId?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: InstallUpdatebucketActivation_Input | null;
  status_?: InstallUpdatestatus_Input | null;
  stepsToComplete_?: InstallUpdatestepsToComplete_Input | null;
  region_?: InstallUpdateregion_Input | null;
  proceedWOutPermit_?: InstallUpdateproceedWOutPermit_Input | null;
  calendarColor_?: InstallUpdatecalendarColor_Input | null;
  textEmailSent_?: InstallUpdatetextEmailSent_Input | null;
  textemailSent2_?: InstallUpdatetextemailSent2_Input | null;
  textemailSent3_?: InstallUpdatetextemailSent3_Input | null;
  roofComplete_?: InstallUpdateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallUpdatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallUpdatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallUpdatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallUpdateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallUpdatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallUpdatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallUpdatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallUpdaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallUpdatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallUpdatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallUpdateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallUpdatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallUpdatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallUpdatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallUpdatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallUpdatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallUpdateorganizeDropboxFolders_Input | null;
  project?: ProjectUpdateOneWithoutInstallInspectionInput | null;
  interconnectionPostInstall?: InterconnectionPostInstallUpdateOneWithoutLinkToInstallInput | null;
  funding?: FundingUpdateManyWithoutInstallPageInput | null;
}

export interface InstallUpdateWithoutInterconnectionPostInstallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  commissionedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt1?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt2?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt3?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt1Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt2Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt3Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roofScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  photoAuditFailureDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  requestedDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentOrderedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  adderFormSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledServiceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  finalSolarInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ficUploadedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionFailedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timestampEmailToDistributor?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate3?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  customerCity?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  specialInstallInstructionsRequests?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  installerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  deliveryLocationNotes?: NullableStringFieldUpdateOperationsInput | null;
  installerArrivalWindow?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfElectricalNotYetCompleted?: NullableStringFieldUpdateOperationsInput | null;
  missingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  reasonInspectionFailedOrConditionalPass?: NullableStringFieldUpdateOperationsInput | null;
  missingIssues?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  ruleOfBackcharge?: NullableStringFieldUpdateOperationsInput | null;
  costOfRepair?: NullableFloatFieldUpdateOperationsInput | null;
  totalCost?: NullableFloatFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  installerName?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureInstallerId?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureProjectId?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  numberOfArrays?: NullableIntFieldUpdateOperationsInput | null;
  installItemId?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: InstallUpdatebucketActivation_Input | null;
  status_?: InstallUpdatestatus_Input | null;
  stepsToComplete_?: InstallUpdatestepsToComplete_Input | null;
  region_?: InstallUpdateregion_Input | null;
  proceedWOutPermit_?: InstallUpdateproceedWOutPermit_Input | null;
  calendarColor_?: InstallUpdatecalendarColor_Input | null;
  textEmailSent_?: InstallUpdatetextEmailSent_Input | null;
  textemailSent2_?: InstallUpdatetextemailSent2_Input | null;
  textemailSent3_?: InstallUpdatetextemailSent3_Input | null;
  roofComplete_?: InstallUpdateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallUpdatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallUpdatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallUpdatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallUpdateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallUpdatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallUpdatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallUpdatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallUpdaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallUpdatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallUpdatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallUpdateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallUpdatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallUpdatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallUpdatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallUpdatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallUpdatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallUpdateorganizeDropboxFolders_Input | null;
  project?: ProjectUpdateOneWithoutInstallInspectionInput | null;
  funding?: FundingUpdateManyWithoutInstallPageInput | null;
  InstallReady?: InstallReadyUpdateManyWithoutLinkToInstallInput | null;
}

export interface InstallUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  commissionedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt1?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt2?: NullableDateTimeFieldUpdateOperationsInput | null;
  installerSchedulingAttempt3?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt1Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt2Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerSchedulingAttempt3Lvm?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mpuCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roofScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  roughInspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  modulesInstallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalWorkCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  photoAuditFailureDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  m1SubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  requestedDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentOrderedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  equipmentDeliveryDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  adderFormSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledServiceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  finalSolarInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ficUploadedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionFailedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timestampEmailToDistributor?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate3?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  customerCity?: NullableStringFieldUpdateOperationsInput | null;
  reasonForHold?: NullableStringFieldUpdateOperationsInput | null;
  specialInstallInstructionsRequests?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  installerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  customerSchedulingNotes?: NullableStringFieldUpdateOperationsInput | null;
  deliveryLocationNotes?: NullableStringFieldUpdateOperationsInput | null;
  installerArrivalWindow?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfElectricalNotYetCompleted?: NullableStringFieldUpdateOperationsInput | null;
  missingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  reasonInspectionFailedOrConditionalPass?: NullableStringFieldUpdateOperationsInput | null;
  missingIssues?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  ruleOfBackcharge?: NullableStringFieldUpdateOperationsInput | null;
  costOfRepair?: NullableFloatFieldUpdateOperationsInput | null;
  totalCost?: NullableFloatFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  installerName?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureInstallerId?: NullableStringFieldUpdateOperationsInput | null;
  siteCaptureProjectId?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  numberOfArrays?: NullableIntFieldUpdateOperationsInput | null;
  installItemId?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: InstallUpdatebucketActivation_Input | null;
  status_?: InstallUpdatestatus_Input | null;
  stepsToComplete_?: InstallUpdatestepsToComplete_Input | null;
  region_?: InstallUpdateregion_Input | null;
  proceedWOutPermit_?: InstallUpdateproceedWOutPermit_Input | null;
  calendarColor_?: InstallUpdatecalendarColor_Input | null;
  textEmailSent_?: InstallUpdatetextEmailSent_Input | null;
  textemailSent2_?: InstallUpdatetextemailSent2_Input | null;
  textemailSent3_?: InstallUpdatetextemailSent3_Input | null;
  roofComplete_?: InstallUpdateroofComplete_Input | null;
  scheduledInstallConfirmedWithCustomer_?: InstallUpdatescheduledInstallConfirmedWithCustomer_Input | null;
  photoAudit_?: InstallUpdatephotoAudit_Input | null;
  missingPhotosNotification_?: InstallUpdatemissingPhotosNotification_Input | null;
  adderFormNeeded_?: InstallUpdateadderFormNeeded_Input | null;
  scheduledInspectionConfirmedWithCustomer_?: InstallUpdatescheduledInspectionConfirmedWithCustomer_Input | null;
  didInspectionPass_?: InstallUpdatedidInspectionPass_Input | null;
  permitCorrectionsNeeded_?: InstallUpdatepermitCorrectionsNeeded_Input | null;
  requestDesignCorrections_?: InstallUpdaterequestDesignCorrections_Input | null;
  createUtilityApprovalPto_?: InstallUpdatecreateUtilityApprovalPto_Input | null;
  createInstallerPurchaseOrder_?: InstallUpdatecreateInstallerPurchaseOrder_Input | null;
  installScheduledDateChanged_?: InstallUpdateinstallScheduledDateChanged_Input | null;
  reasonForBackcharge_?: InstallUpdatereasonForBackcharge_Input | null;
  noCallNoShowOccurrences_?: InstallUpdatenoCallNoShowOccurrences_Input | null;
  failedInspectionOccurrences_?: InstallUpdatefailedInspectionOccurrences_Input | null;
  failureToUpdateTheCrmOccurrences_?: InstallUpdatefailureToUpdateTheCrmOccurrences_Input | null;
  mpuLinked_?: InstallUpdatempuLinked_Input | null;
  organizeDropboxFolders_?: InstallUpdateorganizeDropboxFolders_Input | null;
  interconnectionPostInstall?: InterconnectionPostInstallUpdateOneWithoutLinkToInstallInput | null;
  funding?: FundingUpdateManyWithoutInstallPageInput | null;
  InstallReady?: InstallReadyUpdateManyWithoutLinkToInstallInput | null;
}

export interface InstallUpdateadderFormNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatebucketActivation_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatecalendarColor_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatecreateInstallerPurchaseOrder_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatecreateUtilityApprovalPto_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatedidInspectionPass_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatefailedInspectionOccurrences_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatefailureToUpdateTheCrmOccurrences_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdateinstallScheduledDateChanged_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatemissingPhotosNotification_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatempuLinked_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatenoCallNoShowOccurrences_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdateorganizeDropboxFolders_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatepermitCorrectionsNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatephotoAudit_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdateproceedWOutPermit_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatereasonForBackcharge_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdaterequestDesignCorrections_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdateroofComplete_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatescheduledInspectionConfirmedWithCustomer_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatescheduledInstallConfirmedWithCustomer_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatestepsToComplete_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatetextEmailSent_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatetextemailSent2_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpdatetextemailSent3_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InstallUpsertWithoutFundingInput {
  update: InstallUpdateWithoutFundingInput;
  create: InstallCreateWithoutFundingInput;
}

export interface InstallUpsertWithoutInstallReadyInput {
  update: InstallUpdateWithoutInstallReadyInput;
  create: InstallCreateWithoutInstallReadyInput;
}

export interface InstallUpsertWithoutInterconnectionPostInstallInput {
  update: InstallUpdateWithoutInterconnectionPostInstallInput;
  create: InstallCreateWithoutInterconnectionPostInstallInput;
}

export interface InstallUpsertWithoutProjectInput {
  update: InstallUpdateWithoutProjectInput;
  create: InstallCreateWithoutProjectInput;
}

export interface InstallWhereInput {
  AND?: InstallWhereInput[] | null;
  OR?: InstallWhereInput[] | null;
  NOT?: InstallWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  utilityDocsSentDate?: DateTimeNullableFilter | null;
  commissionedDate?: DateTimeNullableFilter | null;
  permitApprovedDate?: DateTimeNullableFilter | null;
  installerSchedulingAttempt1?: DateTimeNullableFilter | null;
  installerSchedulingAttempt2?: DateTimeNullableFilter | null;
  installerSchedulingAttempt3?: DateTimeNullableFilter | null;
  customerSchedulingAttempt1Lvm?: DateTimeNullableFilter | null;
  customerSchedulingAttempt2Lvm?: DateTimeNullableFilter | null;
  customerSchedulingAttempt3Lvm?: DateTimeNullableFilter | null;
  mpuScheduledDate?: DateTimeNullableFilter | null;
  mpuCompleteDate?: DateTimeNullableFilter | null;
  roofScheduledDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  roughInspectionScheduledDate?: DateTimeNullableFilter | null;
  roughInspectionCompleteDate?: DateTimeNullableFilter | null;
  modulesInstallScheduledDate?: DateTimeNullableFilter | null;
  modulesInstallCompleteDate?: DateTimeNullableFilter | null;
  electricalWorkScheduledDate?: DateTimeNullableFilter | null;
  electricalWorkCompletedDate?: DateTimeNullableFilter | null;
  photoAuditFailureDate?: DateTimeNullableFilter | null;
  m1SubmittedDate?: DateTimeNullableFilter | null;
  requestedDeliveryDate?: DateTimeNullableFilter | null;
  equipmentOrderedDate?: DateTimeNullableFilter | null;
  equipmentDeliveryDate?: DateTimeNullableFilter | null;
  adderFormSignedDate?: DateTimeNullableFilter | null;
  scheduledServiceDate?: DateTimeNullableFilter | null;
  finalSolarInspectionDate?: DateTimeNullableFilter | null;
  ficUploadedDate?: DateTimeNullableFilter | null;
  inspectionFailedDate?: DateTimeNullableFilter | null;
  inspectionCompleteDate?: DateTimeNullableFilter | null;
  correctionsRequestedDate?: DateTimeNullableFilter | null;
  correctionsCompletedDate?: DateTimeNullableFilter | null;
  correctionsSubmittedDate?: DateTimeNullableFilter | null;
  correctionsApprovedDate?: DateTimeNullableFilter | null;
  nemApprovedDate?: DateTimeNullableFilter | null;
  bucketActivatedDate?: DateTimeNullableFilter | null;
  bucketCompletionDate?: DateTimeNullableFilter | null;
  timestampEmailToDistributor?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  hoaApprovedDate?: DateTimeNullableFilter | null;
  nemApprovedDate3?: DateTimeNullableFilter | null;
  bucketActivation_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  stepsToComplete_?: StringNullableListFilter | null;
  region_?: StringNullableListFilter | null;
  proceedWOutPermit_?: StringNullableListFilter | null;
  calendarColor_?: StringNullableListFilter | null;
  textEmailSent_?: StringNullableListFilter | null;
  textemailSent2_?: StringNullableListFilter | null;
  textemailSent3_?: StringNullableListFilter | null;
  roofComplete_?: StringNullableListFilter | null;
  scheduledInstallConfirmedWithCustomer_?: StringNullableListFilter | null;
  photoAudit_?: StringNullableListFilter | null;
  missingPhotosNotification_?: StringNullableListFilter | null;
  adderFormNeeded_?: StringNullableListFilter | null;
  scheduledInspectionConfirmedWithCustomer_?: StringNullableListFilter | null;
  didInspectionPass_?: StringNullableListFilter | null;
  permitCorrectionsNeeded_?: StringNullableListFilter | null;
  requestDesignCorrections_?: StringNullableListFilter | null;
  createUtilityApprovalPto_?: StringNullableListFilter | null;
  createInstallerPurchaseOrder_?: StringNullableListFilter | null;
  installScheduledDateChanged_?: StringNullableListFilter | null;
  reasonForBackcharge_?: StringNullableListFilter | null;
  noCallNoShowOccurrences_?: StringNullableListFilter | null;
  failedInspectionOccurrences_?: StringNullableListFilter | null;
  failureToUpdateTheCrmOccurrences_?: StringNullableListFilter | null;
  mpuLinked_?: StringNullableListFilter | null;
  organizeDropboxFolders_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  customerCity?: StringNullableFilter | null;
  reasonForHold?: StringNullableFilter | null;
  specialInstallInstructionsRequests?: StringNullableFilter | null;
  workOrderRef?: StringNullableFilter | null;
  installerSchedulingNotes?: StringNullableFilter | null;
  customerSchedulingNotes?: StringNullableFilter | null;
  deliveryLocationNotes?: StringNullableFilter | null;
  installerArrivalWindow?: StringNullableFilter | null;
  descriptionOfElectricalNotYetCompleted?: StringNullableFilter | null;
  missingPhotos?: StringNullableFilter | null;
  reasonInspectionFailedOrConditionalPass?: StringNullableFilter | null;
  missingIssues?: StringNullableFilter | null;
  descriptionOfDesignCorrections?: StringNullableFilter | null;
  ruleOfBackcharge?: StringNullableFilter | null;
  costOfRepair?: FloatNullableFilter | null;
  totalCost?: FloatNullableFilter | null;
  description?: StringNullableFilter | null;
  installerName?: StringNullableFilter | null;
  siteCaptureInstallerId?: StringNullableFilter | null;
  siteCaptureProjectId?: StringNullableFilter | null;
  pathToDropboxParentFolder?: StringNullableFilter | null;
  numberOfModules?: IntNullableFilter | null;
  numberOfArrays?: IntNullableFilter | null;
  installItemId?: StringNullableFilter | null;
  project?: ProjectWhereInput | null;
  interconnectionPostInstall?: InterconnectionPostInstallWhereInput | null;
  funding?: FundingListRelationFilter | null;
  InstallReady?: InstallReadyListRelationFilter | null;
}

export interface InstallWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
}

export interface IntFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface IntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface IntNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntNullableFilter | null;
}

export interface IntNullableListFilter {
  equals?: number[] | null;
  has?: number | null;
  hasEvery?: number[] | null;
  hasSome?: number[] | null;
  isEmpty?: boolean | null;
}

export interface InterconnectionPostInstallCreateNestedOneWithoutInterconnectionPreInstallInput {
  create?: InterconnectionPostInstallCreateWithoutInterconnectionPreInstallInput | null;
  connectOrCreate?: InterconnectionPostInstallCreateOrConnectWithoutInterconnectionPreInstallInput | null;
  connect?: InterconnectionPostInstallWhereUniqueInput | null;
}

export interface InterconnectionPostInstallCreateNestedOneWithoutLinkToInstallInput {
  create?: InterconnectionPostInstallCreateWithoutLinkToInstallInput | null;
  connectOrCreate?: InterconnectionPostInstallCreateOrConnectWithoutLinkToInstallInput | null;
  connect?: InterconnectionPostInstallWhereUniqueInput | null;
}

export interface InterconnectionPostInstallCreateNestedOneWithoutProjectInput {
  create?: InterconnectionPostInstallCreateWithoutProjectInput | null;
  connectOrCreate?: InterconnectionPostInstallCreateOrConnectWithoutProjectInput | null;
  connect?: InterconnectionPostInstallWhereUniqueInput | null;
}

export interface InterconnectionPostInstallCreateOrConnectWithoutInterconnectionPreInstallInput {
  where: InterconnectionPostInstallWhereUniqueInput;
  create: InterconnectionPostInstallCreateWithoutInterconnectionPreInstallInput;
}

export interface InterconnectionPostInstallCreateOrConnectWithoutLinkToInstallInput {
  where: InterconnectionPostInstallWhereUniqueInput;
  create: InterconnectionPostInstallCreateWithoutLinkToInstallInput;
}

export interface InterconnectionPostInstallCreateOrConnectWithoutProjectInput {
  where: InterconnectionPostInstallWhereUniqueInput;
  create: InterconnectionPostInstallCreateWithoutProjectInput;
}

export interface InterconnectionPostInstallCreateWithoutInterconnectionPreInstallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bucketActivationDate?: any | null;
  nextItemDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ficUploadedDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoApprovedDateMeterOrdered?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  ptoStatus_?: InterconnectionPostInstallCreateptoStatus_Input | null;
  urgentMessage1_?: InterconnectionPostInstallCreateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPostInstallCreateurgentMessage2_Input | null;
  region_?: InterconnectionPostInstallCreateregion_Input | null;
  bucketActivation_?: InterconnectionPostInstallCreatebucketActivation_Input | null;
  waitingFor_?: InterconnectionPostInstallCreatewaitingFor_Input | null;
  project?: ProjectCreateNestedOneWithoutInterconnectionPostInput | null;
  linkToInstall?: InstallCreateNestedOneWithoutInterconnectionPostInstallInput | null;
}

export interface InterconnectionPostInstallCreateWithoutLinkToInstallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bucketActivationDate?: any | null;
  nextItemDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ficUploadedDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoApprovedDateMeterOrdered?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  ptoStatus_?: InterconnectionPostInstallCreateptoStatus_Input | null;
  urgentMessage1_?: InterconnectionPostInstallCreateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPostInstallCreateurgentMessage2_Input | null;
  region_?: InterconnectionPostInstallCreateregion_Input | null;
  bucketActivation_?: InterconnectionPostInstallCreatebucketActivation_Input | null;
  waitingFor_?: InterconnectionPostInstallCreatewaitingFor_Input | null;
  project?: ProjectCreateNestedOneWithoutInterconnectionPostInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutLinkToInterconnectionPostInstallInput | null;
}

export interface InterconnectionPostInstallCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  bucketActivationDate?: any | null;
  nextItemDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ficUploadedDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoApprovedDateMeterOrdered?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  ptoStatus_?: InterconnectionPostInstallCreateptoStatus_Input | null;
  urgentMessage1_?: InterconnectionPostInstallCreateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPostInstallCreateurgentMessage2_Input | null;
  region_?: InterconnectionPostInstallCreateregion_Input | null;
  bucketActivation_?: InterconnectionPostInstallCreatebucketActivation_Input | null;
  waitingFor_?: InterconnectionPostInstallCreatewaitingFor_Input | null;
  linkToInstall?: InstallCreateNestedOneWithoutInterconnectionPostInstallInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutLinkToInterconnectionPostInstallInput | null;
}

export interface InterconnectionPostInstallCreatebucketActivation_Input {
  set?: string[] | null;
}

export interface InterconnectionPostInstallCreateptoStatus_Input {
  set?: string[] | null;
}

export interface InterconnectionPostInstallCreateregion_Input {
  set?: string[] | null;
}

export interface InterconnectionPostInstallCreateurgentMessage1_Input {
  set?: string[] | null;
}

export interface InterconnectionPostInstallCreateurgentMessage2_Input {
  set?: string[] | null;
}

export interface InterconnectionPostInstallCreatewaitingFor_Input {
  set?: string[] | null;
}

export interface InterconnectionPostInstallUpdateOneWithoutInterconnectionPreInstallInput {
  create?: InterconnectionPostInstallCreateWithoutInterconnectionPreInstallInput | null;
  connectOrCreate?: InterconnectionPostInstallCreateOrConnectWithoutInterconnectionPreInstallInput | null;
  upsert?: InterconnectionPostInstallUpsertWithoutInterconnectionPreInstallInput | null;
  connect?: InterconnectionPostInstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InterconnectionPostInstallUpdateWithoutInterconnectionPreInstallInput | null;
}

export interface InterconnectionPostInstallUpdateOneWithoutLinkToInstallInput {
  create?: InterconnectionPostInstallCreateWithoutLinkToInstallInput | null;
  connectOrCreate?: InterconnectionPostInstallCreateOrConnectWithoutLinkToInstallInput | null;
  upsert?: InterconnectionPostInstallUpsertWithoutLinkToInstallInput | null;
  connect?: InterconnectionPostInstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InterconnectionPostInstallUpdateWithoutLinkToInstallInput | null;
}

export interface InterconnectionPostInstallUpdateOneWithoutProjectInput {
  create?: InterconnectionPostInstallCreateWithoutProjectInput | null;
  connectOrCreate?: InterconnectionPostInstallCreateOrConnectWithoutProjectInput | null;
  upsert?: InterconnectionPostInstallUpsertWithoutProjectInput | null;
  connect?: InterconnectionPostInstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InterconnectionPostInstallUpdateWithoutProjectInput | null;
}

export interface InterconnectionPostInstallUpdateWithoutInterconnectionPreInstallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ficUploadedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoApprovedDateMeterOrdered?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  ptoStatus_?: InterconnectionPostInstallUpdateptoStatus_Input | null;
  urgentMessage1_?: InterconnectionPostInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPostInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPostInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPostInstallUpdatebucketActivation_Input | null;
  waitingFor_?: InterconnectionPostInstallUpdatewaitingFor_Input | null;
  project?: ProjectUpdateOneWithoutInterconnectionPostInput | null;
  linkToInstall?: InstallUpdateOneWithoutInterconnectionPostInstallInput | null;
}

export interface InterconnectionPostInstallUpdateWithoutLinkToInstallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ficUploadedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoApprovedDateMeterOrdered?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  ptoStatus_?: InterconnectionPostInstallUpdateptoStatus_Input | null;
  urgentMessage1_?: InterconnectionPostInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPostInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPostInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPostInstallUpdatebucketActivation_Input | null;
  waitingFor_?: InterconnectionPostInstallUpdatewaitingFor_Input | null;
  project?: ProjectUpdateOneWithoutInterconnectionPostInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutLinkToInterconnectionPostInstallInput | null;
}

export interface InterconnectionPostInstallUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ficUploadedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoApprovedDateMeterOrdered?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  ptoStatus_?: InterconnectionPostInstallUpdateptoStatus_Input | null;
  urgentMessage1_?: InterconnectionPostInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPostInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPostInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPostInstallUpdatebucketActivation_Input | null;
  waitingFor_?: InterconnectionPostInstallUpdatewaitingFor_Input | null;
  linkToInstall?: InstallUpdateOneWithoutInterconnectionPostInstallInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutLinkToInterconnectionPostInstallInput | null;
}

export interface InterconnectionPostInstallUpdatebucketActivation_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPostInstallUpdateptoStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPostInstallUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPostInstallUpdateurgentMessage1_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPostInstallUpdateurgentMessage2_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPostInstallUpdatewaitingFor_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPostInstallUpsertWithoutInterconnectionPreInstallInput {
  update: InterconnectionPostInstallUpdateWithoutInterconnectionPreInstallInput;
  create: InterconnectionPostInstallCreateWithoutInterconnectionPreInstallInput;
}

export interface InterconnectionPostInstallUpsertWithoutLinkToInstallInput {
  update: InterconnectionPostInstallUpdateWithoutLinkToInstallInput;
  create: InterconnectionPostInstallCreateWithoutLinkToInstallInput;
}

export interface InterconnectionPostInstallUpsertWithoutProjectInput {
  update: InterconnectionPostInstallUpdateWithoutProjectInput;
  create: InterconnectionPostInstallCreateWithoutProjectInput;
}

export interface InterconnectionPostInstallWhereInput {
  AND?: InterconnectionPostInstallWhereInput[] | null;
  OR?: InterconnectionPostInstallWhereInput[] | null;
  NOT?: InterconnectionPostInstallWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  bucketActivationDate?: DateTimeNullableFilter | null;
  nextItemDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  scheduledInspectionDate?: DateTimeNullableFilter | null;
  inspectionCompleteDate?: DateTimeNullableFilter | null;
  ficUploadedDate?: DateTimeNullableFilter | null;
  ptoSignatureRequestedDate?: DateTimeNullableFilter | null;
  ptoSubmittedDate?: DateTimeNullableFilter | null;
  ptoApprovedDateMeterOrdered?: DateTimeNullableFilter | null;
  ptoResubmittedDate?: DateTimeNullableFilter | null;
  ptoReceivedDate?: DateTimeNullableFilter | null;
  ptoStatus_?: StringNullableListFilter | null;
  urgentMessage1_?: StringNullableListFilter | null;
  urgentMessage2_?: StringNullableListFilter | null;
  region_?: StringNullableListFilter | null;
  bucketActivation_?: StringNullableListFilter | null;
  waitingFor_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  applicationNumber?: StringNullableFilter | null;
  project?: ProjectWhereInput | null;
  linkToInstall?: InstallWhereInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallWhereInput | null;
  installId?: IntNullableFilter | null;
}

export interface InterconnectionPostInstallWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  installId?: number | null;
}

export interface InterconnectionPreInstallCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  nextItemDate?: any | null;
  nemSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  nemResubmittedDate?: any | null;
  nemSignatureRequestedDate?: any | null;
  checkRequestedDate?: any | null;
  nemPaidDate?: any | null;
  nemApprovedDate?: any | null;
  cadCorrectionsSentDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  utilityDocsSentDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  bucketActivationDate?: any | null;
  installCompleteDateOld?: any | null;
  inspectionCompleteDateOld?: any | null;
  systemEnergizedDate?: any | null;
  refundRequestedDate?: any | null;
  refundReceivedDate?: any | null;
  paymentDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  meterServiceAcct?: string | null;
  workOrderRef?: string | null;
  utilityApplicationFees?: number | null;
  utilityFeeNotes?: string | null;
  refundAmount?: number | null;
  descriptionOfDesignCorrection?: string | null;
  changeOrderDescription?: string | null;
  projectItemId?: string | null;
  customerEmail?: string | null;
  waitingForText?: string | null;
  interconnectionPostInstallId?: number | null;
  permitId?: number | null;
  urgentMessage1_?: InterconnectionPreInstallCreateManyurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallCreateManyurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallCreateManyregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallCreateManybucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallCreateManyutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallCreateManywaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallCreateManyutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallCreateManynemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallCreateManyptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallCreateManydidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallCreateManyptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallCreateManyrequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallCreateManychangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallCreateManyapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallCreateManympuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallCreateManycadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallCreateManyoldPreInstallApproval_Input | null;
}

export interface InterconnectionPreInstallCreateManySalesRepInputEnvelope {
  data?: InterconnectionPreInstallCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface InterconnectionPreInstallCreateManyapprovalsWaitingOn_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManybucketActivation_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManycadStatus_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManychangeOrder_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManydidInspectionPass_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManympuCreated_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManynemAgreementSubmitted_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyoldPreInstallApproval_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyptoStatus_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyptoUploadPhotosTaskTriggered_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyregion_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyrequestDesignCorrection_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyurgentMessage1_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyurgentMessage2_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyutilityApprovalNeededPriorToInstall_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManyutilityPreInstallStatus_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateManywaitingFor_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput {
  create?: InterconnectionPreInstallCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: InterconnectionPreInstallCreateManySalesRepInputEnvelope | null;
  connect?: InterconnectionPreInstallWhereUniqueInput[] | null;
}

export interface InterconnectionPreInstallCreateNestedOneWithoutLinkToInterconnectionPostInstallInput {
  create?: InterconnectionPreInstallCreateWithoutLinkToInterconnectionPostInstallInput | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutLinkToInterconnectionPostInstallInput | null;
  connect?: InterconnectionPreInstallWhereUniqueInput | null;
}

export interface InterconnectionPreInstallCreateNestedOneWithoutLinkToPermittingInput {
  create?: InterconnectionPreInstallCreateWithoutLinkToPermittingInput | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutLinkToPermittingInput | null;
  connect?: InterconnectionPreInstallWhereUniqueInput | null;
}

export interface InterconnectionPreInstallCreateNestedOneWithoutProjectInput {
  create?: InterconnectionPreInstallCreateWithoutProjectInput | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutProjectInput | null;
  connect?: InterconnectionPreInstallWhereUniqueInput | null;
}

export interface InterconnectionPreInstallCreateOrConnectWithoutLinkToInterconnectionPostInstallInput {
  where: InterconnectionPreInstallWhereUniqueInput;
  create: InterconnectionPreInstallCreateWithoutLinkToInterconnectionPostInstallInput;
}

export interface InterconnectionPreInstallCreateOrConnectWithoutLinkToPermittingInput {
  where: InterconnectionPreInstallWhereUniqueInput;
  create: InterconnectionPreInstallCreateWithoutLinkToPermittingInput;
}

export interface InterconnectionPreInstallCreateOrConnectWithoutProjectInput {
  where: InterconnectionPreInstallWhereUniqueInput;
  create: InterconnectionPreInstallCreateWithoutProjectInput;
}

export interface InterconnectionPreInstallCreateOrConnectWithoutSalesRepInput {
  where: InterconnectionPreInstallWhereUniqueInput;
  create: InterconnectionPreInstallCreateWithoutSalesRepInput;
}

export interface InterconnectionPreInstallCreateWithoutLinkToInterconnectionPostInstallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  nextItemDate?: any | null;
  nemSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  nemResubmittedDate?: any | null;
  nemSignatureRequestedDate?: any | null;
  checkRequestedDate?: any | null;
  nemPaidDate?: any | null;
  nemApprovedDate?: any | null;
  cadCorrectionsSentDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  utilityDocsSentDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  bucketActivationDate?: any | null;
  installCompleteDateOld?: any | null;
  inspectionCompleteDateOld?: any | null;
  systemEnergizedDate?: any | null;
  refundRequestedDate?: any | null;
  refundReceivedDate?: any | null;
  paymentDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  meterServiceAcct?: string | null;
  workOrderRef?: string | null;
  utilityApplicationFees?: number | null;
  utilityFeeNotes?: string | null;
  refundAmount?: number | null;
  descriptionOfDesignCorrection?: string | null;
  changeOrderDescription?: string | null;
  projectItemId?: string | null;
  customerEmail?: string | null;
  waitingForText?: string | null;
  urgentMessage1_?: InterconnectionPreInstallCreateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallCreateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallCreateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallCreatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallCreateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallCreatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallCreateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallCreatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallCreateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallCreatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallCreateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallCreaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallCreatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallCreateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallCreatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallCreatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallCreateoldPreInstallApproval_Input | null;
  project?: ProjectCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  linkToPermitting?: PermitCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutPreInstallsAsSalesRepInput | null;
}

export interface InterconnectionPreInstallCreateWithoutLinkToPermittingInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  nextItemDate?: any | null;
  nemSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  nemResubmittedDate?: any | null;
  nemSignatureRequestedDate?: any | null;
  checkRequestedDate?: any | null;
  nemPaidDate?: any | null;
  nemApprovedDate?: any | null;
  cadCorrectionsSentDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  utilityDocsSentDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  bucketActivationDate?: any | null;
  installCompleteDateOld?: any | null;
  inspectionCompleteDateOld?: any | null;
  systemEnergizedDate?: any | null;
  refundRequestedDate?: any | null;
  refundReceivedDate?: any | null;
  paymentDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  meterServiceAcct?: string | null;
  workOrderRef?: string | null;
  utilityApplicationFees?: number | null;
  utilityFeeNotes?: string | null;
  refundAmount?: number | null;
  descriptionOfDesignCorrection?: string | null;
  changeOrderDescription?: string | null;
  projectItemId?: string | null;
  customerEmail?: string | null;
  waitingForText?: string | null;
  urgentMessage1_?: InterconnectionPreInstallCreateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallCreateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallCreateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallCreatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallCreateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallCreatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallCreateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallCreatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallCreateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallCreatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallCreateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallCreaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallCreatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallCreateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallCreatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallCreatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallCreateoldPreInstallApproval_Input | null;
  project?: ProjectCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  linkToInterconnectionPostInstall?: InterconnectionPostInstallCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutPreInstallsAsSalesRepInput | null;
}

export interface InterconnectionPreInstallCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  nextItemDate?: any | null;
  nemSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  nemResubmittedDate?: any | null;
  nemSignatureRequestedDate?: any | null;
  checkRequestedDate?: any | null;
  nemPaidDate?: any | null;
  nemApprovedDate?: any | null;
  cadCorrectionsSentDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  utilityDocsSentDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  bucketActivationDate?: any | null;
  installCompleteDateOld?: any | null;
  inspectionCompleteDateOld?: any | null;
  systemEnergizedDate?: any | null;
  refundRequestedDate?: any | null;
  refundReceivedDate?: any | null;
  paymentDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  meterServiceAcct?: string | null;
  workOrderRef?: string | null;
  utilityApplicationFees?: number | null;
  utilityFeeNotes?: string | null;
  refundAmount?: number | null;
  descriptionOfDesignCorrection?: string | null;
  changeOrderDescription?: string | null;
  projectItemId?: string | null;
  customerEmail?: string | null;
  waitingForText?: string | null;
  urgentMessage1_?: InterconnectionPreInstallCreateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallCreateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallCreateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallCreatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallCreateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallCreatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallCreateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallCreatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallCreateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallCreatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallCreateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallCreaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallCreatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallCreateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallCreatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallCreatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallCreateoldPreInstallApproval_Input | null;
  linkToPermitting?: PermitCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  linkToInterconnectionPostInstall?: InterconnectionPostInstallCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutPreInstallsAsSalesRepInput | null;
}

export interface InterconnectionPreInstallCreateWithoutSalesRepInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  nextItemDate?: any | null;
  nemSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  nemResubmittedDate?: any | null;
  nemSignatureRequestedDate?: any | null;
  checkRequestedDate?: any | null;
  nemPaidDate?: any | null;
  nemApprovedDate?: any | null;
  cadCorrectionsSentDate?: any | null;
  scheduledInstallDate?: any | null;
  installCompleteDate?: any | null;
  scheduledInspectionDate?: any | null;
  inspectionCompleteDate?: any | null;
  ptoSignatureRequestedDate?: any | null;
  utilityDocsSentDate?: any | null;
  ptoSubmittedDate?: any | null;
  ptoResubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  bucketActivationDate?: any | null;
  installCompleteDateOld?: any | null;
  inspectionCompleteDateOld?: any | null;
  systemEnergizedDate?: any | null;
  refundRequestedDate?: any | null;
  refundReceivedDate?: any | null;
  paymentDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompletedDate?: any | null;
  accountUpdate?: string | null;
  applicationNumber?: string | null;
  meterServiceAcct?: string | null;
  workOrderRef?: string | null;
  utilityApplicationFees?: number | null;
  utilityFeeNotes?: string | null;
  refundAmount?: number | null;
  descriptionOfDesignCorrection?: string | null;
  changeOrderDescription?: string | null;
  projectItemId?: string | null;
  customerEmail?: string | null;
  waitingForText?: string | null;
  urgentMessage1_?: InterconnectionPreInstallCreateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallCreateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallCreateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallCreatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallCreateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallCreatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallCreateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallCreatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallCreateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallCreatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallCreateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallCreaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallCreatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallCreateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallCreatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallCreatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallCreateoldPreInstallApproval_Input | null;
  project?: ProjectCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  linkToPermitting?: PermitCreateNestedOneWithoutInterconnectionPreInstallInput | null;
  linkToInterconnectionPostInstall?: InterconnectionPostInstallCreateNestedOneWithoutInterconnectionPreInstallInput | null;
}

export interface InterconnectionPreInstallCreateapprovalsWaitingOn_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreatebucketActivation_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreatecadStatus_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreatechangeOrder_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreatedidInspectionPass_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreatempuCreated_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreatenemAgreementSubmitted_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateoldPreInstallApproval_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateptoStatus_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateptoUploadPhotosTaskTriggered_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateregion_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreaterequestDesignCorrection_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateurgentMessage1_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateurgentMessage2_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateutilityApprovalNeededPriorToInstall_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreateutilityPreInstallStatus_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallCreatewaitingFor_Input {
  set?: string[] | null;
}

export interface InterconnectionPreInstallListRelationFilter {
  every?: InterconnectionPreInstallWhereInput | null;
  some?: InterconnectionPreInstallWhereInput | null;
  none?: InterconnectionPreInstallWhereInput | null;
}

export interface InterconnectionPreInstallScalarWhereInput {
  AND?: InterconnectionPreInstallScalarWhereInput[] | null;
  OR?: InterconnectionPreInstallScalarWhereInput[] | null;
  NOT?: InterconnectionPreInstallScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  nextItemDate?: DateTimeNullableFilter | null;
  nemSubmittedDate?: DateTimeNullableFilter | null;
  estimatedApprovalDate?: DateTimeNullableFilter | null;
  nemResubmittedDate?: DateTimeNullableFilter | null;
  nemSignatureRequestedDate?: DateTimeNullableFilter | null;
  checkRequestedDate?: DateTimeNullableFilter | null;
  nemPaidDate?: DateTimeNullableFilter | null;
  nemApprovedDate?: DateTimeNullableFilter | null;
  cadCorrectionsSentDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  scheduledInspectionDate?: DateTimeNullableFilter | null;
  inspectionCompleteDate?: DateTimeNullableFilter | null;
  ptoSignatureRequestedDate?: DateTimeNullableFilter | null;
  utilityDocsSentDate?: DateTimeNullableFilter | null;
  ptoSubmittedDate?: DateTimeNullableFilter | null;
  ptoResubmittedDate?: DateTimeNullableFilter | null;
  ptoReceivedDate?: DateTimeNullableFilter | null;
  bucketActivationDate?: DateTimeNullableFilter | null;
  installCompleteDateOld?: DateTimeNullableFilter | null;
  inspectionCompleteDateOld?: DateTimeNullableFilter | null;
  systemEnergizedDate?: DateTimeNullableFilter | null;
  refundRequestedDate?: DateTimeNullableFilter | null;
  refundReceivedDate?: DateTimeNullableFilter | null;
  paymentDate?: DateTimeNullableFilter | null;
  correctionsRequestedDate?: DateTimeNullableFilter | null;
  correctionsCompletedDate?: DateTimeNullableFilter | null;
  urgentMessage1_?: StringNullableListFilter | null;
  urgentMessage2_?: StringNullableListFilter | null;
  region_?: StringNullableListFilter | null;
  bucketActivation_?: StringNullableListFilter | null;
  utilityPreInstallStatus_?: StringNullableListFilter | null;
  waitingFor_?: StringNullableListFilter | null;
  utilityApprovalNeededPriorToInstall_?: StringNullableListFilter | null;
  nemAgreementSubmitted_?: StringNullableListFilter | null;
  ptoStatus_?: StringNullableListFilter | null;
  didInspectionPass_?: StringNullableListFilter | null;
  ptoUploadPhotosTaskTriggered_?: StringNullableListFilter | null;
  requestDesignCorrection_?: StringNullableListFilter | null;
  changeOrder_?: StringNullableListFilter | null;
  approvalsWaitingOn_?: StringNullableListFilter | null;
  mpuCreated_?: StringNullableListFilter | null;
  cadStatus_?: StringNullableListFilter | null;
  oldPreInstallApproval_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  applicationNumber?: StringNullableFilter | null;
  meterServiceAcct?: StringNullableFilter | null;
  workOrderRef?: StringNullableFilter | null;
  utilityApplicationFees?: FloatNullableFilter | null;
  utilityFeeNotes?: StringNullableFilter | null;
  refundAmount?: FloatNullableFilter | null;
  descriptionOfDesignCorrection?: StringNullableFilter | null;
  changeOrderDescription?: StringNullableFilter | null;
  projectItemId?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  waitingForText?: StringNullableFilter | null;
  interconnectionPostInstallId?: IntNullableFilter | null;
  permitId?: IntNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface InterconnectionPreInstallUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  systemEnergizedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  utilityApplicationFees?: NullableFloatFieldUpdateOperationsInput | null;
  utilityFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableFloatFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrection?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  waitingForText?: NullableStringFieldUpdateOperationsInput | null;
  urgentMessage1_?: InterconnectionPreInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallUpdatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallUpdateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallUpdatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallUpdateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallUpdatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallUpdateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallUpdatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallUpdateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallUpdaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallUpdatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallUpdateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallUpdatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallUpdatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallUpdateoldPreInstallApproval_Input | null;
}

export interface InterconnectionPreInstallUpdateManyWithWhereWithoutSalesRepInput {
  where: InterconnectionPreInstallScalarWhereInput;
  data: InterconnectionPreInstallUpdateManyMutationInput;
}

export interface InterconnectionPreInstallUpdateManyWithoutSalesRepInput {
  create?: InterconnectionPreInstallCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: InterconnectionPreInstallUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: InterconnectionPreInstallCreateManySalesRepInputEnvelope | null;
  connect?: InterconnectionPreInstallWhereUniqueInput[] | null;
  set?: InterconnectionPreInstallWhereUniqueInput[] | null;
  disconnect?: InterconnectionPreInstallWhereUniqueInput[] | null;
  delete?: InterconnectionPreInstallWhereUniqueInput[] | null;
  update?: InterconnectionPreInstallUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: InterconnectionPreInstallUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: InterconnectionPreInstallScalarWhereInput[] | null;
}

export interface InterconnectionPreInstallUpdateOneWithoutLinkToInterconnectionPostInstallInput {
  create?: InterconnectionPreInstallCreateWithoutLinkToInterconnectionPostInstallInput | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutLinkToInterconnectionPostInstallInput | null;
  upsert?: InterconnectionPreInstallUpsertWithoutLinkToInterconnectionPostInstallInput | null;
  connect?: InterconnectionPreInstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InterconnectionPreInstallUpdateWithoutLinkToInterconnectionPostInstallInput | null;
}

export interface InterconnectionPreInstallUpdateOneWithoutLinkToPermittingInput {
  create?: InterconnectionPreInstallCreateWithoutLinkToPermittingInput | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutLinkToPermittingInput | null;
  upsert?: InterconnectionPreInstallUpsertWithoutLinkToPermittingInput | null;
  connect?: InterconnectionPreInstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InterconnectionPreInstallUpdateWithoutLinkToPermittingInput | null;
}

export interface InterconnectionPreInstallUpdateOneWithoutProjectInput {
  create?: InterconnectionPreInstallCreateWithoutProjectInput | null;
  connectOrCreate?: InterconnectionPreInstallCreateOrConnectWithoutProjectInput | null;
  upsert?: InterconnectionPreInstallUpsertWithoutProjectInput | null;
  connect?: InterconnectionPreInstallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InterconnectionPreInstallUpdateWithoutProjectInput | null;
}

export interface InterconnectionPreInstallUpdateWithWhereUniqueWithoutSalesRepInput {
  where: InterconnectionPreInstallWhereUniqueInput;
  data: InterconnectionPreInstallUpdateWithoutSalesRepInput;
}

export interface InterconnectionPreInstallUpdateWithoutLinkToInterconnectionPostInstallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  systemEnergizedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  utilityApplicationFees?: NullableFloatFieldUpdateOperationsInput | null;
  utilityFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableFloatFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrection?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  waitingForText?: NullableStringFieldUpdateOperationsInput | null;
  urgentMessage1_?: InterconnectionPreInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallUpdatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallUpdateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallUpdatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallUpdateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallUpdatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallUpdateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallUpdatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallUpdateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallUpdaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallUpdatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallUpdateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallUpdatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallUpdatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallUpdateoldPreInstallApproval_Input | null;
  project?: ProjectUpdateOneWithoutInterconnectionPreInstallInput | null;
  linkToPermitting?: PermitUpdateOneWithoutInterconnectionPreInstallInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutPreInstallsAsSalesRepInput | null;
}

export interface InterconnectionPreInstallUpdateWithoutLinkToPermittingInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  systemEnergizedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  utilityApplicationFees?: NullableFloatFieldUpdateOperationsInput | null;
  utilityFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableFloatFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrection?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  waitingForText?: NullableStringFieldUpdateOperationsInput | null;
  urgentMessage1_?: InterconnectionPreInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallUpdatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallUpdateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallUpdatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallUpdateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallUpdatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallUpdateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallUpdatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallUpdateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallUpdaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallUpdatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallUpdateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallUpdatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallUpdatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallUpdateoldPreInstallApproval_Input | null;
  project?: ProjectUpdateOneWithoutInterconnectionPreInstallInput | null;
  linkToInterconnectionPostInstall?: InterconnectionPostInstallUpdateOneWithoutInterconnectionPreInstallInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutPreInstallsAsSalesRepInput | null;
}

export interface InterconnectionPreInstallUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  systemEnergizedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  utilityApplicationFees?: NullableFloatFieldUpdateOperationsInput | null;
  utilityFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableFloatFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrection?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  waitingForText?: NullableStringFieldUpdateOperationsInput | null;
  urgentMessage1_?: InterconnectionPreInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallUpdatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallUpdateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallUpdatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallUpdateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallUpdatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallUpdateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallUpdatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallUpdateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallUpdaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallUpdatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallUpdateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallUpdatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallUpdatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallUpdateoldPreInstallApproval_Input | null;
  linkToPermitting?: PermitUpdateOneWithoutInterconnectionPreInstallInput | null;
  linkToInterconnectionPostInstall?: InterconnectionPostInstallUpdateOneWithoutInterconnectionPreInstallInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutPreInstallsAsSalesRepInput | null;
}

export interface InterconnectionPreInstallUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemPaidDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCorrectionsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInspectionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSignatureRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  utilityDocsSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoResubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  systemEnergizedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  refundReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompletedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  applicationNumber?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  workOrderRef?: NullableStringFieldUpdateOperationsInput | null;
  utilityApplicationFees?: NullableFloatFieldUpdateOperationsInput | null;
  utilityFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableFloatFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrection?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  waitingForText?: NullableStringFieldUpdateOperationsInput | null;
  urgentMessage1_?: InterconnectionPreInstallUpdateurgentMessage1_Input | null;
  urgentMessage2_?: InterconnectionPreInstallUpdateurgentMessage2_Input | null;
  region_?: InterconnectionPreInstallUpdateregion_Input | null;
  bucketActivation_?: InterconnectionPreInstallUpdatebucketActivation_Input | null;
  utilityPreInstallStatus_?: InterconnectionPreInstallUpdateutilityPreInstallStatus_Input | null;
  waitingFor_?: InterconnectionPreInstallUpdatewaitingFor_Input | null;
  utilityApprovalNeededPriorToInstall_?: InterconnectionPreInstallUpdateutilityApprovalNeededPriorToInstall_Input | null;
  nemAgreementSubmitted_?: InterconnectionPreInstallUpdatenemAgreementSubmitted_Input | null;
  ptoStatus_?: InterconnectionPreInstallUpdateptoStatus_Input | null;
  didInspectionPass_?: InterconnectionPreInstallUpdatedidInspectionPass_Input | null;
  ptoUploadPhotosTaskTriggered_?: InterconnectionPreInstallUpdateptoUploadPhotosTaskTriggered_Input | null;
  requestDesignCorrection_?: InterconnectionPreInstallUpdaterequestDesignCorrection_Input | null;
  changeOrder_?: InterconnectionPreInstallUpdatechangeOrder_Input | null;
  approvalsWaitingOn_?: InterconnectionPreInstallUpdateapprovalsWaitingOn_Input | null;
  mpuCreated_?: InterconnectionPreInstallUpdatempuCreated_Input | null;
  cadStatus_?: InterconnectionPreInstallUpdatecadStatus_Input | null;
  oldPreInstallApproval_?: InterconnectionPreInstallUpdateoldPreInstallApproval_Input | null;
  project?: ProjectUpdateOneWithoutInterconnectionPreInstallInput | null;
  linkToPermitting?: PermitUpdateOneWithoutInterconnectionPreInstallInput | null;
  linkToInterconnectionPostInstall?: InterconnectionPostInstallUpdateOneWithoutInterconnectionPreInstallInput | null;
}

export interface InterconnectionPreInstallUpdateapprovalsWaitingOn_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdatebucketActivation_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdatecadStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdatechangeOrder_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdatedidInspectionPass_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdatempuCreated_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdatenemAgreementSubmitted_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateoldPreInstallApproval_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateptoStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateptoUploadPhotosTaskTriggered_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdaterequestDesignCorrection_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateurgentMessage1_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateurgentMessage2_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateutilityApprovalNeededPriorToInstall_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdateutilityPreInstallStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpdatewaitingFor_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface InterconnectionPreInstallUpsertWithWhereUniqueWithoutSalesRepInput {
  where: InterconnectionPreInstallWhereUniqueInput;
  update: InterconnectionPreInstallUpdateWithoutSalesRepInput;
  create: InterconnectionPreInstallCreateWithoutSalesRepInput;
}

export interface InterconnectionPreInstallUpsertWithoutLinkToInterconnectionPostInstallInput {
  update: InterconnectionPreInstallUpdateWithoutLinkToInterconnectionPostInstallInput;
  create: InterconnectionPreInstallCreateWithoutLinkToInterconnectionPostInstallInput;
}

export interface InterconnectionPreInstallUpsertWithoutLinkToPermittingInput {
  update: InterconnectionPreInstallUpdateWithoutLinkToPermittingInput;
  create: InterconnectionPreInstallCreateWithoutLinkToPermittingInput;
}

export interface InterconnectionPreInstallUpsertWithoutProjectInput {
  update: InterconnectionPreInstallUpdateWithoutProjectInput;
  create: InterconnectionPreInstallCreateWithoutProjectInput;
}

export interface InterconnectionPreInstallWhereInput {
  AND?: InterconnectionPreInstallWhereInput[] | null;
  OR?: InterconnectionPreInstallWhereInput[] | null;
  NOT?: InterconnectionPreInstallWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  nextItemDate?: DateTimeNullableFilter | null;
  nemSubmittedDate?: DateTimeNullableFilter | null;
  estimatedApprovalDate?: DateTimeNullableFilter | null;
  nemResubmittedDate?: DateTimeNullableFilter | null;
  nemSignatureRequestedDate?: DateTimeNullableFilter | null;
  checkRequestedDate?: DateTimeNullableFilter | null;
  nemPaidDate?: DateTimeNullableFilter | null;
  nemApprovedDate?: DateTimeNullableFilter | null;
  cadCorrectionsSentDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  scheduledInspectionDate?: DateTimeNullableFilter | null;
  inspectionCompleteDate?: DateTimeNullableFilter | null;
  ptoSignatureRequestedDate?: DateTimeNullableFilter | null;
  utilityDocsSentDate?: DateTimeNullableFilter | null;
  ptoSubmittedDate?: DateTimeNullableFilter | null;
  ptoResubmittedDate?: DateTimeNullableFilter | null;
  ptoReceivedDate?: DateTimeNullableFilter | null;
  bucketActivationDate?: DateTimeNullableFilter | null;
  installCompleteDateOld?: DateTimeNullableFilter | null;
  inspectionCompleteDateOld?: DateTimeNullableFilter | null;
  systemEnergizedDate?: DateTimeNullableFilter | null;
  refundRequestedDate?: DateTimeNullableFilter | null;
  refundReceivedDate?: DateTimeNullableFilter | null;
  paymentDate?: DateTimeNullableFilter | null;
  correctionsRequestedDate?: DateTimeNullableFilter | null;
  correctionsCompletedDate?: DateTimeNullableFilter | null;
  urgentMessage1_?: StringNullableListFilter | null;
  urgentMessage2_?: StringNullableListFilter | null;
  region_?: StringNullableListFilter | null;
  bucketActivation_?: StringNullableListFilter | null;
  utilityPreInstallStatus_?: StringNullableListFilter | null;
  waitingFor_?: StringNullableListFilter | null;
  utilityApprovalNeededPriorToInstall_?: StringNullableListFilter | null;
  nemAgreementSubmitted_?: StringNullableListFilter | null;
  ptoStatus_?: StringNullableListFilter | null;
  didInspectionPass_?: StringNullableListFilter | null;
  ptoUploadPhotosTaskTriggered_?: StringNullableListFilter | null;
  requestDesignCorrection_?: StringNullableListFilter | null;
  changeOrder_?: StringNullableListFilter | null;
  approvalsWaitingOn_?: StringNullableListFilter | null;
  mpuCreated_?: StringNullableListFilter | null;
  cadStatus_?: StringNullableListFilter | null;
  oldPreInstallApproval_?: StringNullableListFilter | null;
  accountUpdate?: StringNullableFilter | null;
  applicationNumber?: StringNullableFilter | null;
  meterServiceAcct?: StringNullableFilter | null;
  workOrderRef?: StringNullableFilter | null;
  utilityApplicationFees?: FloatNullableFilter | null;
  utilityFeeNotes?: StringNullableFilter | null;
  refundAmount?: FloatNullableFilter | null;
  descriptionOfDesignCorrection?: StringNullableFilter | null;
  changeOrderDescription?: StringNullableFilter | null;
  projectItemId?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  waitingForText?: StringNullableFilter | null;
  project?: ProjectWhereInput | null;
  linkToPermitting?: PermitWhereInput | null;
  linkToInterconnectionPostInstall?: InterconnectionPostInstallWhereInput | null;
  interconnectionPostInstallId?: IntNullableFilter | null;
  permitId?: IntNullableFilter | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface InterconnectionPreInstallWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  interconnectionPostInstallId?: number | null;
  permitId?: number | null;
}

export interface JsonNullableFilter {
  equals?: any | null;
  not?: any | null;
}

export interface LeadCreateManyContactInput {
  id?: number | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  salesEmployeeId_SalesRep?: number | null;
  salesEmployeeId_Setter?: number | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateManyleadSourceType_Input | null;
  referral_?: LeadCreateManyreferral_Input | null;
  leadCreatedFrom_?: LeadCreateManyleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateManyleadStatus_Input | null;
  paymentStatus_?: LeadCreateManypaymentStatus_Input | null;
}

export interface LeadCreateManyContactInputEnvelope {
  data?: LeadCreateManyContactInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface LeadCreateManySalesRepInput {
  id?: number | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  contactId?: number | null;
  salesEmployeeId_Setter?: number | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateManyleadSourceType_Input | null;
  referral_?: LeadCreateManyreferral_Input | null;
  leadCreatedFrom_?: LeadCreateManyleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateManyleadStatus_Input | null;
  paymentStatus_?: LeadCreateManypaymentStatus_Input | null;
}

export interface LeadCreateManySalesRepInputEnvelope {
  data?: LeadCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface LeadCreateManySetterInput {
  id?: number | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  contactId?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateManyleadSourceType_Input | null;
  referral_?: LeadCreateManyreferral_Input | null;
  leadCreatedFrom_?: LeadCreateManyleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateManyleadStatus_Input | null;
  paymentStatus_?: LeadCreateManypaymentStatus_Input | null;
}

export interface LeadCreateManySetterInputEnvelope {
  data?: LeadCreateManySetterInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface LeadCreateManyleadCreatedFrom_Input {
  set?: string[] | null;
}

export interface LeadCreateManyleadSourceType_Input {
  set?: string[] | null;
}

export interface LeadCreateManyleadStatus_Input {
  set?: string[] | null;
}

export interface LeadCreateManypaymentStatus_Input {
  set?: string[] | null;
}

export interface LeadCreateManyreferral_Input {
  set?: string[] | null;
}

export interface LeadCreateNestedManyWithoutContactInput {
  create?: LeadCreateWithoutContactInput[] | null;
  connectOrCreate?: LeadCreateOrConnectWithoutContactInput[] | null;
  createMany?: LeadCreateManyContactInputEnvelope | null;
  connect?: LeadWhereUniqueInput[] | null;
}

export interface LeadCreateNestedManyWithoutSalesRepInput {
  create?: LeadCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: LeadCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: LeadCreateManySalesRepInputEnvelope | null;
  connect?: LeadWhereUniqueInput[] | null;
}

export interface LeadCreateNestedManyWithoutSetterInput {
  create?: LeadCreateWithoutSetterInput[] | null;
  connectOrCreate?: LeadCreateOrConnectWithoutSetterInput[] | null;
  createMany?: LeadCreateManySetterInputEnvelope | null;
  connect?: LeadWhereUniqueInput[] | null;
}

export interface LeadCreateNestedOneWithoutAppointmentInput {
  create?: LeadCreateWithoutAppointmentInput | null;
  connectOrCreate?: LeadCreateOrConnectWithoutAppointmentInput | null;
  connect?: LeadWhereUniqueInput | null;
}

export interface LeadCreateNestedOneWithoutDealInput {
  create?: LeadCreateWithoutDealInput | null;
  connectOrCreate?: LeadCreateOrConnectWithoutDealInput | null;
  connect?: LeadWhereUniqueInput | null;
}

export interface LeadCreateOrConnectWithoutAppointmentInput {
  where: LeadWhereUniqueInput;
  create: LeadCreateWithoutAppointmentInput;
}

export interface LeadCreateOrConnectWithoutContactInput {
  where: LeadWhereUniqueInput;
  create: LeadCreateWithoutContactInput;
}

export interface LeadCreateOrConnectWithoutDealInput {
  where: LeadWhereUniqueInput;
  create: LeadCreateWithoutDealInput;
}

export interface LeadCreateOrConnectWithoutSalesRepInput {
  where: LeadWhereUniqueInput;
  create: LeadCreateWithoutSalesRepInput;
}

export interface LeadCreateOrConnectWithoutSetterInput {
  where: LeadWhereUniqueInput;
  create: LeadCreateWithoutSetterInput;
}

export interface LeadCreateWithoutAppointmentInput {
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateleadSourceType_Input | null;
  referral_?: LeadCreatereferral_Input | null;
  leadCreatedFrom_?: LeadCreateleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateleadStatus_Input | null;
  paymentStatus_?: LeadCreatepaymentStatus_Input | null;
  contact?: ContactCreateNestedOneWithoutLeadInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutLeadsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutLeadsAsSetterInput | null;
  deal?: DealCreateNestedManyWithoutLeadInput | null;
}

export interface LeadCreateWithoutContactInput {
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateleadSourceType_Input | null;
  referral_?: LeadCreatereferral_Input | null;
  leadCreatedFrom_?: LeadCreateleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateleadStatus_Input | null;
  paymentStatus_?: LeadCreatepaymentStatus_Input | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutLeadsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutLeadsAsSetterInput | null;
  appointment?: AppointmentCreateNestedManyWithoutLeadInfoInput | null;
  deal?: DealCreateNestedManyWithoutLeadInput | null;
}

export interface LeadCreateWithoutDealInput {
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateleadSourceType_Input | null;
  referral_?: LeadCreatereferral_Input | null;
  leadCreatedFrom_?: LeadCreateleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateleadStatus_Input | null;
  paymentStatus_?: LeadCreatepaymentStatus_Input | null;
  contact?: ContactCreateNestedOneWithoutLeadInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutLeadsAsSalesRepInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutLeadsAsSetterInput | null;
  appointment?: AppointmentCreateNestedManyWithoutLeadInfoInput | null;
}

export interface LeadCreateWithoutSalesRepInput {
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateleadSourceType_Input | null;
  referral_?: LeadCreatereferral_Input | null;
  leadCreatedFrom_?: LeadCreateleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateleadStatus_Input | null;
  paymentStatus_?: LeadCreatepaymentStatus_Input | null;
  contact?: ContactCreateNestedOneWithoutLeadInput | null;
  Setter?: SalesEmployeeCreateNestedOneWithoutLeadsAsSetterInput | null;
  appointment?: AppointmentCreateNestedManyWithoutLeadInfoInput | null;
  deal?: DealCreateNestedManyWithoutLeadInput | null;
}

export interface LeadCreateWithoutSetterInput {
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: string | null;
  podioId?: string | null;
  dateLeadSubmitted?: any | null;
  appointmentStartDate?: any | null;
  appointmentEndDate?: any | null;
  externalId?: string | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  leadSourceType?: string | null;
  referral?: string | null;
  leadCreatedFrom?: string | null;
  leadStatus?: string | null;
  paymentStatus?: string | null;
  utilityBill?: string | null;
  averageBill?: string | null;
  creditScore?: string | null;
  homeOwner?: string | null;
  contractSignedDate?: any | null;
  referralContactInfo?: string | null;
  leadtype?: string | null;
  leadId?: string | null;
  calendarEmail?: string | null;
  systemSize?: number | null;
  leadNotes?: string | null;
  leadSourceType_?: LeadCreateleadSourceType_Input | null;
  referral_?: LeadCreatereferral_Input | null;
  leadCreatedFrom_?: LeadCreateleadCreatedFrom_Input | null;
  leadStatus_?: LeadCreateleadStatus_Input | null;
  paymentStatus_?: LeadCreatepaymentStatus_Input | null;
  contact?: ContactCreateNestedOneWithoutLeadInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutLeadsAsSalesRepInput | null;
  appointment?: AppointmentCreateNestedManyWithoutLeadInfoInput | null;
  deal?: DealCreateNestedManyWithoutLeadInput | null;
}

export interface LeadCreateleadCreatedFrom_Input {
  set?: string[] | null;
}

export interface LeadCreateleadSourceType_Input {
  set?: string[] | null;
}

export interface LeadCreateleadStatus_Input {
  set?: string[] | null;
}

export interface LeadCreatepaymentStatus_Input {
  set?: string[] | null;
}

export interface LeadCreatereferral_Input {
  set?: string[] | null;
}

export interface LeadHookCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  provider?: string | null;
  type?: string | null;
  campaign?: string | null;
  status?: LeadHookStatus | null;
  endpoint?: string | null;
}

export interface LeadHookUpdateInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  campaign?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumLeadHookStatusFieldUpdateOperationsInput | null;
  endpoint?: NullableStringFieldUpdateOperationsInput | null;
}

export interface LeadHookWhereUniqueInput {
  id?: number | null;
  provider?: string | null;
}

export interface LeadListRelationFilter {
  every?: LeadWhereInput | null;
  some?: LeadWhereInput | null;
  none?: LeadWhereInput | null;
}

export interface LeadScalarWhereInput {
  AND?: LeadScalarWhereInput[] | null;
  OR?: LeadScalarWhereInput[] | null;
  NOT?: LeadScalarWhereInput[] | null;
  id?: IntFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  team?: StringNullableFilter | null;
  podioId?: StringNullableFilter | null;
  dateLeadSubmitted?: DateTimeNullableFilter | null;
  appointmentStartDate?: DateTimeNullableFilter | null;
  appointmentEndDate?: DateTimeNullableFilter | null;
  externalId?: StringNullableFilter | null;
  provider?: StringNullableFilter | null;
  type?: StringNullableFilter | null;
  campaign?: StringNullableFilter | null;
  leadSourceType_?: StringNullableListFilter | null;
  leadSourceType?: StringNullableFilter | null;
  referral_?: StringNullableListFilter | null;
  referral?: StringNullableFilter | null;
  leadCreatedFrom_?: StringNullableListFilter | null;
  leadCreatedFrom?: StringNullableFilter | null;
  leadStatus_?: StringNullableListFilter | null;
  leadStatus?: StringNullableFilter | null;
  paymentStatus_?: StringNullableListFilter | null;
  paymentStatus?: StringNullableFilter | null;
  utilityBill?: StringNullableFilter | null;
  averageBill?: StringNullableFilter | null;
  creditScore?: StringNullableFilter | null;
  homeOwner?: StringNullableFilter | null;
  contactId?: IntNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  referralContactInfo?: StringNullableFilter | null;
  leadtype?: StringNullableFilter | null;
  leadId?: StringNullableFilter | null;
  calendarEmail?: StringNullableFilter | null;
  systemSize?: IntNullableFilter | null;
  leadNotes?: StringNullableFilter | null;
}

export interface LeadUpdateManyMutationInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  campaign?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  referral?: NullableStringFieldUpdateOperationsInput | null;
  leadCreatedFrom?: NullableStringFieldUpdateOperationsInput | null;
  leadStatus?: NullableStringFieldUpdateOperationsInput | null;
  paymentStatus?: NullableStringFieldUpdateOperationsInput | null;
  utilityBill?: NullableStringFieldUpdateOperationsInput | null;
  averageBill?: NullableStringFieldUpdateOperationsInput | null;
  creditScore?: NullableStringFieldUpdateOperationsInput | null;
  homeOwner?: NullableStringFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  referralContactInfo?: NullableStringFieldUpdateOperationsInput | null;
  leadtype?: NullableStringFieldUpdateOperationsInput | null;
  leadId?: NullableStringFieldUpdateOperationsInput | null;
  calendarEmail?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableIntFieldUpdateOperationsInput | null;
  leadNotes?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType_?: LeadUpdateleadSourceType_Input | null;
  referral_?: LeadUpdatereferral_Input | null;
  leadCreatedFrom_?: LeadUpdateleadCreatedFrom_Input | null;
  leadStatus_?: LeadUpdateleadStatus_Input | null;
  paymentStatus_?: LeadUpdatepaymentStatus_Input | null;
}

export interface LeadUpdateManyWithWhereWithoutContactInput {
  where: LeadScalarWhereInput;
  data: LeadUpdateManyMutationInput;
}

export interface LeadUpdateManyWithWhereWithoutSalesRepInput {
  where: LeadScalarWhereInput;
  data: LeadUpdateManyMutationInput;
}

export interface LeadUpdateManyWithWhereWithoutSetterInput {
  where: LeadScalarWhereInput;
  data: LeadUpdateManyMutationInput;
}

export interface LeadUpdateManyWithoutContactInput {
  create?: LeadCreateWithoutContactInput[] | null;
  connectOrCreate?: LeadCreateOrConnectWithoutContactInput[] | null;
  upsert?: LeadUpsertWithWhereUniqueWithoutContactInput[] | null;
  createMany?: LeadCreateManyContactInputEnvelope | null;
  connect?: LeadWhereUniqueInput[] | null;
  set?: LeadWhereUniqueInput[] | null;
  disconnect?: LeadWhereUniqueInput[] | null;
  delete?: LeadWhereUniqueInput[] | null;
  update?: LeadUpdateWithWhereUniqueWithoutContactInput[] | null;
  updateMany?: LeadUpdateManyWithWhereWithoutContactInput[] | null;
  deleteMany?: LeadScalarWhereInput[] | null;
}

export interface LeadUpdateManyWithoutSalesRepInput {
  create?: LeadCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: LeadCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: LeadUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: LeadCreateManySalesRepInputEnvelope | null;
  connect?: LeadWhereUniqueInput[] | null;
  set?: LeadWhereUniqueInput[] | null;
  disconnect?: LeadWhereUniqueInput[] | null;
  delete?: LeadWhereUniqueInput[] | null;
  update?: LeadUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: LeadUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: LeadScalarWhereInput[] | null;
}

export interface LeadUpdateManyWithoutSetterInput {
  create?: LeadCreateWithoutSetterInput[] | null;
  connectOrCreate?: LeadCreateOrConnectWithoutSetterInput[] | null;
  upsert?: LeadUpsertWithWhereUniqueWithoutSetterInput[] | null;
  createMany?: LeadCreateManySetterInputEnvelope | null;
  connect?: LeadWhereUniqueInput[] | null;
  set?: LeadWhereUniqueInput[] | null;
  disconnect?: LeadWhereUniqueInput[] | null;
  delete?: LeadWhereUniqueInput[] | null;
  update?: LeadUpdateWithWhereUniqueWithoutSetterInput[] | null;
  updateMany?: LeadUpdateManyWithWhereWithoutSetterInput[] | null;
  deleteMany?: LeadScalarWhereInput[] | null;
}

export interface LeadUpdateOneWithoutAppointmentInput {
  create?: LeadCreateWithoutAppointmentInput | null;
  connectOrCreate?: LeadCreateOrConnectWithoutAppointmentInput | null;
  upsert?: LeadUpsertWithoutAppointmentInput | null;
  connect?: LeadWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: LeadUpdateWithoutAppointmentInput | null;
}

export interface LeadUpdateOneWithoutDealInput {
  create?: LeadCreateWithoutDealInput | null;
  connectOrCreate?: LeadCreateOrConnectWithoutDealInput | null;
  upsert?: LeadUpsertWithoutDealInput | null;
  connect?: LeadWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: LeadUpdateWithoutDealInput | null;
}

export interface LeadUpdateWithWhereUniqueWithoutContactInput {
  where: LeadWhereUniqueInput;
  data: LeadUpdateWithoutContactInput;
}

export interface LeadUpdateWithWhereUniqueWithoutSalesRepInput {
  where: LeadWhereUniqueInput;
  data: LeadUpdateWithoutSalesRepInput;
}

export interface LeadUpdateWithWhereUniqueWithoutSetterInput {
  where: LeadWhereUniqueInput;
  data: LeadUpdateWithoutSetterInput;
}

export interface LeadUpdateWithoutAppointmentInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  campaign?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  referral?: NullableStringFieldUpdateOperationsInput | null;
  leadCreatedFrom?: NullableStringFieldUpdateOperationsInput | null;
  leadStatus?: NullableStringFieldUpdateOperationsInput | null;
  paymentStatus?: NullableStringFieldUpdateOperationsInput | null;
  utilityBill?: NullableStringFieldUpdateOperationsInput | null;
  averageBill?: NullableStringFieldUpdateOperationsInput | null;
  creditScore?: NullableStringFieldUpdateOperationsInput | null;
  homeOwner?: NullableStringFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  referralContactInfo?: NullableStringFieldUpdateOperationsInput | null;
  leadtype?: NullableStringFieldUpdateOperationsInput | null;
  leadId?: NullableStringFieldUpdateOperationsInput | null;
  calendarEmail?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableIntFieldUpdateOperationsInput | null;
  leadNotes?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType_?: LeadUpdateleadSourceType_Input | null;
  referral_?: LeadUpdatereferral_Input | null;
  leadCreatedFrom_?: LeadUpdateleadCreatedFrom_Input | null;
  leadStatus_?: LeadUpdateleadStatus_Input | null;
  paymentStatus_?: LeadUpdatepaymentStatus_Input | null;
  contact?: ContactUpdateOneWithoutLeadInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutLeadsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutLeadsAsSetterInput | null;
  deal?: DealUpdateManyWithoutLeadInput | null;
}

export interface LeadUpdateWithoutContactInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  campaign?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  referral?: NullableStringFieldUpdateOperationsInput | null;
  leadCreatedFrom?: NullableStringFieldUpdateOperationsInput | null;
  leadStatus?: NullableStringFieldUpdateOperationsInput | null;
  paymentStatus?: NullableStringFieldUpdateOperationsInput | null;
  utilityBill?: NullableStringFieldUpdateOperationsInput | null;
  averageBill?: NullableStringFieldUpdateOperationsInput | null;
  creditScore?: NullableStringFieldUpdateOperationsInput | null;
  homeOwner?: NullableStringFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  referralContactInfo?: NullableStringFieldUpdateOperationsInput | null;
  leadtype?: NullableStringFieldUpdateOperationsInput | null;
  leadId?: NullableStringFieldUpdateOperationsInput | null;
  calendarEmail?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableIntFieldUpdateOperationsInput | null;
  leadNotes?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType_?: LeadUpdateleadSourceType_Input | null;
  referral_?: LeadUpdatereferral_Input | null;
  leadCreatedFrom_?: LeadUpdateleadCreatedFrom_Input | null;
  leadStatus_?: LeadUpdateleadStatus_Input | null;
  paymentStatus_?: LeadUpdatepaymentStatus_Input | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutLeadsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutLeadsAsSetterInput | null;
  appointment?: AppointmentUpdateManyWithoutLeadInfoInput | null;
  deal?: DealUpdateManyWithoutLeadInput | null;
}

export interface LeadUpdateWithoutDealInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  campaign?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  referral?: NullableStringFieldUpdateOperationsInput | null;
  leadCreatedFrom?: NullableStringFieldUpdateOperationsInput | null;
  leadStatus?: NullableStringFieldUpdateOperationsInput | null;
  paymentStatus?: NullableStringFieldUpdateOperationsInput | null;
  utilityBill?: NullableStringFieldUpdateOperationsInput | null;
  averageBill?: NullableStringFieldUpdateOperationsInput | null;
  creditScore?: NullableStringFieldUpdateOperationsInput | null;
  homeOwner?: NullableStringFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  referralContactInfo?: NullableStringFieldUpdateOperationsInput | null;
  leadtype?: NullableStringFieldUpdateOperationsInput | null;
  leadId?: NullableStringFieldUpdateOperationsInput | null;
  calendarEmail?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableIntFieldUpdateOperationsInput | null;
  leadNotes?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType_?: LeadUpdateleadSourceType_Input | null;
  referral_?: LeadUpdatereferral_Input | null;
  leadCreatedFrom_?: LeadUpdateleadCreatedFrom_Input | null;
  leadStatus_?: LeadUpdateleadStatus_Input | null;
  paymentStatus_?: LeadUpdatepaymentStatus_Input | null;
  contact?: ContactUpdateOneWithoutLeadInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutLeadsAsSalesRepInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutLeadsAsSetterInput | null;
  appointment?: AppointmentUpdateManyWithoutLeadInfoInput | null;
}

export interface LeadUpdateWithoutSalesRepInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  campaign?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  referral?: NullableStringFieldUpdateOperationsInput | null;
  leadCreatedFrom?: NullableStringFieldUpdateOperationsInput | null;
  leadStatus?: NullableStringFieldUpdateOperationsInput | null;
  paymentStatus?: NullableStringFieldUpdateOperationsInput | null;
  utilityBill?: NullableStringFieldUpdateOperationsInput | null;
  averageBill?: NullableStringFieldUpdateOperationsInput | null;
  creditScore?: NullableStringFieldUpdateOperationsInput | null;
  homeOwner?: NullableStringFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  referralContactInfo?: NullableStringFieldUpdateOperationsInput | null;
  leadtype?: NullableStringFieldUpdateOperationsInput | null;
  leadId?: NullableStringFieldUpdateOperationsInput | null;
  calendarEmail?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableIntFieldUpdateOperationsInput | null;
  leadNotes?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType_?: LeadUpdateleadSourceType_Input | null;
  referral_?: LeadUpdatereferral_Input | null;
  leadCreatedFrom_?: LeadUpdateleadCreatedFrom_Input | null;
  leadStatus_?: LeadUpdateleadStatus_Input | null;
  paymentStatus_?: LeadUpdatepaymentStatus_Input | null;
  contact?: ContactUpdateOneWithoutLeadInput | null;
  Setter?: SalesEmployeeUpdateOneWithoutLeadsAsSetterInput | null;
  appointment?: AppointmentUpdateManyWithoutLeadInfoInput | null;
  deal?: DealUpdateManyWithoutLeadInput | null;
}

export interface LeadUpdateWithoutSetterInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  externalId?: NullableStringFieldUpdateOperationsInput | null;
  provider?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  campaign?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  referral?: NullableStringFieldUpdateOperationsInput | null;
  leadCreatedFrom?: NullableStringFieldUpdateOperationsInput | null;
  leadStatus?: NullableStringFieldUpdateOperationsInput | null;
  paymentStatus?: NullableStringFieldUpdateOperationsInput | null;
  utilityBill?: NullableStringFieldUpdateOperationsInput | null;
  averageBill?: NullableStringFieldUpdateOperationsInput | null;
  creditScore?: NullableStringFieldUpdateOperationsInput | null;
  homeOwner?: NullableStringFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  referralContactInfo?: NullableStringFieldUpdateOperationsInput | null;
  leadtype?: NullableStringFieldUpdateOperationsInput | null;
  leadId?: NullableStringFieldUpdateOperationsInput | null;
  calendarEmail?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableIntFieldUpdateOperationsInput | null;
  leadNotes?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType_?: LeadUpdateleadSourceType_Input | null;
  referral_?: LeadUpdatereferral_Input | null;
  leadCreatedFrom_?: LeadUpdateleadCreatedFrom_Input | null;
  leadStatus_?: LeadUpdateleadStatus_Input | null;
  paymentStatus_?: LeadUpdatepaymentStatus_Input | null;
  contact?: ContactUpdateOneWithoutLeadInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutLeadsAsSalesRepInput | null;
  appointment?: AppointmentUpdateManyWithoutLeadInfoInput | null;
  deal?: DealUpdateManyWithoutLeadInput | null;
}

export interface LeadUpdateleadCreatedFrom_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface LeadUpdateleadSourceType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface LeadUpdateleadStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface LeadUpdatepaymentStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface LeadUpdatereferral_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface LeadUpsertWithWhereUniqueWithoutContactInput {
  where: LeadWhereUniqueInput;
  update: LeadUpdateWithoutContactInput;
  create: LeadCreateWithoutContactInput;
}

export interface LeadUpsertWithWhereUniqueWithoutSalesRepInput {
  where: LeadWhereUniqueInput;
  update: LeadUpdateWithoutSalesRepInput;
  create: LeadCreateWithoutSalesRepInput;
}

export interface LeadUpsertWithWhereUniqueWithoutSetterInput {
  where: LeadWhereUniqueInput;
  update: LeadUpdateWithoutSetterInput;
  create: LeadCreateWithoutSetterInput;
}

export interface LeadUpsertWithoutAppointmentInput {
  update: LeadUpdateWithoutAppointmentInput;
  create: LeadCreateWithoutAppointmentInput;
}

export interface LeadUpsertWithoutDealInput {
  update: LeadUpdateWithoutDealInput;
  create: LeadCreateWithoutDealInput;
}

export interface LeadWhereInput {
  AND?: LeadWhereInput[] | null;
  OR?: LeadWhereInput[] | null;
  NOT?: LeadWhereInput[] | null;
  id?: IntFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  team?: StringNullableFilter | null;
  podioId?: StringNullableFilter | null;
  dateLeadSubmitted?: DateTimeNullableFilter | null;
  appointmentStartDate?: DateTimeNullableFilter | null;
  appointmentEndDate?: DateTimeNullableFilter | null;
  externalId?: StringNullableFilter | null;
  provider?: StringNullableFilter | null;
  type?: StringNullableFilter | null;
  campaign?: StringNullableFilter | null;
  leadSourceType_?: StringNullableListFilter | null;
  leadSourceType?: StringNullableFilter | null;
  referral_?: StringNullableListFilter | null;
  referral?: StringNullableFilter | null;
  leadCreatedFrom_?: StringNullableListFilter | null;
  leadCreatedFrom?: StringNullableFilter | null;
  leadStatus_?: StringNullableListFilter | null;
  leadStatus?: StringNullableFilter | null;
  paymentStatus_?: StringNullableListFilter | null;
  paymentStatus?: StringNullableFilter | null;
  utilityBill?: StringNullableFilter | null;
  averageBill?: StringNullableFilter | null;
  creditScore?: StringNullableFilter | null;
  homeOwner?: StringNullableFilter | null;
  contactId?: IntNullableFilter | null;
  contact?: ContactWhereInput | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
  salesEmployeeId_Setter?: IntNullableFilter | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  Setter?: SalesEmployeeWhereInput | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  referralContactInfo?: StringNullableFilter | null;
  leadtype?: StringNullableFilter | null;
  leadId?: StringNullableFilter | null;
  calendarEmail?: StringNullableFilter | null;
  systemSize?: IntNullableFilter | null;
  leadNotes?: StringNullableFilter | null;
  appointment?: AppointmentListRelationFilter | null;
  deal?: DealListRelationFilter | null;
}

export interface LeadWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  contactId?: number | null;
}

export interface LeaderboardCreateNestedOneWithoutTopInput {
  create?: LeaderboardCreateWithoutTopInput | null;
  connectOrCreate?: LeaderboardCreateOrConnectWithoutTopInput | null;
  connect?: LeaderboardWhereUniqueInput | null;
}

export interface LeaderboardCreateOrConnectWithoutTopInput {
  where: LeaderboardWhereUniqueInput;
  create: LeaderboardCreateWithoutTopInput;
}

export interface LeaderboardCreateWithoutTopInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  gte?: any | null;
  lte?: any | null;
  results?: any | null;
  title?: string | null;
  statKey?: string | null;
  type?: string | null;
  month?: string | null;
  year?: string | null;
}

export interface LeaderboardUpdateOneWithoutTopInput {
  create?: LeaderboardCreateWithoutTopInput | null;
  connectOrCreate?: LeaderboardCreateOrConnectWithoutTopInput | null;
  upsert?: LeaderboardUpsertWithoutTopInput | null;
  connect?: LeaderboardWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: LeaderboardUpdateWithoutTopInput | null;
}

export interface LeaderboardUpdateWithoutTopInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  gte?: NullableDateTimeFieldUpdateOperationsInput | null;
  lte?: NullableDateTimeFieldUpdateOperationsInput | null;
  results?: any | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  statKey?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  month?: NullableStringFieldUpdateOperationsInput | null;
  year?: NullableStringFieldUpdateOperationsInput | null;
}

export interface LeaderboardUpsertWithoutTopInput {
  update: LeaderboardUpdateWithoutTopInput;
  create: LeaderboardCreateWithoutTopInput;
}

export interface LeaderboardWhereInput {
  AND?: LeaderboardWhereInput[] | null;
  OR?: LeaderboardWhereInput[] | null;
  NOT?: LeaderboardWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  gte?: DateTimeNullableFilter | null;
  lte?: DateTimeNullableFilter | null;
  results?: JsonNullableFilter | null;
  title?: StringNullableFilter | null;
  top?: UserListRelationFilter | null;
  statKey?: StringNullableFilter | null;
  type?: StringNullableFilter | null;
  month?: StringNullableFilter | null;
  year?: StringNullableFilter | null;
}

export interface LeaderboardWhereUniqueInput {
  id?: number | null;
}

export interface LocationCreateNestedOneWithoutCustomerInput {
  create?: LocationCreateWithoutCustomerInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutCustomerInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutMasterCustomerInput {
  create?: LocationCreateWithoutMasterCustomerInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutMasterCustomerInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutSalesRepInput {
  create?: LocationCreateWithoutSalesRepInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutSalesRepInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateOrConnectWithoutCustomerInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutCustomerInput;
}

export interface LocationCreateOrConnectWithoutMasterCustomerInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutMasterCustomerInput;
}

export interface LocationCreateOrConnectWithoutSalesRepInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutSalesRepInput;
}

export interface LocationCreateWithoutCustomerInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  city?: string | null;
  mapInSync?: boolean | null;
  streetAddress?: string | null;
  streetNumber?: string | null;
  country?: string | null;
  streetName?: string | null;
  state?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  salesRep?: SalesRepCreateNestedManyWithoutLocationInput | null;
  MasterCustomer?: MasterCustomerCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutMasterCustomerInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  city?: string | null;
  mapInSync?: boolean | null;
  streetAddress?: string | null;
  streetNumber?: string | null;
  country?: string | null;
  streetName?: string | null;
  state?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  customer?: CustomerCreateNestedManyWithoutLocationInput | null;
  salesRep?: SalesRepCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  city?: string | null;
  mapInSync?: boolean | null;
  streetAddress?: string | null;
  streetNumber?: string | null;
  country?: string | null;
  streetName?: string | null;
  state?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  formattedAddress?: string | null;
  showAddress?: string | null;
  customer?: CustomerCreateNestedManyWithoutLocationInput | null;
  MasterCustomer?: MasterCustomerCreateNestedManyWithoutLocationInput | null;
}

export interface LocationUpdateOneWithoutCustomerInput {
  create?: LocationCreateWithoutCustomerInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutCustomerInput | null;
  upsert?: LocationUpsertWithoutCustomerInput | null;
  connect?: LocationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: LocationUpdateWithoutCustomerInput | null;
}

export interface LocationUpdateOneWithoutMasterCustomerInput {
  create?: LocationCreateWithoutMasterCustomerInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutMasterCustomerInput | null;
  upsert?: LocationUpsertWithoutMasterCustomerInput | null;
  connect?: LocationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: LocationUpdateWithoutMasterCustomerInput | null;
}

export interface LocationUpdateOneWithoutSalesRepInput {
  create?: LocationCreateWithoutSalesRepInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutSalesRepInput | null;
  upsert?: LocationUpsertWithoutSalesRepInput | null;
  connect?: LocationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: LocationUpdateWithoutSalesRepInput | null;
}

export interface LocationUpdateWithoutCustomerInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  mapInSync?: NullableBoolFieldUpdateOperationsInput | null;
  streetAddress?: NullableStringFieldUpdateOperationsInput | null;
  streetNumber?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  streetName?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  showAddress?: NullableStringFieldUpdateOperationsInput | null;
  salesRep?: SalesRepUpdateManyWithoutLocationInput | null;
  MasterCustomer?: MasterCustomerUpdateManyWithoutLocationInput | null;
}

export interface LocationUpdateWithoutMasterCustomerInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  mapInSync?: NullableBoolFieldUpdateOperationsInput | null;
  streetAddress?: NullableStringFieldUpdateOperationsInput | null;
  streetNumber?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  streetName?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  showAddress?: NullableStringFieldUpdateOperationsInput | null;
  customer?: CustomerUpdateManyWithoutLocationInput | null;
  salesRep?: SalesRepUpdateManyWithoutLocationInput | null;
}

export interface LocationUpdateWithoutSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  mapInSync?: NullableBoolFieldUpdateOperationsInput | null;
  streetAddress?: NullableStringFieldUpdateOperationsInput | null;
  streetNumber?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  streetName?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  showAddress?: NullableStringFieldUpdateOperationsInput | null;
  customer?: CustomerUpdateManyWithoutLocationInput | null;
  MasterCustomer?: MasterCustomerUpdateManyWithoutLocationInput | null;
}

export interface LocationUpsertWithoutCustomerInput {
  update: LocationUpdateWithoutCustomerInput;
  create: LocationCreateWithoutCustomerInput;
}

export interface LocationUpsertWithoutMasterCustomerInput {
  update: LocationUpdateWithoutMasterCustomerInput;
  create: LocationCreateWithoutMasterCustomerInput;
}

export interface LocationUpsertWithoutSalesRepInput {
  update: LocationUpdateWithoutSalesRepInput;
  create: LocationCreateWithoutSalesRepInput;
}

export interface LocationWhereInput {
  AND?: LocationWhereInput[] | null;
  OR?: LocationWhereInput[] | null;
  NOT?: LocationWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  city?: StringNullableFilter | null;
  mapInSync?: BoolNullableFilter | null;
  streetAddress?: StringNullableFilter | null;
  streetNumber?: StringNullableFilter | null;
  country?: StringNullableFilter | null;
  streetName?: StringNullableFilter | null;
  state?: StringNullableFilter | null;
  postalCode?: StringNullableFilter | null;
  lat?: DecimalNullableFilter | null;
  lng?: DecimalNullableFilter | null;
  formattedAddress?: StringNullableFilter | null;
  showAddress?: StringNullableFilter | null;
  customer?: CustomerListRelationFilter | null;
  salesRep?: SalesRepListRelationFilter | null;
  MasterCustomer?: MasterCustomerListRelationFilter | null;
}

export interface LocationWhereUniqueInput {
  id?: number | null;
}

export interface MasterCustomerCreateManyContactInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  projectId?: number | null;
  salesRepId?: number | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  salesTeamId?: number | null;
  setterId?: number | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  locationId?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreateManygreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreateManystepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreateManyfsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreateManyfsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreateManytest_Input | null;
  installReady_?: MasterCustomerCreateManyinstallReady_Input | null;
  stipulations_?: MasterCustomerCreateManystipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreateManyhoaNeeded2_Input | null;
}

export interface MasterCustomerCreateManyContactInputEnvelope {
  data?: MasterCustomerCreateManyContactInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface MasterCustomerCreateManyLocationInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  projectId?: number | null;
  salesRepId?: number | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  salesTeamId?: number | null;
  setterId?: number | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  contactId?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreateManygreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreateManystepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreateManyfsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreateManyfsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreateManytest_Input | null;
  installReady_?: MasterCustomerCreateManyinstallReady_Input | null;
  stipulations_?: MasterCustomerCreateManystipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreateManyhoaNeeded2_Input | null;
}

export interface MasterCustomerCreateManyLocationInputEnvelope {
  data?: MasterCustomerCreateManyLocationInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface MasterCustomerCreateManyProjectInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  salesRepId?: number | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  salesTeamId?: number | null;
  setterId?: number | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreateManygreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreateManystepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreateManyfsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreateManyfsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreateManytest_Input | null;
  installReady_?: MasterCustomerCreateManyinstallReady_Input | null;
  stipulations_?: MasterCustomerCreateManystipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreateManyhoaNeeded2_Input | null;
}

export interface MasterCustomerCreateManyProjectInputEnvelope {
  data?: MasterCustomerCreateManyProjectInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface MasterCustomerCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  projectId?: number | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  salesTeamId?: number | null;
  setterId?: number | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreateManygreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreateManystepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreateManyfsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreateManyfsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreateManytest_Input | null;
  installReady_?: MasterCustomerCreateManyinstallReady_Input | null;
  stipulations_?: MasterCustomerCreateManystipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreateManyhoaNeeded2_Input | null;
}

export interface MasterCustomerCreateManySalesRepInputEnvelope {
  data?: MasterCustomerCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface MasterCustomerCreateManySalesTeamInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  projectId?: number | null;
  salesRepId?: number | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  setterId?: number | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreateManygreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreateManystepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreateManyfsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreateManyfsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreateManytest_Input | null;
  installReady_?: MasterCustomerCreateManyinstallReady_Input | null;
  stipulations_?: MasterCustomerCreateManystipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreateManyhoaNeeded2_Input | null;
}

export interface MasterCustomerCreateManySalesTeamInputEnvelope {
  data?: MasterCustomerCreateManySalesTeamInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface MasterCustomerCreateManySetterInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  projectId?: number | null;
  salesRepId?: number | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  salesTeamId?: number | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreateManygreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreateManystepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreateManyfsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreateManyfsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreateManytest_Input | null;
  installReady_?: MasterCustomerCreateManyinstallReady_Input | null;
  stipulations_?: MasterCustomerCreateManystipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreateManyhoaNeeded2_Input | null;
}

export interface MasterCustomerCreateManySetterInputEnvelope {
  data?: MasterCustomerCreateManySetterInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface MasterCustomerCreateManyfsDauntlessOutcome_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateManyfsValorOutcome_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateManygreenLights_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateManyhoaNeeded2_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateManyinstallReady_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateManystepsRequiredForFluentApproved_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateManystipulations_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateManytest_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateNestedManyWithoutContactInput {
  create?: MasterCustomerCreateWithoutContactInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutContactInput[] | null;
  createMany?: MasterCustomerCreateManyContactInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
}

export interface MasterCustomerCreateNestedManyWithoutLocationInput {
  create?: MasterCustomerCreateWithoutLocationInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutLocationInput[] | null;
  createMany?: MasterCustomerCreateManyLocationInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
}

export interface MasterCustomerCreateNestedManyWithoutProjectInput {
  create?: MasterCustomerCreateWithoutProjectInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutProjectInput[] | null;
  createMany?: MasterCustomerCreateManyProjectInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
}

export interface MasterCustomerCreateNestedManyWithoutSalesRepInput {
  create?: MasterCustomerCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: MasterCustomerCreateManySalesRepInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
}

export interface MasterCustomerCreateNestedManyWithoutSalesTeamInput {
  create?: MasterCustomerCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutSalesTeamInput[] | null;
  createMany?: MasterCustomerCreateManySalesTeamInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
}

export interface MasterCustomerCreateNestedManyWithoutSetterInput {
  create?: MasterCustomerCreateWithoutSetterInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutSetterInput[] | null;
  createMany?: MasterCustomerCreateManySetterInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
}

export interface MasterCustomerCreateNestedOneWithoutCustomerInput {
  create?: MasterCustomerCreateWithoutCustomerInput | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutCustomerInput | null;
  connect?: MasterCustomerWhereUniqueInput | null;
}

export interface MasterCustomerCreateNestedOneWithoutInstallReadyInput {
  create?: MasterCustomerCreateWithoutInstallReadyInput | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutInstallReadyInput | null;
  connect?: MasterCustomerWhereUniqueInput | null;
}

export interface MasterCustomerCreateOrConnectWithoutContactInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutContactInput;
}

export interface MasterCustomerCreateOrConnectWithoutCustomerInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutCustomerInput;
}

export interface MasterCustomerCreateOrConnectWithoutInstallReadyInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutInstallReadyInput;
}

export interface MasterCustomerCreateOrConnectWithoutLocationInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutLocationInput;
}

export interface MasterCustomerCreateOrConnectWithoutProjectInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutProjectInput;
}

export interface MasterCustomerCreateOrConnectWithoutSalesRepInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutSalesRepInput;
}

export interface MasterCustomerCreateOrConnectWithoutSalesTeamInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutSalesTeamInput;
}

export interface MasterCustomerCreateOrConnectWithoutSetterInput {
  where: MasterCustomerWhereUniqueInput;
  create: MasterCustomerCreateWithoutSetterInput;
}

export interface MasterCustomerCreateWithoutContactInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  location?: LocationCreateNestedOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutMasterCustomersInput | null;
  setter?: SetterCreateNestedOneWithoutMasterCustomersInput | null;
  customer?: CustomerCreateNestedOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyCreateNestedManyWithoutLinkToMasterListInput | null;
  project?: ProjectCreateNestedOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerCreateWithoutCustomerInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  contact?: ContactCreateNestedOneWithoutMasterCustomerInput | null;
  location?: LocationCreateNestedOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutMasterCustomersInput | null;
  setter?: SetterCreateNestedOneWithoutMasterCustomersInput | null;
  installReady?: InstallReadyCreateNestedManyWithoutLinkToMasterListInput | null;
  project?: ProjectCreateNestedOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerCreateWithoutInstallReadyInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  contact?: ContactCreateNestedOneWithoutMasterCustomerInput | null;
  location?: LocationCreateNestedOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutMasterCustomersInput | null;
  setter?: SetterCreateNestedOneWithoutMasterCustomersInput | null;
  customer?: CustomerCreateNestedOneWithoutMasterCustomerInput | null;
  project?: ProjectCreateNestedOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerCreateWithoutLocationInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  contact?: ContactCreateNestedOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutMasterCustomersInput | null;
  setter?: SetterCreateNestedOneWithoutMasterCustomersInput | null;
  customer?: CustomerCreateNestedOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyCreateNestedManyWithoutLinkToMasterListInput | null;
  project?: ProjectCreateNestedOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  contact?: ContactCreateNestedOneWithoutMasterCustomerInput | null;
  location?: LocationCreateNestedOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutMasterCustomersInput | null;
  setter?: SetterCreateNestedOneWithoutMasterCustomersInput | null;
  customer?: CustomerCreateNestedOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyCreateNestedManyWithoutLinkToMasterListInput | null;
}

export interface MasterCustomerCreateWithoutSalesRepInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  contact?: ContactCreateNestedOneWithoutMasterCustomerInput | null;
  location?: LocationCreateNestedOneWithoutMasterCustomerInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutMasterCustomersInput | null;
  setter?: SetterCreateNestedOneWithoutMasterCustomersInput | null;
  customer?: CustomerCreateNestedOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyCreateNestedManyWithoutLinkToMasterListInput | null;
  project?: ProjectCreateNestedOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerCreateWithoutSalesTeamInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  contact?: ContactCreateNestedOneWithoutMasterCustomerInput | null;
  location?: LocationCreateNestedOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutMasterCustomersInput | null;
  setter?: SetterCreateNestedOneWithoutMasterCustomersInput | null;
  customer?: CustomerCreateNestedOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyCreateNestedManyWithoutLinkToMasterListInput | null;
  project?: ProjectCreateNestedOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerCreateWithoutSetterInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  fullName?: string | null;
  createdOn?: any | null;
  repId?: number | null;
  installGreenLights?: string | null;
  stepsRequiredForFluentApproved?: string | null;
  team?: team_type | null;
  customerAddress?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerZipcode?: string | null;
  region?: string | null;
  leadSourceType?: string | null;
  appointmentId?: number | null;
  vOutcome?: string | null;
  dOutcome?: string | null;
  archived?: boolean | null;
  dateLeadSubmitted?: any | null;
  dateOutcomeUpdated?: any | null;
  dateAccountCreated?: any | null;
  contractSignedDate?: any | null;
  fluentApprovedDate?: any | null;
  welcomeCallCompleteDate?: any | null;
  siteSurveyCompleteDate?: any | null;
  cadCompleteDate?: any | null;
  permitReceivedDate?: any | null;
  permitSubmittedDate?: any | null;
  hoaApprovedDate?: any | null;
  hoaSubmittedDate?: any | null;
  installCompleteDate?: any | null;
  inspectionCompleteDate?: any | null;
  nemApprovedDate?: any | null;
  nemSubmittedDate?: any | null;
  ptoReceivedDate?: any | null;
  dateInstallReady?: any | null;
  dateNtpSubmitted?: any | null;
  dateNtpApproved?: any | null;
  dateEngineeringComplete?: any | null;
  dateAllPermitsApproved?: any | null;
  dateScheduledInstall?: any | null;
  datePtoSubmitted?: any | null;
  dateM1Submitted?: any | null;
  dateM1Received?: any | null;
  dateCanceled?: any | null;
  appointmentDate?: any | null;
  onHoldDate?: any | null;
  ticketCreatedDate?: any | null;
  ticketScheduledDate?: any | null;
  installCancelledDate?: any | null;
  fluentHomeNotifiedDate?: any | null;
  timeStampOfWhenInstallWasScheduled?: any | null;
  outcome?: string | null;
  systemSize?: number | null;
  systemPrice?: number | null;
  loanProduct?: string | null;
  loanPartnerName?: string | null;
  stipulations?: string | null;
  greenLights_?: MasterCustomerCreategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerCreatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerCreatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerCreatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerCreatetest_Input | null;
  installReady_?: MasterCustomerCreateinstallReady_Input | null;
  stipulations_?: MasterCustomerCreatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerCreatehoaNeeded2_Input | null;
  contact?: ContactCreateNestedOneWithoutMasterCustomerInput | null;
  location?: LocationCreateNestedOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutMasterCustomersInput | null;
  customer?: CustomerCreateNestedOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyCreateNestedManyWithoutLinkToMasterListInput | null;
  project?: ProjectCreateNestedOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerCreatefsDauntlessOutcome_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreatefsValorOutcome_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreategreenLights_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreatehoaNeeded2_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreateinstallReady_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreatestepsRequiredForFluentApproved_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreatestipulations_Input {
  set?: string[] | null;
}

export interface MasterCustomerCreatetest_Input {
  set?: string[] | null;
}

export interface MasterCustomerListRelationFilter {
  every?: MasterCustomerWhereInput | null;
  some?: MasterCustomerWhereInput | null;
  none?: MasterCustomerWhereInput | null;
}

export interface MasterCustomerOrderByInput {
  id?: SortOrder | null;
  podioId?: SortOrder | null;
  link?: SortOrder | null;
  appItemId?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  fullName?: SortOrder | null;
  createdOn?: SortOrder | null;
  greenLights_?: SortOrder | null;
  stepsRequiredForFluentApproved_?: SortOrder | null;
  fsValorOutcome_?: SortOrder | null;
  fsDauntlessOutcome_?: SortOrder | null;
  test_?: SortOrder | null;
  installReady_?: SortOrder | null;
  stipulations_?: SortOrder | null;
  hoaNeeded2_?: SortOrder | null;
  projectId?: SortOrder | null;
  salesRepId?: SortOrder | null;
  repId?: SortOrder | null;
  installGreenLights?: SortOrder | null;
  stepsRequiredForFluentApproved?: SortOrder | null;
  team?: SortOrder | null;
  customerAddress?: SortOrder | null;
  customerPhone?: SortOrder | null;
  customerEmail?: SortOrder | null;
  customerZipcode?: SortOrder | null;
  region?: SortOrder | null;
  leadSourceType?: SortOrder | null;
  appointmentId?: SortOrder | null;
  vOutcome?: SortOrder | null;
  dOutcome?: SortOrder | null;
  salesTeamId?: SortOrder | null;
  setterId?: SortOrder | null;
  archived?: SortOrder | null;
  dateLeadSubmitted?: SortOrder | null;
  dateOutcomeUpdated?: SortOrder | null;
  dateAccountCreated?: SortOrder | null;
  contractSignedDate?: SortOrder | null;
  fluentApprovedDate?: SortOrder | null;
  welcomeCallCompleteDate?: SortOrder | null;
  siteSurveyCompleteDate?: SortOrder | null;
  cadCompleteDate?: SortOrder | null;
  permitReceivedDate?: SortOrder | null;
  permitSubmittedDate?: SortOrder | null;
  hoaApprovedDate?: SortOrder | null;
  hoaSubmittedDate?: SortOrder | null;
  installCompleteDate?: SortOrder | null;
  inspectionCompleteDate?: SortOrder | null;
  nemApprovedDate?: SortOrder | null;
  nemSubmittedDate?: SortOrder | null;
  ptoReceivedDate?: SortOrder | null;
  dateInstallReady?: SortOrder | null;
  dateNtpSubmitted?: SortOrder | null;
  dateNtpApproved?: SortOrder | null;
  dateEngineeringComplete?: SortOrder | null;
  dateAllPermitsApproved?: SortOrder | null;
  dateScheduledInstall?: SortOrder | null;
  datePtoSubmitted?: SortOrder | null;
  dateM1Submitted?: SortOrder | null;
  dateM1Received?: SortOrder | null;
  dateCanceled?: SortOrder | null;
  appointmentDate?: SortOrder | null;
  onHoldDate?: SortOrder | null;
  ticketCreatedDate?: SortOrder | null;
  ticketScheduledDate?: SortOrder | null;
  installCancelledDate?: SortOrder | null;
  fluentHomeNotifiedDate?: SortOrder | null;
  timeStampOfWhenInstallWasScheduled?: SortOrder | null;
  outcome?: SortOrder | null;
  systemSize?: SortOrder | null;
  systemPrice?: SortOrder | null;
  contactId?: SortOrder | null;
  locationId?: SortOrder | null;
  loanProduct?: SortOrder | null;
  loanPartnerName?: SortOrder | null;
  stipulations?: SortOrder | null;
}

export interface MasterCustomerScalarWhereInput {
  AND?: MasterCustomerScalarWhereInput[] | null;
  OR?: MasterCustomerScalarWhereInput[] | null;
  NOT?: MasterCustomerScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  fullName?: StringNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  greenLights_?: StringNullableListFilter | null;
  stepsRequiredForFluentApproved_?: StringNullableListFilter | null;
  fsValorOutcome_?: StringNullableListFilter | null;
  fsDauntlessOutcome_?: StringNullableListFilter | null;
  test_?: StringNullableListFilter | null;
  installReady_?: StringNullableListFilter | null;
  stipulations_?: StringNullableListFilter | null;
  hoaNeeded2_?: StringNullableListFilter | null;
  projectId?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  repId?: IntNullableFilter | null;
  installGreenLights?: StringNullableFilter | null;
  stepsRequiredForFluentApproved?: StringNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  customerAddress?: StringNullableFilter | null;
  customerPhone?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  customerZipcode?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  leadSourceType?: StringNullableFilter | null;
  appointmentId?: IntNullableFilter | null;
  vOutcome?: StringNullableFilter | null;
  dOutcome?: StringNullableFilter | null;
  salesTeamId?: IntNullableFilter | null;
  setterId?: IntNullableFilter | null;
  archived?: BoolNullableFilter | null;
  dateLeadSubmitted?: DateTimeNullableFilter | null;
  dateOutcomeUpdated?: DateTimeNullableFilter | null;
  dateAccountCreated?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  fluentApprovedDate?: DateTimeNullableFilter | null;
  welcomeCallCompleteDate?: DateTimeNullableFilter | null;
  siteSurveyCompleteDate?: DateTimeNullableFilter | null;
  cadCompleteDate?: DateTimeNullableFilter | null;
  permitReceivedDate?: DateTimeNullableFilter | null;
  permitSubmittedDate?: DateTimeNullableFilter | null;
  hoaApprovedDate?: DateTimeNullableFilter | null;
  hoaSubmittedDate?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  inspectionCompleteDate?: DateTimeNullableFilter | null;
  nemApprovedDate?: DateTimeNullableFilter | null;
  nemSubmittedDate?: DateTimeNullableFilter | null;
  ptoReceivedDate?: DateTimeNullableFilter | null;
  dateInstallReady?: DateTimeNullableFilter | null;
  dateNtpSubmitted?: DateTimeNullableFilter | null;
  dateNtpApproved?: DateTimeNullableFilter | null;
  dateEngineeringComplete?: DateTimeNullableFilter | null;
  dateAllPermitsApproved?: DateTimeNullableFilter | null;
  dateScheduledInstall?: DateTimeNullableFilter | null;
  datePtoSubmitted?: DateTimeNullableFilter | null;
  dateM1Submitted?: DateTimeNullableFilter | null;
  dateM1Received?: DateTimeNullableFilter | null;
  dateCanceled?: DateTimeNullableFilter | null;
  appointmentDate?: DateTimeNullableFilter | null;
  onHoldDate?: DateTimeNullableFilter | null;
  ticketCreatedDate?: DateTimeNullableFilter | null;
  ticketScheduledDate?: DateTimeNullableFilter | null;
  installCancelledDate?: DateTimeNullableFilter | null;
  fluentHomeNotifiedDate?: DateTimeNullableFilter | null;
  timeStampOfWhenInstallWasScheduled?: DateTimeNullableFilter | null;
  outcome?: StringNullableFilter | null;
  systemSize?: FloatNullableFilter | null;
  systemPrice?: FloatNullableFilter | null;
  contactId?: IntNullableFilter | null;
  locationId?: IntNullableFilter | null;
  loanProduct?: StringNullableFilter | null;
  loanPartnerName?: StringNullableFilter | null;
  stipulations?: StringNullableFilter | null;
}

export interface MasterCustomerUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
}

export interface MasterCustomerUpdateManyWithWhereWithoutContactInput {
  where: MasterCustomerScalarWhereInput;
  data: MasterCustomerUpdateManyMutationInput;
}

export interface MasterCustomerUpdateManyWithWhereWithoutLocationInput {
  where: MasterCustomerScalarWhereInput;
  data: MasterCustomerUpdateManyMutationInput;
}

export interface MasterCustomerUpdateManyWithWhereWithoutProjectInput {
  where: MasterCustomerScalarWhereInput;
  data: MasterCustomerUpdateManyMutationInput;
}

export interface MasterCustomerUpdateManyWithWhereWithoutSalesRepInput {
  where: MasterCustomerScalarWhereInput;
  data: MasterCustomerUpdateManyMutationInput;
}

export interface MasterCustomerUpdateManyWithWhereWithoutSalesTeamInput {
  where: MasterCustomerScalarWhereInput;
  data: MasterCustomerUpdateManyMutationInput;
}

export interface MasterCustomerUpdateManyWithWhereWithoutSetterInput {
  where: MasterCustomerScalarWhereInput;
  data: MasterCustomerUpdateManyMutationInput;
}

export interface MasterCustomerUpdateManyWithoutContactInput {
  create?: MasterCustomerCreateWithoutContactInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutContactInput[] | null;
  upsert?: MasterCustomerUpsertWithWhereUniqueWithoutContactInput[] | null;
  createMany?: MasterCustomerCreateManyContactInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
  set?: MasterCustomerWhereUniqueInput[] | null;
  disconnect?: MasterCustomerWhereUniqueInput[] | null;
  delete?: MasterCustomerWhereUniqueInput[] | null;
  update?: MasterCustomerUpdateWithWhereUniqueWithoutContactInput[] | null;
  updateMany?: MasterCustomerUpdateManyWithWhereWithoutContactInput[] | null;
  deleteMany?: MasterCustomerScalarWhereInput[] | null;
}

export interface MasterCustomerUpdateManyWithoutLocationInput {
  create?: MasterCustomerCreateWithoutLocationInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutLocationInput[] | null;
  upsert?: MasterCustomerUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: MasterCustomerCreateManyLocationInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
  set?: MasterCustomerWhereUniqueInput[] | null;
  disconnect?: MasterCustomerWhereUniqueInput[] | null;
  delete?: MasterCustomerWhereUniqueInput[] | null;
  update?: MasterCustomerUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: MasterCustomerUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: MasterCustomerScalarWhereInput[] | null;
}

export interface MasterCustomerUpdateManyWithoutProjectInput {
  create?: MasterCustomerCreateWithoutProjectInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutProjectInput[] | null;
  upsert?: MasterCustomerUpsertWithWhereUniqueWithoutProjectInput[] | null;
  createMany?: MasterCustomerCreateManyProjectInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
  set?: MasterCustomerWhereUniqueInput[] | null;
  disconnect?: MasterCustomerWhereUniqueInput[] | null;
  delete?: MasterCustomerWhereUniqueInput[] | null;
  update?: MasterCustomerUpdateWithWhereUniqueWithoutProjectInput[] | null;
  updateMany?: MasterCustomerUpdateManyWithWhereWithoutProjectInput[] | null;
  deleteMany?: MasterCustomerScalarWhereInput[] | null;
}

export interface MasterCustomerUpdateManyWithoutSalesRepInput {
  create?: MasterCustomerCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: MasterCustomerUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: MasterCustomerCreateManySalesRepInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
  set?: MasterCustomerWhereUniqueInput[] | null;
  disconnect?: MasterCustomerWhereUniqueInput[] | null;
  delete?: MasterCustomerWhereUniqueInput[] | null;
  update?: MasterCustomerUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: MasterCustomerUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: MasterCustomerScalarWhereInput[] | null;
}

export interface MasterCustomerUpdateManyWithoutSalesTeamInput {
  create?: MasterCustomerCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutSalesTeamInput[] | null;
  upsert?: MasterCustomerUpsertWithWhereUniqueWithoutSalesTeamInput[] | null;
  createMany?: MasterCustomerCreateManySalesTeamInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
  set?: MasterCustomerWhereUniqueInput[] | null;
  disconnect?: MasterCustomerWhereUniqueInput[] | null;
  delete?: MasterCustomerWhereUniqueInput[] | null;
  update?: MasterCustomerUpdateWithWhereUniqueWithoutSalesTeamInput[] | null;
  updateMany?: MasterCustomerUpdateManyWithWhereWithoutSalesTeamInput[] | null;
  deleteMany?: MasterCustomerScalarWhereInput[] | null;
}

export interface MasterCustomerUpdateManyWithoutSetterInput {
  create?: MasterCustomerCreateWithoutSetterInput[] | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutSetterInput[] | null;
  upsert?: MasterCustomerUpsertWithWhereUniqueWithoutSetterInput[] | null;
  createMany?: MasterCustomerCreateManySetterInputEnvelope | null;
  connect?: MasterCustomerWhereUniqueInput[] | null;
  set?: MasterCustomerWhereUniqueInput[] | null;
  disconnect?: MasterCustomerWhereUniqueInput[] | null;
  delete?: MasterCustomerWhereUniqueInput[] | null;
  update?: MasterCustomerUpdateWithWhereUniqueWithoutSetterInput[] | null;
  updateMany?: MasterCustomerUpdateManyWithWhereWithoutSetterInput[] | null;
  deleteMany?: MasterCustomerScalarWhereInput[] | null;
}

export interface MasterCustomerUpdateOneWithoutCustomerInput {
  create?: MasterCustomerCreateWithoutCustomerInput | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutCustomerInput | null;
  upsert?: MasterCustomerUpsertWithoutCustomerInput | null;
  connect?: MasterCustomerWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: MasterCustomerUpdateWithoutCustomerInput | null;
}

export interface MasterCustomerUpdateOneWithoutInstallReadyInput {
  create?: MasterCustomerCreateWithoutInstallReadyInput | null;
  connectOrCreate?: MasterCustomerCreateOrConnectWithoutInstallReadyInput | null;
  upsert?: MasterCustomerUpsertWithoutInstallReadyInput | null;
  connect?: MasterCustomerWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: MasterCustomerUpdateWithoutInstallReadyInput | null;
}

export interface MasterCustomerUpdateWithWhereUniqueWithoutContactInput {
  where: MasterCustomerWhereUniqueInput;
  data: MasterCustomerUpdateWithoutContactInput;
}

export interface MasterCustomerUpdateWithWhereUniqueWithoutLocationInput {
  where: MasterCustomerWhereUniqueInput;
  data: MasterCustomerUpdateWithoutLocationInput;
}

export interface MasterCustomerUpdateWithWhereUniqueWithoutProjectInput {
  where: MasterCustomerWhereUniqueInput;
  data: MasterCustomerUpdateWithoutProjectInput;
}

export interface MasterCustomerUpdateWithWhereUniqueWithoutSalesRepInput {
  where: MasterCustomerWhereUniqueInput;
  data: MasterCustomerUpdateWithoutSalesRepInput;
}

export interface MasterCustomerUpdateWithWhereUniqueWithoutSalesTeamInput {
  where: MasterCustomerWhereUniqueInput;
  data: MasterCustomerUpdateWithoutSalesTeamInput;
}

export interface MasterCustomerUpdateWithWhereUniqueWithoutSetterInput {
  where: MasterCustomerWhereUniqueInput;
  data: MasterCustomerUpdateWithoutSetterInput;
}

export interface MasterCustomerUpdateWithoutContactInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  location?: LocationUpdateOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepUpdateOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutMasterCustomersInput | null;
  setter?: SetterUpdateOneWithoutMasterCustomersInput | null;
  customer?: CustomerUpdateOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyUpdateManyWithoutLinkToMasterListInput | null;
  project?: ProjectUpdateOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerUpdateWithoutCustomerInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  contact?: ContactUpdateOneWithoutMasterCustomerInput | null;
  location?: LocationUpdateOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepUpdateOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutMasterCustomersInput | null;
  setter?: SetterUpdateOneWithoutMasterCustomersInput | null;
  installReady?: InstallReadyUpdateManyWithoutLinkToMasterListInput | null;
  project?: ProjectUpdateOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerUpdateWithoutInstallReadyInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  contact?: ContactUpdateOneWithoutMasterCustomerInput | null;
  location?: LocationUpdateOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepUpdateOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutMasterCustomersInput | null;
  setter?: SetterUpdateOneWithoutMasterCustomersInput | null;
  customer?: CustomerUpdateOneWithoutMasterCustomerInput | null;
  project?: ProjectUpdateOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerUpdateWithoutLocationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  contact?: ContactUpdateOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepUpdateOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutMasterCustomersInput | null;
  setter?: SetterUpdateOneWithoutMasterCustomersInput | null;
  customer?: CustomerUpdateOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyUpdateManyWithoutLinkToMasterListInput | null;
  project?: ProjectUpdateOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  contact?: ContactUpdateOneWithoutMasterCustomerInput | null;
  location?: LocationUpdateOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepUpdateOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutMasterCustomersInput | null;
  setter?: SetterUpdateOneWithoutMasterCustomersInput | null;
  customer?: CustomerUpdateOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyUpdateManyWithoutLinkToMasterListInput | null;
}

export interface MasterCustomerUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  contact?: ContactUpdateOneWithoutMasterCustomerInput | null;
  location?: LocationUpdateOneWithoutMasterCustomerInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutMasterCustomersInput | null;
  setter?: SetterUpdateOneWithoutMasterCustomersInput | null;
  customer?: CustomerUpdateOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyUpdateManyWithoutLinkToMasterListInput | null;
  project?: ProjectUpdateOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerUpdateWithoutSalesTeamInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  contact?: ContactUpdateOneWithoutMasterCustomerInput | null;
  location?: LocationUpdateOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepUpdateOneWithoutMasterCustomersInput | null;
  setter?: SetterUpdateOneWithoutMasterCustomersInput | null;
  customer?: CustomerUpdateOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyUpdateManyWithoutLinkToMasterListInput | null;
  project?: ProjectUpdateOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerUpdateWithoutSetterInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  repId?: NullableIntFieldUpdateOperationsInput | null;
  installGreenLights?: NullableStringFieldUpdateOperationsInput | null;
  stepsRequiredForFluentApproved?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  customerAddress?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  customerZipcode?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  leadSourceType?: NullableStringFieldUpdateOperationsInput | null;
  appointmentId?: NullableIntFieldUpdateOperationsInput | null;
  vOutcome?: NullableStringFieldUpdateOperationsInput | null;
  dOutcome?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  dateLeadSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOutcomeUpdated?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAccountCreated?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  welcomeCallCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cadCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  inspectionCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nemSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ptoReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateInstallReady?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateNtpApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEngineeringComplete?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateAllPermitsApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateScheduledInstall?: NullableDateTimeFieldUpdateOperationsInput | null;
  datePtoSubmitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Submitted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateM1Received?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCanceled?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ticketScheduledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  installCancelledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentHomeNotifiedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeStampOfWhenInstallWasScheduled?: NullableDateTimeFieldUpdateOperationsInput | null;
  outcome?: NullableStringFieldUpdateOperationsInput | null;
  systemSize?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  loanProduct?: NullableStringFieldUpdateOperationsInput | null;
  loanPartnerName?: NullableStringFieldUpdateOperationsInput | null;
  stipulations?: NullableStringFieldUpdateOperationsInput | null;
  greenLights_?: MasterCustomerUpdategreenLights_Input | null;
  stepsRequiredForFluentApproved_?: MasterCustomerUpdatestepsRequiredForFluentApproved_Input | null;
  fsValorOutcome_?: MasterCustomerUpdatefsValorOutcome_Input | null;
  fsDauntlessOutcome_?: MasterCustomerUpdatefsDauntlessOutcome_Input | null;
  test_?: MasterCustomerUpdatetest_Input | null;
  installReady_?: MasterCustomerUpdateinstallReady_Input | null;
  stipulations_?: MasterCustomerUpdatestipulations_Input | null;
  hoaNeeded2_?: MasterCustomerUpdatehoaNeeded2_Input | null;
  contact?: ContactUpdateOneWithoutMasterCustomerInput | null;
  location?: LocationUpdateOneWithoutMasterCustomerInput | null;
  salesRep?: SalesRepUpdateOneWithoutMasterCustomersInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutMasterCustomersInput | null;
  customer?: CustomerUpdateOneWithoutMasterCustomerInput | null;
  installReady?: InstallReadyUpdateManyWithoutLinkToMasterListInput | null;
  project?: ProjectUpdateOneWithoutMasterCustomersInput | null;
}

export interface MasterCustomerUpdatefsDauntlessOutcome_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpdatefsValorOutcome_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpdategreenLights_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpdatehoaNeeded2_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpdateinstallReady_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpdatestepsRequiredForFluentApproved_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpdatestipulations_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpdatetest_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface MasterCustomerUpsertWithWhereUniqueWithoutContactInput {
  where: MasterCustomerWhereUniqueInput;
  update: MasterCustomerUpdateWithoutContactInput;
  create: MasterCustomerCreateWithoutContactInput;
}

export interface MasterCustomerUpsertWithWhereUniqueWithoutLocationInput {
  where: MasterCustomerWhereUniqueInput;
  update: MasterCustomerUpdateWithoutLocationInput;
  create: MasterCustomerCreateWithoutLocationInput;
}

export interface MasterCustomerUpsertWithWhereUniqueWithoutProjectInput {
  where: MasterCustomerWhereUniqueInput;
  update: MasterCustomerUpdateWithoutProjectInput;
  create: MasterCustomerCreateWithoutProjectInput;
}

export interface MasterCustomerUpsertWithWhereUniqueWithoutSalesRepInput {
  where: MasterCustomerWhereUniqueInput;
  update: MasterCustomerUpdateWithoutSalesRepInput;
  create: MasterCustomerCreateWithoutSalesRepInput;
}

export interface MasterCustomerUpsertWithWhereUniqueWithoutSalesTeamInput {
  where: MasterCustomerWhereUniqueInput;
  update: MasterCustomerUpdateWithoutSalesTeamInput;
  create: MasterCustomerCreateWithoutSalesTeamInput;
}

export interface MasterCustomerUpsertWithWhereUniqueWithoutSetterInput {
  where: MasterCustomerWhereUniqueInput;
  update: MasterCustomerUpdateWithoutSetterInput;
  create: MasterCustomerCreateWithoutSetterInput;
}

export interface MasterCustomerUpsertWithoutCustomerInput {
  update: MasterCustomerUpdateWithoutCustomerInput;
  create: MasterCustomerCreateWithoutCustomerInput;
}

export interface MasterCustomerUpsertWithoutInstallReadyInput {
  update: MasterCustomerUpdateWithoutInstallReadyInput;
  create: MasterCustomerCreateWithoutInstallReadyInput;
}

export interface MasterCustomerWhereInput {
  AND?: MasterCustomerWhereInput[] | null;
  OR?: MasterCustomerWhereInput[] | null;
  NOT?: MasterCustomerWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  fullName?: StringNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  greenLights_?: StringNullableListFilter | null;
  stepsRequiredForFluentApproved_?: StringNullableListFilter | null;
  fsValorOutcome_?: StringNullableListFilter | null;
  fsDauntlessOutcome_?: StringNullableListFilter | null;
  test_?: StringNullableListFilter | null;
  installReady_?: StringNullableListFilter | null;
  stipulations_?: StringNullableListFilter | null;
  hoaNeeded2_?: StringNullableListFilter | null;
  projectId?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  repId?: IntNullableFilter | null;
  installGreenLights?: StringNullableFilter | null;
  stepsRequiredForFluentApproved?: StringNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  customerAddress?: StringNullableFilter | null;
  customerPhone?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  customerZipcode?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  leadSourceType?: StringNullableFilter | null;
  appointmentId?: IntNullableFilter | null;
  vOutcome?: StringNullableFilter | null;
  dOutcome?: StringNullableFilter | null;
  contact?: ContactWhereInput | null;
  location?: LocationWhereInput | null;
  salesTeamId?: IntNullableFilter | null;
  setterId?: IntNullableFilter | null;
  archived?: BoolNullableFilter | null;
  dateLeadSubmitted?: DateTimeNullableFilter | null;
  dateOutcomeUpdated?: DateTimeNullableFilter | null;
  dateAccountCreated?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  fluentApprovedDate?: DateTimeNullableFilter | null;
  welcomeCallCompleteDate?: DateTimeNullableFilter | null;
  siteSurveyCompleteDate?: DateTimeNullableFilter | null;
  cadCompleteDate?: DateTimeNullableFilter | null;
  permitReceivedDate?: DateTimeNullableFilter | null;
  permitSubmittedDate?: DateTimeNullableFilter | null;
  hoaApprovedDate?: DateTimeNullableFilter | null;
  hoaSubmittedDate?: DateTimeNullableFilter | null;
  installCompleteDate?: DateTimeNullableFilter | null;
  inspectionCompleteDate?: DateTimeNullableFilter | null;
  nemApprovedDate?: DateTimeNullableFilter | null;
  nemSubmittedDate?: DateTimeNullableFilter | null;
  ptoReceivedDate?: DateTimeNullableFilter | null;
  dateInstallReady?: DateTimeNullableFilter | null;
  dateNtpSubmitted?: DateTimeNullableFilter | null;
  dateNtpApproved?: DateTimeNullableFilter | null;
  dateEngineeringComplete?: DateTimeNullableFilter | null;
  dateAllPermitsApproved?: DateTimeNullableFilter | null;
  dateScheduledInstall?: DateTimeNullableFilter | null;
  datePtoSubmitted?: DateTimeNullableFilter | null;
  dateM1Submitted?: DateTimeNullableFilter | null;
  dateM1Received?: DateTimeNullableFilter | null;
  dateCanceled?: DateTimeNullableFilter | null;
  appointmentDate?: DateTimeNullableFilter | null;
  onHoldDate?: DateTimeNullableFilter | null;
  ticketCreatedDate?: DateTimeNullableFilter | null;
  ticketScheduledDate?: DateTimeNullableFilter | null;
  installCancelledDate?: DateTimeNullableFilter | null;
  fluentHomeNotifiedDate?: DateTimeNullableFilter | null;
  timeStampOfWhenInstallWasScheduled?: DateTimeNullableFilter | null;
  outcome?: StringNullableFilter | null;
  systemSize?: FloatNullableFilter | null;
  systemPrice?: FloatNullableFilter | null;
  salesRep?: SalesRepWhereInput | null;
  salesTeam?: SalesTeamWhereInput | null;
  setter?: SetterWhereInput | null;
  customer?: CustomerWhereInput | null;
  contactId?: IntNullableFilter | null;
  locationId?: IntNullableFilter | null;
  loanProduct?: StringNullableFilter | null;
  loanPartnerName?: StringNullableFilter | null;
  stipulations?: StringNullableFilter | null;
  installReady?: InstallReadyListRelationFilter | null;
  project?: ProjectWhereInput | null;
}

export interface MasterCustomerWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  contactId?: number | null;
}

export interface MatchUpCreateInput {
  winner?: number | null;
  order?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  statKey?: string | null;
  type?: string | null;
  scoreboard?: any | null;
  active?: boolean | null;
  participants?: ParticipantCreateNestedManyWithoutMatchUpInput | null;
  round?: RoundCreateNestedOneWithoutMatchUpsInput | null;
}

export interface MatchUpCreateManyRoundInput {
  id?: number | null;
  winner?: number | null;
  order?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  statKey?: string | null;
  type?: string | null;
  scoreboard?: any | null;
  active?: boolean | null;
}

export interface MatchUpCreateManyRoundInputEnvelope {
  data?: MatchUpCreateManyRoundInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface MatchUpCreateNestedManyWithoutParticipantsInput {
  create?: MatchUpCreateWithoutParticipantsInput[] | null;
  connectOrCreate?: MatchUpCreateOrConnectWithoutParticipantsInput[] | null;
  connect?: MatchUpWhereUniqueInput[] | null;
}

export interface MatchUpCreateNestedManyWithoutRoundInput {
  create?: MatchUpCreateWithoutRoundInput[] | null;
  connectOrCreate?: MatchUpCreateOrConnectWithoutRoundInput[] | null;
  createMany?: MatchUpCreateManyRoundInputEnvelope | null;
  connect?: MatchUpWhereUniqueInput[] | null;
}

export interface MatchUpCreateOrConnectWithoutParticipantsInput {
  where: MatchUpWhereUniqueInput;
  create: MatchUpCreateWithoutParticipantsInput;
}

export interface MatchUpCreateOrConnectWithoutRoundInput {
  where: MatchUpWhereUniqueInput;
  create: MatchUpCreateWithoutRoundInput;
}

export interface MatchUpCreateWithoutParticipantsInput {
  winner?: number | null;
  order?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  statKey?: string | null;
  type?: string | null;
  scoreboard?: any | null;
  active?: boolean | null;
  round?: RoundCreateNestedOneWithoutMatchUpsInput | null;
}

export interface MatchUpCreateWithoutRoundInput {
  winner?: number | null;
  order?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  statKey?: string | null;
  type?: string | null;
  scoreboard?: any | null;
  active?: boolean | null;
  participants?: ParticipantCreateNestedManyWithoutMatchUpInput | null;
}

export interface MatchUpListRelationFilter {
  every?: MatchUpWhereInput | null;
  some?: MatchUpWhereInput | null;
  none?: MatchUpWhereInput | null;
}

export interface MatchUpScalarWhereInput {
  AND?: MatchUpScalarWhereInput[] | null;
  OR?: MatchUpScalarWhereInput[] | null;
  NOT?: MatchUpScalarWhereInput[] | null;
  id?: IntFilter | null;
  winner?: IntNullableFilter | null;
  roundId?: IntNullableFilter | null;
  order?: IntNullableFilter | null;
  startDate?: DateTimeNullableFilter | null;
  endDate?: DateTimeNullableFilter | null;
  statKey?: StringNullableFilter | null;
  type?: StringNullableFilter | null;
  scoreboard?: JsonNullableFilter | null;
  active?: BoolNullableFilter | null;
}

export interface MatchUpUpdateManyMutationInput {
  winner?: NullableIntFieldUpdateOperationsInput | null;
  order?: NullableIntFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  endDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  statKey?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  scoreboard?: any | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
}

export interface MatchUpUpdateManyWithWhereWithoutParticipantsInput {
  where: MatchUpScalarWhereInput;
  data: MatchUpUpdateManyMutationInput;
}

export interface MatchUpUpdateManyWithWhereWithoutRoundInput {
  where: MatchUpScalarWhereInput;
  data: MatchUpUpdateManyMutationInput;
}

export interface MatchUpUpdateManyWithoutParticipantsInput {
  create?: MatchUpCreateWithoutParticipantsInput[] | null;
  connectOrCreate?: MatchUpCreateOrConnectWithoutParticipantsInput[] | null;
  upsert?: MatchUpUpsertWithWhereUniqueWithoutParticipantsInput[] | null;
  connect?: MatchUpWhereUniqueInput[] | null;
  set?: MatchUpWhereUniqueInput[] | null;
  disconnect?: MatchUpWhereUniqueInput[] | null;
  delete?: MatchUpWhereUniqueInput[] | null;
  update?: MatchUpUpdateWithWhereUniqueWithoutParticipantsInput[] | null;
  updateMany?: MatchUpUpdateManyWithWhereWithoutParticipantsInput[] | null;
  deleteMany?: MatchUpScalarWhereInput[] | null;
}

export interface MatchUpUpdateManyWithoutRoundInput {
  create?: MatchUpCreateWithoutRoundInput[] | null;
  connectOrCreate?: MatchUpCreateOrConnectWithoutRoundInput[] | null;
  upsert?: MatchUpUpsertWithWhereUniqueWithoutRoundInput[] | null;
  createMany?: MatchUpCreateManyRoundInputEnvelope | null;
  connect?: MatchUpWhereUniqueInput[] | null;
  set?: MatchUpWhereUniqueInput[] | null;
  disconnect?: MatchUpWhereUniqueInput[] | null;
  delete?: MatchUpWhereUniqueInput[] | null;
  update?: MatchUpUpdateWithWhereUniqueWithoutRoundInput[] | null;
  updateMany?: MatchUpUpdateManyWithWhereWithoutRoundInput[] | null;
  deleteMany?: MatchUpScalarWhereInput[] | null;
}

export interface MatchUpUpdateWithWhereUniqueWithoutParticipantsInput {
  where: MatchUpWhereUniqueInput;
  data: MatchUpUpdateWithoutParticipantsInput;
}

export interface MatchUpUpdateWithWhereUniqueWithoutRoundInput {
  where: MatchUpWhereUniqueInput;
  data: MatchUpUpdateWithoutRoundInput;
}

export interface MatchUpUpdateWithoutParticipantsInput {
  winner?: NullableIntFieldUpdateOperationsInput | null;
  order?: NullableIntFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  endDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  statKey?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  scoreboard?: any | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  round?: RoundUpdateOneWithoutMatchUpsInput | null;
}

export interface MatchUpUpdateWithoutRoundInput {
  winner?: NullableIntFieldUpdateOperationsInput | null;
  order?: NullableIntFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  endDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  statKey?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  scoreboard?: any | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  participants?: ParticipantUpdateManyWithoutMatchUpInput | null;
}

export interface MatchUpUpsertWithWhereUniqueWithoutParticipantsInput {
  where: MatchUpWhereUniqueInput;
  update: MatchUpUpdateWithoutParticipantsInput;
  create: MatchUpCreateWithoutParticipantsInput;
}

export interface MatchUpUpsertWithWhereUniqueWithoutRoundInput {
  where: MatchUpWhereUniqueInput;
  update: MatchUpUpdateWithoutRoundInput;
  create: MatchUpCreateWithoutRoundInput;
}

export interface MatchUpWhereInput {
  AND?: MatchUpWhereInput[] | null;
  OR?: MatchUpWhereInput[] | null;
  NOT?: MatchUpWhereInput[] | null;
  id?: IntFilter | null;
  participants?: ParticipantListRelationFilter | null;
  winner?: IntNullableFilter | null;
  round?: RoundWhereInput | null;
  roundId?: IntNullableFilter | null;
  order?: IntNullableFilter | null;
  startDate?: DateTimeNullableFilter | null;
  endDate?: DateTimeNullableFilter | null;
  statKey?: StringNullableFilter | null;
  type?: StringNullableFilter | null;
  scoreboard?: JsonNullableFilter | null;
  active?: BoolNullableFilter | null;
}

export interface MatchUpWhereUniqueInput {
  id?: number | null;
}

export interface NestedBoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface NestedDateTimeFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeFilter | null;
}

export interface NestedDateTimeNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
}

export interface NestedDecimalNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalNullableFilter | null;
}

export interface NestedEnumASSIGNMENT_TYPEFilter {
  equals?: ASSIGNMENT_TYPE | null;
  in?: ASSIGNMENT_TYPE[] | null;
  notIn?: ASSIGNMENT_TYPE[] | null;
  not?: NestedEnumASSIGNMENT_TYPEFilter | null;
}

export interface NestedEnumEliminationTypeFilter {
  equals?: EliminationType | null;
  in?: EliminationType[] | null;
  notIn?: EliminationType[] | null;
  not?: NestedEnumEliminationTypeFilter | null;
}

export interface NestedEnumFieldKeyRelationTypeNullableFilter {
  equals?: FieldKeyRelationType | null;
  in?: FieldKeyRelationType[] | null;
  notIn?: FieldKeyRelationType[] | null;
  not?: NestedEnumFieldKeyRelationTypeNullableFilter | null;
}

export interface NestedEnumGENERATED_TYPEFilter {
  equals?: GENERATED_TYPE | null;
  in?: GENERATED_TYPE[] | null;
  notIn?: GENERATED_TYPE[] | null;
  not?: NestedEnumGENERATED_TYPEFilter | null;
}

export interface NestedEnumSTATUS_TYPEFilter {
  equals?: STATUS_TYPE | null;
  in?: STATUS_TYPE[] | null;
  notIn?: STATUS_TYPE[] | null;
  not?: NestedEnumSTATUS_TYPEFilter | null;
}

export interface NestedEnumTimeSheetStatusNullableFilter {
  equals?: TimeSheetStatus | null;
  in?: TimeSheetStatus[] | null;
  notIn?: TimeSheetStatus[] | null;
  not?: NestedEnumTimeSheetStatusNullableFilter | null;
}

export interface NestedEnumteam_typeFilter {
  equals?: team_type | null;
  in?: team_type[] | null;
  notIn?: team_type[] | null;
  not?: NestedEnumteam_typeFilter | null;
}

export interface NestedEnumteam_typeNullableFilter {
  equals?: team_type | null;
  in?: team_type[] | null;
  notIn?: team_type[] | null;
  not?: NestedEnumteam_typeNullableFilter | null;
}

export interface NestedEnumuser_roleNullableFilter {
  equals?: user_role | null;
  in?: user_role[] | null;
  notIn?: user_role[] | null;
  not?: NestedEnumuser_roleNullableFilter | null;
}

export interface NestedFloatFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatFilter | null;
}

export interface NestedFloatNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatNullableFilter | null;
}

export interface NestedIntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface NestedIntNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntNullableFilter | null;
}

export interface NestedStringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringFilter | null;
}

export interface NestedStringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringNullableFilter | null;
}

export interface NotificationCreateManyUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  body?: string | null;
  subtitle?: string | null;
  data?: any | null;
  status?: string | null;
  statusMessage?: string | null;
  expoTokens?: NotificationCreateManyexpoTokensInput | null;
}

export interface NotificationCreateManyUserInputEnvelope {
  data?: NotificationCreateManyUserInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface NotificationCreateManyexpoTokensInput {
  set?: string[] | null;
}

export interface NotificationCreateNestedManyWithoutUserInput {
  create?: NotificationCreateWithoutUserInput[] | null;
  connectOrCreate?: NotificationCreateOrConnectWithoutUserInput[] | null;
  createMany?: NotificationCreateManyUserInputEnvelope | null;
  connect?: NotificationWhereUniqueInput[] | null;
}

export interface NotificationCreateOrConnectWithoutUserInput {
  where: NotificationWhereUniqueInput;
  create: NotificationCreateWithoutUserInput;
}

export interface NotificationCreateWithoutUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  body?: string | null;
  subtitle?: string | null;
  data?: any | null;
  status?: string | null;
  statusMessage?: string | null;
  expoTokens?: NotificationCreateexpoTokensInput | null;
}

export interface NotificationCreateexpoTokensInput {
  set?: string[] | null;
}

export interface NotificationListRelationFilter {
  every?: NotificationWhereInput | null;
  some?: NotificationWhereInput | null;
  none?: NotificationWhereInput | null;
}

export interface NotificationScalarWhereInput {
  AND?: NotificationScalarWhereInput[] | null;
  OR?: NotificationScalarWhereInput[] | null;
  NOT?: NotificationScalarWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  title?: StringNullableFilter | null;
  body?: StringNullableFilter | null;
  subtitle?: StringNullableFilter | null;
  expoTokens?: StringNullableListFilter | null;
  userId?: IntNullableFilter | null;
  data?: JsonNullableFilter | null;
  status?: StringNullableFilter | null;
  statusMessage?: StringNullableFilter | null;
}

export interface NotificationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  body?: NullableStringFieldUpdateOperationsInput | null;
  subtitle?: NullableStringFieldUpdateOperationsInput | null;
  data?: any | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  statusMessage?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: NotificationUpdateexpoTokensInput | null;
}

export interface NotificationUpdateManyWithWhereWithoutUserInput {
  where: NotificationScalarWhereInput;
  data: NotificationUpdateManyMutationInput;
}

export interface NotificationUpdateManyWithoutUserInput {
  create?: NotificationCreateWithoutUserInput[] | null;
  connectOrCreate?: NotificationCreateOrConnectWithoutUserInput[] | null;
  upsert?: NotificationUpsertWithWhereUniqueWithoutUserInput[] | null;
  createMany?: NotificationCreateManyUserInputEnvelope | null;
  connect?: NotificationWhereUniqueInput[] | null;
  set?: NotificationWhereUniqueInput[] | null;
  disconnect?: NotificationWhereUniqueInput[] | null;
  delete?: NotificationWhereUniqueInput[] | null;
  update?: NotificationUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: NotificationUpdateManyWithWhereWithoutUserInput[] | null;
  deleteMany?: NotificationScalarWhereInput[] | null;
}

export interface NotificationUpdateWithWhereUniqueWithoutUserInput {
  where: NotificationWhereUniqueInput;
  data: NotificationUpdateWithoutUserInput;
}

export interface NotificationUpdateWithoutUserInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  body?: NullableStringFieldUpdateOperationsInput | null;
  subtitle?: NullableStringFieldUpdateOperationsInput | null;
  data?: any | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  statusMessage?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: NotificationUpdateexpoTokensInput | null;
}

export interface NotificationUpdateexpoTokensInput {
  set?: string[] | null;
  push?: string | null;
}

export interface NotificationUpsertWithWhereUniqueWithoutUserInput {
  where: NotificationWhereUniqueInput;
  update: NotificationUpdateWithoutUserInput;
  create: NotificationCreateWithoutUserInput;
}

export interface NotificationWhereInput {
  AND?: NotificationWhereInput[] | null;
  OR?: NotificationWhereInput[] | null;
  NOT?: NotificationWhereInput[] | null;
  id?: StringFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  title?: StringNullableFilter | null;
  body?: StringNullableFilter | null;
  subtitle?: StringNullableFilter | null;
  expoTokens?: StringNullableListFilter | null;
  user?: UserWhereInput | null;
  userId?: IntNullableFilter | null;
  data?: JsonNullableFilter | null;
  status?: StringNullableFilter | null;
  statusMessage?: StringNullableFilter | null;
}

export interface NotificationWhereUniqueInput {
  id?: string | null;
}

export interface NullableBoolFieldUpdateOperationsInput {
  set?: boolean | null;
}

export interface NullableDateTimeFieldUpdateOperationsInput {
  set?: any | null;
}

export interface NullableDecimalFieldUpdateOperationsInput {
  set?: any | null;
  increment?: any | null;
  decrement?: any | null;
  multiply?: any | null;
  divide?: any | null;
}

export interface NullableEnumFieldKeyRelationTypeFieldUpdateOperationsInput {
  set?: FieldKeyRelationType | null;
}

export interface NullableEnumLeadHookStatusFieldUpdateOperationsInput {
  set?: LeadHookStatus | null;
}

export interface NullableEnumTimeSheetStatusFieldUpdateOperationsInput {
  set?: TimeSheetStatus | null;
}

export interface NullableEnumteam_typeFieldUpdateOperationsInput {
  set?: team_type | null;
}

export interface NullableEnumuser_roleFieldUpdateOperationsInput {
  set?: user_role | null;
}

export interface NullableFloatFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface NullableIntFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface NullableStringFieldUpdateOperationsInput {
  set?: string | null;
}

export interface OfficeCreateManyOrganizationInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  salesTeamId?: number | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateManyonboard_Input | null;
  closerPriority?: OfficeCreateManycloserPriorityInput | null;
}

export interface OfficeCreateManyOrganizationInputEnvelope {
  data?: OfficeCreateManyOrganizationInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface OfficeCreateManySalesTeamInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  officeId?: number | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateManyonboard_Input | null;
  closerPriority?: OfficeCreateManycloserPriorityInput | null;
}

export interface OfficeCreateManySalesTeamInputEnvelope {
  data?: OfficeCreateManySalesTeamInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface OfficeCreateManycloserPriorityInput {
  set?: number[] | null;
}

export interface OfficeCreateManyonboard_Input {
  set?: string[] | null;
}

export interface OfficeCreateNestedManyWithoutManagersInput {
  create?: OfficeCreateWithoutManagersInput[] | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutManagersInput[] | null;
  connect?: OfficeWhereUniqueInput[] | null;
}

export interface OfficeCreateNestedManyWithoutOrganizationInput {
  create?: OfficeCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: OfficeCreateManyOrganizationInputEnvelope | null;
  connect?: OfficeWhereUniqueInput[] | null;
}

export interface OfficeCreateNestedManyWithoutSalesTeamInput {
  create?: OfficeCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutSalesTeamInput[] | null;
  createMany?: OfficeCreateManySalesTeamInputEnvelope | null;
  connect?: OfficeWhereUniqueInput[] | null;
}

export interface OfficeCreateNestedOneWithoutClosersInput {
  create?: OfficeCreateWithoutClosersInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutClosersInput | null;
  connect?: OfficeWhereUniqueInput | null;
}

export interface OfficeCreateNestedOneWithoutSalesEmployeesInput {
  create?: OfficeCreateWithoutSalesEmployeesInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutSalesEmployeesInput | null;
  connect?: OfficeWhereUniqueInput | null;
}

export interface OfficeCreateNestedOneWithoutSettersInput {
  create?: OfficeCreateWithoutSettersInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutSettersInput | null;
  connect?: OfficeWhereUniqueInput | null;
}

export interface OfficeCreateNestedOneWithoutUnassignedLeadsInput {
  create?: OfficeCreateWithoutUnassignedLeadsInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutUnassignedLeadsInput | null;
  connect?: OfficeWhereUniqueInput | null;
}

export interface OfficeCreateOrConnectWithoutClosersInput {
  where: OfficeWhereUniqueInput;
  create: OfficeCreateWithoutClosersInput;
}

export interface OfficeCreateOrConnectWithoutManagersInput {
  where: OfficeWhereUniqueInput;
  create: OfficeCreateWithoutManagersInput;
}

export interface OfficeCreateOrConnectWithoutOrganizationInput {
  where: OfficeWhereUniqueInput;
  create: OfficeCreateWithoutOrganizationInput;
}

export interface OfficeCreateOrConnectWithoutSalesEmployeesInput {
  where: OfficeWhereUniqueInput;
  create: OfficeCreateWithoutSalesEmployeesInput;
}

export interface OfficeCreateOrConnectWithoutSalesTeamInput {
  where: OfficeWhereUniqueInput;
  create: OfficeCreateWithoutSalesTeamInput;
}

export interface OfficeCreateOrConnectWithoutSettersInput {
  where: OfficeWhereUniqueInput;
  create: OfficeCreateWithoutSettersInput;
}

export interface OfficeCreateOrConnectWithoutUnassignedLeadsInput {
  where: OfficeWhereUniqueInput;
  create: OfficeCreateWithoutUnassignedLeadsInput;
}

export interface OfficeCreateWithoutClosersInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateonboard_Input | null;
  closerPriority?: OfficeCreatecloserPriorityInput | null;
  organization?: OrganizationCreateNestedOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadCreateNestedManyWithoutOfficeInput | null;
  setters?: SetterCreateNestedManyWithoutOfficeInput | null;
  managers?: SalesRepCreateNestedManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeCreateNestedManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeCreateWithoutManagersInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateonboard_Input | null;
  closerPriority?: OfficeCreatecloserPriorityInput | null;
  organization?: OrganizationCreateNestedOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadCreateNestedManyWithoutOfficeInput | null;
  closers?: SalesRepCreateNestedManyWithoutOfficeInput | null;
  setters?: SetterCreateNestedManyWithoutOfficeInput | null;
  SalesEmployees?: SalesEmployeeCreateNestedManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeCreateWithoutOrganizationInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateonboard_Input | null;
  closerPriority?: OfficeCreatecloserPriorityInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadCreateNestedManyWithoutOfficeInput | null;
  closers?: SalesRepCreateNestedManyWithoutOfficeInput | null;
  setters?: SetterCreateNestedManyWithoutOfficeInput | null;
  managers?: SalesRepCreateNestedManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeCreateNestedManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeCreateWithoutSalesEmployeesInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateonboard_Input | null;
  closerPriority?: OfficeCreatecloserPriorityInput | null;
  organization?: OrganizationCreateNestedOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadCreateNestedManyWithoutOfficeInput | null;
  closers?: SalesRepCreateNestedManyWithoutOfficeInput | null;
  setters?: SetterCreateNestedManyWithoutOfficeInput | null;
  managers?: SalesRepCreateNestedManyWithoutOfficeManagerOfInput | null;
  Managers?: SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeCreateWithoutSalesTeamInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateonboard_Input | null;
  closerPriority?: OfficeCreatecloserPriorityInput | null;
  organization?: OrganizationCreateNestedOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadCreateNestedManyWithoutOfficeInput | null;
  closers?: SalesRepCreateNestedManyWithoutOfficeInput | null;
  setters?: SetterCreateNestedManyWithoutOfficeInput | null;
  managers?: SalesRepCreateNestedManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeCreateNestedManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeCreateWithoutSettersInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateonboard_Input | null;
  closerPriority?: OfficeCreatecloserPriorityInput | null;
  organization?: OrganizationCreateNestedOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadCreateNestedManyWithoutOfficeInput | null;
  closers?: SalesRepCreateNestedManyWithoutOfficeInput | null;
  managers?: SalesRepCreateNestedManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeCreateNestedManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeCreateWithoutUnassignedLeadsInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  team?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  onboard?: string | null;
  assignmentType?: ASSIGNMENT_TYPE | null;
  onboard_?: OfficeCreateonboard_Input | null;
  closerPriority?: OfficeCreatecloserPriorityInput | null;
  organization?: OrganizationCreateNestedOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutOfficesInput | null;
  closers?: SalesRepCreateNestedManyWithoutOfficeInput | null;
  setters?: SetterCreateNestedManyWithoutOfficeInput | null;
  managers?: SalesRepCreateNestedManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeCreateNestedManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeCreatecloserPriorityInput {
  set?: number[] | null;
}

export interface OfficeCreateonboard_Input {
  set?: string[] | null;
}

export interface OfficeListRelationFilter {
  every?: OfficeWhereInput | null;
  some?: OfficeWhereInput | null;
  none?: OfficeWhereInput | null;
}

export interface OfficeScalarWhereInput {
  AND?: OfficeScalarWhereInput[] | null;
  OR?: OfficeScalarWhereInput[] | null;
  NOT?: OfficeScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  team?: StringNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  salesTeamId?: IntNullableFilter | null;
  name?: StringNullableFilter | null;
  officeId?: IntNullableFilter | null;
  onboard?: StringNullableFilter | null;
  onboard_?: StringNullableListFilter | null;
  closerPriority?: IntNullableListFilter | null;
  assignmentType?: EnumASSIGNMENT_TYPEFilter | null;
}

export interface OfficeUpdateInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  organization?: OrganizationUpdateOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadUpdateManyWithoutOfficeInput | null;
  closers?: SalesRepUpdateManyWithoutOfficeInput | null;
  setters?: SetterUpdateManyWithoutOfficeInput | null;
  managers?: SalesRepUpdateManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeUpdateManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
}

export interface OfficeUpdateManyWithWhereWithoutManagersInput {
  where: OfficeScalarWhereInput;
  data: OfficeUpdateManyMutationInput;
}

export interface OfficeUpdateManyWithWhereWithoutOrganizationInput {
  where: OfficeScalarWhereInput;
  data: OfficeUpdateManyMutationInput;
}

export interface OfficeUpdateManyWithWhereWithoutSalesTeamInput {
  where: OfficeScalarWhereInput;
  data: OfficeUpdateManyMutationInput;
}

export interface OfficeUpdateManyWithoutManagersInput {
  create?: OfficeCreateWithoutManagersInput[] | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutManagersInput[] | null;
  upsert?: OfficeUpsertWithWhereUniqueWithoutManagersInput[] | null;
  connect?: OfficeWhereUniqueInput[] | null;
  set?: OfficeWhereUniqueInput[] | null;
  disconnect?: OfficeWhereUniqueInput[] | null;
  delete?: OfficeWhereUniqueInput[] | null;
  update?: OfficeUpdateWithWhereUniqueWithoutManagersInput[] | null;
  updateMany?: OfficeUpdateManyWithWhereWithoutManagersInput[] | null;
  deleteMany?: OfficeScalarWhereInput[] | null;
}

export interface OfficeUpdateManyWithoutOrganizationInput {
  create?: OfficeCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: OfficeUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: OfficeCreateManyOrganizationInputEnvelope | null;
  connect?: OfficeWhereUniqueInput[] | null;
  set?: OfficeWhereUniqueInput[] | null;
  disconnect?: OfficeWhereUniqueInput[] | null;
  delete?: OfficeWhereUniqueInput[] | null;
  update?: OfficeUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: OfficeUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: OfficeScalarWhereInput[] | null;
}

export interface OfficeUpdateManyWithoutSalesTeamInput {
  create?: OfficeCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutSalesTeamInput[] | null;
  upsert?: OfficeUpsertWithWhereUniqueWithoutSalesTeamInput[] | null;
  createMany?: OfficeCreateManySalesTeamInputEnvelope | null;
  connect?: OfficeWhereUniqueInput[] | null;
  set?: OfficeWhereUniqueInput[] | null;
  disconnect?: OfficeWhereUniqueInput[] | null;
  delete?: OfficeWhereUniqueInput[] | null;
  update?: OfficeUpdateWithWhereUniqueWithoutSalesTeamInput[] | null;
  updateMany?: OfficeUpdateManyWithWhereWithoutSalesTeamInput[] | null;
  deleteMany?: OfficeScalarWhereInput[] | null;
}

export interface OfficeUpdateOneWithoutClosersInput {
  create?: OfficeCreateWithoutClosersInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutClosersInput | null;
  upsert?: OfficeUpsertWithoutClosersInput | null;
  connect?: OfficeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: OfficeUpdateWithoutClosersInput | null;
}

export interface OfficeUpdateOneWithoutSalesEmployeesInput {
  create?: OfficeCreateWithoutSalesEmployeesInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutSalesEmployeesInput | null;
  upsert?: OfficeUpsertWithoutSalesEmployeesInput | null;
  connect?: OfficeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: OfficeUpdateWithoutSalesEmployeesInput | null;
}

export interface OfficeUpdateOneWithoutSettersInput {
  create?: OfficeCreateWithoutSettersInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutSettersInput | null;
  upsert?: OfficeUpsertWithoutSettersInput | null;
  connect?: OfficeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: OfficeUpdateWithoutSettersInput | null;
}

export interface OfficeUpdateOneWithoutUnassignedLeadsInput {
  create?: OfficeCreateWithoutUnassignedLeadsInput | null;
  connectOrCreate?: OfficeCreateOrConnectWithoutUnassignedLeadsInput | null;
  upsert?: OfficeUpsertWithoutUnassignedLeadsInput | null;
  connect?: OfficeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: OfficeUpdateWithoutUnassignedLeadsInput | null;
}

export interface OfficeUpdateWithWhereUniqueWithoutManagersInput {
  where: OfficeWhereUniqueInput;
  data: OfficeUpdateWithoutManagersInput;
}

export interface OfficeUpdateWithWhereUniqueWithoutOrganizationInput {
  where: OfficeWhereUniqueInput;
  data: OfficeUpdateWithoutOrganizationInput;
}

export interface OfficeUpdateWithWhereUniqueWithoutSalesTeamInput {
  where: OfficeWhereUniqueInput;
  data: OfficeUpdateWithoutSalesTeamInput;
}

export interface OfficeUpdateWithoutClosersInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  organization?: OrganizationUpdateOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadUpdateManyWithoutOfficeInput | null;
  setters?: SetterUpdateManyWithoutOfficeInput | null;
  managers?: SalesRepUpdateManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeUpdateManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdateWithoutManagersInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  organization?: OrganizationUpdateOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadUpdateManyWithoutOfficeInput | null;
  closers?: SalesRepUpdateManyWithoutOfficeInput | null;
  setters?: SetterUpdateManyWithoutOfficeInput | null;
  SalesEmployees?: SalesEmployeeUpdateManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdateWithoutOrganizationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadUpdateManyWithoutOfficeInput | null;
  closers?: SalesRepUpdateManyWithoutOfficeInput | null;
  setters?: SetterUpdateManyWithoutOfficeInput | null;
  managers?: SalesRepUpdateManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeUpdateManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdateWithoutSalesEmployeesInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  organization?: OrganizationUpdateOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadUpdateManyWithoutOfficeInput | null;
  closers?: SalesRepUpdateManyWithoutOfficeInput | null;
  setters?: SetterUpdateManyWithoutOfficeInput | null;
  managers?: SalesRepUpdateManyWithoutOfficeManagerOfInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdateWithoutSalesTeamInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  organization?: OrganizationUpdateOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadUpdateManyWithoutOfficeInput | null;
  closers?: SalesRepUpdateManyWithoutOfficeInput | null;
  setters?: SetterUpdateManyWithoutOfficeInput | null;
  managers?: SalesRepUpdateManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeUpdateManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdateWithoutSettersInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  organization?: OrganizationUpdateOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutOfficesInput | null;
  unassignedLeads?: GeneratedLeadUpdateManyWithoutOfficeInput | null;
  closers?: SalesRepUpdateManyWithoutOfficeInput | null;
  managers?: SalesRepUpdateManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeUpdateManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdateWithoutUnassignedLeadsInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  team?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  onboard?: NullableStringFieldUpdateOperationsInput | null;
  assignmentType?: EnumASSIGNMENT_TYPEFieldUpdateOperationsInput | null;
  onboard_?: OfficeUpdateonboard_Input | null;
  closerPriority?: OfficeUpdatecloserPriorityInput | null;
  organization?: OrganizationUpdateOneWithoutOfficesInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutOfficesInput | null;
  closers?: SalesRepUpdateManyWithoutOfficeInput | null;
  setters?: SetterUpdateManyWithoutOfficeInput | null;
  managers?: SalesRepUpdateManyWithoutOfficeManagerOfInput | null;
  SalesEmployees?: SalesEmployeeUpdateManyWithoutOfficeAsRepInput | null;
  Managers?: SalesEmployeeUpdateManyWithoutOfficesAsManagerInput | null;
}

export interface OfficeUpdatecloserPriorityInput {
  set?: number[] | null;
  push?: number | null;
}

export interface OfficeUpdateonboard_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface OfficeUpsertWithWhereUniqueWithoutManagersInput {
  where: OfficeWhereUniqueInput;
  update: OfficeUpdateWithoutManagersInput;
  create: OfficeCreateWithoutManagersInput;
}

export interface OfficeUpsertWithWhereUniqueWithoutOrganizationInput {
  where: OfficeWhereUniqueInput;
  update: OfficeUpdateWithoutOrganizationInput;
  create: OfficeCreateWithoutOrganizationInput;
}

export interface OfficeUpsertWithWhereUniqueWithoutSalesTeamInput {
  where: OfficeWhereUniqueInput;
  update: OfficeUpdateWithoutSalesTeamInput;
  create: OfficeCreateWithoutSalesTeamInput;
}

export interface OfficeUpsertWithoutClosersInput {
  update: OfficeUpdateWithoutClosersInput;
  create: OfficeCreateWithoutClosersInput;
}

export interface OfficeUpsertWithoutSalesEmployeesInput {
  update: OfficeUpdateWithoutSalesEmployeesInput;
  create: OfficeCreateWithoutSalesEmployeesInput;
}

export interface OfficeUpsertWithoutSettersInput {
  update: OfficeUpdateWithoutSettersInput;
  create: OfficeCreateWithoutSettersInput;
}

export interface OfficeUpsertWithoutUnassignedLeadsInput {
  update: OfficeUpdateWithoutUnassignedLeadsInput;
  create: OfficeCreateWithoutUnassignedLeadsInput;
}

export interface OfficeWhereInput {
  AND?: OfficeWhereInput[] | null;
  OR?: OfficeWhereInput[] | null;
  NOT?: OfficeWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  team?: StringNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  salesTeamId?: IntNullableFilter | null;
  name?: StringNullableFilter | null;
  officeId?: IntNullableFilter | null;
  onboard?: StringNullableFilter | null;
  onboard_?: StringNullableListFilter | null;
  closerPriority?: IntNullableListFilter | null;
  assignmentType?: EnumASSIGNMENT_TYPEFilter | null;
  organization?: OrganizationWhereInput | null;
  salesTeam?: SalesTeamWhereInput | null;
  unassignedLeads?: GeneratedLeadListRelationFilter | null;
  closers?: SalesRepListRelationFilter | null;
  setters?: SetterListRelationFilter | null;
  managers?: SalesRepListRelationFilter | null;
  SalesEmployees?: SalesEmployeeListRelationFilter | null;
  Managers?: SalesEmployeeListRelationFilter | null;
}

export interface OfficeWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
}

export interface OrganizationCreateManyParentInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
}

export interface OrganizationCreateManyParentInputEnvelope {
  data?: OrganizationCreateManyParentInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface OrganizationCreateNestedManyWithoutParentInput {
  create?: OrganizationCreateWithoutParentInput[] | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutParentInput[] | null;
  createMany?: OrganizationCreateManyParentInputEnvelope | null;
  connect?: OrganizationWhereUniqueInput[] | null;
}

export interface OrganizationCreateNestedOneWithoutChildrenInput {
  create?: OrganizationCreateWithoutChildrenInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutChildrenInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutManagersInput {
  create?: OrganizationCreateWithoutManagersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutManagersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutOfficesInput {
  create?: OrganizationCreateWithoutOfficesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutOfficesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOrConnectWithoutChildrenInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutChildrenInput;
}

export interface OrganizationCreateOrConnectWithoutManagersInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutManagersInput;
}

export interface OrganizationCreateOrConnectWithoutOfficesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutOfficesInput;
}

export interface OrganizationCreateOrConnectWithoutParentInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutParentInput;
}

export interface OrganizationCreateWithoutChildrenInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  parent?: OrganizationCreateNestedOneWithoutChildrenInput | null;
  offices?: OfficeCreateNestedManyWithoutOrganizationInput | null;
  managers?: SalesRepCreateNestedManyWithoutOrganizationManagerOfInput | null;
}

export interface OrganizationCreateWithoutManagersInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  parent?: OrganizationCreateNestedOneWithoutChildrenInput | null;
  offices?: OfficeCreateNestedManyWithoutOrganizationInput | null;
  children?: OrganizationCreateNestedManyWithoutParentInput | null;
}

export interface OrganizationCreateWithoutOfficesInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  parent?: OrganizationCreateNestedOneWithoutChildrenInput | null;
  children?: OrganizationCreateNestedManyWithoutParentInput | null;
  managers?: SalesRepCreateNestedManyWithoutOrganizationManagerOfInput | null;
}

export interface OrganizationCreateWithoutParentInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  offices?: OfficeCreateNestedManyWithoutOrganizationInput | null;
  children?: OrganizationCreateNestedManyWithoutParentInput | null;
  managers?: SalesRepCreateNestedManyWithoutOrganizationManagerOfInput | null;
}

export interface OrganizationListRelationFilter {
  every?: OrganizationWhereInput | null;
  some?: OrganizationWhereInput | null;
  none?: OrganizationWhereInput | null;
}

export interface OrganizationScalarWhereInput {
  AND?: OrganizationScalarWhereInput[] | null;
  OR?: OrganizationScalarWhereInput[] | null;
  NOT?: OrganizationScalarWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  parentId?: IntNullableFilter | null;
  name?: StringNullableFilter | null;
}

export interface OrganizationUpdateManyMutationInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
}

export interface OrganizationUpdateManyWithWhereWithoutParentInput {
  where: OrganizationScalarWhereInput;
  data: OrganizationUpdateManyMutationInput;
}

export interface OrganizationUpdateManyWithoutParentInput {
  create?: OrganizationCreateWithoutParentInput[] | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutParentInput[] | null;
  upsert?: OrganizationUpsertWithWhereUniqueWithoutParentInput[] | null;
  createMany?: OrganizationCreateManyParentInputEnvelope | null;
  connect?: OrganizationWhereUniqueInput[] | null;
  set?: OrganizationWhereUniqueInput[] | null;
  disconnect?: OrganizationWhereUniqueInput[] | null;
  delete?: OrganizationWhereUniqueInput[] | null;
  update?: OrganizationUpdateWithWhereUniqueWithoutParentInput[] | null;
  updateMany?: OrganizationUpdateManyWithWhereWithoutParentInput[] | null;
  deleteMany?: OrganizationScalarWhereInput[] | null;
}

export interface OrganizationUpdateOneWithoutChildrenInput {
  create?: OrganizationCreateWithoutChildrenInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutChildrenInput | null;
  upsert?: OrganizationUpsertWithoutChildrenInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: OrganizationUpdateWithoutChildrenInput | null;
}

export interface OrganizationUpdateOneWithoutManagersInput {
  create?: OrganizationCreateWithoutManagersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutManagersInput | null;
  upsert?: OrganizationUpsertWithoutManagersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: OrganizationUpdateWithoutManagersInput | null;
}

export interface OrganizationUpdateOneWithoutOfficesInput {
  create?: OrganizationCreateWithoutOfficesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutOfficesInput | null;
  upsert?: OrganizationUpsertWithoutOfficesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: OrganizationUpdateWithoutOfficesInput | null;
}

export interface OrganizationUpdateWithWhereUniqueWithoutParentInput {
  where: OrganizationWhereUniqueInput;
  data: OrganizationUpdateWithoutParentInput;
}

export interface OrganizationUpdateWithoutChildrenInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  parent?: OrganizationUpdateOneWithoutChildrenInput | null;
  offices?: OfficeUpdateManyWithoutOrganizationInput | null;
  managers?: SalesRepUpdateManyWithoutOrganizationManagerOfInput | null;
}

export interface OrganizationUpdateWithoutManagersInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  parent?: OrganizationUpdateOneWithoutChildrenInput | null;
  offices?: OfficeUpdateManyWithoutOrganizationInput | null;
  children?: OrganizationUpdateManyWithoutParentInput | null;
}

export interface OrganizationUpdateWithoutOfficesInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  parent?: OrganizationUpdateOneWithoutChildrenInput | null;
  children?: OrganizationUpdateManyWithoutParentInput | null;
  managers?: SalesRepUpdateManyWithoutOrganizationManagerOfInput | null;
}

export interface OrganizationUpdateWithoutParentInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  offices?: OfficeUpdateManyWithoutOrganizationInput | null;
  children?: OrganizationUpdateManyWithoutParentInput | null;
  managers?: SalesRepUpdateManyWithoutOrganizationManagerOfInput | null;
}

export interface OrganizationUpsertWithWhereUniqueWithoutParentInput {
  where: OrganizationWhereUniqueInput;
  update: OrganizationUpdateWithoutParentInput;
  create: OrganizationCreateWithoutParentInput;
}

export interface OrganizationUpsertWithoutChildrenInput {
  update: OrganizationUpdateWithoutChildrenInput;
  create: OrganizationCreateWithoutChildrenInput;
}

export interface OrganizationUpsertWithoutManagersInput {
  update: OrganizationUpdateWithoutManagersInput;
  create: OrganizationCreateWithoutManagersInput;
}

export interface OrganizationUpsertWithoutOfficesInput {
  update: OrganizationUpdateWithoutOfficesInput;
  create: OrganizationCreateWithoutOfficesInput;
}

export interface OrganizationWhereInput {
  AND?: OrganizationWhereInput[] | null;
  OR?: OrganizationWhereInput[] | null;
  NOT?: OrganizationWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  parentId?: IntNullableFilter | null;
  name?: StringNullableFilter | null;
  parent?: OrganizationWhereInput | null;
  offices?: OfficeListRelationFilter | null;
  children?: OrganizationListRelationFilter | null;
  managers?: SalesRepListRelationFilter | null;
}

export interface OrganizationWhereUniqueInput {
  id?: number | null;
}

export interface ParticipantCreateManyTournamentInput {
  id?: number | null;
  title?: string | null;
  matchUpId?: number | null;
  active?: boolean | null;
  seed?: number | null;
}

export interface ParticipantCreateManyTournamentInputEnvelope {
  data?: ParticipantCreateManyTournamentInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface ParticipantCreateNestedManyWithoutCaptainsInput {
  create?: ParticipantCreateWithoutCaptainsInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutCaptainsInput[] | null;
  connect?: ParticipantWhereUniqueInput[] | null;
}

export interface ParticipantCreateNestedManyWithoutMatchUpInput {
  create?: ParticipantCreateWithoutMatchUpInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutMatchUpInput[] | null;
  connect?: ParticipantWhereUniqueInput[] | null;
}

export interface ParticipantCreateNestedManyWithoutTournamentInput {
  create?: ParticipantCreateWithoutTournamentInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutTournamentInput[] | null;
  createMany?: ParticipantCreateManyTournamentInputEnvelope | null;
  connect?: ParticipantWhereUniqueInput[] | null;
}

export interface ParticipantCreateNestedManyWithoutUserInput {
  create?: ParticipantCreateWithoutUserInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutUserInput[] | null;
  connect?: ParticipantWhereUniqueInput[] | null;
}

export interface ParticipantCreateOrConnectWithoutCaptainsInput {
  where: ParticipantWhereUniqueInput;
  create: ParticipantCreateWithoutCaptainsInput;
}

export interface ParticipantCreateOrConnectWithoutMatchUpInput {
  where: ParticipantWhereUniqueInput;
  create: ParticipantCreateWithoutMatchUpInput;
}

export interface ParticipantCreateOrConnectWithoutTournamentInput {
  where: ParticipantWhereUniqueInput;
  create: ParticipantCreateWithoutTournamentInput;
}

export interface ParticipantCreateOrConnectWithoutUserInput {
  where: ParticipantWhereUniqueInput;
  create: ParticipantCreateWithoutUserInput;
}

export interface ParticipantCreateWithoutCaptainsInput {
  title?: string | null;
  matchUpId?: number | null;
  active?: boolean | null;
  seed?: number | null;
  user?: UserCreateNestedManyWithoutParticipantInput | null;
  tournament?: TournamentCreateNestedOneWithoutRegistrantsInput | null;
  matchUp?: MatchUpCreateNestedManyWithoutParticipantsInput | null;
}

export interface ParticipantCreateWithoutMatchUpInput {
  title?: string | null;
  matchUpId?: number | null;
  active?: boolean | null;
  seed?: number | null;
  user?: UserCreateNestedManyWithoutParticipantInput | null;
  captains?: UserCreateNestedManyWithoutParticipantCaptainInput | null;
  tournament?: TournamentCreateNestedOneWithoutRegistrantsInput | null;
}

export interface ParticipantCreateWithoutTournamentInput {
  title?: string | null;
  matchUpId?: number | null;
  active?: boolean | null;
  seed?: number | null;
  user?: UserCreateNestedManyWithoutParticipantInput | null;
  captains?: UserCreateNestedManyWithoutParticipantCaptainInput | null;
  matchUp?: MatchUpCreateNestedManyWithoutParticipantsInput | null;
}

export interface ParticipantCreateWithoutUserInput {
  title?: string | null;
  matchUpId?: number | null;
  active?: boolean | null;
  seed?: number | null;
  captains?: UserCreateNestedManyWithoutParticipantCaptainInput | null;
  tournament?: TournamentCreateNestedOneWithoutRegistrantsInput | null;
  matchUp?: MatchUpCreateNestedManyWithoutParticipantsInput | null;
}

export interface ParticipantListRelationFilter {
  every?: ParticipantWhereInput | null;
  some?: ParticipantWhereInput | null;
  none?: ParticipantWhereInput | null;
}

export interface ParticipantScalarWhereInput {
  AND?: ParticipantScalarWhereInput[] | null;
  OR?: ParticipantScalarWhereInput[] | null;
  NOT?: ParticipantScalarWhereInput[] | null;
  id?: IntFilter | null;
  title?: StringNullableFilter | null;
  matchUpId?: IntNullableFilter | null;
  active?: BoolNullableFilter | null;
  seed?: IntNullableFilter | null;
  tournamentId?: IntNullableFilter | null;
}

export interface ParticipantUpdateManyMutationInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  matchUpId?: NullableIntFieldUpdateOperationsInput | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  seed?: NullableIntFieldUpdateOperationsInput | null;
}

export interface ParticipantUpdateManyWithWhereWithoutCaptainsInput {
  where: ParticipantScalarWhereInput;
  data: ParticipantUpdateManyMutationInput;
}

export interface ParticipantUpdateManyWithWhereWithoutMatchUpInput {
  where: ParticipantScalarWhereInput;
  data: ParticipantUpdateManyMutationInput;
}

export interface ParticipantUpdateManyWithWhereWithoutTournamentInput {
  where: ParticipantScalarWhereInput;
  data: ParticipantUpdateManyMutationInput;
}

export interface ParticipantUpdateManyWithWhereWithoutUserInput {
  where: ParticipantScalarWhereInput;
  data: ParticipantUpdateManyMutationInput;
}

export interface ParticipantUpdateManyWithoutCaptainsInput {
  create?: ParticipantCreateWithoutCaptainsInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutCaptainsInput[] | null;
  upsert?: ParticipantUpsertWithWhereUniqueWithoutCaptainsInput[] | null;
  connect?: ParticipantWhereUniqueInput[] | null;
  set?: ParticipantWhereUniqueInput[] | null;
  disconnect?: ParticipantWhereUniqueInput[] | null;
  delete?: ParticipantWhereUniqueInput[] | null;
  update?: ParticipantUpdateWithWhereUniqueWithoutCaptainsInput[] | null;
  updateMany?: ParticipantUpdateManyWithWhereWithoutCaptainsInput[] | null;
  deleteMany?: ParticipantScalarWhereInput[] | null;
}

export interface ParticipantUpdateManyWithoutMatchUpInput {
  create?: ParticipantCreateWithoutMatchUpInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutMatchUpInput[] | null;
  upsert?: ParticipantUpsertWithWhereUniqueWithoutMatchUpInput[] | null;
  connect?: ParticipantWhereUniqueInput[] | null;
  set?: ParticipantWhereUniqueInput[] | null;
  disconnect?: ParticipantWhereUniqueInput[] | null;
  delete?: ParticipantWhereUniqueInput[] | null;
  update?: ParticipantUpdateWithWhereUniqueWithoutMatchUpInput[] | null;
  updateMany?: ParticipantUpdateManyWithWhereWithoutMatchUpInput[] | null;
  deleteMany?: ParticipantScalarWhereInput[] | null;
}

export interface ParticipantUpdateManyWithoutTournamentInput {
  create?: ParticipantCreateWithoutTournamentInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutTournamentInput[] | null;
  upsert?: ParticipantUpsertWithWhereUniqueWithoutTournamentInput[] | null;
  createMany?: ParticipantCreateManyTournamentInputEnvelope | null;
  connect?: ParticipantWhereUniqueInput[] | null;
  set?: ParticipantWhereUniqueInput[] | null;
  disconnect?: ParticipantWhereUniqueInput[] | null;
  delete?: ParticipantWhereUniqueInput[] | null;
  update?: ParticipantUpdateWithWhereUniqueWithoutTournamentInput[] | null;
  updateMany?: ParticipantUpdateManyWithWhereWithoutTournamentInput[] | null;
  deleteMany?: ParticipantScalarWhereInput[] | null;
}

export interface ParticipantUpdateManyWithoutUserInput {
  create?: ParticipantCreateWithoutUserInput[] | null;
  connectOrCreate?: ParticipantCreateOrConnectWithoutUserInput[] | null;
  upsert?: ParticipantUpsertWithWhereUniqueWithoutUserInput[] | null;
  connect?: ParticipantWhereUniqueInput[] | null;
  set?: ParticipantWhereUniqueInput[] | null;
  disconnect?: ParticipantWhereUniqueInput[] | null;
  delete?: ParticipantWhereUniqueInput[] | null;
  update?: ParticipantUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: ParticipantUpdateManyWithWhereWithoutUserInput[] | null;
  deleteMany?: ParticipantScalarWhereInput[] | null;
}

export interface ParticipantUpdateWithWhereUniqueWithoutCaptainsInput {
  where: ParticipantWhereUniqueInput;
  data: ParticipantUpdateWithoutCaptainsInput;
}

export interface ParticipantUpdateWithWhereUniqueWithoutMatchUpInput {
  where: ParticipantWhereUniqueInput;
  data: ParticipantUpdateWithoutMatchUpInput;
}

export interface ParticipantUpdateWithWhereUniqueWithoutTournamentInput {
  where: ParticipantWhereUniqueInput;
  data: ParticipantUpdateWithoutTournamentInput;
}

export interface ParticipantUpdateWithWhereUniqueWithoutUserInput {
  where: ParticipantWhereUniqueInput;
  data: ParticipantUpdateWithoutUserInput;
}

export interface ParticipantUpdateWithoutCaptainsInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  matchUpId?: NullableIntFieldUpdateOperationsInput | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  seed?: NullableIntFieldUpdateOperationsInput | null;
  user?: UserUpdateManyWithoutParticipantInput | null;
  tournament?: TournamentUpdateOneWithoutRegistrantsInput | null;
  matchUp?: MatchUpUpdateManyWithoutParticipantsInput | null;
}

export interface ParticipantUpdateWithoutMatchUpInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  matchUpId?: NullableIntFieldUpdateOperationsInput | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  seed?: NullableIntFieldUpdateOperationsInput | null;
  user?: UserUpdateManyWithoutParticipantInput | null;
  captains?: UserUpdateManyWithoutParticipantCaptainInput | null;
  tournament?: TournamentUpdateOneWithoutRegistrantsInput | null;
}

export interface ParticipantUpdateWithoutTournamentInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  matchUpId?: NullableIntFieldUpdateOperationsInput | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  seed?: NullableIntFieldUpdateOperationsInput | null;
  user?: UserUpdateManyWithoutParticipantInput | null;
  captains?: UserUpdateManyWithoutParticipantCaptainInput | null;
  matchUp?: MatchUpUpdateManyWithoutParticipantsInput | null;
}

export interface ParticipantUpdateWithoutUserInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  matchUpId?: NullableIntFieldUpdateOperationsInput | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  seed?: NullableIntFieldUpdateOperationsInput | null;
  captains?: UserUpdateManyWithoutParticipantCaptainInput | null;
  tournament?: TournamentUpdateOneWithoutRegistrantsInput | null;
  matchUp?: MatchUpUpdateManyWithoutParticipantsInput | null;
}

export interface ParticipantUpsertWithWhereUniqueWithoutCaptainsInput {
  where: ParticipantWhereUniqueInput;
  update: ParticipantUpdateWithoutCaptainsInput;
  create: ParticipantCreateWithoutCaptainsInput;
}

export interface ParticipantUpsertWithWhereUniqueWithoutMatchUpInput {
  where: ParticipantWhereUniqueInput;
  update: ParticipantUpdateWithoutMatchUpInput;
  create: ParticipantCreateWithoutMatchUpInput;
}

export interface ParticipantUpsertWithWhereUniqueWithoutTournamentInput {
  where: ParticipantWhereUniqueInput;
  update: ParticipantUpdateWithoutTournamentInput;
  create: ParticipantCreateWithoutTournamentInput;
}

export interface ParticipantUpsertWithWhereUniqueWithoutUserInput {
  where: ParticipantWhereUniqueInput;
  update: ParticipantUpdateWithoutUserInput;
  create: ParticipantCreateWithoutUserInput;
}

export interface ParticipantWhereInput {
  AND?: ParticipantWhereInput[] | null;
  OR?: ParticipantWhereInput[] | null;
  NOT?: ParticipantWhereInput[] | null;
  id?: IntFilter | null;
  title?: StringNullableFilter | null;
  user?: UserListRelationFilter | null;
  captains?: UserListRelationFilter | null;
  tournament?: TournamentWhereInput | null;
  matchUp?: MatchUpListRelationFilter | null;
  matchUpId?: IntNullableFilter | null;
  active?: BoolNullableFilter | null;
  seed?: IntNullableFilter | null;
  tournamentId?: IntNullableFilter | null;
}

export interface ParticipantWhereUniqueInput {
  id?: number | null;
}

export interface PermitCreateNestedOneWithoutDesignInput {
  create?: PermitCreateWithoutDesignInput | null;
  connectOrCreate?: PermitCreateOrConnectWithoutDesignInput | null;
  connect?: PermitWhereUniqueInput | null;
}

export interface PermitCreateNestedOneWithoutInterconnectionPreInstallInput {
  create?: PermitCreateWithoutInterconnectionPreInstallInput | null;
  connectOrCreate?: PermitCreateOrConnectWithoutInterconnectionPreInstallInput | null;
  connect?: PermitWhereUniqueInput | null;
}

export interface PermitCreateNestedOneWithoutProjectInput {
  create?: PermitCreateWithoutProjectInput | null;
  connectOrCreate?: PermitCreateOrConnectWithoutProjectInput | null;
  connect?: PermitWhereUniqueInput | null;
}

export interface PermitCreateOrConnectWithoutDesignInput {
  where: PermitWhereUniqueInput;
  create: PermitCreateWithoutDesignInput;
}

export interface PermitCreateOrConnectWithoutInterconnectionPreInstallInput {
  where: PermitWhereUniqueInput;
  create: PermitCreateWithoutInterconnectionPreInstallInput;
}

export interface PermitCreateOrConnectWithoutProjectInput {
  where: PermitWhereUniqueInput;
  create: PermitCreateWithoutProjectInput;
}

export interface PermitCreateWithoutDesignInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  accountUpdate?: string | null;
  nextItemDate?: any | null;
  permitCreatedDate?: any | null;
  permitSentDate?: any | null;
  permitSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  permitMailedDate?: any | null;
  buildingPermitSubmittedDate?: any | null;
  buildingPermitApprovedDate?: any | null;
  electricalPermitSubmittedDate?: any | null;
  electricalPermitApprovedDate?: any | null;
  zoningPermitSubmittedDate?: any | null;
  zoningPermitApprovedDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompleteDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  allPermitsApprovedDate?: any | null;
  permitReceivedDate?: any | null;
  checkRequestedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  nextItemDescription?: string | null;
  permittingTeamNotes?: string | null;
  descriptionOfDesignCorrections?: string | null;
  permitNumbers?: string | null;
  permitFees?: number | null;
  permitFeeNotes?: string | null;
  refundAmount?: string | null;
  otherRelevantNotes?: string | null;
  projectItemId?: string | null;
  customerAddressOld?: string | null;
  bucketActivation_?: PermitCreatebucketActivation_Input | null;
  status_?: PermitCreatestatus_Input | null;
  stepsToComplete_?: PermitCreatestepsToComplete_Input | null;
  region_?: PermitCreateregion_Input | null;
  signaturesRequired_?: PermitCreatesignaturesRequired_Input | null;
  contractorRegistrationNeeded_?: PermitCreatecontractorRegistrationNeeded_Input | null;
  isUtilityApprovalRequiredPriorToInstallation_?: PermitCreateisUtilityApprovalRequiredPriorToInstallation_Input | null;
  deliveryMethod_?: PermitCreatedeliveryMethod_Input | null;
  additionalTasksNeeded_?: PermitCreateadditionalTasksNeeded_Input | null;
  buildingPermitRequired_?: PermitCreatebuildingPermitRequired_Input | null;
  electricalPermitRequired_?: PermitCreateelectricalPermitRequired_Input | null;
  zoningPermitRequired_?: PermitCreatezoningPermitRequired_Input | null;
  requestDesignCorrections_?: PermitCreaterequestDesignCorrections_Input | null;
  correctionsNeeded_?: PermitCreatecorrectionsNeeded_Input | null;
  checkNeeded_?: PermitCreatecheckNeeded_Input | null;
  eligibleForRefund_?: PermitCreateeligibleForRefund_Input | null;
  approvalsWaitingOn_?: PermitCreateapprovalsWaitingOn_Input | null;
  createUtilityApprovalPto_?: PermitCreatecreateUtilityApprovalPto_Input | null;
  createInstall_?: PermitCreatecreateInstall_Input | null;
  bucketActivatedTask_?: PermitCreatebucketActivatedTask_Input | null;
  project?: ProjectCreateNestedOneWithoutPermitInput | null;
  InterconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutLinkToPermittingInput | null;
}

export interface PermitCreateWithoutInterconnectionPreInstallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  accountUpdate?: string | null;
  nextItemDate?: any | null;
  permitCreatedDate?: any | null;
  permitSentDate?: any | null;
  permitSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  permitMailedDate?: any | null;
  buildingPermitSubmittedDate?: any | null;
  buildingPermitApprovedDate?: any | null;
  electricalPermitSubmittedDate?: any | null;
  electricalPermitApprovedDate?: any | null;
  zoningPermitSubmittedDate?: any | null;
  zoningPermitApprovedDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompleteDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  allPermitsApprovedDate?: any | null;
  permitReceivedDate?: any | null;
  checkRequestedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  nextItemDescription?: string | null;
  permittingTeamNotes?: string | null;
  descriptionOfDesignCorrections?: string | null;
  permitNumbers?: string | null;
  permitFees?: number | null;
  permitFeeNotes?: string | null;
  refundAmount?: string | null;
  otherRelevantNotes?: string | null;
  projectItemId?: string | null;
  customerAddressOld?: string | null;
  bucketActivation_?: PermitCreatebucketActivation_Input | null;
  status_?: PermitCreatestatus_Input | null;
  stepsToComplete_?: PermitCreatestepsToComplete_Input | null;
  region_?: PermitCreateregion_Input | null;
  signaturesRequired_?: PermitCreatesignaturesRequired_Input | null;
  contractorRegistrationNeeded_?: PermitCreatecontractorRegistrationNeeded_Input | null;
  isUtilityApprovalRequiredPriorToInstallation_?: PermitCreateisUtilityApprovalRequiredPriorToInstallation_Input | null;
  deliveryMethod_?: PermitCreatedeliveryMethod_Input | null;
  additionalTasksNeeded_?: PermitCreateadditionalTasksNeeded_Input | null;
  buildingPermitRequired_?: PermitCreatebuildingPermitRequired_Input | null;
  electricalPermitRequired_?: PermitCreateelectricalPermitRequired_Input | null;
  zoningPermitRequired_?: PermitCreatezoningPermitRequired_Input | null;
  requestDesignCorrections_?: PermitCreaterequestDesignCorrections_Input | null;
  correctionsNeeded_?: PermitCreatecorrectionsNeeded_Input | null;
  checkNeeded_?: PermitCreatecheckNeeded_Input | null;
  eligibleForRefund_?: PermitCreateeligibleForRefund_Input | null;
  approvalsWaitingOn_?: PermitCreateapprovalsWaitingOn_Input | null;
  createUtilityApprovalPto_?: PermitCreatecreateUtilityApprovalPto_Input | null;
  createInstall_?: PermitCreatecreateInstall_Input | null;
  bucketActivatedTask_?: PermitCreatebucketActivatedTask_Input | null;
  project?: ProjectCreateNestedOneWithoutPermitInput | null;
  design?: DesignCreateNestedOneWithoutLinkToPermittingBucketInput | null;
}

export interface PermitCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  accountUpdate?: string | null;
  nextItemDate?: any | null;
  permitCreatedDate?: any | null;
  permitSentDate?: any | null;
  permitSubmittedDate?: any | null;
  estimatedApprovalDate?: any | null;
  permitMailedDate?: any | null;
  buildingPermitSubmittedDate?: any | null;
  buildingPermitApprovedDate?: any | null;
  electricalPermitSubmittedDate?: any | null;
  electricalPermitApprovedDate?: any | null;
  zoningPermitSubmittedDate?: any | null;
  zoningPermitApprovedDate?: any | null;
  correctionsRequestedDate?: any | null;
  correctionsCompleteDate?: any | null;
  correctionsSubmittedDate?: any | null;
  correctionsApprovedDate?: any | null;
  allPermitsApprovedDate?: any | null;
  permitReceivedDate?: any | null;
  checkRequestedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  nextItemDescription?: string | null;
  permittingTeamNotes?: string | null;
  descriptionOfDesignCorrections?: string | null;
  permitNumbers?: string | null;
  permitFees?: number | null;
  permitFeeNotes?: string | null;
  refundAmount?: string | null;
  otherRelevantNotes?: string | null;
  projectItemId?: string | null;
  customerAddressOld?: string | null;
  bucketActivation_?: PermitCreatebucketActivation_Input | null;
  status_?: PermitCreatestatus_Input | null;
  stepsToComplete_?: PermitCreatestepsToComplete_Input | null;
  region_?: PermitCreateregion_Input | null;
  signaturesRequired_?: PermitCreatesignaturesRequired_Input | null;
  contractorRegistrationNeeded_?: PermitCreatecontractorRegistrationNeeded_Input | null;
  isUtilityApprovalRequiredPriorToInstallation_?: PermitCreateisUtilityApprovalRequiredPriorToInstallation_Input | null;
  deliveryMethod_?: PermitCreatedeliveryMethod_Input | null;
  additionalTasksNeeded_?: PermitCreateadditionalTasksNeeded_Input | null;
  buildingPermitRequired_?: PermitCreatebuildingPermitRequired_Input | null;
  electricalPermitRequired_?: PermitCreateelectricalPermitRequired_Input | null;
  zoningPermitRequired_?: PermitCreatezoningPermitRequired_Input | null;
  requestDesignCorrections_?: PermitCreaterequestDesignCorrections_Input | null;
  correctionsNeeded_?: PermitCreatecorrectionsNeeded_Input | null;
  checkNeeded_?: PermitCreatecheckNeeded_Input | null;
  eligibleForRefund_?: PermitCreateeligibleForRefund_Input | null;
  approvalsWaitingOn_?: PermitCreateapprovalsWaitingOn_Input | null;
  createUtilityApprovalPto_?: PermitCreatecreateUtilityApprovalPto_Input | null;
  createInstall_?: PermitCreatecreateInstall_Input | null;
  bucketActivatedTask_?: PermitCreatebucketActivatedTask_Input | null;
  design?: DesignCreateNestedOneWithoutLinkToPermittingBucketInput | null;
  InterconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutLinkToPermittingInput | null;
}

export interface PermitCreateadditionalTasksNeeded_Input {
  set?: string[] | null;
}

export interface PermitCreateapprovalsWaitingOn_Input {
  set?: string[] | null;
}

export interface PermitCreatebucketActivatedTask_Input {
  set?: string[] | null;
}

export interface PermitCreatebucketActivation_Input {
  set?: string[] | null;
}

export interface PermitCreatebuildingPermitRequired_Input {
  set?: string[] | null;
}

export interface PermitCreatecheckNeeded_Input {
  set?: string[] | null;
}

export interface PermitCreatecontractorRegistrationNeeded_Input {
  set?: string[] | null;
}

export interface PermitCreatecorrectionsNeeded_Input {
  set?: string[] | null;
}

export interface PermitCreatecreateInstall_Input {
  set?: string[] | null;
}

export interface PermitCreatecreateUtilityApprovalPto_Input {
  set?: string[] | null;
}

export interface PermitCreatedeliveryMethod_Input {
  set?: string[] | null;
}

export interface PermitCreateelectricalPermitRequired_Input {
  set?: string[] | null;
}

export interface PermitCreateeligibleForRefund_Input {
  set?: string[] | null;
}

export interface PermitCreateisUtilityApprovalRequiredPriorToInstallation_Input {
  set?: string[] | null;
}

export interface PermitCreateregion_Input {
  set?: string[] | null;
}

export interface PermitCreaterequestDesignCorrections_Input {
  set?: string[] | null;
}

export interface PermitCreatesignaturesRequired_Input {
  set?: string[] | null;
}

export interface PermitCreatestatus_Input {
  set?: string[] | null;
}

export interface PermitCreatestepsToComplete_Input {
  set?: string[] | null;
}

export interface PermitCreatezoningPermitRequired_Input {
  set?: string[] | null;
}

export interface PermitUpdateOneWithoutDesignInput {
  create?: PermitCreateWithoutDesignInput | null;
  connectOrCreate?: PermitCreateOrConnectWithoutDesignInput | null;
  upsert?: PermitUpsertWithoutDesignInput | null;
  connect?: PermitWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: PermitUpdateWithoutDesignInput | null;
}

export interface PermitUpdateOneWithoutInterconnectionPreInstallInput {
  create?: PermitCreateWithoutInterconnectionPreInstallInput | null;
  connectOrCreate?: PermitCreateOrConnectWithoutInterconnectionPreInstallInput | null;
  upsert?: PermitUpsertWithoutInterconnectionPreInstallInput | null;
  connect?: PermitWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: PermitUpdateWithoutInterconnectionPreInstallInput | null;
}

export interface PermitUpdateOneWithoutProjectInput {
  create?: PermitCreateWithoutProjectInput | null;
  connectOrCreate?: PermitCreateOrConnectWithoutProjectInput | null;
  upsert?: PermitUpsertWithoutProjectInput | null;
  connect?: PermitWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: PermitUpdateWithoutProjectInput | null;
}

export interface PermitUpdateWithoutDesignInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitMailedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  buildingPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  buildingPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  zoningPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  zoningPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  allPermitsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDescription?: NullableStringFieldUpdateOperationsInput | null;
  permittingTeamNotes?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  permitNumbers?: NullableStringFieldUpdateOperationsInput | null;
  permitFees?: NullableFloatFieldUpdateOperationsInput | null;
  permitFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableStringFieldUpdateOperationsInput | null;
  otherRelevantNotes?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerAddressOld?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: PermitUpdatebucketActivation_Input | null;
  status_?: PermitUpdatestatus_Input | null;
  stepsToComplete_?: PermitUpdatestepsToComplete_Input | null;
  region_?: PermitUpdateregion_Input | null;
  signaturesRequired_?: PermitUpdatesignaturesRequired_Input | null;
  contractorRegistrationNeeded_?: PermitUpdatecontractorRegistrationNeeded_Input | null;
  isUtilityApprovalRequiredPriorToInstallation_?: PermitUpdateisUtilityApprovalRequiredPriorToInstallation_Input | null;
  deliveryMethod_?: PermitUpdatedeliveryMethod_Input | null;
  additionalTasksNeeded_?: PermitUpdateadditionalTasksNeeded_Input | null;
  buildingPermitRequired_?: PermitUpdatebuildingPermitRequired_Input | null;
  electricalPermitRequired_?: PermitUpdateelectricalPermitRequired_Input | null;
  zoningPermitRequired_?: PermitUpdatezoningPermitRequired_Input | null;
  requestDesignCorrections_?: PermitUpdaterequestDesignCorrections_Input | null;
  correctionsNeeded_?: PermitUpdatecorrectionsNeeded_Input | null;
  checkNeeded_?: PermitUpdatecheckNeeded_Input | null;
  eligibleForRefund_?: PermitUpdateeligibleForRefund_Input | null;
  approvalsWaitingOn_?: PermitUpdateapprovalsWaitingOn_Input | null;
  createUtilityApprovalPto_?: PermitUpdatecreateUtilityApprovalPto_Input | null;
  createInstall_?: PermitUpdatecreateInstall_Input | null;
  bucketActivatedTask_?: PermitUpdatebucketActivatedTask_Input | null;
  project?: ProjectUpdateOneWithoutPermitInput | null;
  InterconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutLinkToPermittingInput | null;
}

export interface PermitUpdateWithoutInterconnectionPreInstallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitMailedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  buildingPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  buildingPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  zoningPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  zoningPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  allPermitsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDescription?: NullableStringFieldUpdateOperationsInput | null;
  permittingTeamNotes?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  permitNumbers?: NullableStringFieldUpdateOperationsInput | null;
  permitFees?: NullableFloatFieldUpdateOperationsInput | null;
  permitFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableStringFieldUpdateOperationsInput | null;
  otherRelevantNotes?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerAddressOld?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: PermitUpdatebucketActivation_Input | null;
  status_?: PermitUpdatestatus_Input | null;
  stepsToComplete_?: PermitUpdatestepsToComplete_Input | null;
  region_?: PermitUpdateregion_Input | null;
  signaturesRequired_?: PermitUpdatesignaturesRequired_Input | null;
  contractorRegistrationNeeded_?: PermitUpdatecontractorRegistrationNeeded_Input | null;
  isUtilityApprovalRequiredPriorToInstallation_?: PermitUpdateisUtilityApprovalRequiredPriorToInstallation_Input | null;
  deliveryMethod_?: PermitUpdatedeliveryMethod_Input | null;
  additionalTasksNeeded_?: PermitUpdateadditionalTasksNeeded_Input | null;
  buildingPermitRequired_?: PermitUpdatebuildingPermitRequired_Input | null;
  electricalPermitRequired_?: PermitUpdateelectricalPermitRequired_Input | null;
  zoningPermitRequired_?: PermitUpdatezoningPermitRequired_Input | null;
  requestDesignCorrections_?: PermitUpdaterequestDesignCorrections_Input | null;
  correctionsNeeded_?: PermitUpdatecorrectionsNeeded_Input | null;
  checkNeeded_?: PermitUpdatecheckNeeded_Input | null;
  eligibleForRefund_?: PermitUpdateeligibleForRefund_Input | null;
  approvalsWaitingOn_?: PermitUpdateapprovalsWaitingOn_Input | null;
  createUtilityApprovalPto_?: PermitUpdatecreateUtilityApprovalPto_Input | null;
  createInstall_?: PermitUpdatecreateInstall_Input | null;
  bucketActivatedTask_?: PermitUpdatebucketActivatedTask_Input | null;
  project?: ProjectUpdateOneWithoutPermitInput | null;
  design?: DesignUpdateOneWithoutLinkToPermittingBucketInput | null;
}

export interface PermitUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  nextItemDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitCreatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimatedApprovalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitMailedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  buildingPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  buildingPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  electricalPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  zoningPermitSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  zoningPermitApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  correctionsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  allPermitsApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  permitReceivedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  checkRequestedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  nextItemDescription?: NullableStringFieldUpdateOperationsInput | null;
  permittingTeamNotes?: NullableStringFieldUpdateOperationsInput | null;
  descriptionOfDesignCorrections?: NullableStringFieldUpdateOperationsInput | null;
  permitNumbers?: NullableStringFieldUpdateOperationsInput | null;
  permitFees?: NullableFloatFieldUpdateOperationsInput | null;
  permitFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  refundAmount?: NullableStringFieldUpdateOperationsInput | null;
  otherRelevantNotes?: NullableStringFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  customerAddressOld?: NullableStringFieldUpdateOperationsInput | null;
  bucketActivation_?: PermitUpdatebucketActivation_Input | null;
  status_?: PermitUpdatestatus_Input | null;
  stepsToComplete_?: PermitUpdatestepsToComplete_Input | null;
  region_?: PermitUpdateregion_Input | null;
  signaturesRequired_?: PermitUpdatesignaturesRequired_Input | null;
  contractorRegistrationNeeded_?: PermitUpdatecontractorRegistrationNeeded_Input | null;
  isUtilityApprovalRequiredPriorToInstallation_?: PermitUpdateisUtilityApprovalRequiredPriorToInstallation_Input | null;
  deliveryMethod_?: PermitUpdatedeliveryMethod_Input | null;
  additionalTasksNeeded_?: PermitUpdateadditionalTasksNeeded_Input | null;
  buildingPermitRequired_?: PermitUpdatebuildingPermitRequired_Input | null;
  electricalPermitRequired_?: PermitUpdateelectricalPermitRequired_Input | null;
  zoningPermitRequired_?: PermitUpdatezoningPermitRequired_Input | null;
  requestDesignCorrections_?: PermitUpdaterequestDesignCorrections_Input | null;
  correctionsNeeded_?: PermitUpdatecorrectionsNeeded_Input | null;
  checkNeeded_?: PermitUpdatecheckNeeded_Input | null;
  eligibleForRefund_?: PermitUpdateeligibleForRefund_Input | null;
  approvalsWaitingOn_?: PermitUpdateapprovalsWaitingOn_Input | null;
  createUtilityApprovalPto_?: PermitUpdatecreateUtilityApprovalPto_Input | null;
  createInstall_?: PermitUpdatecreateInstall_Input | null;
  bucketActivatedTask_?: PermitUpdatebucketActivatedTask_Input | null;
  design?: DesignUpdateOneWithoutLinkToPermittingBucketInput | null;
  InterconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutLinkToPermittingInput | null;
}

export interface PermitUpdateadditionalTasksNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdateapprovalsWaitingOn_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatebucketActivatedTask_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatebucketActivation_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatebuildingPermitRequired_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatecheckNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatecontractorRegistrationNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatecorrectionsNeeded_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatecreateInstall_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatecreateUtilityApprovalPto_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatedeliveryMethod_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdateelectricalPermitRequired_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdateeligibleForRefund_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdateisUtilityApprovalRequiredPriorToInstallation_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdaterequestDesignCorrections_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatesignaturesRequired_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatestepsToComplete_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpdatezoningPermitRequired_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface PermitUpsertWithoutDesignInput {
  update: PermitUpdateWithoutDesignInput;
  create: PermitCreateWithoutDesignInput;
}

export interface PermitUpsertWithoutInterconnectionPreInstallInput {
  update: PermitUpdateWithoutInterconnectionPreInstallInput;
  create: PermitCreateWithoutInterconnectionPreInstallInput;
}

export interface PermitUpsertWithoutProjectInput {
  update: PermitUpdateWithoutProjectInput;
  create: PermitCreateWithoutProjectInput;
}

export interface PermitWhereInput {
  AND?: PermitWhereInput[] | null;
  OR?: PermitWhereInput[] | null;
  NOT?: PermitWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  accountUpdate?: StringNullableFilter | null;
  bucketActivation_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  stepsToComplete_?: StringNullableListFilter | null;
  region_?: StringNullableListFilter | null;
  signaturesRequired_?: StringNullableListFilter | null;
  contractorRegistrationNeeded_?: StringNullableListFilter | null;
  isUtilityApprovalRequiredPriorToInstallation_?: StringNullableListFilter | null;
  deliveryMethod_?: StringNullableListFilter | null;
  additionalTasksNeeded_?: StringNullableListFilter | null;
  buildingPermitRequired_?: StringNullableListFilter | null;
  electricalPermitRequired_?: StringNullableListFilter | null;
  zoningPermitRequired_?: StringNullableListFilter | null;
  requestDesignCorrections_?: StringNullableListFilter | null;
  correctionsNeeded_?: StringNullableListFilter | null;
  checkNeeded_?: StringNullableListFilter | null;
  eligibleForRefund_?: StringNullableListFilter | null;
  approvalsWaitingOn_?: StringNullableListFilter | null;
  createUtilityApprovalPto_?: StringNullableListFilter | null;
  createInstall_?: StringNullableListFilter | null;
  bucketActivatedTask_?: StringNullableListFilter | null;
  nextItemDate?: DateTimeNullableFilter | null;
  permitCreatedDate?: DateTimeNullableFilter | null;
  permitSentDate?: DateTimeNullableFilter | null;
  permitSubmittedDate?: DateTimeNullableFilter | null;
  estimatedApprovalDate?: DateTimeNullableFilter | null;
  permitMailedDate?: DateTimeNullableFilter | null;
  buildingPermitSubmittedDate?: DateTimeNullableFilter | null;
  buildingPermitApprovedDate?: DateTimeNullableFilter | null;
  electricalPermitSubmittedDate?: DateTimeNullableFilter | null;
  electricalPermitApprovedDate?: DateTimeNullableFilter | null;
  zoningPermitSubmittedDate?: DateTimeNullableFilter | null;
  zoningPermitApprovedDate?: DateTimeNullableFilter | null;
  correctionsRequestedDate?: DateTimeNullableFilter | null;
  correctionsCompleteDate?: DateTimeNullableFilter | null;
  correctionsSubmittedDate?: DateTimeNullableFilter | null;
  correctionsApprovedDate?: DateTimeNullableFilter | null;
  allPermitsApprovedDate?: DateTimeNullableFilter | null;
  permitReceivedDate?: DateTimeNullableFilter | null;
  checkRequestedDate?: DateTimeNullableFilter | null;
  bucketActivatedDate?: DateTimeNullableFilter | null;
  bucketCompletionDate?: DateTimeNullableFilter | null;
  nextItemDescription?: StringNullableFilter | null;
  permittingTeamNotes?: StringNullableFilter | null;
  descriptionOfDesignCorrections?: StringNullableFilter | null;
  permitNumbers?: StringNullableFilter | null;
  permitFees?: FloatNullableFilter | null;
  permitFeeNotes?: StringNullableFilter | null;
  refundAmount?: StringNullableFilter | null;
  otherRelevantNotes?: StringNullableFilter | null;
  projectItemId?: StringNullableFilter | null;
  customerAddressOld?: StringNullableFilter | null;
  project?: ProjectWhereInput | null;
  design?: DesignWhereInput | null;
  InterconnectionPreInstall?: InterconnectionPreInstallWhereInput | null;
}

export interface PermitWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
}

export interface PodioWebhookCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  workspace?: string | null;
  appName?: string | null;
  appId?: number | null;
  type?: string | null;
  podioHookId?: number | null;
  fieldId?: number | null;
  externalFieldId?: string | null;
  url?: string | null;
  summary?: string | null;
  description?: string | null;
}

export interface ProjectCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  welcomeCallId?: number | null;
  interconnectionPostInstallId?: number | null;
  proposalId?: number | null;
  designId?: number | null;
  permitId?: number | null;
  installId?: number | null;
  interconnectionPreInstallId?: number | null;
  fundingId?: number | null;
  installReadyId?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  communicationPreference_?: ProjectCreateManycommunicationPreference_Input | null;
  region_?: ProjectCreateManyregion_Input | null;
  whiteGloveProject_?: ProjectCreateManywhiteGloveProject_Input | null;
  stage_?: ProjectCreateManystage_Input | null;
  bucketWhenOnHold_?: ProjectCreateManybucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreateManyreasonForHold_Input | null;
  feeCollected_?: ProjectCreateManyfeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreateManystepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreateManystepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreateManystepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreateManyfilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateManyprojectType_Input | null;
  managedBy_?: ProjectCreateManymanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateManyassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreateManyfriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateManyprojectStatus_Input | null;
  createdFrom_?: ProjectCreateManycreatedFrom_Input | null;
  testSurvey_?: ProjectCreateManytestSurvey_Input | null;
  testProject_?: ProjectCreateManytestProject_Input | null;
}

export interface ProjectCreateManySalesRepInputEnvelope {
  data?: ProjectCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface ProjectCreateManyassignedToConcierge_Input {
  set?: string[] | null;
}

export interface ProjectCreateManybucketWhenOnHold_Input {
  set?: string[] | null;
}

export interface ProjectCreateManycommunicationPreference_Input {
  set?: string[] | null;
}

export interface ProjectCreateManycreatedFrom_Input {
  set?: string[] | null;
}

export interface ProjectCreateManyfeeCollected_Input {
  set?: string[] | null;
}

export interface ProjectCreateManyfilePreliminaryNotice_Input {
  set?: string[] | null;
}

export interface ProjectCreateManyfriendsAndFamilyReferral_Input {
  set?: string[] | null;
}

export interface ProjectCreateManymanagedBy_Input {
  set?: string[] | null;
}

export interface ProjectCreateManyprojectStatus_Input {
  set?: string[] | null;
}

export interface ProjectCreateManyprojectType_Input {
  set?: string[] | null;
}

export interface ProjectCreateManyreasonForHold_Input {
  set?: string[] | null;
}

export interface ProjectCreateManyregion_Input {
  set?: string[] | null;
}

export interface ProjectCreateManystage_Input {
  set?: string[] | null;
}

export interface ProjectCreateManystepsRequiredForFinalFunding_Input {
  set?: string[] | null;
}

export interface ProjectCreateManystepsRequiredForFluentApproved_Input {
  set?: string[] | null;
}

export interface ProjectCreateManystepsRequiredForInstall_Input {
  set?: string[] | null;
}

export interface ProjectCreateManytestProject_Input {
  set?: string[] | null;
}

export interface ProjectCreateManytestSurvey_Input {
  set?: string[] | null;
}

export interface ProjectCreateManywhiteGloveProject_Input {
  set?: string[] | null;
}

export interface ProjectCreateNestedManyWithoutSalesRepInput {
  create?: ProjectCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: ProjectCreateManySalesRepInputEnvelope | null;
  connect?: ProjectWhereUniqueInput[] | null;
}

export interface ProjectCreateNestedOneWithoutCurrentProposalInput {
  create?: ProjectCreateWithoutCurrentProposalInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutCurrentProposalInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutDesignInput {
  create?: ProjectCreateWithoutDesignInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutDesignInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutFundingInput {
  create?: ProjectCreateWithoutFundingInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutFundingInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutHoaInput {
  create?: ProjectCreateWithoutHoaInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutHoaInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutInstallInspectionInput {
  create?: ProjectCreateWithoutInstallInspectionInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInstallInspectionInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutInstallReadyInput {
  create?: ProjectCreateWithoutInstallReadyInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInstallReadyInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutInterconnectionPostInput {
  create?: ProjectCreateWithoutInterconnectionPostInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInterconnectionPostInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutInterconnectionPreInstallInput {
  create?: ProjectCreateWithoutInterconnectionPreInstallInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInterconnectionPreInstallInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutMasterCustomersInput {
  create?: ProjectCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutMasterCustomersInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutPermitInput {
  create?: ProjectCreateWithoutPermitInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutPermitInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateNestedOneWithoutWelcomeCallInput {
  create?: ProjectCreateWithoutWelcomeCallInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutWelcomeCallInput | null;
  connect?: ProjectWhereUniqueInput | null;
}

export interface ProjectCreateOrConnectWithoutCurrentProposalInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutCurrentProposalInput;
}

export interface ProjectCreateOrConnectWithoutDesignInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutDesignInput;
}

export interface ProjectCreateOrConnectWithoutFundingInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutFundingInput;
}

export interface ProjectCreateOrConnectWithoutHoaInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutHoaInput;
}

export interface ProjectCreateOrConnectWithoutInstallInspectionInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutInstallInspectionInput;
}

export interface ProjectCreateOrConnectWithoutInstallReadyInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutInstallReadyInput;
}

export interface ProjectCreateOrConnectWithoutInterconnectionPostInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutInterconnectionPostInput;
}

export interface ProjectCreateOrConnectWithoutInterconnectionPreInstallInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutInterconnectionPreInstallInput;
}

export interface ProjectCreateOrConnectWithoutMasterCustomersInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutMasterCustomersInput;
}

export interface ProjectCreateOrConnectWithoutPermitInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutPermitInput;
}

export interface ProjectCreateOrConnectWithoutSalesRepInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutSalesRepInput;
}

export interface ProjectCreateOrConnectWithoutWelcomeCallInput {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutWelcomeCallInput;
}

export interface ProjectCreateWithoutCurrentProposalInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutDesignInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutFundingInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutHoaInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutInstallInspectionInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutInstallReadyInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutInterconnectionPostInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutInterconnectionPreInstallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutMasterCustomersInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutPermitInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutSalesRepInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateWithoutWelcomeCallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  mostRecentCustomerCommunicationDate?: any | null;
  mostRecentRepCommunicationDate?: any | null;
  reasonForHoldDescribe?: string | null;
  reasonCancelled?: string | null;
  requestedCancellationDate?: any | null;
  feeAmount?: number | null;
  cancellationFeeNotes?: string | null;
  preliminaryNoticeFiledDate?: any | null;
  specialRequestsNotes?: string | null;
  referredBy?: string | null;
  cancellationDate?: any | null;
  stageWhenCancelled?: string | null;
  salesReviewStartDate?: any | null;
  stagePriorToSalesReview?: string | null;
  notes?: string | null;
  installSchedulingInformation?: string | null;
  dateFluentApproved?: any | null;
  parentFolder?: string | null;
  installFolder?: string | null;
  siteSurveyFolder?: string | null;
  docsFolder?: string | null;
  customerFolder?: string | null;
  salesFolder?: string | null;
  projectComments?: string | null;
  jobTitle?: string | null;
  comment?: string | null;
  commissionStructureText?: string | null;
  qbCustomerId?: string | null;
  onHoldDate?: any | null;
  communicationLogPodioItemId?: number | null;
  projectCompleteDate?: any | null;
  projectItemId?: string | null;
  communicationPreference_?: ProjectCreatecommunicationPreference_Input | null;
  region_?: ProjectCreateregion_Input | null;
  whiteGloveProject_?: ProjectCreatewhiteGloveProject_Input | null;
  stage_?: ProjectCreatestage_Input | null;
  bucketWhenOnHold_?: ProjectCreatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectCreatereasonForHold_Input | null;
  feeCollected_?: ProjectCreatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectCreatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectCreatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectCreatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectCreatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectCreateprojectType_Input | null;
  managedBy_?: ProjectCreatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectCreateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectCreatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectCreateprojectStatus_Input | null;
  createdFrom_?: ProjectCreatecreatedFrom_Input | null;
  testSurvey_?: ProjectCreatetestSurvey_Input | null;
  testProject_?: ProjectCreatetestProject_Input | null;
  hoa?: HOACreateNestedOneWithoutProjectInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProjectInput | null;
  currentProposal?: ProposalCreateNestedOneWithoutProjectInput | null;
  design?: DesignCreateNestedOneWithoutProjectInput | null;
  permit?: PermitCreateNestedOneWithoutProjectInput | null;
  installInspection?: InstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallCreateNestedOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallCreateNestedOneWithoutProjectInput | null;
  funding?: FundingCreateNestedOneWithoutProjectInput | null;
  installReady?: InstallReadyCreateNestedOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectCreateassignedToConcierge_Input {
  set?: string[] | null;
}

export interface ProjectCreatebucketWhenOnHold_Input {
  set?: string[] | null;
}

export interface ProjectCreatecommunicationPreference_Input {
  set?: string[] | null;
}

export interface ProjectCreatecreatedFrom_Input {
  set?: string[] | null;
}

export interface ProjectCreatefeeCollected_Input {
  set?: string[] | null;
}

export interface ProjectCreatefilePreliminaryNotice_Input {
  set?: string[] | null;
}

export interface ProjectCreatefriendsAndFamilyReferral_Input {
  set?: string[] | null;
}

export interface ProjectCreatemanagedBy_Input {
  set?: string[] | null;
}

export interface ProjectCreateprojectStatus_Input {
  set?: string[] | null;
}

export interface ProjectCreateprojectType_Input {
  set?: string[] | null;
}

export interface ProjectCreatereasonForHold_Input {
  set?: string[] | null;
}

export interface ProjectCreateregion_Input {
  set?: string[] | null;
}

export interface ProjectCreatestage_Input {
  set?: string[] | null;
}

export interface ProjectCreatestepsRequiredForFinalFunding_Input {
  set?: string[] | null;
}

export interface ProjectCreatestepsRequiredForFluentApproved_Input {
  set?: string[] | null;
}

export interface ProjectCreatestepsRequiredForInstall_Input {
  set?: string[] | null;
}

export interface ProjectCreatetestProject_Input {
  set?: string[] | null;
}

export interface ProjectCreatetestSurvey_Input {
  set?: string[] | null;
}

export interface ProjectCreatewhiteGloveProject_Input {
  set?: string[] | null;
}

export interface ProjectListRelationFilter {
  every?: ProjectWhereInput | null;
  some?: ProjectWhereInput | null;
  none?: ProjectWhereInput | null;
}

export interface ProjectScalarWhereInput {
  AND?: ProjectScalarWhereInput[] | null;
  OR?: ProjectScalarWhereInput[] | null;
  NOT?: ProjectScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  customerName?: StringNullableFilter | null;
  customerPhone?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  mostRecentCustomerCommunicationDate?: DateTimeNullableFilter | null;
  mostRecentRepCommunicationDate?: DateTimeNullableFilter | null;
  communicationPreference_?: StringNullableListFilter | null;
  region_?: StringNullableListFilter | null;
  whiteGloveProject_?: StringNullableListFilter | null;
  stage_?: StringNullableListFilter | null;
  bucketWhenOnHold_?: StringNullableListFilter | null;
  reasonForHold_?: StringNullableListFilter | null;
  feeCollected_?: StringNullableListFilter | null;
  stepsRequiredForInstall_?: StringNullableListFilter | null;
  stepsRequiredForFluentApproved_?: StringNullableListFilter | null;
  stepsRequiredForFinalFunding_?: StringNullableListFilter | null;
  filePreliminaryNotice_?: StringNullableListFilter | null;
  projectType_?: StringNullableListFilter | null;
  managedBy_?: StringNullableListFilter | null;
  assignedToConcierge_?: StringNullableListFilter | null;
  friendsAndFamilyReferral_?: StringNullableListFilter | null;
  projectStatus_?: StringNullableListFilter | null;
  createdFrom_?: StringNullableListFilter | null;
  testSurvey_?: StringNullableListFilter | null;
  testProject_?: StringNullableListFilter | null;
  reasonForHoldDescribe?: StringNullableFilter | null;
  reasonCancelled?: StringNullableFilter | null;
  requestedCancellationDate?: DateTimeNullableFilter | null;
  feeAmount?: FloatNullableFilter | null;
  cancellationFeeNotes?: StringNullableFilter | null;
  preliminaryNoticeFiledDate?: DateTimeNullableFilter | null;
  specialRequestsNotes?: StringNullableFilter | null;
  referredBy?: StringNullableFilter | null;
  cancellationDate?: DateTimeNullableFilter | null;
  stageWhenCancelled?: StringNullableFilter | null;
  salesReviewStartDate?: DateTimeNullableFilter | null;
  stagePriorToSalesReview?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  installSchedulingInformation?: StringNullableFilter | null;
  dateFluentApproved?: DateTimeNullableFilter | null;
  parentFolder?: StringNullableFilter | null;
  installFolder?: StringNullableFilter | null;
  siteSurveyFolder?: StringNullableFilter | null;
  docsFolder?: StringNullableFilter | null;
  customerFolder?: StringNullableFilter | null;
  salesFolder?: StringNullableFilter | null;
  projectComments?: StringNullableFilter | null;
  jobTitle?: StringNullableFilter | null;
  comment?: StringNullableFilter | null;
  commissionStructureText?: StringNullableFilter | null;
  qbCustomerId?: StringNullableFilter | null;
  onHoldDate?: DateTimeNullableFilter | null;
  communicationLogPodioItemId?: IntNullableFilter | null;
  projectCompleteDate?: DateTimeNullableFilter | null;
  projectItemId?: StringNullableFilter | null;
  welcomeCallId?: IntNullableFilter | null;
  interconnectionPostInstallId?: IntNullableFilter | null;
  proposalId?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  designId?: IntNullableFilter | null;
  permitId?: IntNullableFilter | null;
  installId?: IntNullableFilter | null;
  interconnectionPreInstallId?: IntNullableFilter | null;
  fundingId?: IntNullableFilter | null;
  installReadyId?: IntNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface ProjectUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
}

export interface ProjectUpdateManyWithWhereWithoutSalesRepInput {
  where: ProjectScalarWhereInput;
  data: ProjectUpdateManyMutationInput;
}

export interface ProjectUpdateManyWithoutSalesRepInput {
  create?: ProjectCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: ProjectUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: ProjectCreateManySalesRepInputEnvelope | null;
  connect?: ProjectWhereUniqueInput[] | null;
  set?: ProjectWhereUniqueInput[] | null;
  disconnect?: ProjectWhereUniqueInput[] | null;
  delete?: ProjectWhereUniqueInput[] | null;
  update?: ProjectUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: ProjectUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: ProjectScalarWhereInput[] | null;
}

export interface ProjectUpdateOneWithoutCurrentProposalInput {
  create?: ProjectCreateWithoutCurrentProposalInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutCurrentProposalInput | null;
  upsert?: ProjectUpsertWithoutCurrentProposalInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutCurrentProposalInput | null;
}

export interface ProjectUpdateOneWithoutDesignInput {
  create?: ProjectCreateWithoutDesignInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutDesignInput | null;
  upsert?: ProjectUpsertWithoutDesignInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutDesignInput | null;
}

export interface ProjectUpdateOneWithoutFundingInput {
  create?: ProjectCreateWithoutFundingInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutFundingInput | null;
  upsert?: ProjectUpsertWithoutFundingInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutFundingInput | null;
}

export interface ProjectUpdateOneWithoutHoaInput {
  create?: ProjectCreateWithoutHoaInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutHoaInput | null;
  upsert?: ProjectUpsertWithoutHoaInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutHoaInput | null;
}

export interface ProjectUpdateOneWithoutInstallInspectionInput {
  create?: ProjectCreateWithoutInstallInspectionInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInstallInspectionInput | null;
  upsert?: ProjectUpsertWithoutInstallInspectionInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutInstallInspectionInput | null;
}

export interface ProjectUpdateOneWithoutInstallReadyInput {
  create?: ProjectCreateWithoutInstallReadyInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInstallReadyInput | null;
  upsert?: ProjectUpsertWithoutInstallReadyInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutInstallReadyInput | null;
}

export interface ProjectUpdateOneWithoutInterconnectionPostInput {
  create?: ProjectCreateWithoutInterconnectionPostInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInterconnectionPostInput | null;
  upsert?: ProjectUpsertWithoutInterconnectionPostInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutInterconnectionPostInput | null;
}

export interface ProjectUpdateOneWithoutInterconnectionPreInstallInput {
  create?: ProjectCreateWithoutInterconnectionPreInstallInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutInterconnectionPreInstallInput | null;
  upsert?: ProjectUpsertWithoutInterconnectionPreInstallInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutInterconnectionPreInstallInput | null;
}

export interface ProjectUpdateOneWithoutMasterCustomersInput {
  create?: ProjectCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutMasterCustomersInput | null;
  upsert?: ProjectUpsertWithoutMasterCustomersInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutMasterCustomersInput | null;
}

export interface ProjectUpdateOneWithoutPermitInput {
  create?: ProjectCreateWithoutPermitInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutPermitInput | null;
  upsert?: ProjectUpsertWithoutPermitInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutPermitInput | null;
}

export interface ProjectUpdateOneWithoutWelcomeCallInput {
  create?: ProjectCreateWithoutWelcomeCallInput | null;
  connectOrCreate?: ProjectCreateOrConnectWithoutWelcomeCallInput | null;
  upsert?: ProjectUpsertWithoutWelcomeCallInput | null;
  connect?: ProjectWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProjectUpdateWithoutWelcomeCallInput | null;
}

export interface ProjectUpdateWithWhereUniqueWithoutSalesRepInput {
  where: ProjectWhereUniqueInput;
  data: ProjectUpdateWithoutSalesRepInput;
}

export interface ProjectUpdateWithoutCurrentProposalInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutDesignInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutFundingInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutHoaInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutInstallInspectionInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutInstallReadyInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutInterconnectionPostInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutInterconnectionPreInstallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutMasterCustomersInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutPermitInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateWithoutWelcomeCallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  customerPhone?: NullableStringFieldUpdateOperationsInput | null;
  customerEmail?: NullableStringFieldUpdateOperationsInput | null;
  mostRecentCustomerCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  mostRecentRepCommunicationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  reasonForHoldDescribe?: NullableStringFieldUpdateOperationsInput | null;
  reasonCancelled?: NullableStringFieldUpdateOperationsInput | null;
  requestedCancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  feeAmount?: NullableFloatFieldUpdateOperationsInput | null;
  cancellationFeeNotes?: NullableStringFieldUpdateOperationsInput | null;
  preliminaryNoticeFiledDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  referredBy?: NullableStringFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stageWhenCancelled?: NullableStringFieldUpdateOperationsInput | null;
  salesReviewStartDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  stagePriorToSalesReview?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  installSchedulingInformation?: NullableStringFieldUpdateOperationsInput | null;
  dateFluentApproved?: NullableDateTimeFieldUpdateOperationsInput | null;
  parentFolder?: NullableStringFieldUpdateOperationsInput | null;
  installFolder?: NullableStringFieldUpdateOperationsInput | null;
  siteSurveyFolder?: NullableStringFieldUpdateOperationsInput | null;
  docsFolder?: NullableStringFieldUpdateOperationsInput | null;
  customerFolder?: NullableStringFieldUpdateOperationsInput | null;
  salesFolder?: NullableStringFieldUpdateOperationsInput | null;
  projectComments?: NullableStringFieldUpdateOperationsInput | null;
  jobTitle?: NullableStringFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  qbCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  onHoldDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  communicationLogPodioItemId?: NullableIntFieldUpdateOperationsInput | null;
  projectCompleteDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  projectItemId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreference_?: ProjectUpdatecommunicationPreference_Input | null;
  region_?: ProjectUpdateregion_Input | null;
  whiteGloveProject_?: ProjectUpdatewhiteGloveProject_Input | null;
  stage_?: ProjectUpdatestage_Input | null;
  bucketWhenOnHold_?: ProjectUpdatebucketWhenOnHold_Input | null;
  reasonForHold_?: ProjectUpdatereasonForHold_Input | null;
  feeCollected_?: ProjectUpdatefeeCollected_Input | null;
  stepsRequiredForInstall_?: ProjectUpdatestepsRequiredForInstall_Input | null;
  stepsRequiredForFluentApproved_?: ProjectUpdatestepsRequiredForFluentApproved_Input | null;
  stepsRequiredForFinalFunding_?: ProjectUpdatestepsRequiredForFinalFunding_Input | null;
  filePreliminaryNotice_?: ProjectUpdatefilePreliminaryNotice_Input | null;
  projectType_?: ProjectUpdateprojectType_Input | null;
  managedBy_?: ProjectUpdatemanagedBy_Input | null;
  assignedToConcierge_?: ProjectUpdateassignedToConcierge_Input | null;
  friendsAndFamilyReferral_?: ProjectUpdatefriendsAndFamilyReferral_Input | null;
  projectStatus_?: ProjectUpdateprojectStatus_Input | null;
  createdFrom_?: ProjectUpdatecreatedFrom_Input | null;
  testSurvey_?: ProjectUpdatetestSurvey_Input | null;
  testProject_?: ProjectUpdatetestProject_Input | null;
  hoa?: HOAUpdateOneWithoutProjectInput | null;
  salesRep?: SalesRepUpdateOneWithoutProjectInput | null;
  currentProposal?: ProposalUpdateOneWithoutProjectInput | null;
  design?: DesignUpdateOneWithoutProjectInput | null;
  permit?: PermitUpdateOneWithoutProjectInput | null;
  installInspection?: InstallUpdateOneWithoutProjectInput | null;
  interconnectionPost?: InterconnectionPostInstallUpdateOneWithoutProjectInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallUpdateOneWithoutProjectInput | null;
  funding?: FundingUpdateOneWithoutProjectInput | null;
  installReady?: InstallReadyUpdateOneWithoutProjectInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutProjectInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput | null;
}

export interface ProjectUpdateassignedToConcierge_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatebucketWhenOnHold_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatecommunicationPreference_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatecreatedFrom_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatefeeCollected_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatefilePreliminaryNotice_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatefriendsAndFamilyReferral_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatemanagedBy_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdateprojectStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdateprojectType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatereasonForHold_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatestage_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatestepsRequiredForFinalFunding_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatestepsRequiredForFluentApproved_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatestepsRequiredForInstall_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatetestProject_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatetestSurvey_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpdatewhiteGloveProject_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProjectUpsertWithWhereUniqueWithoutSalesRepInput {
  where: ProjectWhereUniqueInput;
  update: ProjectUpdateWithoutSalesRepInput;
  create: ProjectCreateWithoutSalesRepInput;
}

export interface ProjectUpsertWithoutCurrentProposalInput {
  update: ProjectUpdateWithoutCurrentProposalInput;
  create: ProjectCreateWithoutCurrentProposalInput;
}

export interface ProjectUpsertWithoutDesignInput {
  update: ProjectUpdateWithoutDesignInput;
  create: ProjectCreateWithoutDesignInput;
}

export interface ProjectUpsertWithoutFundingInput {
  update: ProjectUpdateWithoutFundingInput;
  create: ProjectCreateWithoutFundingInput;
}

export interface ProjectUpsertWithoutHoaInput {
  update: ProjectUpdateWithoutHoaInput;
  create: ProjectCreateWithoutHoaInput;
}

export interface ProjectUpsertWithoutInstallInspectionInput {
  update: ProjectUpdateWithoutInstallInspectionInput;
  create: ProjectCreateWithoutInstallInspectionInput;
}

export interface ProjectUpsertWithoutInstallReadyInput {
  update: ProjectUpdateWithoutInstallReadyInput;
  create: ProjectCreateWithoutInstallReadyInput;
}

export interface ProjectUpsertWithoutInterconnectionPostInput {
  update: ProjectUpdateWithoutInterconnectionPostInput;
  create: ProjectCreateWithoutInterconnectionPostInput;
}

export interface ProjectUpsertWithoutInterconnectionPreInstallInput {
  update: ProjectUpdateWithoutInterconnectionPreInstallInput;
  create: ProjectCreateWithoutInterconnectionPreInstallInput;
}

export interface ProjectUpsertWithoutMasterCustomersInput {
  update: ProjectUpdateWithoutMasterCustomersInput;
  create: ProjectCreateWithoutMasterCustomersInput;
}

export interface ProjectUpsertWithoutPermitInput {
  update: ProjectUpdateWithoutPermitInput;
  create: ProjectCreateWithoutPermitInput;
}

export interface ProjectUpsertWithoutWelcomeCallInput {
  update: ProjectUpdateWithoutWelcomeCallInput;
  create: ProjectCreateWithoutWelcomeCallInput;
}

export interface ProjectWhereInput {
  AND?: ProjectWhereInput[] | null;
  OR?: ProjectWhereInput[] | null;
  NOT?: ProjectWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  customerName?: StringNullableFilter | null;
  customerPhone?: StringNullableFilter | null;
  customerEmail?: StringNullableFilter | null;
  mostRecentCustomerCommunicationDate?: DateTimeNullableFilter | null;
  mostRecentRepCommunicationDate?: DateTimeNullableFilter | null;
  communicationPreference_?: StringNullableListFilter | null;
  region_?: StringNullableListFilter | null;
  whiteGloveProject_?: StringNullableListFilter | null;
  stage_?: StringNullableListFilter | null;
  bucketWhenOnHold_?: StringNullableListFilter | null;
  reasonForHold_?: StringNullableListFilter | null;
  feeCollected_?: StringNullableListFilter | null;
  stepsRequiredForInstall_?: StringNullableListFilter | null;
  stepsRequiredForFluentApproved_?: StringNullableListFilter | null;
  stepsRequiredForFinalFunding_?: StringNullableListFilter | null;
  filePreliminaryNotice_?: StringNullableListFilter | null;
  projectType_?: StringNullableListFilter | null;
  managedBy_?: StringNullableListFilter | null;
  assignedToConcierge_?: StringNullableListFilter | null;
  friendsAndFamilyReferral_?: StringNullableListFilter | null;
  projectStatus_?: StringNullableListFilter | null;
  createdFrom_?: StringNullableListFilter | null;
  testSurvey_?: StringNullableListFilter | null;
  testProject_?: StringNullableListFilter | null;
  reasonForHoldDescribe?: StringNullableFilter | null;
  reasonCancelled?: StringNullableFilter | null;
  requestedCancellationDate?: DateTimeNullableFilter | null;
  feeAmount?: FloatNullableFilter | null;
  cancellationFeeNotes?: StringNullableFilter | null;
  preliminaryNoticeFiledDate?: DateTimeNullableFilter | null;
  specialRequestsNotes?: StringNullableFilter | null;
  referredBy?: StringNullableFilter | null;
  cancellationDate?: DateTimeNullableFilter | null;
  stageWhenCancelled?: StringNullableFilter | null;
  salesReviewStartDate?: DateTimeNullableFilter | null;
  stagePriorToSalesReview?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  installSchedulingInformation?: StringNullableFilter | null;
  dateFluentApproved?: DateTimeNullableFilter | null;
  parentFolder?: StringNullableFilter | null;
  installFolder?: StringNullableFilter | null;
  siteSurveyFolder?: StringNullableFilter | null;
  docsFolder?: StringNullableFilter | null;
  customerFolder?: StringNullableFilter | null;
  salesFolder?: StringNullableFilter | null;
  projectComments?: StringNullableFilter | null;
  jobTitle?: StringNullableFilter | null;
  comment?: StringNullableFilter | null;
  commissionStructureText?: StringNullableFilter | null;
  qbCustomerId?: StringNullableFilter | null;
  onHoldDate?: DateTimeNullableFilter | null;
  communicationLogPodioItemId?: IntNullableFilter | null;
  projectCompleteDate?: DateTimeNullableFilter | null;
  projectItemId?: StringNullableFilter | null;
  hoa?: HOAWhereInput | null;
  salesRep?: SalesRepWhereInput | null;
  currentProposal?: ProposalWhereInput | null;
  design?: DesignWhereInput | null;
  permit?: PermitWhereInput | null;
  installInspection?: InstallWhereInput | null;
  interconnectionPost?: InterconnectionPostInstallWhereInput | null;
  interconnectionPreInstall?: InterconnectionPreInstallWhereInput | null;
  funding?: FundingWhereInput | null;
  installReady?: InstallReadyWhereInput | null;
  welcomeCallId?: IntNullableFilter | null;
  welcomeCall?: WelcomeCallWhereInput | null;
  interconnectionPostInstallId?: IntNullableFilter | null;
  proposalId?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  designId?: IntNullableFilter | null;
  permitId?: IntNullableFilter | null;
  installId?: IntNullableFilter | null;
  interconnectionPreInstallId?: IntNullableFilter | null;
  fundingId?: IntNullableFilter | null;
  installReadyId?: IntNullableFilter | null;
  masterCustomers?: MasterCustomerListRelationFilter | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface ProjectWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  welcomeCallId?: number | null;
  interconnectionPostInstallId?: number | null;
  proposalId?: number | null;
  designId?: number | null;
  permitId?: number | null;
  installId?: number | null;
  interconnectionPreInstallId?: number | null;
  fundingId?: number | null;
  installReadyId?: number | null;
}

export interface ProposalCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateRequested?: any | null;
  dateCompleted?: any | null;
  contractSignedDate?: any | null;
  docReviewBeginDate?: any | null;
  docReviewEndDate?: any | null;
  cancellationDate?: any | null;
  bucketCompletionDate?: any | null;
  homeSecurityAdderEmailSentDate?: any | null;
  customerName?: string | null;
  phone?: string | null;
  email?: string | null;
  customerId?: string | null;
  notes?: string | null;
  specialRequestsNotes?: string | null;
  notesForFailedDocReview?: string | null;
  salesRepPhone?: string | null;
  numberOfInverters?: number | null;
  numberOfModules?: number | null;
  hoaInfo?: string | null;
  meterServiceAcct?: string | null;
  customerUtilityRatePlan?: string | null;
  ifPpaDesiredKWhRate?: number | null;
  totalOffset?: number | null;
  systemSizeKw?: number | null;
  systemPrice?: number | null;
  coverageAmountPromised?: number | null;
  fluentHomeAccountNumber?: string | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  rebateAmountPayableToCustomer?: number | null;
  rebateNotes?: string | null;
  january?: number | null;
  february?: number | null;
  march?: number | null;
  april?: number | null;
  may?: number | null;
  june?: number | null;
  july?: number | null;
  august?: number | null;
  september?: number | null;
  october?: number | null;
  november?: number | null;
  december?: number | null;
  sqFootage?: number | null;
  oldProjectId?: string | null;
  oldProposalId?: string | null;
  ifFinancedCustomerWatt?: number | null;
  commissionStructureText?: string | null;
  pathToDropboxParentFolder?: string | null;
  welcomeCallId?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  region_?: ProposalCreateManyregion_Input | null;
  status_?: ProposalCreateManystatus_Input | null;
  builder_?: ProposalCreateManybuilder_Input | null;
  managedBy_?: ProposalCreateManymanagedBy_Input | null;
  stepsToComplete_?: ProposalCreateManystepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalCreateManygreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalCreateManyutilityBillVerified_Input | null;
  proposalStatus_?: ProposalCreateManyproposalStatus_Input | null;
  proposalType_?: ProposalCreateManyproposalType_Input | null;
  systemType_?: ProposalCreateManysystemType_Input | null;
  inverterType_?: ProposalCreateManyinverterType_Input | null;
  usePremiumModule_?: ProposalCreateManyusePremiumModule_Input | null;
  hoa_?: ProposalCreateManyhoa_Input | null;
  desiredOffest_?: ProposalCreateManydesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalCreateManydesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalCreateManybatteryPromised_Input | null;
  generatorPromised_?: ProposalCreateManygeneratorPromised_Input | null;
  groundMount_?: ProposalCreateManygroundMount_Input | null;
  n18MonthCoverage_?: ProposalCreateManyn18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalCreateManysecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalCreateManyenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalCreateManyonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalCreateManyadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalCreateManyadditionalBulbs8_Input | null;
  energyPackage_?: ProposalCreateManyenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalCreateManyadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalCreateManyadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalCreateManyaccountMoved_Input | null;
  createProject_?: ProposalCreateManycreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalCreateManymigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalCreateManydealerName_Input | null;
  proposalCreatedFrom_?: ProposalCreateManyproposalCreatedFrom_Input | null;
  testProposal_?: ProposalCreateManytestProposal_Input | null;
}

export interface ProposalCreateManySalesRepInputEnvelope {
  data?: ProposalCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface ProposalCreateManyaccountMoved_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyadditionalBulbs8_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyadditionalLedBulbs_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyadditionalNestThermostat2_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyadditionalNestThermostat_Input {
  set?: string[] | null;
}

export interface ProposalCreateManybatteryPromised_Input {
  set?: string[] | null;
}

export interface ProposalCreateManybuilder_Input {
  set?: string[] | null;
}

export interface ProposalCreateManycreateProject_Input {
  set?: string[] | null;
}

export interface ProposalCreateManydealerName_Input {
  set?: string[] | null;
}

export interface ProposalCreateManydesiredOffest_Input {
  set?: string[] | null;
}

export interface ProposalCreateManydesiredPpaEscalatorRate_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyenergyEfficiencyPackageNest24Bulbs_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyenergyPackage_Input {
  set?: string[] | null;
}

export interface ProposalCreateManygeneratorPromised_Input {
  set?: string[] | null;
}

export interface ProposalCreateManygreenButtonDataVerified_Input {
  set?: string[] | null;
}

export interface ProposalCreateManygroundMount_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyhoa_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyinverterType_Input {
  set?: string[] | null;
}

export interface ProposalCreateManymanagedBy_Input {
  set?: string[] | null;
}

export interface ProposalCreateManymigrateProposalToDealerWorkspace_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyn18MonthCoverage_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyonlyLedBulbs24_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyproposalCreatedFrom_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyproposalStatus_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyproposalType_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyregion_Input {
  set?: string[] | null;
}

export interface ProposalCreateManysecurityPackageInProposal_Input {
  set?: string[] | null;
}

export interface ProposalCreateManystatus_Input {
  set?: string[] | null;
}

export interface ProposalCreateManystepsToComplete_Input {
  set?: string[] | null;
}

export interface ProposalCreateManysystemType_Input {
  set?: string[] | null;
}

export interface ProposalCreateManytestProposal_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyusePremiumModule_Input {
  set?: string[] | null;
}

export interface ProposalCreateManyutilityBillVerified_Input {
  set?: string[] | null;
}

export interface ProposalCreateNestedManyWithoutSalesRepInput {
  create?: ProposalCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: ProposalCreateManySalesRepInputEnvelope | null;
  connect?: ProposalWhereUniqueInput[] | null;
}

export interface ProposalCreateNestedOneWithoutFundingInput {
  create?: ProposalCreateWithoutFundingInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutFundingInput | null;
  connect?: ProposalWhereUniqueInput | null;
}

export interface ProposalCreateNestedOneWithoutLinkToDealInput {
  create?: ProposalCreateWithoutLinkToDealInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutLinkToDealInput | null;
  connect?: ProposalWhereUniqueInput | null;
}

export interface ProposalCreateNestedOneWithoutProjectInput {
  create?: ProposalCreateWithoutProjectInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutProjectInput | null;
  connect?: ProposalWhereUniqueInput | null;
}

export interface ProposalCreateNestedOneWithoutWelcomeCallInput {
  create?: ProposalCreateWithoutWelcomeCallInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutWelcomeCallInput | null;
  connect?: ProposalWhereUniqueInput | null;
}

export interface ProposalCreateOrConnectWithoutFundingInput {
  where: ProposalWhereUniqueInput;
  create: ProposalCreateWithoutFundingInput;
}

export interface ProposalCreateOrConnectWithoutLinkToDealInput {
  where: ProposalWhereUniqueInput;
  create: ProposalCreateWithoutLinkToDealInput;
}

export interface ProposalCreateOrConnectWithoutProjectInput {
  where: ProposalWhereUniqueInput;
  create: ProposalCreateWithoutProjectInput;
}

export interface ProposalCreateOrConnectWithoutSalesRepInput {
  where: ProposalWhereUniqueInput;
  create: ProposalCreateWithoutSalesRepInput;
}

export interface ProposalCreateOrConnectWithoutWelcomeCallInput {
  where: ProposalWhereUniqueInput;
  create: ProposalCreateWithoutWelcomeCallInput;
}

export interface ProposalCreateWithoutFundingInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateRequested?: any | null;
  dateCompleted?: any | null;
  contractSignedDate?: any | null;
  docReviewBeginDate?: any | null;
  docReviewEndDate?: any | null;
  cancellationDate?: any | null;
  bucketCompletionDate?: any | null;
  homeSecurityAdderEmailSentDate?: any | null;
  customerName?: string | null;
  phone?: string | null;
  email?: string | null;
  customerId?: string | null;
  notes?: string | null;
  specialRequestsNotes?: string | null;
  notesForFailedDocReview?: string | null;
  salesRepPhone?: string | null;
  numberOfInverters?: number | null;
  numberOfModules?: number | null;
  hoaInfo?: string | null;
  meterServiceAcct?: string | null;
  customerUtilityRatePlan?: string | null;
  ifPpaDesiredKWhRate?: number | null;
  totalOffset?: number | null;
  systemSizeKw?: number | null;
  systemPrice?: number | null;
  coverageAmountPromised?: number | null;
  fluentHomeAccountNumber?: string | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  rebateAmountPayableToCustomer?: number | null;
  rebateNotes?: string | null;
  january?: number | null;
  february?: number | null;
  march?: number | null;
  april?: number | null;
  may?: number | null;
  june?: number | null;
  july?: number | null;
  august?: number | null;
  september?: number | null;
  october?: number | null;
  november?: number | null;
  december?: number | null;
  sqFootage?: number | null;
  oldProjectId?: string | null;
  oldProposalId?: string | null;
  ifFinancedCustomerWatt?: number | null;
  commissionStructureText?: string | null;
  pathToDropboxParentFolder?: string | null;
  region_?: ProposalCreateregion_Input | null;
  status_?: ProposalCreatestatus_Input | null;
  builder_?: ProposalCreatebuilder_Input | null;
  managedBy_?: ProposalCreatemanagedBy_Input | null;
  stepsToComplete_?: ProposalCreatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalCreategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalCreateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalCreateproposalStatus_Input | null;
  proposalType_?: ProposalCreateproposalType_Input | null;
  systemType_?: ProposalCreatesystemType_Input | null;
  inverterType_?: ProposalCreateinverterType_Input | null;
  usePremiumModule_?: ProposalCreateusePremiumModule_Input | null;
  hoa_?: ProposalCreatehoa_Input | null;
  desiredOffest_?: ProposalCreatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalCreatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalCreatebatteryPromised_Input | null;
  generatorPromised_?: ProposalCreategeneratorPromised_Input | null;
  groundMount_?: ProposalCreategroundMount_Input | null;
  n18MonthCoverage_?: ProposalCreaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalCreatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalCreateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalCreateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalCreateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalCreateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalCreateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalCreateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalCreateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalCreateaccountMoved_Input | null;
  createProject_?: ProposalCreatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalCreatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalCreatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalCreateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalCreatetestProposal_Input | null;
  project?: ProjectCreateNestedOneWithoutCurrentProposalInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProposalInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProposalInput | null;
  linkToDeal?: DealCreateNestedOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalCreateWithoutLinkToDealInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateRequested?: any | null;
  dateCompleted?: any | null;
  contractSignedDate?: any | null;
  docReviewBeginDate?: any | null;
  docReviewEndDate?: any | null;
  cancellationDate?: any | null;
  bucketCompletionDate?: any | null;
  homeSecurityAdderEmailSentDate?: any | null;
  customerName?: string | null;
  phone?: string | null;
  email?: string | null;
  customerId?: string | null;
  notes?: string | null;
  specialRequestsNotes?: string | null;
  notesForFailedDocReview?: string | null;
  salesRepPhone?: string | null;
  numberOfInverters?: number | null;
  numberOfModules?: number | null;
  hoaInfo?: string | null;
  meterServiceAcct?: string | null;
  customerUtilityRatePlan?: string | null;
  ifPpaDesiredKWhRate?: number | null;
  totalOffset?: number | null;
  systemSizeKw?: number | null;
  systemPrice?: number | null;
  coverageAmountPromised?: number | null;
  fluentHomeAccountNumber?: string | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  rebateAmountPayableToCustomer?: number | null;
  rebateNotes?: string | null;
  january?: number | null;
  february?: number | null;
  march?: number | null;
  april?: number | null;
  may?: number | null;
  june?: number | null;
  july?: number | null;
  august?: number | null;
  september?: number | null;
  october?: number | null;
  november?: number | null;
  december?: number | null;
  sqFootage?: number | null;
  oldProjectId?: string | null;
  oldProposalId?: string | null;
  ifFinancedCustomerWatt?: number | null;
  commissionStructureText?: string | null;
  pathToDropboxParentFolder?: string | null;
  region_?: ProposalCreateregion_Input | null;
  status_?: ProposalCreatestatus_Input | null;
  builder_?: ProposalCreatebuilder_Input | null;
  managedBy_?: ProposalCreatemanagedBy_Input | null;
  stepsToComplete_?: ProposalCreatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalCreategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalCreateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalCreateproposalStatus_Input | null;
  proposalType_?: ProposalCreateproposalType_Input | null;
  systemType_?: ProposalCreatesystemType_Input | null;
  inverterType_?: ProposalCreateinverterType_Input | null;
  usePremiumModule_?: ProposalCreateusePremiumModule_Input | null;
  hoa_?: ProposalCreatehoa_Input | null;
  desiredOffest_?: ProposalCreatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalCreatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalCreatebatteryPromised_Input | null;
  generatorPromised_?: ProposalCreategeneratorPromised_Input | null;
  groundMount_?: ProposalCreategroundMount_Input | null;
  n18MonthCoverage_?: ProposalCreaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalCreatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalCreateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalCreateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalCreateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalCreateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalCreateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalCreateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalCreateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalCreateaccountMoved_Input | null;
  createProject_?: ProposalCreatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalCreatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalCreatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalCreateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalCreatetestProposal_Input | null;
  project?: ProjectCreateNestedOneWithoutCurrentProposalInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProposalInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProposalInput | null;
  funding?: FundingCreateNestedOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateRequested?: any | null;
  dateCompleted?: any | null;
  contractSignedDate?: any | null;
  docReviewBeginDate?: any | null;
  docReviewEndDate?: any | null;
  cancellationDate?: any | null;
  bucketCompletionDate?: any | null;
  homeSecurityAdderEmailSentDate?: any | null;
  customerName?: string | null;
  phone?: string | null;
  email?: string | null;
  customerId?: string | null;
  notes?: string | null;
  specialRequestsNotes?: string | null;
  notesForFailedDocReview?: string | null;
  salesRepPhone?: string | null;
  numberOfInverters?: number | null;
  numberOfModules?: number | null;
  hoaInfo?: string | null;
  meterServiceAcct?: string | null;
  customerUtilityRatePlan?: string | null;
  ifPpaDesiredKWhRate?: number | null;
  totalOffset?: number | null;
  systemSizeKw?: number | null;
  systemPrice?: number | null;
  coverageAmountPromised?: number | null;
  fluentHomeAccountNumber?: string | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  rebateAmountPayableToCustomer?: number | null;
  rebateNotes?: string | null;
  january?: number | null;
  february?: number | null;
  march?: number | null;
  april?: number | null;
  may?: number | null;
  june?: number | null;
  july?: number | null;
  august?: number | null;
  september?: number | null;
  october?: number | null;
  november?: number | null;
  december?: number | null;
  sqFootage?: number | null;
  oldProjectId?: string | null;
  oldProposalId?: string | null;
  ifFinancedCustomerWatt?: number | null;
  commissionStructureText?: string | null;
  pathToDropboxParentFolder?: string | null;
  region_?: ProposalCreateregion_Input | null;
  status_?: ProposalCreatestatus_Input | null;
  builder_?: ProposalCreatebuilder_Input | null;
  managedBy_?: ProposalCreatemanagedBy_Input | null;
  stepsToComplete_?: ProposalCreatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalCreategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalCreateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalCreateproposalStatus_Input | null;
  proposalType_?: ProposalCreateproposalType_Input | null;
  systemType_?: ProposalCreatesystemType_Input | null;
  inverterType_?: ProposalCreateinverterType_Input | null;
  usePremiumModule_?: ProposalCreateusePremiumModule_Input | null;
  hoa_?: ProposalCreatehoa_Input | null;
  desiredOffest_?: ProposalCreatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalCreatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalCreatebatteryPromised_Input | null;
  generatorPromised_?: ProposalCreategeneratorPromised_Input | null;
  groundMount_?: ProposalCreategroundMount_Input | null;
  n18MonthCoverage_?: ProposalCreaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalCreatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalCreateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalCreateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalCreateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalCreateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalCreateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalCreateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalCreateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalCreateaccountMoved_Input | null;
  createProject_?: ProposalCreatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalCreatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalCreatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalCreateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalCreatetestProposal_Input | null;
  salesRep?: SalesRepCreateNestedOneWithoutProposalInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProposalInput | null;
  linkToDeal?: DealCreateNestedOneWithoutProposalInput | null;
  funding?: FundingCreateNestedOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalCreateWithoutSalesRepInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateRequested?: any | null;
  dateCompleted?: any | null;
  contractSignedDate?: any | null;
  docReviewBeginDate?: any | null;
  docReviewEndDate?: any | null;
  cancellationDate?: any | null;
  bucketCompletionDate?: any | null;
  homeSecurityAdderEmailSentDate?: any | null;
  customerName?: string | null;
  phone?: string | null;
  email?: string | null;
  customerId?: string | null;
  notes?: string | null;
  specialRequestsNotes?: string | null;
  notesForFailedDocReview?: string | null;
  salesRepPhone?: string | null;
  numberOfInverters?: number | null;
  numberOfModules?: number | null;
  hoaInfo?: string | null;
  meterServiceAcct?: string | null;
  customerUtilityRatePlan?: string | null;
  ifPpaDesiredKWhRate?: number | null;
  totalOffset?: number | null;
  systemSizeKw?: number | null;
  systemPrice?: number | null;
  coverageAmountPromised?: number | null;
  fluentHomeAccountNumber?: string | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  rebateAmountPayableToCustomer?: number | null;
  rebateNotes?: string | null;
  january?: number | null;
  february?: number | null;
  march?: number | null;
  april?: number | null;
  may?: number | null;
  june?: number | null;
  july?: number | null;
  august?: number | null;
  september?: number | null;
  october?: number | null;
  november?: number | null;
  december?: number | null;
  sqFootage?: number | null;
  oldProjectId?: string | null;
  oldProposalId?: string | null;
  ifFinancedCustomerWatt?: number | null;
  commissionStructureText?: string | null;
  pathToDropboxParentFolder?: string | null;
  region_?: ProposalCreateregion_Input | null;
  status_?: ProposalCreatestatus_Input | null;
  builder_?: ProposalCreatebuilder_Input | null;
  managedBy_?: ProposalCreatemanagedBy_Input | null;
  stepsToComplete_?: ProposalCreatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalCreategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalCreateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalCreateproposalStatus_Input | null;
  proposalType_?: ProposalCreateproposalType_Input | null;
  systemType_?: ProposalCreatesystemType_Input | null;
  inverterType_?: ProposalCreateinverterType_Input | null;
  usePremiumModule_?: ProposalCreateusePremiumModule_Input | null;
  hoa_?: ProposalCreatehoa_Input | null;
  desiredOffest_?: ProposalCreatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalCreatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalCreatebatteryPromised_Input | null;
  generatorPromised_?: ProposalCreategeneratorPromised_Input | null;
  groundMount_?: ProposalCreategroundMount_Input | null;
  n18MonthCoverage_?: ProposalCreaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalCreatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalCreateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalCreateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalCreateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalCreateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalCreateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalCreateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalCreateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalCreateaccountMoved_Input | null;
  createProject_?: ProposalCreatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalCreatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalCreatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalCreateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalCreatetestProposal_Input | null;
  project?: ProjectCreateNestedOneWithoutCurrentProposalInput | null;
  welcomeCall?: WelcomeCallCreateNestedOneWithoutProposalInput | null;
  linkToDeal?: DealCreateNestedOneWithoutProposalInput | null;
  funding?: FundingCreateNestedOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalCreateWithoutWelcomeCallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateRequested?: any | null;
  dateCompleted?: any | null;
  contractSignedDate?: any | null;
  docReviewBeginDate?: any | null;
  docReviewEndDate?: any | null;
  cancellationDate?: any | null;
  bucketCompletionDate?: any | null;
  homeSecurityAdderEmailSentDate?: any | null;
  customerName?: string | null;
  phone?: string | null;
  email?: string | null;
  customerId?: string | null;
  notes?: string | null;
  specialRequestsNotes?: string | null;
  notesForFailedDocReview?: string | null;
  salesRepPhone?: string | null;
  numberOfInverters?: number | null;
  numberOfModules?: number | null;
  hoaInfo?: string | null;
  meterServiceAcct?: string | null;
  customerUtilityRatePlan?: string | null;
  ifPpaDesiredKWhRate?: number | null;
  totalOffset?: number | null;
  systemSizeKw?: number | null;
  systemPrice?: number | null;
  coverageAmountPromised?: number | null;
  fluentHomeAccountNumber?: string | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  rebateAmountPayableToCustomer?: number | null;
  rebateNotes?: string | null;
  january?: number | null;
  february?: number | null;
  march?: number | null;
  april?: number | null;
  may?: number | null;
  june?: number | null;
  july?: number | null;
  august?: number | null;
  september?: number | null;
  october?: number | null;
  november?: number | null;
  december?: number | null;
  sqFootage?: number | null;
  oldProjectId?: string | null;
  oldProposalId?: string | null;
  ifFinancedCustomerWatt?: number | null;
  commissionStructureText?: string | null;
  pathToDropboxParentFolder?: string | null;
  region_?: ProposalCreateregion_Input | null;
  status_?: ProposalCreatestatus_Input | null;
  builder_?: ProposalCreatebuilder_Input | null;
  managedBy_?: ProposalCreatemanagedBy_Input | null;
  stepsToComplete_?: ProposalCreatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalCreategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalCreateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalCreateproposalStatus_Input | null;
  proposalType_?: ProposalCreateproposalType_Input | null;
  systemType_?: ProposalCreatesystemType_Input | null;
  inverterType_?: ProposalCreateinverterType_Input | null;
  usePremiumModule_?: ProposalCreateusePremiumModule_Input | null;
  hoa_?: ProposalCreatehoa_Input | null;
  desiredOffest_?: ProposalCreatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalCreatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalCreatebatteryPromised_Input | null;
  generatorPromised_?: ProposalCreategeneratorPromised_Input | null;
  groundMount_?: ProposalCreategroundMount_Input | null;
  n18MonthCoverage_?: ProposalCreaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalCreatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalCreateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalCreateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalCreateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalCreateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalCreateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalCreateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalCreateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalCreateaccountMoved_Input | null;
  createProject_?: ProposalCreatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalCreatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalCreatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalCreateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalCreatetestProposal_Input | null;
  project?: ProjectCreateNestedOneWithoutCurrentProposalInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutProposalInput | null;
  linkToDeal?: DealCreateNestedOneWithoutProposalInput | null;
  funding?: FundingCreateNestedOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalCreateaccountMoved_Input {
  set?: string[] | null;
}

export interface ProposalCreateadditionalBulbs8_Input {
  set?: string[] | null;
}

export interface ProposalCreateadditionalLedBulbs_Input {
  set?: string[] | null;
}

export interface ProposalCreateadditionalNestThermostat2_Input {
  set?: string[] | null;
}

export interface ProposalCreateadditionalNestThermostat_Input {
  set?: string[] | null;
}

export interface ProposalCreatebatteryPromised_Input {
  set?: string[] | null;
}

export interface ProposalCreatebuilder_Input {
  set?: string[] | null;
}

export interface ProposalCreatecreateProject_Input {
  set?: string[] | null;
}

export interface ProposalCreatedealerName_Input {
  set?: string[] | null;
}

export interface ProposalCreatedesiredOffest_Input {
  set?: string[] | null;
}

export interface ProposalCreatedesiredPpaEscalatorRate_Input {
  set?: string[] | null;
}

export interface ProposalCreateenergyEfficiencyPackageNest24Bulbs_Input {
  set?: string[] | null;
}

export interface ProposalCreateenergyPackage_Input {
  set?: string[] | null;
}

export interface ProposalCreategeneratorPromised_Input {
  set?: string[] | null;
}

export interface ProposalCreategreenButtonDataVerified_Input {
  set?: string[] | null;
}

export interface ProposalCreategroundMount_Input {
  set?: string[] | null;
}

export interface ProposalCreatehoa_Input {
  set?: string[] | null;
}

export interface ProposalCreateinverterType_Input {
  set?: string[] | null;
}

export interface ProposalCreatemanagedBy_Input {
  set?: string[] | null;
}

export interface ProposalCreatemigrateProposalToDealerWorkspace_Input {
  set?: string[] | null;
}

export interface ProposalCreaten18MonthCoverage_Input {
  set?: string[] | null;
}

export interface ProposalCreateonlyLedBulbs24_Input {
  set?: string[] | null;
}

export interface ProposalCreateproposalCreatedFrom_Input {
  set?: string[] | null;
}

export interface ProposalCreateproposalStatus_Input {
  set?: string[] | null;
}

export interface ProposalCreateproposalType_Input {
  set?: string[] | null;
}

export interface ProposalCreateregion_Input {
  set?: string[] | null;
}

export interface ProposalCreatesecurityPackageInProposal_Input {
  set?: string[] | null;
}

export interface ProposalCreatestatus_Input {
  set?: string[] | null;
}

export interface ProposalCreatestepsToComplete_Input {
  set?: string[] | null;
}

export interface ProposalCreatesystemType_Input {
  set?: string[] | null;
}

export interface ProposalCreatetestProposal_Input {
  set?: string[] | null;
}

export interface ProposalCreateusePremiumModule_Input {
  set?: string[] | null;
}

export interface ProposalCreateutilityBillVerified_Input {
  set?: string[] | null;
}

export interface ProposalListRelationFilter {
  every?: ProposalWhereInput | null;
  some?: ProposalWhereInput | null;
  none?: ProposalWhereInput | null;
}

export interface ProposalScalarWhereInput {
  AND?: ProposalScalarWhereInput[] | null;
  OR?: ProposalScalarWhereInput[] | null;
  NOT?: ProposalScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  dateRequested?: DateTimeNullableFilter | null;
  dateCompleted?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  docReviewBeginDate?: DateTimeNullableFilter | null;
  docReviewEndDate?: DateTimeNullableFilter | null;
  cancellationDate?: DateTimeNullableFilter | null;
  bucketCompletionDate?: DateTimeNullableFilter | null;
  homeSecurityAdderEmailSentDate?: DateTimeNullableFilter | null;
  region_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  builder_?: StringNullableListFilter | null;
  managedBy_?: StringNullableListFilter | null;
  stepsToComplete_?: StringNullableListFilter | null;
  greenButtonDataVerified_?: StringNullableListFilter | null;
  utilityBillVerified_?: StringNullableListFilter | null;
  proposalStatus_?: StringNullableListFilter | null;
  proposalType_?: StringNullableListFilter | null;
  systemType_?: StringNullableListFilter | null;
  inverterType_?: StringNullableListFilter | null;
  usePremiumModule_?: StringNullableListFilter | null;
  hoa_?: StringNullableListFilter | null;
  desiredOffest_?: StringNullableListFilter | null;
  desiredPpaEscalatorRate_?: StringNullableListFilter | null;
  batteryPromised_?: StringNullableListFilter | null;
  generatorPromised_?: StringNullableListFilter | null;
  groundMount_?: StringNullableListFilter | null;
  n18MonthCoverage_?: StringNullableListFilter | null;
  securityPackageInProposal_?: StringNullableListFilter | null;
  energyEfficiencyPackageNest24Bulbs_?: StringNullableListFilter | null;
  onlyLedBulbs24_?: StringNullableListFilter | null;
  additionalNestThermostat_?: StringNullableListFilter | null;
  additionalBulbs8_?: StringNullableListFilter | null;
  energyPackage_?: StringNullableListFilter | null;
  additionalNestThermostat2_?: StringNullableListFilter | null;
  additionalLedBulbs_?: StringNullableListFilter | null;
  accountMoved_?: StringNullableListFilter | null;
  createProject_?: StringNullableListFilter | null;
  migrateProposalToDealerWorkspace_?: StringNullableListFilter | null;
  dealerName_?: StringNullableListFilter | null;
  proposalCreatedFrom_?: StringNullableListFilter | null;
  testProposal_?: StringNullableListFilter | null;
  customerName?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  customerId?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  specialRequestsNotes?: StringNullableFilter | null;
  notesForFailedDocReview?: StringNullableFilter | null;
  salesRepPhone?: StringNullableFilter | null;
  numberOfInverters?: IntNullableFilter | null;
  numberOfModules?: IntNullableFilter | null;
  hoaInfo?: StringNullableFilter | null;
  meterServiceAcct?: StringNullableFilter | null;
  customerUtilityRatePlan?: StringNullableFilter | null;
  ifPpaDesiredKWhRate?: FloatNullableFilter | null;
  totalOffset?: IntNullableFilter | null;
  systemSizeKw?: FloatNullableFilter | null;
  systemPrice?: FloatNullableFilter | null;
  coverageAmountPromised?: FloatNullableFilter | null;
  fluentHomeAccountNumber?: StringNullableFilter | null;
  nestThermostats?: IntNullableFilter | null;
  ledBulbs?: IntNullableFilter | null;
  trenchingRawGroundFootage?: IntNullableFilter | null;
  trenchingConcreteGroundFootage?: IntNullableFilter | null;
  rebateAmountPayableToCustomer?: FloatNullableFilter | null;
  rebateNotes?: StringNullableFilter | null;
  january?: IntNullableFilter | null;
  february?: IntNullableFilter | null;
  march?: IntNullableFilter | null;
  april?: IntNullableFilter | null;
  may?: IntNullableFilter | null;
  june?: IntNullableFilter | null;
  july?: IntNullableFilter | null;
  august?: IntNullableFilter | null;
  september?: IntNullableFilter | null;
  october?: IntNullableFilter | null;
  november?: IntNullableFilter | null;
  december?: IntNullableFilter | null;
  sqFootage?: IntNullableFilter | null;
  oldProjectId?: StringNullableFilter | null;
  oldProposalId?: StringNullableFilter | null;
  ifFinancedCustomerWatt?: FloatNullableFilter | null;
  commissionStructureText?: StringNullableFilter | null;
  pathToDropboxParentFolder?: StringNullableFilter | null;
  welcomeCallId?: IntNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface ProposalUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateRequested?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCompleted?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewBeginDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  homeSecurityAdderEmailSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  customerId?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  notesForFailedDocReview?: NullableStringFieldUpdateOperationsInput | null;
  salesRepPhone?: NullableStringFieldUpdateOperationsInput | null;
  numberOfInverters?: NullableIntFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  customerUtilityRatePlan?: NullableStringFieldUpdateOperationsInput | null;
  ifPpaDesiredKWhRate?: NullableFloatFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeKw?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  fluentHomeAccountNumber?: NullableStringFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  rebateAmountPayableToCustomer?: NullableFloatFieldUpdateOperationsInput | null;
  rebateNotes?: NullableStringFieldUpdateOperationsInput | null;
  january?: NullableIntFieldUpdateOperationsInput | null;
  february?: NullableIntFieldUpdateOperationsInput | null;
  march?: NullableIntFieldUpdateOperationsInput | null;
  april?: NullableIntFieldUpdateOperationsInput | null;
  may?: NullableIntFieldUpdateOperationsInput | null;
  june?: NullableIntFieldUpdateOperationsInput | null;
  july?: NullableIntFieldUpdateOperationsInput | null;
  august?: NullableIntFieldUpdateOperationsInput | null;
  september?: NullableIntFieldUpdateOperationsInput | null;
  october?: NullableIntFieldUpdateOperationsInput | null;
  november?: NullableIntFieldUpdateOperationsInput | null;
  december?: NullableIntFieldUpdateOperationsInput | null;
  sqFootage?: NullableIntFieldUpdateOperationsInput | null;
  oldProjectId?: NullableStringFieldUpdateOperationsInput | null;
  oldProposalId?: NullableStringFieldUpdateOperationsInput | null;
  ifFinancedCustomerWatt?: NullableFloatFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  region_?: ProposalUpdateregion_Input | null;
  status_?: ProposalUpdatestatus_Input | null;
  builder_?: ProposalUpdatebuilder_Input | null;
  managedBy_?: ProposalUpdatemanagedBy_Input | null;
  stepsToComplete_?: ProposalUpdatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalUpdategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalUpdateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalUpdateproposalStatus_Input | null;
  proposalType_?: ProposalUpdateproposalType_Input | null;
  systemType_?: ProposalUpdatesystemType_Input | null;
  inverterType_?: ProposalUpdateinverterType_Input | null;
  usePremiumModule_?: ProposalUpdateusePremiumModule_Input | null;
  hoa_?: ProposalUpdatehoa_Input | null;
  desiredOffest_?: ProposalUpdatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalUpdatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalUpdatebatteryPromised_Input | null;
  generatorPromised_?: ProposalUpdategeneratorPromised_Input | null;
  groundMount_?: ProposalUpdategroundMount_Input | null;
  n18MonthCoverage_?: ProposalUpdaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalUpdatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalUpdateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalUpdateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalUpdateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalUpdateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalUpdateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalUpdateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalUpdateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalUpdateaccountMoved_Input | null;
  createProject_?: ProposalUpdatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalUpdatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalUpdatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalUpdateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalUpdatetestProposal_Input | null;
}

export interface ProposalUpdateManyWithWhereWithoutSalesRepInput {
  where: ProposalScalarWhereInput;
  data: ProposalUpdateManyMutationInput;
}

export interface ProposalUpdateManyWithoutSalesRepInput {
  create?: ProposalCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: ProposalUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: ProposalCreateManySalesRepInputEnvelope | null;
  connect?: ProposalWhereUniqueInput[] | null;
  set?: ProposalWhereUniqueInput[] | null;
  disconnect?: ProposalWhereUniqueInput[] | null;
  delete?: ProposalWhereUniqueInput[] | null;
  update?: ProposalUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: ProposalUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: ProposalScalarWhereInput[] | null;
}

export interface ProposalUpdateOneWithoutFundingInput {
  create?: ProposalCreateWithoutFundingInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutFundingInput | null;
  upsert?: ProposalUpsertWithoutFundingInput | null;
  connect?: ProposalWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProposalUpdateWithoutFundingInput | null;
}

export interface ProposalUpdateOneWithoutLinkToDealInput {
  create?: ProposalCreateWithoutLinkToDealInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutLinkToDealInput | null;
  upsert?: ProposalUpsertWithoutLinkToDealInput | null;
  connect?: ProposalWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProposalUpdateWithoutLinkToDealInput | null;
}

export interface ProposalUpdateOneWithoutProjectInput {
  create?: ProposalCreateWithoutProjectInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutProjectInput | null;
  upsert?: ProposalUpsertWithoutProjectInput | null;
  connect?: ProposalWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProposalUpdateWithoutProjectInput | null;
}

export interface ProposalUpdateOneWithoutWelcomeCallInput {
  create?: ProposalCreateWithoutWelcomeCallInput | null;
  connectOrCreate?: ProposalCreateOrConnectWithoutWelcomeCallInput | null;
  upsert?: ProposalUpsertWithoutWelcomeCallInput | null;
  connect?: ProposalWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProposalUpdateWithoutWelcomeCallInput | null;
}

export interface ProposalUpdateWithWhereUniqueWithoutSalesRepInput {
  where: ProposalWhereUniqueInput;
  data: ProposalUpdateWithoutSalesRepInput;
}

export interface ProposalUpdateWithoutFundingInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateRequested?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCompleted?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewBeginDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  homeSecurityAdderEmailSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  customerId?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  notesForFailedDocReview?: NullableStringFieldUpdateOperationsInput | null;
  salesRepPhone?: NullableStringFieldUpdateOperationsInput | null;
  numberOfInverters?: NullableIntFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  customerUtilityRatePlan?: NullableStringFieldUpdateOperationsInput | null;
  ifPpaDesiredKWhRate?: NullableFloatFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeKw?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  fluentHomeAccountNumber?: NullableStringFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  rebateAmountPayableToCustomer?: NullableFloatFieldUpdateOperationsInput | null;
  rebateNotes?: NullableStringFieldUpdateOperationsInput | null;
  january?: NullableIntFieldUpdateOperationsInput | null;
  february?: NullableIntFieldUpdateOperationsInput | null;
  march?: NullableIntFieldUpdateOperationsInput | null;
  april?: NullableIntFieldUpdateOperationsInput | null;
  may?: NullableIntFieldUpdateOperationsInput | null;
  june?: NullableIntFieldUpdateOperationsInput | null;
  july?: NullableIntFieldUpdateOperationsInput | null;
  august?: NullableIntFieldUpdateOperationsInput | null;
  september?: NullableIntFieldUpdateOperationsInput | null;
  october?: NullableIntFieldUpdateOperationsInput | null;
  november?: NullableIntFieldUpdateOperationsInput | null;
  december?: NullableIntFieldUpdateOperationsInput | null;
  sqFootage?: NullableIntFieldUpdateOperationsInput | null;
  oldProjectId?: NullableStringFieldUpdateOperationsInput | null;
  oldProposalId?: NullableStringFieldUpdateOperationsInput | null;
  ifFinancedCustomerWatt?: NullableFloatFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  region_?: ProposalUpdateregion_Input | null;
  status_?: ProposalUpdatestatus_Input | null;
  builder_?: ProposalUpdatebuilder_Input | null;
  managedBy_?: ProposalUpdatemanagedBy_Input | null;
  stepsToComplete_?: ProposalUpdatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalUpdategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalUpdateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalUpdateproposalStatus_Input | null;
  proposalType_?: ProposalUpdateproposalType_Input | null;
  systemType_?: ProposalUpdatesystemType_Input | null;
  inverterType_?: ProposalUpdateinverterType_Input | null;
  usePremiumModule_?: ProposalUpdateusePremiumModule_Input | null;
  hoa_?: ProposalUpdatehoa_Input | null;
  desiredOffest_?: ProposalUpdatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalUpdatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalUpdatebatteryPromised_Input | null;
  generatorPromised_?: ProposalUpdategeneratorPromised_Input | null;
  groundMount_?: ProposalUpdategroundMount_Input | null;
  n18MonthCoverage_?: ProposalUpdaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalUpdatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalUpdateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalUpdateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalUpdateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalUpdateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalUpdateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalUpdateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalUpdateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalUpdateaccountMoved_Input | null;
  createProject_?: ProposalUpdatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalUpdatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalUpdatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalUpdateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalUpdatetestProposal_Input | null;
  project?: ProjectUpdateOneWithoutCurrentProposalInput | null;
  salesRep?: SalesRepUpdateOneWithoutProposalInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProposalInput | null;
  linkToDeal?: DealUpdateOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalUpdateWithoutLinkToDealInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateRequested?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCompleted?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewBeginDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  homeSecurityAdderEmailSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  customerId?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  notesForFailedDocReview?: NullableStringFieldUpdateOperationsInput | null;
  salesRepPhone?: NullableStringFieldUpdateOperationsInput | null;
  numberOfInverters?: NullableIntFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  customerUtilityRatePlan?: NullableStringFieldUpdateOperationsInput | null;
  ifPpaDesiredKWhRate?: NullableFloatFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeKw?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  fluentHomeAccountNumber?: NullableStringFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  rebateAmountPayableToCustomer?: NullableFloatFieldUpdateOperationsInput | null;
  rebateNotes?: NullableStringFieldUpdateOperationsInput | null;
  january?: NullableIntFieldUpdateOperationsInput | null;
  february?: NullableIntFieldUpdateOperationsInput | null;
  march?: NullableIntFieldUpdateOperationsInput | null;
  april?: NullableIntFieldUpdateOperationsInput | null;
  may?: NullableIntFieldUpdateOperationsInput | null;
  june?: NullableIntFieldUpdateOperationsInput | null;
  july?: NullableIntFieldUpdateOperationsInput | null;
  august?: NullableIntFieldUpdateOperationsInput | null;
  september?: NullableIntFieldUpdateOperationsInput | null;
  october?: NullableIntFieldUpdateOperationsInput | null;
  november?: NullableIntFieldUpdateOperationsInput | null;
  december?: NullableIntFieldUpdateOperationsInput | null;
  sqFootage?: NullableIntFieldUpdateOperationsInput | null;
  oldProjectId?: NullableStringFieldUpdateOperationsInput | null;
  oldProposalId?: NullableStringFieldUpdateOperationsInput | null;
  ifFinancedCustomerWatt?: NullableFloatFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  region_?: ProposalUpdateregion_Input | null;
  status_?: ProposalUpdatestatus_Input | null;
  builder_?: ProposalUpdatebuilder_Input | null;
  managedBy_?: ProposalUpdatemanagedBy_Input | null;
  stepsToComplete_?: ProposalUpdatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalUpdategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalUpdateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalUpdateproposalStatus_Input | null;
  proposalType_?: ProposalUpdateproposalType_Input | null;
  systemType_?: ProposalUpdatesystemType_Input | null;
  inverterType_?: ProposalUpdateinverterType_Input | null;
  usePremiumModule_?: ProposalUpdateusePremiumModule_Input | null;
  hoa_?: ProposalUpdatehoa_Input | null;
  desiredOffest_?: ProposalUpdatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalUpdatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalUpdatebatteryPromised_Input | null;
  generatorPromised_?: ProposalUpdategeneratorPromised_Input | null;
  groundMount_?: ProposalUpdategroundMount_Input | null;
  n18MonthCoverage_?: ProposalUpdaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalUpdatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalUpdateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalUpdateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalUpdateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalUpdateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalUpdateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalUpdateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalUpdateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalUpdateaccountMoved_Input | null;
  createProject_?: ProposalUpdatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalUpdatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalUpdatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalUpdateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalUpdatetestProposal_Input | null;
  project?: ProjectUpdateOneWithoutCurrentProposalInput | null;
  salesRep?: SalesRepUpdateOneWithoutProposalInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProposalInput | null;
  funding?: FundingUpdateOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateRequested?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCompleted?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewBeginDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  homeSecurityAdderEmailSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  customerId?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  notesForFailedDocReview?: NullableStringFieldUpdateOperationsInput | null;
  salesRepPhone?: NullableStringFieldUpdateOperationsInput | null;
  numberOfInverters?: NullableIntFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  customerUtilityRatePlan?: NullableStringFieldUpdateOperationsInput | null;
  ifPpaDesiredKWhRate?: NullableFloatFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeKw?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  fluentHomeAccountNumber?: NullableStringFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  rebateAmountPayableToCustomer?: NullableFloatFieldUpdateOperationsInput | null;
  rebateNotes?: NullableStringFieldUpdateOperationsInput | null;
  january?: NullableIntFieldUpdateOperationsInput | null;
  february?: NullableIntFieldUpdateOperationsInput | null;
  march?: NullableIntFieldUpdateOperationsInput | null;
  april?: NullableIntFieldUpdateOperationsInput | null;
  may?: NullableIntFieldUpdateOperationsInput | null;
  june?: NullableIntFieldUpdateOperationsInput | null;
  july?: NullableIntFieldUpdateOperationsInput | null;
  august?: NullableIntFieldUpdateOperationsInput | null;
  september?: NullableIntFieldUpdateOperationsInput | null;
  october?: NullableIntFieldUpdateOperationsInput | null;
  november?: NullableIntFieldUpdateOperationsInput | null;
  december?: NullableIntFieldUpdateOperationsInput | null;
  sqFootage?: NullableIntFieldUpdateOperationsInput | null;
  oldProjectId?: NullableStringFieldUpdateOperationsInput | null;
  oldProposalId?: NullableStringFieldUpdateOperationsInput | null;
  ifFinancedCustomerWatt?: NullableFloatFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  region_?: ProposalUpdateregion_Input | null;
  status_?: ProposalUpdatestatus_Input | null;
  builder_?: ProposalUpdatebuilder_Input | null;
  managedBy_?: ProposalUpdatemanagedBy_Input | null;
  stepsToComplete_?: ProposalUpdatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalUpdategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalUpdateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalUpdateproposalStatus_Input | null;
  proposalType_?: ProposalUpdateproposalType_Input | null;
  systemType_?: ProposalUpdatesystemType_Input | null;
  inverterType_?: ProposalUpdateinverterType_Input | null;
  usePremiumModule_?: ProposalUpdateusePremiumModule_Input | null;
  hoa_?: ProposalUpdatehoa_Input | null;
  desiredOffest_?: ProposalUpdatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalUpdatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalUpdatebatteryPromised_Input | null;
  generatorPromised_?: ProposalUpdategeneratorPromised_Input | null;
  groundMount_?: ProposalUpdategroundMount_Input | null;
  n18MonthCoverage_?: ProposalUpdaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalUpdatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalUpdateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalUpdateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalUpdateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalUpdateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalUpdateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalUpdateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalUpdateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalUpdateaccountMoved_Input | null;
  createProject_?: ProposalUpdatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalUpdatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalUpdatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalUpdateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalUpdatetestProposal_Input | null;
  salesRep?: SalesRepUpdateOneWithoutProposalInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProposalInput | null;
  linkToDeal?: DealUpdateOneWithoutProposalInput | null;
  funding?: FundingUpdateOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateRequested?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCompleted?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewBeginDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  homeSecurityAdderEmailSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  customerId?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  notesForFailedDocReview?: NullableStringFieldUpdateOperationsInput | null;
  salesRepPhone?: NullableStringFieldUpdateOperationsInput | null;
  numberOfInverters?: NullableIntFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  customerUtilityRatePlan?: NullableStringFieldUpdateOperationsInput | null;
  ifPpaDesiredKWhRate?: NullableFloatFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeKw?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  fluentHomeAccountNumber?: NullableStringFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  rebateAmountPayableToCustomer?: NullableFloatFieldUpdateOperationsInput | null;
  rebateNotes?: NullableStringFieldUpdateOperationsInput | null;
  january?: NullableIntFieldUpdateOperationsInput | null;
  february?: NullableIntFieldUpdateOperationsInput | null;
  march?: NullableIntFieldUpdateOperationsInput | null;
  april?: NullableIntFieldUpdateOperationsInput | null;
  may?: NullableIntFieldUpdateOperationsInput | null;
  june?: NullableIntFieldUpdateOperationsInput | null;
  july?: NullableIntFieldUpdateOperationsInput | null;
  august?: NullableIntFieldUpdateOperationsInput | null;
  september?: NullableIntFieldUpdateOperationsInput | null;
  october?: NullableIntFieldUpdateOperationsInput | null;
  november?: NullableIntFieldUpdateOperationsInput | null;
  december?: NullableIntFieldUpdateOperationsInput | null;
  sqFootage?: NullableIntFieldUpdateOperationsInput | null;
  oldProjectId?: NullableStringFieldUpdateOperationsInput | null;
  oldProposalId?: NullableStringFieldUpdateOperationsInput | null;
  ifFinancedCustomerWatt?: NullableFloatFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  region_?: ProposalUpdateregion_Input | null;
  status_?: ProposalUpdatestatus_Input | null;
  builder_?: ProposalUpdatebuilder_Input | null;
  managedBy_?: ProposalUpdatemanagedBy_Input | null;
  stepsToComplete_?: ProposalUpdatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalUpdategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalUpdateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalUpdateproposalStatus_Input | null;
  proposalType_?: ProposalUpdateproposalType_Input | null;
  systemType_?: ProposalUpdatesystemType_Input | null;
  inverterType_?: ProposalUpdateinverterType_Input | null;
  usePremiumModule_?: ProposalUpdateusePremiumModule_Input | null;
  hoa_?: ProposalUpdatehoa_Input | null;
  desiredOffest_?: ProposalUpdatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalUpdatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalUpdatebatteryPromised_Input | null;
  generatorPromised_?: ProposalUpdategeneratorPromised_Input | null;
  groundMount_?: ProposalUpdategroundMount_Input | null;
  n18MonthCoverage_?: ProposalUpdaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalUpdatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalUpdateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalUpdateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalUpdateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalUpdateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalUpdateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalUpdateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalUpdateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalUpdateaccountMoved_Input | null;
  createProject_?: ProposalUpdatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalUpdatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalUpdatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalUpdateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalUpdatetestProposal_Input | null;
  project?: ProjectUpdateOneWithoutCurrentProposalInput | null;
  welcomeCall?: WelcomeCallUpdateOneWithoutProposalInput | null;
  linkToDeal?: DealUpdateOneWithoutProposalInput | null;
  funding?: FundingUpdateOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalUpdateWithoutWelcomeCallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateRequested?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateCompleted?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewBeginDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  docReviewEndDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  cancellationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  homeSecurityAdderEmailSentDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  customerName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  customerId?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  specialRequestsNotes?: NullableStringFieldUpdateOperationsInput | null;
  notesForFailedDocReview?: NullableStringFieldUpdateOperationsInput | null;
  salesRepPhone?: NullableStringFieldUpdateOperationsInput | null;
  numberOfInverters?: NullableIntFieldUpdateOperationsInput | null;
  numberOfModules?: NullableIntFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  customerUtilityRatePlan?: NullableStringFieldUpdateOperationsInput | null;
  ifPpaDesiredKWhRate?: NullableFloatFieldUpdateOperationsInput | null;
  totalOffset?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeKw?: NullableFloatFieldUpdateOperationsInput | null;
  systemPrice?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  fluentHomeAccountNumber?: NullableStringFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  rebateAmountPayableToCustomer?: NullableFloatFieldUpdateOperationsInput | null;
  rebateNotes?: NullableStringFieldUpdateOperationsInput | null;
  january?: NullableIntFieldUpdateOperationsInput | null;
  february?: NullableIntFieldUpdateOperationsInput | null;
  march?: NullableIntFieldUpdateOperationsInput | null;
  april?: NullableIntFieldUpdateOperationsInput | null;
  may?: NullableIntFieldUpdateOperationsInput | null;
  june?: NullableIntFieldUpdateOperationsInput | null;
  july?: NullableIntFieldUpdateOperationsInput | null;
  august?: NullableIntFieldUpdateOperationsInput | null;
  september?: NullableIntFieldUpdateOperationsInput | null;
  october?: NullableIntFieldUpdateOperationsInput | null;
  november?: NullableIntFieldUpdateOperationsInput | null;
  december?: NullableIntFieldUpdateOperationsInput | null;
  sqFootage?: NullableIntFieldUpdateOperationsInput | null;
  oldProjectId?: NullableStringFieldUpdateOperationsInput | null;
  oldProposalId?: NullableStringFieldUpdateOperationsInput | null;
  ifFinancedCustomerWatt?: NullableFloatFieldUpdateOperationsInput | null;
  commissionStructureText?: NullableStringFieldUpdateOperationsInput | null;
  pathToDropboxParentFolder?: NullableStringFieldUpdateOperationsInput | null;
  region_?: ProposalUpdateregion_Input | null;
  status_?: ProposalUpdatestatus_Input | null;
  builder_?: ProposalUpdatebuilder_Input | null;
  managedBy_?: ProposalUpdatemanagedBy_Input | null;
  stepsToComplete_?: ProposalUpdatestepsToComplete_Input | null;
  greenButtonDataVerified_?: ProposalUpdategreenButtonDataVerified_Input | null;
  utilityBillVerified_?: ProposalUpdateutilityBillVerified_Input | null;
  proposalStatus_?: ProposalUpdateproposalStatus_Input | null;
  proposalType_?: ProposalUpdateproposalType_Input | null;
  systemType_?: ProposalUpdatesystemType_Input | null;
  inverterType_?: ProposalUpdateinverterType_Input | null;
  usePremiumModule_?: ProposalUpdateusePremiumModule_Input | null;
  hoa_?: ProposalUpdatehoa_Input | null;
  desiredOffest_?: ProposalUpdatedesiredOffest_Input | null;
  desiredPpaEscalatorRate_?: ProposalUpdatedesiredPpaEscalatorRate_Input | null;
  batteryPromised_?: ProposalUpdatebatteryPromised_Input | null;
  generatorPromised_?: ProposalUpdategeneratorPromised_Input | null;
  groundMount_?: ProposalUpdategroundMount_Input | null;
  n18MonthCoverage_?: ProposalUpdaten18MonthCoverage_Input | null;
  securityPackageInProposal_?: ProposalUpdatesecurityPackageInProposal_Input | null;
  energyEfficiencyPackageNest24Bulbs_?: ProposalUpdateenergyEfficiencyPackageNest24Bulbs_Input | null;
  onlyLedBulbs24_?: ProposalUpdateonlyLedBulbs24_Input | null;
  additionalNestThermostat_?: ProposalUpdateadditionalNestThermostat_Input | null;
  additionalBulbs8_?: ProposalUpdateadditionalBulbs8_Input | null;
  energyPackage_?: ProposalUpdateenergyPackage_Input | null;
  additionalNestThermostat2_?: ProposalUpdateadditionalNestThermostat2_Input | null;
  additionalLedBulbs_?: ProposalUpdateadditionalLedBulbs_Input | null;
  accountMoved_?: ProposalUpdateaccountMoved_Input | null;
  createProject_?: ProposalUpdatecreateProject_Input | null;
  migrateProposalToDealerWorkspace_?: ProposalUpdatemigrateProposalToDealerWorkspace_Input | null;
  dealerName_?: ProposalUpdatedealerName_Input | null;
  proposalCreatedFrom_?: ProposalUpdateproposalCreatedFrom_Input | null;
  testProposal_?: ProposalUpdatetestProposal_Input | null;
  project?: ProjectUpdateOneWithoutCurrentProposalInput | null;
  salesRep?: SalesRepUpdateOneWithoutProposalInput | null;
  linkToDeal?: DealUpdateOneWithoutProposalInput | null;
  funding?: FundingUpdateOneWithoutProposalInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutProposalsAsSalesRepInput | null;
}

export interface ProposalUpdateaccountMoved_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateadditionalBulbs8_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateadditionalLedBulbs_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateadditionalNestThermostat2_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateadditionalNestThermostat_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatebatteryPromised_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatebuilder_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatecreateProject_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatedealerName_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatedesiredOffest_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatedesiredPpaEscalatorRate_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateenergyEfficiencyPackageNest24Bulbs_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateenergyPackage_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdategeneratorPromised_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdategreenButtonDataVerified_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdategroundMount_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatehoa_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateinverterType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatemanagedBy_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatemigrateProposalToDealerWorkspace_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdaten18MonthCoverage_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateonlyLedBulbs24_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateproposalCreatedFrom_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateproposalStatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateproposalType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateregion_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatesecurityPackageInProposal_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatestepsToComplete_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatesystemType_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdatetestProposal_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateusePremiumModule_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpdateutilityBillVerified_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface ProposalUpsertWithWhereUniqueWithoutSalesRepInput {
  where: ProposalWhereUniqueInput;
  update: ProposalUpdateWithoutSalesRepInput;
  create: ProposalCreateWithoutSalesRepInput;
}

export interface ProposalUpsertWithoutFundingInput {
  update: ProposalUpdateWithoutFundingInput;
  create: ProposalCreateWithoutFundingInput;
}

export interface ProposalUpsertWithoutLinkToDealInput {
  update: ProposalUpdateWithoutLinkToDealInput;
  create: ProposalCreateWithoutLinkToDealInput;
}

export interface ProposalUpsertWithoutProjectInput {
  update: ProposalUpdateWithoutProjectInput;
  create: ProposalCreateWithoutProjectInput;
}

export interface ProposalUpsertWithoutWelcomeCallInput {
  update: ProposalUpdateWithoutWelcomeCallInput;
  create: ProposalCreateWithoutWelcomeCallInput;
}

export interface ProposalWhereInput {
  AND?: ProposalWhereInput[] | null;
  OR?: ProposalWhereInput[] | null;
  NOT?: ProposalWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  dateRequested?: DateTimeNullableFilter | null;
  dateCompleted?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  docReviewBeginDate?: DateTimeNullableFilter | null;
  docReviewEndDate?: DateTimeNullableFilter | null;
  cancellationDate?: DateTimeNullableFilter | null;
  bucketCompletionDate?: DateTimeNullableFilter | null;
  homeSecurityAdderEmailSentDate?: DateTimeNullableFilter | null;
  region_?: StringNullableListFilter | null;
  status_?: StringNullableListFilter | null;
  builder_?: StringNullableListFilter | null;
  managedBy_?: StringNullableListFilter | null;
  stepsToComplete_?: StringNullableListFilter | null;
  greenButtonDataVerified_?: StringNullableListFilter | null;
  utilityBillVerified_?: StringNullableListFilter | null;
  proposalStatus_?: StringNullableListFilter | null;
  proposalType_?: StringNullableListFilter | null;
  systemType_?: StringNullableListFilter | null;
  inverterType_?: StringNullableListFilter | null;
  usePremiumModule_?: StringNullableListFilter | null;
  hoa_?: StringNullableListFilter | null;
  desiredOffest_?: StringNullableListFilter | null;
  desiredPpaEscalatorRate_?: StringNullableListFilter | null;
  batteryPromised_?: StringNullableListFilter | null;
  generatorPromised_?: StringNullableListFilter | null;
  groundMount_?: StringNullableListFilter | null;
  n18MonthCoverage_?: StringNullableListFilter | null;
  securityPackageInProposal_?: StringNullableListFilter | null;
  energyEfficiencyPackageNest24Bulbs_?: StringNullableListFilter | null;
  onlyLedBulbs24_?: StringNullableListFilter | null;
  additionalNestThermostat_?: StringNullableListFilter | null;
  additionalBulbs8_?: StringNullableListFilter | null;
  energyPackage_?: StringNullableListFilter | null;
  additionalNestThermostat2_?: StringNullableListFilter | null;
  additionalLedBulbs_?: StringNullableListFilter | null;
  accountMoved_?: StringNullableListFilter | null;
  createProject_?: StringNullableListFilter | null;
  migrateProposalToDealerWorkspace_?: StringNullableListFilter | null;
  dealerName_?: StringNullableListFilter | null;
  proposalCreatedFrom_?: StringNullableListFilter | null;
  testProposal_?: StringNullableListFilter | null;
  customerName?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  customerId?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  specialRequestsNotes?: StringNullableFilter | null;
  notesForFailedDocReview?: StringNullableFilter | null;
  salesRepPhone?: StringNullableFilter | null;
  numberOfInverters?: IntNullableFilter | null;
  numberOfModules?: IntNullableFilter | null;
  hoaInfo?: StringNullableFilter | null;
  meterServiceAcct?: StringNullableFilter | null;
  customerUtilityRatePlan?: StringNullableFilter | null;
  ifPpaDesiredKWhRate?: FloatNullableFilter | null;
  totalOffset?: IntNullableFilter | null;
  systemSizeKw?: FloatNullableFilter | null;
  systemPrice?: FloatNullableFilter | null;
  coverageAmountPromised?: FloatNullableFilter | null;
  fluentHomeAccountNumber?: StringNullableFilter | null;
  nestThermostats?: IntNullableFilter | null;
  ledBulbs?: IntNullableFilter | null;
  trenchingRawGroundFootage?: IntNullableFilter | null;
  trenchingConcreteGroundFootage?: IntNullableFilter | null;
  rebateAmountPayableToCustomer?: FloatNullableFilter | null;
  rebateNotes?: StringNullableFilter | null;
  january?: IntNullableFilter | null;
  february?: IntNullableFilter | null;
  march?: IntNullableFilter | null;
  april?: IntNullableFilter | null;
  may?: IntNullableFilter | null;
  june?: IntNullableFilter | null;
  july?: IntNullableFilter | null;
  august?: IntNullableFilter | null;
  september?: IntNullableFilter | null;
  october?: IntNullableFilter | null;
  november?: IntNullableFilter | null;
  december?: IntNullableFilter | null;
  sqFootage?: IntNullableFilter | null;
  oldProjectId?: StringNullableFilter | null;
  oldProposalId?: StringNullableFilter | null;
  ifFinancedCustomerWatt?: FloatNullableFilter | null;
  commissionStructureText?: StringNullableFilter | null;
  pathToDropboxParentFolder?: StringNullableFilter | null;
  project?: ProjectWhereInput | null;
  salesRep?: SalesRepWhereInput | null;
  welcomeCallId?: IntNullableFilter | null;
  welcomeCall?: WelcomeCallWhereInput | null;
  linkToDeal?: DealWhereInput | null;
  salesRepId?: IntNullableFilter | null;
  funding?: FundingWhereInput | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface ProposalWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  welcomeCallId?: number | null;
}

export interface RoundCreateManyTournamentInput {
  id?: number | null;
  title?: string | null;
  roundNumber: number;
  group?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface RoundCreateManyTournamentInputEnvelope {
  data?: RoundCreateManyTournamentInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface RoundCreateNestedManyWithoutTournamentInput {
  create?: RoundCreateWithoutTournamentInput[] | null;
  connectOrCreate?: RoundCreateOrConnectWithoutTournamentInput[] | null;
  createMany?: RoundCreateManyTournamentInputEnvelope | null;
  connect?: RoundWhereUniqueInput[] | null;
}

export interface RoundCreateNestedOneWithoutMatchUpsInput {
  create?: RoundCreateWithoutMatchUpsInput | null;
  connectOrCreate?: RoundCreateOrConnectWithoutMatchUpsInput | null;
  connect?: RoundWhereUniqueInput | null;
}

export interface RoundCreateOrConnectWithoutMatchUpsInput {
  where: RoundWhereUniqueInput;
  create: RoundCreateWithoutMatchUpsInput;
}

export interface RoundCreateOrConnectWithoutTournamentInput {
  where: RoundWhereUniqueInput;
  create: RoundCreateWithoutTournamentInput;
}

export interface RoundCreateWithoutMatchUpsInput {
  title?: string | null;
  roundNumber: number;
  group?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  tournament?: TournamentCreateNestedOneWithoutRoundsInput | null;
}

export interface RoundCreateWithoutTournamentInput {
  title?: string | null;
  roundNumber: number;
  group?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  matchUps?: MatchUpCreateNestedManyWithoutRoundInput | null;
}

export interface RoundListRelationFilter {
  every?: RoundWhereInput | null;
  some?: RoundWhereInput | null;
  none?: RoundWhereInput | null;
}

export interface RoundScalarWhereInput {
  AND?: RoundScalarWhereInput[] | null;
  OR?: RoundScalarWhereInput[] | null;
  NOT?: RoundScalarWhereInput[] | null;
  id?: IntFilter | null;
  title?: StringNullableFilter | null;
  roundNumber?: IntFilter | null;
  group?: StringNullableFilter | null;
  tournamentId?: IntNullableFilter | null;
  startDate?: DateTimeNullableFilter | null;
  endDate?: DateTimeNullableFilter | null;
}

export interface RoundUpdateManyMutationInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  roundNumber?: IntFieldUpdateOperationsInput | null;
  group?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  endDate?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface RoundUpdateManyWithWhereWithoutTournamentInput {
  where: RoundScalarWhereInput;
  data: RoundUpdateManyMutationInput;
}

export interface RoundUpdateManyWithoutTournamentInput {
  create?: RoundCreateWithoutTournamentInput[] | null;
  connectOrCreate?: RoundCreateOrConnectWithoutTournamentInput[] | null;
  upsert?: RoundUpsertWithWhereUniqueWithoutTournamentInput[] | null;
  createMany?: RoundCreateManyTournamentInputEnvelope | null;
  connect?: RoundWhereUniqueInput[] | null;
  set?: RoundWhereUniqueInput[] | null;
  disconnect?: RoundWhereUniqueInput[] | null;
  delete?: RoundWhereUniqueInput[] | null;
  update?: RoundUpdateWithWhereUniqueWithoutTournamentInput[] | null;
  updateMany?: RoundUpdateManyWithWhereWithoutTournamentInput[] | null;
  deleteMany?: RoundScalarWhereInput[] | null;
}

export interface RoundUpdateOneWithoutMatchUpsInput {
  create?: RoundCreateWithoutMatchUpsInput | null;
  connectOrCreate?: RoundCreateOrConnectWithoutMatchUpsInput | null;
  upsert?: RoundUpsertWithoutMatchUpsInput | null;
  connect?: RoundWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: RoundUpdateWithoutMatchUpsInput | null;
}

export interface RoundUpdateWithWhereUniqueWithoutTournamentInput {
  where: RoundWhereUniqueInput;
  data: RoundUpdateWithoutTournamentInput;
}

export interface RoundUpdateWithoutMatchUpsInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  roundNumber?: IntFieldUpdateOperationsInput | null;
  group?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  endDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  tournament?: TournamentUpdateOneWithoutRoundsInput | null;
}

export interface RoundUpdateWithoutTournamentInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  roundNumber?: IntFieldUpdateOperationsInput | null;
  group?: NullableStringFieldUpdateOperationsInput | null;
  startDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  endDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  matchUps?: MatchUpUpdateManyWithoutRoundInput | null;
}

export interface RoundUpsertWithWhereUniqueWithoutTournamentInput {
  where: RoundWhereUniqueInput;
  update: RoundUpdateWithoutTournamentInput;
  create: RoundCreateWithoutTournamentInput;
}

export interface RoundUpsertWithoutMatchUpsInput {
  update: RoundUpdateWithoutMatchUpsInput;
  create: RoundCreateWithoutMatchUpsInput;
}

export interface RoundWhereInput {
  AND?: RoundWhereInput[] | null;
  OR?: RoundWhereInput[] | null;
  NOT?: RoundWhereInput[] | null;
  id?: IntFilter | null;
  matchUps?: MatchUpListRelationFilter | null;
  title?: StringNullableFilter | null;
  roundNumber?: IntFilter | null;
  group?: StringNullableFilter | null;
  tournamentId?: IntNullableFilter | null;
  tournament?: TournamentWhereInput | null;
  startDate?: DateTimeNullableFilter | null;
  endDate?: DateTimeNullableFilter | null;
}

export interface RoundWhereUniqueInput {
  id?: number | null;
}

export interface SalesEmployeeCreateManyOfficeAsRepInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  salesRepPodioId?: string | null;
  setterPodioId?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreateManyrolesInput | null;
}

export interface SalesEmployeeCreateManyOfficeAsRepInputEnvelope {
  data?: SalesEmployeeCreateManyOfficeAsRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SalesEmployeeCreateManyrolesInput {
  set?: string[] | null;
}

export interface SalesEmployeeCreateNestedManyWithoutOfficeAsRepInput {
  create?: SalesEmployeeCreateWithoutOfficeAsRepInput[] | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutOfficeAsRepInput[] | null;
  createMany?: SalesEmployeeCreateManyOfficeAsRepInputEnvelope | null;
  connect?: SalesEmployeeWhereUniqueInput[] | null;
}

export interface SalesEmployeeCreateNestedManyWithoutOfficesAsManagerInput {
  create?: SalesEmployeeCreateWithoutOfficesAsManagerInput[] | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutOfficesAsManagerInput[] | null;
  connect?: SalesEmployeeWhereUniqueInput[] | null;
}

export interface SalesEmployeeCreateNestedOneWithoutAssignedAppointmentsInput {
  create?: SalesEmployeeCreateWithoutAssignedAppointmentsInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutAssignedAppointmentsInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutContactsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutContactsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutContactsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutContactsAsSetterInput {
  create?: SalesEmployeeCreateWithoutContactsAsSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutContactsAsSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutDealsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutDealsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutDealsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutDealsAsSetterInput {
  create?: SalesEmployeeCreateWithoutDealsAsSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutDealsAsSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutFundingAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutFundingAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutFundingAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutLeadsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutLeadsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutLeadsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutLeadsAsSetterInput {
  create?: SalesEmployeeCreateWithoutLeadsAsSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutLeadsAsSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutPreInstallsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutPreInstallsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutPreInstallsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutProjectsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutProjectsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutProjectsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutProposalsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutProposalsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutProposalsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutSalesRepInput {
  create?: SalesEmployeeCreateWithoutSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutSetAppointmentsInput {
  create?: SalesEmployeeCreateWithoutSetAppointmentsInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutSetAppointmentsInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutSetterInput {
  create?: SalesEmployeeCreateWithoutSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateNestedOneWithoutWelcomeCallsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutWelcomeCallsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutWelcomeCallsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
}

export interface SalesEmployeeCreateOrConnectWithoutAssignedAppointmentsInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutAssignedAppointmentsInput;
}

export interface SalesEmployeeCreateOrConnectWithoutContactsAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutContactsAsSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutContactsAsSetterInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutContactsAsSetterInput;
}

export interface SalesEmployeeCreateOrConnectWithoutDealsAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutDealsAsSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutDealsAsSetterInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutDealsAsSetterInput;
}

export interface SalesEmployeeCreateOrConnectWithoutFundingAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutFundingAsSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutLeadsAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutLeadsAsSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutLeadsAsSetterInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutLeadsAsSetterInput;
}

export interface SalesEmployeeCreateOrConnectWithoutOfficeAsRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutOfficeAsRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutOfficesAsManagerInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutOfficesAsManagerInput;
}

export interface SalesEmployeeCreateOrConnectWithoutPreInstallsAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutPreInstallsAsSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutProjectsAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutProjectsAsSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutProposalsAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutProposalsAsSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutSalesRepInput;
}

export interface SalesEmployeeCreateOrConnectWithoutSetAppointmentsInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutSetAppointmentsInput;
}

export interface SalesEmployeeCreateOrConnectWithoutSetterInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutSetterInput;
}

export interface SalesEmployeeCreateOrConnectWithoutWelcomeCallsAsSalesRepInput {
  where: SalesEmployeeWhereUniqueInput;
  create: SalesEmployeeCreateWithoutWelcomeCallsAsSalesRepInput;
}

export interface SalesEmployeeCreateWithoutAssignedAppointmentsInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutContactsAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutContactsAsSetterInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutDealsAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutDealsAsSetterInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutFundingAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutLeadsAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutLeadsAsSetterInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutOfficeAsRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutOfficesAsManagerInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
}

export interface SalesEmployeeCreateWithoutPreInstallsAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutProjectsAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutProposalsAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutSetAppointmentsInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutSetterInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreateWithoutWelcomeCallsAsSalesRepInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  createdOn?: any | null;
  fluentEmail?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  personalEmail?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
  roles?: SalesEmployeeCreaterolesInput | null;
  SalesRep?: SalesRepCreateNestedOneWithoutSalesEmployeeInput | null;
  Setter?: SetterCreateNestedOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentCreateNestedManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentCreateNestedManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealCreateNestedManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealCreateNestedManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadCreateNestedManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadCreateNestedManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactCreateNestedManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactCreateNestedManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingCreateNestedManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallCreateNestedManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeCreateNestedOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeCreateNestedManyWithoutManagersInput | null;
}

export interface SalesEmployeeCreaterolesInput {
  set?: string[] | null;
}

export interface SalesEmployeeListRelationFilter {
  every?: SalesEmployeeWhereInput | null;
  some?: SalesEmployeeWhereInput | null;
  none?: SalesEmployeeWhereInput | null;
}

export interface SalesEmployeeScalarWhereInput {
  AND?: SalesEmployeeScalarWhereInput[] | null;
  OR?: SalesEmployeeScalarWhereInput[] | null;
  NOT?: SalesEmployeeScalarWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  roles?: StringNullableListFilter | null;
  fluentEmail?: StringNullableFilter | null;
  fullName?: StringNullableFilter | null;
  firstName?: StringNullableFilter | null;
  lastName?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  personalEmail?: StringNullableFilter | null;
  salesRepPodioId?: StringNullableFilter | null;
  setterPodioId?: StringNullableFilter | null;
  teamRepId?: StringNullableFilter | null;
  teamSetterId?: StringNullableFilter | null;
  officeAsRepId?: IntNullableFilter | null;
}

export interface SalesEmployeeUpdateManyMutationInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
}

export interface SalesEmployeeUpdateManyWithWhereWithoutOfficeAsRepInput {
  where: SalesEmployeeScalarWhereInput;
  data: SalesEmployeeUpdateManyMutationInput;
}

export interface SalesEmployeeUpdateManyWithWhereWithoutOfficesAsManagerInput {
  where: SalesEmployeeScalarWhereInput;
  data: SalesEmployeeUpdateManyMutationInput;
}

export interface SalesEmployeeUpdateManyWithoutOfficeAsRepInput {
  create?: SalesEmployeeCreateWithoutOfficeAsRepInput[] | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutOfficeAsRepInput[] | null;
  upsert?: SalesEmployeeUpsertWithWhereUniqueWithoutOfficeAsRepInput[] | null;
  createMany?: SalesEmployeeCreateManyOfficeAsRepInputEnvelope | null;
  connect?: SalesEmployeeWhereUniqueInput[] | null;
  set?: SalesEmployeeWhereUniqueInput[] | null;
  disconnect?: SalesEmployeeWhereUniqueInput[] | null;
  delete?: SalesEmployeeWhereUniqueInput[] | null;
  update?: SalesEmployeeUpdateWithWhereUniqueWithoutOfficeAsRepInput[] | null;
  updateMany?: SalesEmployeeUpdateManyWithWhereWithoutOfficeAsRepInput[] | null;
  deleteMany?: SalesEmployeeScalarWhereInput[] | null;
}

export interface SalesEmployeeUpdateManyWithoutOfficesAsManagerInput {
  create?: SalesEmployeeCreateWithoutOfficesAsManagerInput[] | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutOfficesAsManagerInput[] | null;
  upsert?: SalesEmployeeUpsertWithWhereUniqueWithoutOfficesAsManagerInput[] | null;
  connect?: SalesEmployeeWhereUniqueInput[] | null;
  set?: SalesEmployeeWhereUniqueInput[] | null;
  disconnect?: SalesEmployeeWhereUniqueInput[] | null;
  delete?: SalesEmployeeWhereUniqueInput[] | null;
  update?: SalesEmployeeUpdateWithWhereUniqueWithoutOfficesAsManagerInput[] | null;
  updateMany?: SalesEmployeeUpdateManyWithWhereWithoutOfficesAsManagerInput[] | null;
  deleteMany?: SalesEmployeeScalarWhereInput[] | null;
}

export interface SalesEmployeeUpdateOneWithoutAssignedAppointmentsInput {
  create?: SalesEmployeeCreateWithoutAssignedAppointmentsInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutAssignedAppointmentsInput | null;
  upsert?: SalesEmployeeUpsertWithoutAssignedAppointmentsInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutAssignedAppointmentsInput | null;
}

export interface SalesEmployeeUpdateOneWithoutContactsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutContactsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutContactsAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutContactsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutContactsAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutContactsAsSetterInput {
  create?: SalesEmployeeCreateWithoutContactsAsSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutContactsAsSetterInput | null;
  upsert?: SalesEmployeeUpsertWithoutContactsAsSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutContactsAsSetterInput | null;
}

export interface SalesEmployeeUpdateOneWithoutDealsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutDealsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutDealsAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutDealsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutDealsAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutDealsAsSetterInput {
  create?: SalesEmployeeCreateWithoutDealsAsSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutDealsAsSetterInput | null;
  upsert?: SalesEmployeeUpsertWithoutDealsAsSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutDealsAsSetterInput | null;
}

export interface SalesEmployeeUpdateOneWithoutFundingAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutFundingAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutFundingAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutFundingAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutFundingAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutLeadsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutLeadsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutLeadsAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutLeadsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutLeadsAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutLeadsAsSetterInput {
  create?: SalesEmployeeCreateWithoutLeadsAsSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutLeadsAsSetterInput | null;
  upsert?: SalesEmployeeUpsertWithoutLeadsAsSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutLeadsAsSetterInput | null;
}

export interface SalesEmployeeUpdateOneWithoutPreInstallsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutPreInstallsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutPreInstallsAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutPreInstallsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutPreInstallsAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutProjectsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutProjectsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutProjectsAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutProjectsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutProjectsAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutProposalsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutProposalsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutProposalsAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutProposalsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutProposalsAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutSalesRepInput {
  create?: SalesEmployeeCreateWithoutSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutSalesRepInput | null;
}

export interface SalesEmployeeUpdateOneWithoutSetAppointmentsInput {
  create?: SalesEmployeeCreateWithoutSetAppointmentsInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutSetAppointmentsInput | null;
  upsert?: SalesEmployeeUpsertWithoutSetAppointmentsInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutSetAppointmentsInput | null;
}

export interface SalesEmployeeUpdateOneWithoutSetterInput {
  create?: SalesEmployeeCreateWithoutSetterInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutSetterInput | null;
  upsert?: SalesEmployeeUpsertWithoutSetterInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutSetterInput | null;
}

export interface SalesEmployeeUpdateOneWithoutWelcomeCallsAsSalesRepInput {
  create?: SalesEmployeeCreateWithoutWelcomeCallsAsSalesRepInput | null;
  connectOrCreate?: SalesEmployeeCreateOrConnectWithoutWelcomeCallsAsSalesRepInput | null;
  upsert?: SalesEmployeeUpsertWithoutWelcomeCallsAsSalesRepInput | null;
  connect?: SalesEmployeeWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesEmployeeUpdateWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface SalesEmployeeUpdateWithWhereUniqueWithoutOfficeAsRepInput {
  where: SalesEmployeeWhereUniqueInput;
  data: SalesEmployeeUpdateWithoutOfficeAsRepInput;
}

export interface SalesEmployeeUpdateWithWhereUniqueWithoutOfficesAsManagerInput {
  where: SalesEmployeeWhereUniqueInput;
  data: SalesEmployeeUpdateWithoutOfficesAsManagerInput;
}

export interface SalesEmployeeUpdateWithoutAssignedAppointmentsInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutContactsAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutContactsAsSetterInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutDealsAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutDealsAsSetterInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutFundingAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutLeadsAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutLeadsAsSetterInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutOfficeAsRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutOfficesAsManagerInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
}

export interface SalesEmployeeUpdateWithoutPreInstallsAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutProjectsAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutProposalsAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutSetAppointmentsInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutSetterInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  welcomeCallsAsSalesRep?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdateWithoutWelcomeCallsAsSalesRepInput {
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  teamRepId?: NullableStringFieldUpdateOperationsInput | null;
  teamSetterId?: NullableStringFieldUpdateOperationsInput | null;
  roles?: SalesEmployeeUpdaterolesInput | null;
  SalesRep?: SalesRepUpdateOneWithoutSalesEmployeeInput | null;
  Setter?: SetterUpdateOneWithoutSalesEmployeeInput | null;
  assignedAppointments?: AppointmentUpdateManyWithoutSalesRepInput | null;
  setAppointments?: AppointmentUpdateManyWithoutSetterInput | null;
  dealsAsSalesRep?: DealUpdateManyWithoutSalesRepInput | null;
  dealsAsSetter?: DealUpdateManyWithoutSetterInput | null;
  leadsAsSalesRep?: LeadUpdateManyWithoutSalesRepInput | null;
  leadsAsSetter?: LeadUpdateManyWithoutSetterInput | null;
  contactsAsSalesRep?: ContactUpdateManyWithoutSalesRepInput | null;
  contactsAsSetter?: ContactUpdateManyWithoutSetterInput | null;
  fundingAsSalesRep?: FundingUpdateManyWithoutSalesRepInput | null;
  projectsAsSalesRep?: ProjectUpdateManyWithoutSalesRepInput | null;
  proposalsAsSalesRep?: ProposalUpdateManyWithoutSalesRepInput | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallUpdateManyWithoutSalesRepInput | null;
  officeAsRep?: OfficeUpdateOneWithoutSalesEmployeesInput | null;
  officesAsManager?: OfficeUpdateManyWithoutManagersInput | null;
}

export interface SalesEmployeeUpdaterolesInput {
  set?: string[] | null;
  push?: string | null;
}

export interface SalesEmployeeUpsertWithWhereUniqueWithoutOfficeAsRepInput {
  where: SalesEmployeeWhereUniqueInput;
  update: SalesEmployeeUpdateWithoutOfficeAsRepInput;
  create: SalesEmployeeCreateWithoutOfficeAsRepInput;
}

export interface SalesEmployeeUpsertWithWhereUniqueWithoutOfficesAsManagerInput {
  where: SalesEmployeeWhereUniqueInput;
  update: SalesEmployeeUpdateWithoutOfficesAsManagerInput;
  create: SalesEmployeeCreateWithoutOfficesAsManagerInput;
}

export interface SalesEmployeeUpsertWithoutAssignedAppointmentsInput {
  update: SalesEmployeeUpdateWithoutAssignedAppointmentsInput;
  create: SalesEmployeeCreateWithoutAssignedAppointmentsInput;
}

export interface SalesEmployeeUpsertWithoutContactsAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutContactsAsSalesRepInput;
  create: SalesEmployeeCreateWithoutContactsAsSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutContactsAsSetterInput {
  update: SalesEmployeeUpdateWithoutContactsAsSetterInput;
  create: SalesEmployeeCreateWithoutContactsAsSetterInput;
}

export interface SalesEmployeeUpsertWithoutDealsAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutDealsAsSalesRepInput;
  create: SalesEmployeeCreateWithoutDealsAsSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutDealsAsSetterInput {
  update: SalesEmployeeUpdateWithoutDealsAsSetterInput;
  create: SalesEmployeeCreateWithoutDealsAsSetterInput;
}

export interface SalesEmployeeUpsertWithoutFundingAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutFundingAsSalesRepInput;
  create: SalesEmployeeCreateWithoutFundingAsSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutLeadsAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutLeadsAsSalesRepInput;
  create: SalesEmployeeCreateWithoutLeadsAsSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutLeadsAsSetterInput {
  update: SalesEmployeeUpdateWithoutLeadsAsSetterInput;
  create: SalesEmployeeCreateWithoutLeadsAsSetterInput;
}

export interface SalesEmployeeUpsertWithoutPreInstallsAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutPreInstallsAsSalesRepInput;
  create: SalesEmployeeCreateWithoutPreInstallsAsSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutProjectsAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutProjectsAsSalesRepInput;
  create: SalesEmployeeCreateWithoutProjectsAsSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutProposalsAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutProposalsAsSalesRepInput;
  create: SalesEmployeeCreateWithoutProposalsAsSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutSalesRepInput {
  update: SalesEmployeeUpdateWithoutSalesRepInput;
  create: SalesEmployeeCreateWithoutSalesRepInput;
}

export interface SalesEmployeeUpsertWithoutSetAppointmentsInput {
  update: SalesEmployeeUpdateWithoutSetAppointmentsInput;
  create: SalesEmployeeCreateWithoutSetAppointmentsInput;
}

export interface SalesEmployeeUpsertWithoutSetterInput {
  update: SalesEmployeeUpdateWithoutSetterInput;
  create: SalesEmployeeCreateWithoutSetterInput;
}

export interface SalesEmployeeUpsertWithoutWelcomeCallsAsSalesRepInput {
  update: SalesEmployeeUpdateWithoutWelcomeCallsAsSalesRepInput;
  create: SalesEmployeeCreateWithoutWelcomeCallsAsSalesRepInput;
}

export interface SalesEmployeeWhereInput {
  AND?: SalesEmployeeWhereInput[] | null;
  OR?: SalesEmployeeWhereInput[] | null;
  NOT?: SalesEmployeeWhereInput[] | null;
  id?: IntFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  roles?: StringNullableListFilter | null;
  fluentEmail?: StringNullableFilter | null;
  fullName?: StringNullableFilter | null;
  firstName?: StringNullableFilter | null;
  lastName?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  personalEmail?: StringNullableFilter | null;
  salesRepPodioId?: StringNullableFilter | null;
  setterPodioId?: StringNullableFilter | null;
  teamRepId?: StringNullableFilter | null;
  teamSetterId?: StringNullableFilter | null;
  SalesRep?: SalesRepWhereInput | null;
  Setter?: SetterWhereInput | null;
  assignedAppointments?: AppointmentListRelationFilter | null;
  setAppointments?: AppointmentListRelationFilter | null;
  dealsAsSalesRep?: DealListRelationFilter | null;
  dealsAsSetter?: DealListRelationFilter | null;
  leadsAsSalesRep?: LeadListRelationFilter | null;
  leadsAsSetter?: LeadListRelationFilter | null;
  contactsAsSalesRep?: ContactListRelationFilter | null;
  contactsAsSetter?: ContactListRelationFilter | null;
  fundingAsSalesRep?: FundingListRelationFilter | null;
  projectsAsSalesRep?: ProjectListRelationFilter | null;
  proposalsAsSalesRep?: ProposalListRelationFilter | null;
  preInstallsAsSalesRep?: InterconnectionPreInstallListRelationFilter | null;
  welcomeCallsAsSalesRep?: WelcomeCallListRelationFilter | null;
  officeAsRepId?: IntNullableFilter | null;
  officeAsRep?: OfficeWhereInput | null;
  officesAsManager?: OfficeListRelationFilter | null;
}

export interface SalesEmployeeWhereUniqueInput {
  id?: number | null;
  fluentEmail?: string | null;
  salesRepPodioId?: string | null;
  setterPodioId?: string | null;
  teamRepId?: string | null;
  teamSetterId?: string | null;
}

export interface SalesRepCreateManyContactInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  locationId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  salesRepId?: number | null;
  userId?: number | null;
  team?: team_type | null;
  salesTeamId?: number | null;
  officeId?: number | null;
  organizationId?: number | null;
  status_?: SalesRepCreateManystatus_Input | null;
  shirtSize_?: SalesRepCreateManyshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreateManysyncWithQuickbooks_Input | null;
  type_?: SalesRepCreateManytype_Input | null;
}

export interface SalesRepCreateManyContactInputEnvelope {
  data?: SalesRepCreateManyContactInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SalesRepCreateManyLocationInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  contactId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  salesRepId?: number | null;
  userId?: number | null;
  team?: team_type | null;
  salesTeamId?: number | null;
  officeId?: number | null;
  organizationId?: number | null;
  status_?: SalesRepCreateManystatus_Input | null;
  shirtSize_?: SalesRepCreateManyshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreateManysyncWithQuickbooks_Input | null;
  type_?: SalesRepCreateManytype_Input | null;
}

export interface SalesRepCreateManyLocationInputEnvelope {
  data?: SalesRepCreateManyLocationInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SalesRepCreateManyOfficeInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  salesRepId?: number | null;
  userId?: number | null;
  team?: team_type | null;
  salesTeamId?: number | null;
  organizationId?: number | null;
  status_?: SalesRepCreateManystatus_Input | null;
  shirtSize_?: SalesRepCreateManyshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreateManysyncWithQuickbooks_Input | null;
  type_?: SalesRepCreateManytype_Input | null;
}

export interface SalesRepCreateManyOfficeInputEnvelope {
  data?: SalesRepCreateManyOfficeInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SalesRepCreateManyOrganizationManagerOfInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  salesRepId?: number | null;
  userId?: number | null;
  team?: team_type | null;
  salesTeamId?: number | null;
  officeId?: number | null;
  status_?: SalesRepCreateManystatus_Input | null;
  shirtSize_?: SalesRepCreateManyshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreateManysyncWithQuickbooks_Input | null;
  type_?: SalesRepCreateManytype_Input | null;
}

export interface SalesRepCreateManyOrganizationManagerOfInputEnvelope {
  data?: SalesRepCreateManyOrganizationManagerOfInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SalesRepCreateManyRecruitedByInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  userId?: number | null;
  team?: team_type | null;
  salesTeamId?: number | null;
  officeId?: number | null;
  organizationId?: number | null;
  status_?: SalesRepCreateManystatus_Input | null;
  shirtSize_?: SalesRepCreateManyshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreateManysyncWithQuickbooks_Input | null;
  type_?: SalesRepCreateManytype_Input | null;
}

export interface SalesRepCreateManyRecruitedByInputEnvelope {
  data?: SalesRepCreateManyRecruitedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SalesRepCreateManySalesTeamInput {
  id?: number | null;
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  contactId?: number | null;
  locationId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  salesRepId?: number | null;
  userId?: number | null;
  team?: team_type | null;
  officeId?: number | null;
  organizationId?: number | null;
  status_?: SalesRepCreateManystatus_Input | null;
  shirtSize_?: SalesRepCreateManyshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreateManysyncWithQuickbooks_Input | null;
  type_?: SalesRepCreateManytype_Input | null;
}

export interface SalesRepCreateManySalesTeamInputEnvelope {
  data?: SalesRepCreateManySalesTeamInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SalesRepCreateManyshirtSize_Input {
  set?: string[] | null;
}

export interface SalesRepCreateManystatus_Input {
  set?: string[] | null;
}

export interface SalesRepCreateManysyncWithQuickbooks_Input {
  set?: string[] | null;
}

export interface SalesRepCreateManytype_Input {
  set?: string[] | null;
}

export interface SalesRepCreateNestedManyWithoutContactInput {
  create?: SalesRepCreateWithoutContactInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutContactInput[] | null;
  createMany?: SalesRepCreateManyContactInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
}

export interface SalesRepCreateNestedManyWithoutLocationInput {
  create?: SalesRepCreateWithoutLocationInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutLocationInput[] | null;
  createMany?: SalesRepCreateManyLocationInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
}

export interface SalesRepCreateNestedManyWithoutOfficeInput {
  create?: SalesRepCreateWithoutOfficeInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutOfficeInput[] | null;
  createMany?: SalesRepCreateManyOfficeInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
}

export interface SalesRepCreateNestedManyWithoutOfficeManagerOfInput {
  create?: SalesRepCreateWithoutOfficeManagerOfInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutOfficeManagerOfInput[] | null;
  connect?: SalesRepWhereUniqueInput[] | null;
}

export interface SalesRepCreateNestedManyWithoutOrganizationManagerOfInput {
  create?: SalesRepCreateWithoutOrganizationManagerOfInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutOrganizationManagerOfInput[] | null;
  createMany?: SalesRepCreateManyOrganizationManagerOfInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
}

export interface SalesRepCreateNestedManyWithoutRecruitedByInput {
  create?: SalesRepCreateWithoutRecruitedByInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutRecruitedByInput[] | null;
  createMany?: SalesRepCreateManyRecruitedByInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
}

export interface SalesRepCreateNestedManyWithoutSalesTeamInput {
  create?: SalesRepCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutSalesTeamInput[] | null;
  createMany?: SalesRepCreateManySalesTeamInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
}

export interface SalesRepCreateNestedOneWithoutAppointmentInput {
  create?: SalesRepCreateWithoutAppointmentInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutAppointmentInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutAssignedLeadsInput {
  create?: SalesRepCreateWithoutAssignedLeadsInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutAssignedLeadsInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutFundingInput {
  create?: SalesRepCreateWithoutFundingInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutFundingInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutMasterCustomersInput {
  create?: SalesRepCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutMasterCustomersInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutProjectInput {
  create?: SalesRepCreateWithoutProjectInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutProjectInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutProposalInput {
  create?: SalesRepCreateWithoutProposalInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutProposalInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutRecruitedInput {
  create?: SalesRepCreateWithoutRecruitedInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutRecruitedInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutSalesEmployeeInput {
  create?: SalesRepCreateWithoutSalesEmployeeInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutSalesEmployeeInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutUserInput {
  create?: SalesRepCreateWithoutUserInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutUserInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateNestedOneWithoutWelcomeCallInput {
  create?: SalesRepCreateWithoutWelcomeCallInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutWelcomeCallInput | null;
  connect?: SalesRepWhereUniqueInput | null;
}

export interface SalesRepCreateOrConnectWithoutAppointmentInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutAppointmentInput;
}

export interface SalesRepCreateOrConnectWithoutAssignedLeadsInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutAssignedLeadsInput;
}

export interface SalesRepCreateOrConnectWithoutContactInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutContactInput;
}

export interface SalesRepCreateOrConnectWithoutFundingInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutFundingInput;
}

export interface SalesRepCreateOrConnectWithoutLocationInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutLocationInput;
}

export interface SalesRepCreateOrConnectWithoutMasterCustomersInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutMasterCustomersInput;
}

export interface SalesRepCreateOrConnectWithoutOfficeInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutOfficeInput;
}

export interface SalesRepCreateOrConnectWithoutOfficeManagerOfInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutOfficeManagerOfInput;
}

export interface SalesRepCreateOrConnectWithoutOrganizationManagerOfInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutOrganizationManagerOfInput;
}

export interface SalesRepCreateOrConnectWithoutProjectInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutProjectInput;
}

export interface SalesRepCreateOrConnectWithoutProposalInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutProposalInput;
}

export interface SalesRepCreateOrConnectWithoutRecruitedByInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutRecruitedByInput;
}

export interface SalesRepCreateOrConnectWithoutRecruitedInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutRecruitedInput;
}

export interface SalesRepCreateOrConnectWithoutSalesEmployeeInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutSalesEmployeeInput;
}

export interface SalesRepCreateOrConnectWithoutSalesTeamInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutSalesTeamInput;
}

export interface SalesRepCreateOrConnectWithoutUserInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutUserInput;
}

export interface SalesRepCreateOrConnectWithoutWelcomeCallInput {
  where: SalesRepWhereUniqueInput;
  create: SalesRepCreateWithoutWelcomeCallInput;
}

export interface SalesRepCreateWithoutAppointmentInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutAssignedLeadsInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutContactInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutFundingInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutLocationInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutMasterCustomersInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutOfficeInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutOfficeManagerOfInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutOrganizationManagerOfInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutProjectInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutProposalInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutRecruitedByInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutRecruitedInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutSalesEmployeeInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
}

export interface SalesRepCreateWithoutSalesTeamInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutUserInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateWithoutWelcomeCallInput {
  podioId?: string | null;
  link?: string | null;
  appItemId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  type?: string | null;
  fullName?: string | null;
  fluentEmail?: string | null;
  status?: string | null;
  salesManagerId?: number | null;
  dateOfBirth?: any | null;
  dateStarted?: any | null;
  dateEnded?: any | null;
  syncWithQuickbooks?: string | null;
  quickbooksId?: string | null;
  caHISLicenseNumber?: string | null;
  team?: team_type | null;
  status_?: SalesRepCreatestatus_Input | null;
  shirtSize_?: SalesRepCreateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepCreatesyncWithQuickbooks_Input | null;
  type_?: SalesRepCreatetype_Input | null;
  contact?: ContactCreateNestedOneWithoutSalesRepInput | null;
  location?: LocationCreateNestedOneWithoutSalesRepInput | null;
  office?: OfficeCreateNestedOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationCreateNestedOneWithoutManagersInput | null;
  recruitedBy?: SalesRepCreateNestedOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSalesRepsInput | null;
  user?: UserCreateNestedOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadCreateNestedManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesRepInput | null;
  recruited?: SalesRepCreateNestedManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeCreateNestedManyWithoutManagersInput | null;
  Project?: ProjectCreateNestedManyWithoutSalesRepInput | null;
  Proposal?: ProposalCreateNestedManyWithoutSalesRepInput | null;
  Funding?: FundingCreateNestedManyWithoutSalesRepInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSalesRepInput | null;
}

export interface SalesRepCreateshirtSize_Input {
  set?: string[] | null;
}

export interface SalesRepCreatestatus_Input {
  set?: string[] | null;
}

export interface SalesRepCreatesyncWithQuickbooks_Input {
  set?: string[] | null;
}

export interface SalesRepCreatetype_Input {
  set?: string[] | null;
}

export interface SalesRepListRelationFilter {
  every?: SalesRepWhereInput | null;
  some?: SalesRepWhereInput | null;
  none?: SalesRepWhereInput | null;
}

export interface SalesRepScalarWhereInput {
  AND?: SalesRepScalarWhereInput[] | null;
  OR?: SalesRepScalarWhereInput[] | null;
  NOT?: SalesRepScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  status_?: StringNullableListFilter | null;
  shirtSize_?: StringNullableListFilter | null;
  syncWithQuickbooks_?: StringNullableListFilter | null;
  type_?: StringNullableListFilter | null;
  type?: StringNullableFilter | null;
  fullName?: StringNullableFilter | null;
  fluentEmail?: StringNullableFilter | null;
  status?: StringNullableFilter | null;
  salesManagerId?: IntNullableFilter | null;
  contactId?: IntNullableFilter | null;
  locationId?: IntNullableFilter | null;
  dateOfBirth?: DateTimeNullableFilter | null;
  dateStarted?: DateTimeNullableFilter | null;
  dateEnded?: DateTimeNullableFilter | null;
  syncWithQuickbooks?: StringNullableFilter | null;
  quickbooksId?: StringNullableFilter | null;
  caHISLicenseNumber?: StringNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  userId?: IntNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  salesTeamId?: IntNullableFilter | null;
  officeId?: IntNullableFilter | null;
  organizationId?: IntNullableFilter | null;
}

export interface SalesRepUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
}

export interface SalesRepUpdateManyWithWhereWithoutContactInput {
  where: SalesRepScalarWhereInput;
  data: SalesRepUpdateManyMutationInput;
}

export interface SalesRepUpdateManyWithWhereWithoutLocationInput {
  where: SalesRepScalarWhereInput;
  data: SalesRepUpdateManyMutationInput;
}

export interface SalesRepUpdateManyWithWhereWithoutOfficeInput {
  where: SalesRepScalarWhereInput;
  data: SalesRepUpdateManyMutationInput;
}

export interface SalesRepUpdateManyWithWhereWithoutOfficeManagerOfInput {
  where: SalesRepScalarWhereInput;
  data: SalesRepUpdateManyMutationInput;
}

export interface SalesRepUpdateManyWithWhereWithoutOrganizationManagerOfInput {
  where: SalesRepScalarWhereInput;
  data: SalesRepUpdateManyMutationInput;
}

export interface SalesRepUpdateManyWithWhereWithoutRecruitedByInput {
  where: SalesRepScalarWhereInput;
  data: SalesRepUpdateManyMutationInput;
}

export interface SalesRepUpdateManyWithWhereWithoutSalesTeamInput {
  where: SalesRepScalarWhereInput;
  data: SalesRepUpdateManyMutationInput;
}

export interface SalesRepUpdateManyWithoutContactInput {
  create?: SalesRepCreateWithoutContactInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutContactInput[] | null;
  upsert?: SalesRepUpsertWithWhereUniqueWithoutContactInput[] | null;
  createMany?: SalesRepCreateManyContactInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
  set?: SalesRepWhereUniqueInput[] | null;
  disconnect?: SalesRepWhereUniqueInput[] | null;
  delete?: SalesRepWhereUniqueInput[] | null;
  update?: SalesRepUpdateWithWhereUniqueWithoutContactInput[] | null;
  updateMany?: SalesRepUpdateManyWithWhereWithoutContactInput[] | null;
  deleteMany?: SalesRepScalarWhereInput[] | null;
}

export interface SalesRepUpdateManyWithoutLocationInput {
  create?: SalesRepCreateWithoutLocationInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutLocationInput[] | null;
  upsert?: SalesRepUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: SalesRepCreateManyLocationInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
  set?: SalesRepWhereUniqueInput[] | null;
  disconnect?: SalesRepWhereUniqueInput[] | null;
  delete?: SalesRepWhereUniqueInput[] | null;
  update?: SalesRepUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: SalesRepUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: SalesRepScalarWhereInput[] | null;
}

export interface SalesRepUpdateManyWithoutOfficeInput {
  create?: SalesRepCreateWithoutOfficeInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutOfficeInput[] | null;
  upsert?: SalesRepUpsertWithWhereUniqueWithoutOfficeInput[] | null;
  createMany?: SalesRepCreateManyOfficeInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
  set?: SalesRepWhereUniqueInput[] | null;
  disconnect?: SalesRepWhereUniqueInput[] | null;
  delete?: SalesRepWhereUniqueInput[] | null;
  update?: SalesRepUpdateWithWhereUniqueWithoutOfficeInput[] | null;
  updateMany?: SalesRepUpdateManyWithWhereWithoutOfficeInput[] | null;
  deleteMany?: SalesRepScalarWhereInput[] | null;
}

export interface SalesRepUpdateManyWithoutOfficeManagerOfInput {
  create?: SalesRepCreateWithoutOfficeManagerOfInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutOfficeManagerOfInput[] | null;
  upsert?: SalesRepUpsertWithWhereUniqueWithoutOfficeManagerOfInput[] | null;
  connect?: SalesRepWhereUniqueInput[] | null;
  set?: SalesRepWhereUniqueInput[] | null;
  disconnect?: SalesRepWhereUniqueInput[] | null;
  delete?: SalesRepWhereUniqueInput[] | null;
  update?: SalesRepUpdateWithWhereUniqueWithoutOfficeManagerOfInput[] | null;
  updateMany?: SalesRepUpdateManyWithWhereWithoutOfficeManagerOfInput[] | null;
  deleteMany?: SalesRepScalarWhereInput[] | null;
}

export interface SalesRepUpdateManyWithoutOrganizationManagerOfInput {
  create?: SalesRepCreateWithoutOrganizationManagerOfInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutOrganizationManagerOfInput[] | null;
  upsert?: SalesRepUpsertWithWhereUniqueWithoutOrganizationManagerOfInput[] | null;
  createMany?: SalesRepCreateManyOrganizationManagerOfInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
  set?: SalesRepWhereUniqueInput[] | null;
  disconnect?: SalesRepWhereUniqueInput[] | null;
  delete?: SalesRepWhereUniqueInput[] | null;
  update?: SalesRepUpdateWithWhereUniqueWithoutOrganizationManagerOfInput[] | null;
  updateMany?: SalesRepUpdateManyWithWhereWithoutOrganizationManagerOfInput[] | null;
  deleteMany?: SalesRepScalarWhereInput[] | null;
}

export interface SalesRepUpdateManyWithoutRecruitedByInput {
  create?: SalesRepCreateWithoutRecruitedByInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutRecruitedByInput[] | null;
  upsert?: SalesRepUpsertWithWhereUniqueWithoutRecruitedByInput[] | null;
  createMany?: SalesRepCreateManyRecruitedByInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
  set?: SalesRepWhereUniqueInput[] | null;
  disconnect?: SalesRepWhereUniqueInput[] | null;
  delete?: SalesRepWhereUniqueInput[] | null;
  update?: SalesRepUpdateWithWhereUniqueWithoutRecruitedByInput[] | null;
  updateMany?: SalesRepUpdateManyWithWhereWithoutRecruitedByInput[] | null;
  deleteMany?: SalesRepScalarWhereInput[] | null;
}

export interface SalesRepUpdateManyWithoutSalesTeamInput {
  create?: SalesRepCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutSalesTeamInput[] | null;
  upsert?: SalesRepUpsertWithWhereUniqueWithoutSalesTeamInput[] | null;
  createMany?: SalesRepCreateManySalesTeamInputEnvelope | null;
  connect?: SalesRepWhereUniqueInput[] | null;
  set?: SalesRepWhereUniqueInput[] | null;
  disconnect?: SalesRepWhereUniqueInput[] | null;
  delete?: SalesRepWhereUniqueInput[] | null;
  update?: SalesRepUpdateWithWhereUniqueWithoutSalesTeamInput[] | null;
  updateMany?: SalesRepUpdateManyWithWhereWithoutSalesTeamInput[] | null;
  deleteMany?: SalesRepScalarWhereInput[] | null;
}

export interface SalesRepUpdateOneWithoutAppointmentInput {
  create?: SalesRepCreateWithoutAppointmentInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutAppointmentInput | null;
  upsert?: SalesRepUpsertWithoutAppointmentInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutAppointmentInput | null;
}

export interface SalesRepUpdateOneWithoutAssignedLeadsInput {
  create?: SalesRepCreateWithoutAssignedLeadsInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutAssignedLeadsInput | null;
  upsert?: SalesRepUpsertWithoutAssignedLeadsInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutAssignedLeadsInput | null;
}

export interface SalesRepUpdateOneWithoutFundingInput {
  create?: SalesRepCreateWithoutFundingInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutFundingInput | null;
  upsert?: SalesRepUpsertWithoutFundingInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutFundingInput | null;
}

export interface SalesRepUpdateOneWithoutMasterCustomersInput {
  create?: SalesRepCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutMasterCustomersInput | null;
  upsert?: SalesRepUpsertWithoutMasterCustomersInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutMasterCustomersInput | null;
}

export interface SalesRepUpdateOneWithoutProjectInput {
  create?: SalesRepCreateWithoutProjectInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutProjectInput | null;
  upsert?: SalesRepUpsertWithoutProjectInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutProjectInput | null;
}

export interface SalesRepUpdateOneWithoutProposalInput {
  create?: SalesRepCreateWithoutProposalInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutProposalInput | null;
  upsert?: SalesRepUpsertWithoutProposalInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutProposalInput | null;
}

export interface SalesRepUpdateOneWithoutRecruitedInput {
  create?: SalesRepCreateWithoutRecruitedInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutRecruitedInput | null;
  upsert?: SalesRepUpsertWithoutRecruitedInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutRecruitedInput | null;
}

export interface SalesRepUpdateOneWithoutSalesEmployeeInput {
  create?: SalesRepCreateWithoutSalesEmployeeInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutSalesEmployeeInput | null;
  upsert?: SalesRepUpsertWithoutSalesEmployeeInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutSalesEmployeeInput | null;
}

export interface SalesRepUpdateOneWithoutUserInput {
  create?: SalesRepCreateWithoutUserInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutUserInput | null;
  upsert?: SalesRepUpsertWithoutUserInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutUserInput | null;
}

export interface SalesRepUpdateOneWithoutWelcomeCallInput {
  create?: SalesRepCreateWithoutWelcomeCallInput | null;
  connectOrCreate?: SalesRepCreateOrConnectWithoutWelcomeCallInput | null;
  upsert?: SalesRepUpsertWithoutWelcomeCallInput | null;
  connect?: SalesRepWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesRepUpdateWithoutWelcomeCallInput | null;
}

export interface SalesRepUpdateWithWhereUniqueWithoutContactInput {
  where: SalesRepWhereUniqueInput;
  data: SalesRepUpdateWithoutContactInput;
}

export interface SalesRepUpdateWithWhereUniqueWithoutLocationInput {
  where: SalesRepWhereUniqueInput;
  data: SalesRepUpdateWithoutLocationInput;
}

export interface SalesRepUpdateWithWhereUniqueWithoutOfficeInput {
  where: SalesRepWhereUniqueInput;
  data: SalesRepUpdateWithoutOfficeInput;
}

export interface SalesRepUpdateWithWhereUniqueWithoutOfficeManagerOfInput {
  where: SalesRepWhereUniqueInput;
  data: SalesRepUpdateWithoutOfficeManagerOfInput;
}

export interface SalesRepUpdateWithWhereUniqueWithoutOrganizationManagerOfInput {
  where: SalesRepWhereUniqueInput;
  data: SalesRepUpdateWithoutOrganizationManagerOfInput;
}

export interface SalesRepUpdateWithWhereUniqueWithoutRecruitedByInput {
  where: SalesRepWhereUniqueInput;
  data: SalesRepUpdateWithoutRecruitedByInput;
}

export interface SalesRepUpdateWithWhereUniqueWithoutSalesTeamInput {
  where: SalesRepWhereUniqueInput;
  data: SalesRepUpdateWithoutSalesTeamInput;
}

export interface SalesRepUpdateWithoutAppointmentInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutAssignedLeadsInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutContactInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutFundingInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutLocationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutMasterCustomersInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutOfficeInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutOfficeManagerOfInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutOrganizationManagerOfInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutProposalInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutRecruitedByInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutRecruitedInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutSalesEmployeeInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
}

export interface SalesRepUpdateWithoutSalesTeamInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutUserInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  WelcomeCall?: WelcomeCallUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateWithoutWelcomeCallInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  salesManagerId?: NullableIntFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateStarted?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateEnded?: NullableDateTimeFieldUpdateOperationsInput | null;
  syncWithQuickbooks?: NullableStringFieldUpdateOperationsInput | null;
  quickbooksId?: NullableStringFieldUpdateOperationsInput | null;
  caHISLicenseNumber?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  status_?: SalesRepUpdatestatus_Input | null;
  shirtSize_?: SalesRepUpdateshirtSize_Input | null;
  syncWithQuickbooks_?: SalesRepUpdatesyncWithQuickbooks_Input | null;
  type_?: SalesRepUpdatetype_Input | null;
  contact?: ContactUpdateOneWithoutSalesRepInput | null;
  location?: LocationUpdateOneWithoutSalesRepInput | null;
  office?: OfficeUpdateOneWithoutClosersInput | null;
  organizationManagerOf?: OrganizationUpdateOneWithoutManagersInput | null;
  recruitedBy?: SalesRepUpdateOneWithoutRecruitedInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSalesRepsInput | null;
  user?: UserUpdateOneWithoutSalesRepInfoInput | null;
  assignedLeads?: GeneratedLeadUpdateManyWithoutAssignedToInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesRepInput | null;
  recruited?: SalesRepUpdateManyWithoutRecruitedByInput | null;
  officeManagerOf?: OfficeUpdateManyWithoutManagersInput | null;
  Project?: ProjectUpdateManyWithoutSalesRepInput | null;
  Proposal?: ProposalUpdateManyWithoutSalesRepInput | null;
  Funding?: FundingUpdateManyWithoutSalesRepInput | null;
  Appointment?: AppointmentUpdateManyWithoutAssignedToInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSalesRepInput | null;
}

export interface SalesRepUpdateshirtSize_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface SalesRepUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface SalesRepUpdatesyncWithQuickbooks_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface SalesRepUpdatetype_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface SalesRepUpsertWithWhereUniqueWithoutContactInput {
  where: SalesRepWhereUniqueInput;
  update: SalesRepUpdateWithoutContactInput;
  create: SalesRepCreateWithoutContactInput;
}

export interface SalesRepUpsertWithWhereUniqueWithoutLocationInput {
  where: SalesRepWhereUniqueInput;
  update: SalesRepUpdateWithoutLocationInput;
  create: SalesRepCreateWithoutLocationInput;
}

export interface SalesRepUpsertWithWhereUniqueWithoutOfficeInput {
  where: SalesRepWhereUniqueInput;
  update: SalesRepUpdateWithoutOfficeInput;
  create: SalesRepCreateWithoutOfficeInput;
}

export interface SalesRepUpsertWithWhereUniqueWithoutOfficeManagerOfInput {
  where: SalesRepWhereUniqueInput;
  update: SalesRepUpdateWithoutOfficeManagerOfInput;
  create: SalesRepCreateWithoutOfficeManagerOfInput;
}

export interface SalesRepUpsertWithWhereUniqueWithoutOrganizationManagerOfInput {
  where: SalesRepWhereUniqueInput;
  update: SalesRepUpdateWithoutOrganizationManagerOfInput;
  create: SalesRepCreateWithoutOrganizationManagerOfInput;
}

export interface SalesRepUpsertWithWhereUniqueWithoutRecruitedByInput {
  where: SalesRepWhereUniqueInput;
  update: SalesRepUpdateWithoutRecruitedByInput;
  create: SalesRepCreateWithoutRecruitedByInput;
}

export interface SalesRepUpsertWithWhereUniqueWithoutSalesTeamInput {
  where: SalesRepWhereUniqueInput;
  update: SalesRepUpdateWithoutSalesTeamInput;
  create: SalesRepCreateWithoutSalesTeamInput;
}

export interface SalesRepUpsertWithoutAppointmentInput {
  update: SalesRepUpdateWithoutAppointmentInput;
  create: SalesRepCreateWithoutAppointmentInput;
}

export interface SalesRepUpsertWithoutAssignedLeadsInput {
  update: SalesRepUpdateWithoutAssignedLeadsInput;
  create: SalesRepCreateWithoutAssignedLeadsInput;
}

export interface SalesRepUpsertWithoutFundingInput {
  update: SalesRepUpdateWithoutFundingInput;
  create: SalesRepCreateWithoutFundingInput;
}

export interface SalesRepUpsertWithoutMasterCustomersInput {
  update: SalesRepUpdateWithoutMasterCustomersInput;
  create: SalesRepCreateWithoutMasterCustomersInput;
}

export interface SalesRepUpsertWithoutProjectInput {
  update: SalesRepUpdateWithoutProjectInput;
  create: SalesRepCreateWithoutProjectInput;
}

export interface SalesRepUpsertWithoutProposalInput {
  update: SalesRepUpdateWithoutProposalInput;
  create: SalesRepCreateWithoutProposalInput;
}

export interface SalesRepUpsertWithoutRecruitedInput {
  update: SalesRepUpdateWithoutRecruitedInput;
  create: SalesRepCreateWithoutRecruitedInput;
}

export interface SalesRepUpsertWithoutSalesEmployeeInput {
  update: SalesRepUpdateWithoutSalesEmployeeInput;
  create: SalesRepCreateWithoutSalesEmployeeInput;
}

export interface SalesRepUpsertWithoutUserInput {
  update: SalesRepUpdateWithoutUserInput;
  create: SalesRepCreateWithoutUserInput;
}

export interface SalesRepUpsertWithoutWelcomeCallInput {
  update: SalesRepUpdateWithoutWelcomeCallInput;
  create: SalesRepCreateWithoutWelcomeCallInput;
}

export interface SalesRepWhereInput {
  AND?: SalesRepWhereInput[] | null;
  OR?: SalesRepWhereInput[] | null;
  NOT?: SalesRepWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  status_?: StringNullableListFilter | null;
  shirtSize_?: StringNullableListFilter | null;
  syncWithQuickbooks_?: StringNullableListFilter | null;
  type_?: StringNullableListFilter | null;
  type?: StringNullableFilter | null;
  fullName?: StringNullableFilter | null;
  fluentEmail?: StringNullableFilter | null;
  status?: StringNullableFilter | null;
  salesManagerId?: IntNullableFilter | null;
  contactId?: IntNullableFilter | null;
  locationId?: IntNullableFilter | null;
  dateOfBirth?: DateTimeNullableFilter | null;
  dateStarted?: DateTimeNullableFilter | null;
  dateEnded?: DateTimeNullableFilter | null;
  syncWithQuickbooks?: StringNullableFilter | null;
  quickbooksId?: StringNullableFilter | null;
  caHISLicenseNumber?: StringNullableFilter | null;
  salesRepId?: IntNullableFilter | null;
  userId?: IntNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  salesTeamId?: IntNullableFilter | null;
  officeId?: IntNullableFilter | null;
  organizationId?: IntNullableFilter | null;
  contact?: ContactWhereInput | null;
  location?: LocationWhereInput | null;
  office?: OfficeWhereInput | null;
  organizationManagerOf?: OrganizationWhereInput | null;
  recruitedBy?: SalesRepWhereInput | null;
  salesTeam?: SalesTeamWhereInput | null;
  user?: UserWhereInput | null;
  assignedLeads?: GeneratedLeadListRelationFilter | null;
  masterCustomers?: MasterCustomerListRelationFilter | null;
  recruited?: SalesRepListRelationFilter | null;
  officeManagerOf?: OfficeListRelationFilter | null;
  Project?: ProjectListRelationFilter | null;
  Proposal?: ProposalListRelationFilter | null;
  Funding?: FundingListRelationFilter | null;
  WelcomeCall?: WelcomeCallListRelationFilter | null;
  Appointment?: AppointmentListRelationFilter | null;
  SalesEmployee?: SalesEmployeeWhereInput | null;
}

export interface SalesRepWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  userId?: number | null;
}

export interface SalesTeamCreateNestedOneWithoutAppKeysInput {
  create?: SalesTeamCreateWithoutAppKeysInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutAppKeysInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
}

export interface SalesTeamCreateNestedOneWithoutMasterCustomersInput {
  create?: SalesTeamCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutMasterCustomersInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
}

export interface SalesTeamCreateNestedOneWithoutOfficesInput {
  create?: SalesTeamCreateWithoutOfficesInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutOfficesInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
}

export interface SalesTeamCreateNestedOneWithoutSalesRepsInput {
  create?: SalesTeamCreateWithoutSalesRepsInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutSalesRepsInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
}

export interface SalesTeamCreateNestedOneWithoutSettersInput {
  create?: SalesTeamCreateWithoutSettersInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutSettersInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
}

export interface SalesTeamCreateOrConnectWithoutAppKeysInput {
  where: SalesTeamWhereUniqueInput;
  create: SalesTeamCreateWithoutAppKeysInput;
}

export interface SalesTeamCreateOrConnectWithoutMasterCustomersInput {
  where: SalesTeamWhereUniqueInput;
  create: SalesTeamCreateWithoutMasterCustomersInput;
}

export interface SalesTeamCreateOrConnectWithoutOfficesInput {
  where: SalesTeamWhereUniqueInput;
  create: SalesTeamCreateWithoutOfficesInput;
}

export interface SalesTeamCreateOrConnectWithoutSalesRepsInput {
  where: SalesTeamWhereUniqueInput;
  create: SalesTeamCreateWithoutSalesRepsInput;
}

export interface SalesTeamCreateOrConnectWithoutSettersInput {
  where: SalesTeamWhereUniqueInput;
  create: SalesTeamCreateWithoutSettersInput;
}

export interface SalesTeamCreateWithoutAppKeysInput {
  link?: string | null;
  appItemId?: number | null;
  podioSpaceId?: string | null;
  podioId?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: team_type | null;
  title?: string | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesTeamInput | null;
  offices?: OfficeCreateNestedManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepCreateNestedManyWithoutSalesTeamInput | null;
  setters?: SetterCreateNestedManyWithoutSalesTeamInput | null;
}

export interface SalesTeamCreateWithoutMasterCustomersInput {
  link?: string | null;
  appItemId?: number | null;
  podioSpaceId?: string | null;
  podioId?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: team_type | null;
  title?: string | null;
  appKeys?: AppKeyCreateNestedManyWithoutSalesTeamInput | null;
  offices?: OfficeCreateNestedManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepCreateNestedManyWithoutSalesTeamInput | null;
  setters?: SetterCreateNestedManyWithoutSalesTeamInput | null;
}

export interface SalesTeamCreateWithoutOfficesInput {
  link?: string | null;
  appItemId?: number | null;
  podioSpaceId?: string | null;
  podioId?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: team_type | null;
  title?: string | null;
  appKeys?: AppKeyCreateNestedManyWithoutSalesTeamInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepCreateNestedManyWithoutSalesTeamInput | null;
  setters?: SetterCreateNestedManyWithoutSalesTeamInput | null;
}

export interface SalesTeamCreateWithoutSalesRepsInput {
  link?: string | null;
  appItemId?: number | null;
  podioSpaceId?: string | null;
  podioId?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: team_type | null;
  title?: string | null;
  appKeys?: AppKeyCreateNestedManyWithoutSalesTeamInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesTeamInput | null;
  offices?: OfficeCreateNestedManyWithoutSalesTeamInput | null;
  setters?: SetterCreateNestedManyWithoutSalesTeamInput | null;
}

export interface SalesTeamCreateWithoutSettersInput {
  link?: string | null;
  appItemId?: number | null;
  podioSpaceId?: string | null;
  podioId?: string | null;
  archived?: boolean | null;
  createdOn?: any | null;
  team?: team_type | null;
  title?: string | null;
  appKeys?: AppKeyCreateNestedManyWithoutSalesTeamInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSalesTeamInput | null;
  offices?: OfficeCreateNestedManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepCreateNestedManyWithoutSalesTeamInput | null;
}

export interface SalesTeamUpdateOneWithoutAppKeysInput {
  create?: SalesTeamCreateWithoutAppKeysInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutAppKeysInput | null;
  upsert?: SalesTeamUpsertWithoutAppKeysInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesTeamUpdateWithoutAppKeysInput | null;
}

export interface SalesTeamUpdateOneWithoutMasterCustomersInput {
  create?: SalesTeamCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutMasterCustomersInput | null;
  upsert?: SalesTeamUpsertWithoutMasterCustomersInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesTeamUpdateWithoutMasterCustomersInput | null;
}

export interface SalesTeamUpdateOneWithoutOfficesInput {
  create?: SalesTeamCreateWithoutOfficesInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutOfficesInput | null;
  upsert?: SalesTeamUpsertWithoutOfficesInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesTeamUpdateWithoutOfficesInput | null;
}

export interface SalesTeamUpdateOneWithoutSalesRepsInput {
  create?: SalesTeamCreateWithoutSalesRepsInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutSalesRepsInput | null;
  upsert?: SalesTeamUpsertWithoutSalesRepsInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesTeamUpdateWithoutSalesRepsInput | null;
}

export interface SalesTeamUpdateOneWithoutSettersInput {
  create?: SalesTeamCreateWithoutSettersInput | null;
  connectOrCreate?: SalesTeamCreateOrConnectWithoutSettersInput | null;
  upsert?: SalesTeamUpsertWithoutSettersInput | null;
  connect?: SalesTeamWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SalesTeamUpdateWithoutSettersInput | null;
}

export interface SalesTeamUpdateWithoutAppKeysInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioSpaceId?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesTeamInput | null;
  offices?: OfficeUpdateManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepUpdateManyWithoutSalesTeamInput | null;
  setters?: SetterUpdateManyWithoutSalesTeamInput | null;
}

export interface SalesTeamUpdateWithoutMasterCustomersInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioSpaceId?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  appKeys?: AppKeyUpdateManyWithoutSalesTeamInput | null;
  offices?: OfficeUpdateManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepUpdateManyWithoutSalesTeamInput | null;
  setters?: SetterUpdateManyWithoutSalesTeamInput | null;
}

export interface SalesTeamUpdateWithoutOfficesInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioSpaceId?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  appKeys?: AppKeyUpdateManyWithoutSalesTeamInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepUpdateManyWithoutSalesTeamInput | null;
  setters?: SetterUpdateManyWithoutSalesTeamInput | null;
}

export interface SalesTeamUpdateWithoutSalesRepsInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioSpaceId?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  appKeys?: AppKeyUpdateManyWithoutSalesTeamInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesTeamInput | null;
  offices?: OfficeUpdateManyWithoutSalesTeamInput | null;
  setters?: SetterUpdateManyWithoutSalesTeamInput | null;
}

export interface SalesTeamUpdateWithoutSettersInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioSpaceId?: NullableStringFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  title?: NullableStringFieldUpdateOperationsInput | null;
  appKeys?: AppKeyUpdateManyWithoutSalesTeamInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSalesTeamInput | null;
  offices?: OfficeUpdateManyWithoutSalesTeamInput | null;
  salesReps?: SalesRepUpdateManyWithoutSalesTeamInput | null;
}

export interface SalesTeamUpsertWithoutAppKeysInput {
  update: SalesTeamUpdateWithoutAppKeysInput;
  create: SalesTeamCreateWithoutAppKeysInput;
}

export interface SalesTeamUpsertWithoutMasterCustomersInput {
  update: SalesTeamUpdateWithoutMasterCustomersInput;
  create: SalesTeamCreateWithoutMasterCustomersInput;
}

export interface SalesTeamUpsertWithoutOfficesInput {
  update: SalesTeamUpdateWithoutOfficesInput;
  create: SalesTeamCreateWithoutOfficesInput;
}

export interface SalesTeamUpsertWithoutSalesRepsInput {
  update: SalesTeamUpdateWithoutSalesRepsInput;
  create: SalesTeamCreateWithoutSalesRepsInput;
}

export interface SalesTeamUpsertWithoutSettersInput {
  update: SalesTeamUpdateWithoutSettersInput;
  create: SalesTeamCreateWithoutSettersInput;
}

export interface SalesTeamWhereInput {
  AND?: SalesTeamWhereInput[] | null;
  OR?: SalesTeamWhereInput[] | null;
  NOT?: SalesTeamWhereInput[] | null;
  id?: IntFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  podioSpaceId?: StringNullableFilter | null;
  podioId?: StringNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  title?: StringNullableFilter | null;
  appKeys?: AppKeyListRelationFilter | null;
  masterCustomers?: MasterCustomerListRelationFilter | null;
  offices?: OfficeListRelationFilter | null;
  salesReps?: SalesRepListRelationFilter | null;
  setters?: SetterListRelationFilter | null;
}

export interface SalesTeamWhereUniqueInput {
  id?: number | null;
  podioSpaceId?: string | null;
  podioId?: string | null;
}

export interface SetterCreateManyContactInput {
  id?: number | null;
  salesTeamId?: number | null;
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  userId?: number | null;
  officeId?: number | null;
  status_?: SetterCreateManystatus_Input | null;
}

export interface SetterCreateManyContactInputEnvelope {
  data?: SetterCreateManyContactInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SetterCreateManyOfficeInput {
  id?: number | null;
  salesTeamId?: number | null;
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  contactId?: number | null;
  fullName?: string | null;
  status?: string | null;
  userId?: number | null;
  status_?: SetterCreateManystatus_Input | null;
}

export interface SetterCreateManyOfficeInputEnvelope {
  data?: SetterCreateManyOfficeInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SetterCreateManySalesTeamInput {
  id?: number | null;
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  contactId?: number | null;
  fullName?: string | null;
  status?: string | null;
  userId?: number | null;
  officeId?: number | null;
  status_?: SetterCreateManystatus_Input | null;
}

export interface SetterCreateManySalesTeamInputEnvelope {
  data?: SetterCreateManySalesTeamInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface SetterCreateManystatus_Input {
  set?: string[] | null;
}

export interface SetterCreateNestedManyWithoutContactInput {
  create?: SetterCreateWithoutContactInput[] | null;
  connectOrCreate?: SetterCreateOrConnectWithoutContactInput[] | null;
  createMany?: SetterCreateManyContactInputEnvelope | null;
  connect?: SetterWhereUniqueInput[] | null;
}

export interface SetterCreateNestedManyWithoutOfficeInput {
  create?: SetterCreateWithoutOfficeInput[] | null;
  connectOrCreate?: SetterCreateOrConnectWithoutOfficeInput[] | null;
  createMany?: SetterCreateManyOfficeInputEnvelope | null;
  connect?: SetterWhereUniqueInput[] | null;
}

export interface SetterCreateNestedManyWithoutSalesTeamInput {
  create?: SetterCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: SetterCreateOrConnectWithoutSalesTeamInput[] | null;
  createMany?: SetterCreateManySalesTeamInputEnvelope | null;
  connect?: SetterWhereUniqueInput[] | null;
}

export interface SetterCreateNestedOneWithoutAppointmentInput {
  create?: SetterCreateWithoutAppointmentInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutAppointmentInput | null;
  connect?: SetterWhereUniqueInput | null;
}

export interface SetterCreateNestedOneWithoutCrumbInput {
  create?: SetterCreateWithoutCrumbInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutCrumbInput | null;
  connect?: SetterWhereUniqueInput | null;
}

export interface SetterCreateNestedOneWithoutMasterCustomersInput {
  create?: SetterCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutMasterCustomersInput | null;
  connect?: SetterWhereUniqueInput | null;
}

export interface SetterCreateNestedOneWithoutSalesEmployeeInput {
  create?: SetterCreateWithoutSalesEmployeeInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutSalesEmployeeInput | null;
  connect?: SetterWhereUniqueInput | null;
}

export interface SetterCreateNestedOneWithoutTimeSheetInput {
  create?: SetterCreateWithoutTimeSheetInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutTimeSheetInput | null;
  connect?: SetterWhereUniqueInput | null;
}

export interface SetterCreateNestedOneWithoutUserInput {
  create?: SetterCreateWithoutUserInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutUserInput | null;
  connect?: SetterWhereUniqueInput | null;
}

export interface SetterCreateOrConnectWithoutAppointmentInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutAppointmentInput;
}

export interface SetterCreateOrConnectWithoutContactInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutContactInput;
}

export interface SetterCreateOrConnectWithoutCrumbInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutCrumbInput;
}

export interface SetterCreateOrConnectWithoutMasterCustomersInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutMasterCustomersInput;
}

export interface SetterCreateOrConnectWithoutOfficeInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutOfficeInput;
}

export interface SetterCreateOrConnectWithoutSalesEmployeeInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutSalesEmployeeInput;
}

export interface SetterCreateOrConnectWithoutSalesTeamInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutSalesTeamInput;
}

export interface SetterCreateOrConnectWithoutTimeSheetInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutTimeSheetInput;
}

export interface SetterCreateOrConnectWithoutUserInput {
  where: SetterWhereUniqueInput;
  create: SetterCreateWithoutUserInput;
}

export interface SetterCreateWithoutAppointmentInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreateWithoutContactInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreateWithoutCrumbInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreateWithoutMasterCustomersInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreateWithoutOfficeInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreateWithoutSalesEmployeeInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
}

export interface SetterCreateWithoutSalesTeamInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreateWithoutTimeSheetInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  user?: UserCreateNestedOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreateWithoutUserInput {
  link?: string | null;
  appItemId?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdOn?: any | null;
  setterPhone?: string | null;
  personalEmail?: string | null;
  fluentEmail?: string | null;
  region?: string | null;
  corporateRecruit?: string | null;
  recruitedById?: number | null;
  shirtSize?: string | null;
  team?: team_type | null;
  fullName?: string | null;
  status?: string | null;
  status_?: SetterCreatestatus_Input | null;
  contact?: ContactCreateNestedOneWithoutSetterInput | null;
  office?: OfficeCreateNestedOneWithoutSettersInput | null;
  masterCustomers?: MasterCustomerCreateNestedManyWithoutSetterInput | null;
  salesTeam?: SalesTeamCreateNestedOneWithoutSettersInput | null;
  timeSheet?: TimeSheetCreateNestedManyWithoutSetterInput | null;
  Crumb?: CrumbCreateNestedManyWithoutSetterInput | null;
  Appointment?: AppointmentCreateNestedManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeCreateNestedOneWithoutSetterInput | null;
}

export interface SetterCreatestatus_Input {
  set?: string[] | null;
}

export interface SetterListRelationFilter {
  every?: SetterWhereInput | null;
  some?: SetterWhereInput | null;
  none?: SetterWhereInput | null;
}

export interface SetterScalarWhereInput {
  AND?: SetterScalarWhereInput[] | null;
  OR?: SetterScalarWhereInput[] | null;
  NOT?: SetterScalarWhereInput[] | null;
  id?: IntFilter | null;
  salesTeamId?: IntNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  setterPhone?: StringNullableFilter | null;
  personalEmail?: StringNullableFilter | null;
  fluentEmail?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  corporateRecruit?: StringNullableFilter | null;
  recruitedById?: IntNullableFilter | null;
  shirtSize?: StringNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  contactId?: IntNullableFilter | null;
  fullName?: StringNullableFilter | null;
  status?: StringNullableFilter | null;
  status_?: StringNullableListFilter | null;
  userId?: IntNullableFilter | null;
  officeId?: IntNullableFilter | null;
}

export interface SetterUpdateManyMutationInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
}

export interface SetterUpdateManyWithWhereWithoutContactInput {
  where: SetterScalarWhereInput;
  data: SetterUpdateManyMutationInput;
}

export interface SetterUpdateManyWithWhereWithoutOfficeInput {
  where: SetterScalarWhereInput;
  data: SetterUpdateManyMutationInput;
}

export interface SetterUpdateManyWithWhereWithoutSalesTeamInput {
  where: SetterScalarWhereInput;
  data: SetterUpdateManyMutationInput;
}

export interface SetterUpdateManyWithoutContactInput {
  create?: SetterCreateWithoutContactInput[] | null;
  connectOrCreate?: SetterCreateOrConnectWithoutContactInput[] | null;
  upsert?: SetterUpsertWithWhereUniqueWithoutContactInput[] | null;
  createMany?: SetterCreateManyContactInputEnvelope | null;
  connect?: SetterWhereUniqueInput[] | null;
  set?: SetterWhereUniqueInput[] | null;
  disconnect?: SetterWhereUniqueInput[] | null;
  delete?: SetterWhereUniqueInput[] | null;
  update?: SetterUpdateWithWhereUniqueWithoutContactInput[] | null;
  updateMany?: SetterUpdateManyWithWhereWithoutContactInput[] | null;
  deleteMany?: SetterScalarWhereInput[] | null;
}

export interface SetterUpdateManyWithoutOfficeInput {
  create?: SetterCreateWithoutOfficeInput[] | null;
  connectOrCreate?: SetterCreateOrConnectWithoutOfficeInput[] | null;
  upsert?: SetterUpsertWithWhereUniqueWithoutOfficeInput[] | null;
  createMany?: SetterCreateManyOfficeInputEnvelope | null;
  connect?: SetterWhereUniqueInput[] | null;
  set?: SetterWhereUniqueInput[] | null;
  disconnect?: SetterWhereUniqueInput[] | null;
  delete?: SetterWhereUniqueInput[] | null;
  update?: SetterUpdateWithWhereUniqueWithoutOfficeInput[] | null;
  updateMany?: SetterUpdateManyWithWhereWithoutOfficeInput[] | null;
  deleteMany?: SetterScalarWhereInput[] | null;
}

export interface SetterUpdateManyWithoutSalesTeamInput {
  create?: SetterCreateWithoutSalesTeamInput[] | null;
  connectOrCreate?: SetterCreateOrConnectWithoutSalesTeamInput[] | null;
  upsert?: SetterUpsertWithWhereUniqueWithoutSalesTeamInput[] | null;
  createMany?: SetterCreateManySalesTeamInputEnvelope | null;
  connect?: SetterWhereUniqueInput[] | null;
  set?: SetterWhereUniqueInput[] | null;
  disconnect?: SetterWhereUniqueInput[] | null;
  delete?: SetterWhereUniqueInput[] | null;
  update?: SetterUpdateWithWhereUniqueWithoutSalesTeamInput[] | null;
  updateMany?: SetterUpdateManyWithWhereWithoutSalesTeamInput[] | null;
  deleteMany?: SetterScalarWhereInput[] | null;
}

export interface SetterUpdateOneWithoutAppointmentInput {
  create?: SetterCreateWithoutAppointmentInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutAppointmentInput | null;
  upsert?: SetterUpsertWithoutAppointmentInput | null;
  connect?: SetterWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SetterUpdateWithoutAppointmentInput | null;
}

export interface SetterUpdateOneWithoutCrumbInput {
  create?: SetterCreateWithoutCrumbInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutCrumbInput | null;
  upsert?: SetterUpsertWithoutCrumbInput | null;
  connect?: SetterWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SetterUpdateWithoutCrumbInput | null;
}

export interface SetterUpdateOneWithoutMasterCustomersInput {
  create?: SetterCreateWithoutMasterCustomersInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutMasterCustomersInput | null;
  upsert?: SetterUpsertWithoutMasterCustomersInput | null;
  connect?: SetterWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SetterUpdateWithoutMasterCustomersInput | null;
}

export interface SetterUpdateOneWithoutSalesEmployeeInput {
  create?: SetterCreateWithoutSalesEmployeeInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutSalesEmployeeInput | null;
  upsert?: SetterUpsertWithoutSalesEmployeeInput | null;
  connect?: SetterWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SetterUpdateWithoutSalesEmployeeInput | null;
}

export interface SetterUpdateOneWithoutTimeSheetInput {
  create?: SetterCreateWithoutTimeSheetInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutTimeSheetInput | null;
  upsert?: SetterUpsertWithoutTimeSheetInput | null;
  connect?: SetterWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SetterUpdateWithoutTimeSheetInput | null;
}

export interface SetterUpdateOneWithoutUserInput {
  create?: SetterCreateWithoutUserInput | null;
  connectOrCreate?: SetterCreateOrConnectWithoutUserInput | null;
  upsert?: SetterUpsertWithoutUserInput | null;
  connect?: SetterWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SetterUpdateWithoutUserInput | null;
}

export interface SetterUpdateWithWhereUniqueWithoutContactInput {
  where: SetterWhereUniqueInput;
  data: SetterUpdateWithoutContactInput;
}

export interface SetterUpdateWithWhereUniqueWithoutOfficeInput {
  where: SetterWhereUniqueInput;
  data: SetterUpdateWithoutOfficeInput;
}

export interface SetterUpdateWithWhereUniqueWithoutSalesTeamInput {
  where: SetterWhereUniqueInput;
  data: SetterUpdateWithoutSalesTeamInput;
}

export interface SetterUpdateWithoutAppointmentInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdateWithoutContactInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdateWithoutCrumbInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdateWithoutMasterCustomersInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdateWithoutOfficeInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdateWithoutSalesEmployeeInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
}

export interface SetterUpdateWithoutSalesTeamInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdateWithoutTimeSheetInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  user?: UserUpdateOneWithoutSetterInfoInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdateWithoutUserInput {
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  setterPhone?: NullableStringFieldUpdateOperationsInput | null;
  personalEmail?: NullableStringFieldUpdateOperationsInput | null;
  fluentEmail?: NullableStringFieldUpdateOperationsInput | null;
  region?: NullableStringFieldUpdateOperationsInput | null;
  corporateRecruit?: NullableStringFieldUpdateOperationsInput | null;
  recruitedById?: NullableIntFieldUpdateOperationsInput | null;
  shirtSize?: NullableStringFieldUpdateOperationsInput | null;
  team?: NullableEnumteam_typeFieldUpdateOperationsInput | null;
  fullName?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableStringFieldUpdateOperationsInput | null;
  status_?: SetterUpdatestatus_Input | null;
  contact?: ContactUpdateOneWithoutSetterInput | null;
  office?: OfficeUpdateOneWithoutSettersInput | null;
  masterCustomers?: MasterCustomerUpdateManyWithoutSetterInput | null;
  salesTeam?: SalesTeamUpdateOneWithoutSettersInput | null;
  timeSheet?: TimeSheetUpdateManyWithoutSetterInput | null;
  Crumb?: CrumbUpdateManyWithoutSetterInput | null;
  Appointment?: AppointmentUpdateManyWithoutSetterInput | null;
  SalesEmployee?: SalesEmployeeUpdateOneWithoutSetterInput | null;
}

export interface SetterUpdatestatus_Input {
  set?: string[] | null;
  push?: string | null;
}

export interface SetterUpsertWithWhereUniqueWithoutContactInput {
  where: SetterWhereUniqueInput;
  update: SetterUpdateWithoutContactInput;
  create: SetterCreateWithoutContactInput;
}

export interface SetterUpsertWithWhereUniqueWithoutOfficeInput {
  where: SetterWhereUniqueInput;
  update: SetterUpdateWithoutOfficeInput;
  create: SetterCreateWithoutOfficeInput;
}

export interface SetterUpsertWithWhereUniqueWithoutSalesTeamInput {
  where: SetterWhereUniqueInput;
  update: SetterUpdateWithoutSalesTeamInput;
  create: SetterCreateWithoutSalesTeamInput;
}

export interface SetterUpsertWithoutAppointmentInput {
  update: SetterUpdateWithoutAppointmentInput;
  create: SetterCreateWithoutAppointmentInput;
}

export interface SetterUpsertWithoutCrumbInput {
  update: SetterUpdateWithoutCrumbInput;
  create: SetterCreateWithoutCrumbInput;
}

export interface SetterUpsertWithoutMasterCustomersInput {
  update: SetterUpdateWithoutMasterCustomersInput;
  create: SetterCreateWithoutMasterCustomersInput;
}

export interface SetterUpsertWithoutSalesEmployeeInput {
  update: SetterUpdateWithoutSalesEmployeeInput;
  create: SetterCreateWithoutSalesEmployeeInput;
}

export interface SetterUpsertWithoutTimeSheetInput {
  update: SetterUpdateWithoutTimeSheetInput;
  create: SetterCreateWithoutTimeSheetInput;
}

export interface SetterUpsertWithoutUserInput {
  update: SetterUpdateWithoutUserInput;
  create: SetterCreateWithoutUserInput;
}

export interface SetterWhereInput {
  AND?: SetterWhereInput[] | null;
  OR?: SetterWhereInput[] | null;
  NOT?: SetterWhereInput[] | null;
  id?: IntFilter | null;
  salesTeamId?: IntNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  setterPhone?: StringNullableFilter | null;
  personalEmail?: StringNullableFilter | null;
  fluentEmail?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  corporateRecruit?: StringNullableFilter | null;
  recruitedById?: IntNullableFilter | null;
  shirtSize?: StringNullableFilter | null;
  team?: Enumteam_typeNullableFilter | null;
  contactId?: IntNullableFilter | null;
  fullName?: StringNullableFilter | null;
  status?: StringNullableFilter | null;
  status_?: StringNullableListFilter | null;
  userId?: IntNullableFilter | null;
  officeId?: IntNullableFilter | null;
  contact?: ContactWhereInput | null;
  office?: OfficeWhereInput | null;
  user?: UserWhereInput | null;
  masterCustomers?: MasterCustomerListRelationFilter | null;
  salesTeam?: SalesTeamWhereInput | null;
  timeSheet?: TimeSheetListRelationFilter | null;
  Crumb?: CrumbListRelationFilter | null;
  Appointment?: AppointmentListRelationFilter | null;
  SalesEmployee?: SalesEmployeeWhereInput | null;
}

export interface SetterWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
  userId?: number | null;
}

export interface StringFieldUpdateOperationsInput {
  set?: string | null;
}

export interface StringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringFilter | null;
}

export interface StringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringNullableFilter | null;
}

export interface StringNullableListFilter {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface TimeSheetCreateInput {
  startTime: any;
  endTime?: any | null;
  status?: TimeSheetStatus | null;
  verificationStatus?: string | null;
  note?: string | null;
  setter?: SetterCreateNestedOneWithoutTimeSheetInput | null;
  user?: UserCreateNestedOneWithoutTimeSheetInput | null;
  crumbs?: CrumbCreateNestedManyWithoutTimeSheetInput | null;
}

export interface TimeSheetCreateManySetterInput {
  id?: number | null;
  startTime: any;
  endTime?: any | null;
  status?: TimeSheetStatus | null;
  verificationStatus?: string | null;
  note?: string | null;
  userId?: number | null;
}

export interface TimeSheetCreateManySetterInputEnvelope {
  data?: TimeSheetCreateManySetterInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface TimeSheetCreateManyUserInput {
  id?: number | null;
  startTime: any;
  endTime?: any | null;
  status?: TimeSheetStatus | null;
  verificationStatus?: string | null;
  note?: string | null;
  setterId?: number | null;
}

export interface TimeSheetCreateManyUserInputEnvelope {
  data?: TimeSheetCreateManyUserInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface TimeSheetCreateNestedManyWithoutSetterInput {
  create?: TimeSheetCreateWithoutSetterInput[] | null;
  connectOrCreate?: TimeSheetCreateOrConnectWithoutSetterInput[] | null;
  createMany?: TimeSheetCreateManySetterInputEnvelope | null;
  connect?: TimeSheetWhereUniqueInput[] | null;
}

export interface TimeSheetCreateNestedManyWithoutUserInput {
  create?: TimeSheetCreateWithoutUserInput[] | null;
  connectOrCreate?: TimeSheetCreateOrConnectWithoutUserInput[] | null;
  createMany?: TimeSheetCreateManyUserInputEnvelope | null;
  connect?: TimeSheetWhereUniqueInput[] | null;
}

export interface TimeSheetCreateNestedOneWithoutCrumbsInput {
  create?: TimeSheetCreateWithoutCrumbsInput | null;
  connectOrCreate?: TimeSheetCreateOrConnectWithoutCrumbsInput | null;
  connect?: TimeSheetWhereUniqueInput | null;
}

export interface TimeSheetCreateOrConnectWithoutCrumbsInput {
  where: TimeSheetWhereUniqueInput;
  create: TimeSheetCreateWithoutCrumbsInput;
}

export interface TimeSheetCreateOrConnectWithoutSetterInput {
  where: TimeSheetWhereUniqueInput;
  create: TimeSheetCreateWithoutSetterInput;
}

export interface TimeSheetCreateOrConnectWithoutUserInput {
  where: TimeSheetWhereUniqueInput;
  create: TimeSheetCreateWithoutUserInput;
}

export interface TimeSheetCreateWithoutCrumbsInput {
  startTime: any;
  endTime?: any | null;
  status?: TimeSheetStatus | null;
  verificationStatus?: string | null;
  note?: string | null;
  setter?: SetterCreateNestedOneWithoutTimeSheetInput | null;
  user?: UserCreateNestedOneWithoutTimeSheetInput | null;
}

export interface TimeSheetCreateWithoutSetterInput {
  startTime: any;
  endTime?: any | null;
  status?: TimeSheetStatus | null;
  verificationStatus?: string | null;
  note?: string | null;
  user?: UserCreateNestedOneWithoutTimeSheetInput | null;
  crumbs?: CrumbCreateNestedManyWithoutTimeSheetInput | null;
}

export interface TimeSheetCreateWithoutUserInput {
  startTime: any;
  endTime?: any | null;
  status?: TimeSheetStatus | null;
  verificationStatus?: string | null;
  note?: string | null;
  setter?: SetterCreateNestedOneWithoutTimeSheetInput | null;
  crumbs?: CrumbCreateNestedManyWithoutTimeSheetInput | null;
}

export interface TimeSheetListRelationFilter {
  every?: TimeSheetWhereInput | null;
  some?: TimeSheetWhereInput | null;
  none?: TimeSheetWhereInput | null;
}

export interface TimeSheetOrderByInput {
  id?: SortOrder | null;
  startTime?: SortOrder | null;
  endTime?: SortOrder | null;
  status?: SortOrder | null;
  verificationStatus?: SortOrder | null;
  note?: SortOrder | null;
  setterId?: SortOrder | null;
  userId?: SortOrder | null;
}

export interface TimeSheetScalarWhereInput {
  AND?: TimeSheetScalarWhereInput[] | null;
  OR?: TimeSheetScalarWhereInput[] | null;
  NOT?: TimeSheetScalarWhereInput[] | null;
  id?: IntFilter | null;
  startTime?: DateTimeFilter | null;
  endTime?: DateTimeNullableFilter | null;
  status?: EnumTimeSheetStatusNullableFilter | null;
  verificationStatus?: StringNullableFilter | null;
  note?: StringNullableFilter | null;
  setterId?: IntNullableFilter | null;
  userId?: IntNullableFilter | null;
}

export interface TimeSheetUpdateInput {
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: NullableEnumTimeSheetStatusFieldUpdateOperationsInput | null;
  verificationStatus?: NullableStringFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  setter?: SetterUpdateOneWithoutTimeSheetInput | null;
  user?: UserUpdateOneWithoutTimeSheetInput | null;
  crumbs?: CrumbUpdateManyWithoutTimeSheetInput | null;
}

export interface TimeSheetUpdateManyMutationInput {
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: NullableEnumTimeSheetStatusFieldUpdateOperationsInput | null;
  verificationStatus?: NullableStringFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
}

export interface TimeSheetUpdateManyWithWhereWithoutSetterInput {
  where: TimeSheetScalarWhereInput;
  data: TimeSheetUpdateManyMutationInput;
}

export interface TimeSheetUpdateManyWithWhereWithoutUserInput {
  where: TimeSheetScalarWhereInput;
  data: TimeSheetUpdateManyMutationInput;
}

export interface TimeSheetUpdateManyWithoutSetterInput {
  create?: TimeSheetCreateWithoutSetterInput[] | null;
  connectOrCreate?: TimeSheetCreateOrConnectWithoutSetterInput[] | null;
  upsert?: TimeSheetUpsertWithWhereUniqueWithoutSetterInput[] | null;
  createMany?: TimeSheetCreateManySetterInputEnvelope | null;
  connect?: TimeSheetWhereUniqueInput[] | null;
  set?: TimeSheetWhereUniqueInput[] | null;
  disconnect?: TimeSheetWhereUniqueInput[] | null;
  delete?: TimeSheetWhereUniqueInput[] | null;
  update?: TimeSheetUpdateWithWhereUniqueWithoutSetterInput[] | null;
  updateMany?: TimeSheetUpdateManyWithWhereWithoutSetterInput[] | null;
  deleteMany?: TimeSheetScalarWhereInput[] | null;
}

export interface TimeSheetUpdateManyWithoutUserInput {
  create?: TimeSheetCreateWithoutUserInput[] | null;
  connectOrCreate?: TimeSheetCreateOrConnectWithoutUserInput[] | null;
  upsert?: TimeSheetUpsertWithWhereUniqueWithoutUserInput[] | null;
  createMany?: TimeSheetCreateManyUserInputEnvelope | null;
  connect?: TimeSheetWhereUniqueInput[] | null;
  set?: TimeSheetWhereUniqueInput[] | null;
  disconnect?: TimeSheetWhereUniqueInput[] | null;
  delete?: TimeSheetWhereUniqueInput[] | null;
  update?: TimeSheetUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: TimeSheetUpdateManyWithWhereWithoutUserInput[] | null;
  deleteMany?: TimeSheetScalarWhereInput[] | null;
}

export interface TimeSheetUpdateOneRequiredWithoutCrumbsInput {
  create?: TimeSheetCreateWithoutCrumbsInput | null;
  connectOrCreate?: TimeSheetCreateOrConnectWithoutCrumbsInput | null;
  upsert?: TimeSheetUpsertWithoutCrumbsInput | null;
  connect?: TimeSheetWhereUniqueInput | null;
  update?: TimeSheetUpdateWithoutCrumbsInput | null;
}

export interface TimeSheetUpdateWithWhereUniqueWithoutSetterInput {
  where: TimeSheetWhereUniqueInput;
  data: TimeSheetUpdateWithoutSetterInput;
}

export interface TimeSheetUpdateWithWhereUniqueWithoutUserInput {
  where: TimeSheetWhereUniqueInput;
  data: TimeSheetUpdateWithoutUserInput;
}

export interface TimeSheetUpdateWithoutCrumbsInput {
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: NullableEnumTimeSheetStatusFieldUpdateOperationsInput | null;
  verificationStatus?: NullableStringFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  setter?: SetterUpdateOneWithoutTimeSheetInput | null;
  user?: UserUpdateOneWithoutTimeSheetInput | null;
}

export interface TimeSheetUpdateWithoutSetterInput {
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: NullableEnumTimeSheetStatusFieldUpdateOperationsInput | null;
  verificationStatus?: NullableStringFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  user?: UserUpdateOneWithoutTimeSheetInput | null;
  crumbs?: CrumbUpdateManyWithoutTimeSheetInput | null;
}

export interface TimeSheetUpdateWithoutUserInput {
  startTime?: DateTimeFieldUpdateOperationsInput | null;
  endTime?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: NullableEnumTimeSheetStatusFieldUpdateOperationsInput | null;
  verificationStatus?: NullableStringFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  setter?: SetterUpdateOneWithoutTimeSheetInput | null;
  crumbs?: CrumbUpdateManyWithoutTimeSheetInput | null;
}

export interface TimeSheetUpsertWithWhereUniqueWithoutSetterInput {
  where: TimeSheetWhereUniqueInput;
  update: TimeSheetUpdateWithoutSetterInput;
  create: TimeSheetCreateWithoutSetterInput;
}

export interface TimeSheetUpsertWithWhereUniqueWithoutUserInput {
  where: TimeSheetWhereUniqueInput;
  update: TimeSheetUpdateWithoutUserInput;
  create: TimeSheetCreateWithoutUserInput;
}

export interface TimeSheetUpsertWithoutCrumbsInput {
  update: TimeSheetUpdateWithoutCrumbsInput;
  create: TimeSheetCreateWithoutCrumbsInput;
}

export interface TimeSheetWhereInput {
  AND?: TimeSheetWhereInput[] | null;
  OR?: TimeSheetWhereInput[] | null;
  NOT?: TimeSheetWhereInput[] | null;
  id?: IntFilter | null;
  setter?: SetterWhereInput | null;
  user?: UserWhereInput | null;
  crumbs?: CrumbListRelationFilter | null;
  startTime?: DateTimeFilter | null;
  endTime?: DateTimeNullableFilter | null;
  status?: EnumTimeSheetStatusNullableFilter | null;
  verificationStatus?: StringNullableFilter | null;
  note?: StringNullableFilter | null;
  setterId?: IntNullableFilter | null;
  userId?: IntNullableFilter | null;
}

export interface TimeSheetWhereUniqueInput {
  id?: number | null;
}

export interface TournamentCreateNestedOneWithoutRegistrantsInput {
  create?: TournamentCreateWithoutRegistrantsInput | null;
  connectOrCreate?: TournamentCreateOrConnectWithoutRegistrantsInput | null;
  connect?: TournamentWhereUniqueInput | null;
}

export interface TournamentCreateNestedOneWithoutRoundsInput {
  create?: TournamentCreateWithoutRoundsInput | null;
  connectOrCreate?: TournamentCreateOrConnectWithoutRoundsInput | null;
  connect?: TournamentWhereUniqueInput | null;
}

export interface TournamentCreateOrConnectWithoutRegistrantsInput {
  where: TournamentWhereUniqueInput;
  create: TournamentCreateWithoutRegistrantsInput;
}

export interface TournamentCreateOrConnectWithoutRoundsInput {
  where: TournamentWhereUniqueInput;
  create: TournamentCreateWithoutRoundsInput;
}

export interface TournamentCreateWithoutRegistrantsInput {
  title?: string | null;
  description?: string | null;
  eliminationType: EliminationType;
  seeded?: boolean | null;
  active?: boolean | null;
  rounds?: RoundCreateNestedManyWithoutTournamentInput | null;
}

export interface TournamentCreateWithoutRoundsInput {
  title?: string | null;
  description?: string | null;
  eliminationType: EliminationType;
  seeded?: boolean | null;
  active?: boolean | null;
  registrants?: ParticipantCreateNestedManyWithoutTournamentInput | null;
}

export interface TournamentUpdateOneWithoutRegistrantsInput {
  create?: TournamentCreateWithoutRegistrantsInput | null;
  connectOrCreate?: TournamentCreateOrConnectWithoutRegistrantsInput | null;
  upsert?: TournamentUpsertWithoutRegistrantsInput | null;
  connect?: TournamentWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: TournamentUpdateWithoutRegistrantsInput | null;
}

export interface TournamentUpdateOneWithoutRoundsInput {
  create?: TournamentCreateWithoutRoundsInput | null;
  connectOrCreate?: TournamentCreateOrConnectWithoutRoundsInput | null;
  upsert?: TournamentUpsertWithoutRoundsInput | null;
  connect?: TournamentWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: TournamentUpdateWithoutRoundsInput | null;
}

export interface TournamentUpdateWithoutRegistrantsInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  eliminationType?: EnumEliminationTypeFieldUpdateOperationsInput | null;
  seeded?: NullableBoolFieldUpdateOperationsInput | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  rounds?: RoundUpdateManyWithoutTournamentInput | null;
}

export interface TournamentUpdateWithoutRoundsInput {
  title?: NullableStringFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  eliminationType?: EnumEliminationTypeFieldUpdateOperationsInput | null;
  seeded?: NullableBoolFieldUpdateOperationsInput | null;
  active?: NullableBoolFieldUpdateOperationsInput | null;
  registrants?: ParticipantUpdateManyWithoutTournamentInput | null;
}

export interface TournamentUpsertWithoutRegistrantsInput {
  update: TournamentUpdateWithoutRegistrantsInput;
  create: TournamentCreateWithoutRegistrantsInput;
}

export interface TournamentUpsertWithoutRoundsInput {
  update: TournamentUpdateWithoutRoundsInput;
  create: TournamentCreateWithoutRoundsInput;
}

export interface TournamentWhereInput {
  AND?: TournamentWhereInput[] | null;
  OR?: TournamentWhereInput[] | null;
  NOT?: TournamentWhereInput[] | null;
  id?: IntFilter | null;
  rounds?: RoundListRelationFilter | null;
  title?: StringNullableFilter | null;
  description?: StringNullableFilter | null;
  eliminationType?: EnumEliminationTypeFilter | null;
  registrants?: ParticipantListRelationFilter | null;
  seeded?: BoolNullableFilter | null;
  active?: BoolNullableFilter | null;
}

export interface TournamentWhereUniqueInput {
  id?: number | null;
}

export interface UserCreateInput {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  role?: user_role | null;
  team: team_type;
  googleToken?: string | null;
  lastLogin?: any | null;
  enabled?: boolean | null;
  passwordKey?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  profilePic?: string | null;
  expoTokens?: UserCreateexpoTokensInput | null;
  roles?: UserCreaterolesInput | null;
  appAccess?: UserCreateappAccessInput | null;
  featureAccess?: UserCreatefeatureAccessInput | null;
  salesRepInfo?: SalesRepCreateNestedOneWithoutUserInput | null;
  setterInfo?: SetterCreateNestedOneWithoutUserInput | null;
  leaderboard?: LeaderboardCreateNestedOneWithoutTopInput | null;
  participantCaptain?: ParticipantCreateNestedManyWithoutCaptainsInput | null;
  participant?: ParticipantCreateNestedManyWithoutUserInput | null;
  TimeSheet?: TimeSheetCreateNestedManyWithoutUserInput | null;
  Notification?: NotificationCreateNestedManyWithoutUserInput | null;
}

export interface UserCreateNestedManyWithoutParticipantCaptainInput {
  create?: UserCreateWithoutParticipantCaptainInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutParticipantCaptainInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
}

export interface UserCreateNestedManyWithoutParticipantInput {
  create?: UserCreateWithoutParticipantInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutParticipantInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
}

export interface UserCreateNestedOneWithoutSalesRepInfoInput {
  create?: UserCreateWithoutSalesRepInfoInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSalesRepInfoInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutSetterInfoInput {
  create?: UserCreateWithoutSetterInfoInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSetterInfoInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutTimeSheetInput {
  create?: UserCreateWithoutTimeSheetInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutTimeSheetInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOrConnectWithoutParticipantCaptainInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutParticipantCaptainInput;
}

export interface UserCreateOrConnectWithoutParticipantInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutParticipantInput;
}

export interface UserCreateOrConnectWithoutSalesRepInfoInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSalesRepInfoInput;
}

export interface UserCreateOrConnectWithoutSetterInfoInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSetterInfoInput;
}

export interface UserCreateOrConnectWithoutTimeSheetInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTimeSheetInput;
}

export interface UserCreateWithoutParticipantCaptainInput {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  role?: user_role | null;
  team: team_type;
  googleToken?: string | null;
  lastLogin?: any | null;
  enabled?: boolean | null;
  passwordKey?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  profilePic?: string | null;
  expoTokens?: UserCreateexpoTokensInput | null;
  roles?: UserCreaterolesInput | null;
  appAccess?: UserCreateappAccessInput | null;
  featureAccess?: UserCreatefeatureAccessInput | null;
  salesRepInfo?: SalesRepCreateNestedOneWithoutUserInput | null;
  setterInfo?: SetterCreateNestedOneWithoutUserInput | null;
  leaderboard?: LeaderboardCreateNestedOneWithoutTopInput | null;
  participant?: ParticipantCreateNestedManyWithoutUserInput | null;
  TimeSheet?: TimeSheetCreateNestedManyWithoutUserInput | null;
  Notification?: NotificationCreateNestedManyWithoutUserInput | null;
}

export interface UserCreateWithoutParticipantInput {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  role?: user_role | null;
  team: team_type;
  googleToken?: string | null;
  lastLogin?: any | null;
  enabled?: boolean | null;
  passwordKey?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  profilePic?: string | null;
  expoTokens?: UserCreateexpoTokensInput | null;
  roles?: UserCreaterolesInput | null;
  appAccess?: UserCreateappAccessInput | null;
  featureAccess?: UserCreatefeatureAccessInput | null;
  salesRepInfo?: SalesRepCreateNestedOneWithoutUserInput | null;
  setterInfo?: SetterCreateNestedOneWithoutUserInput | null;
  leaderboard?: LeaderboardCreateNestedOneWithoutTopInput | null;
  participantCaptain?: ParticipantCreateNestedManyWithoutCaptainsInput | null;
  TimeSheet?: TimeSheetCreateNestedManyWithoutUserInput | null;
  Notification?: NotificationCreateNestedManyWithoutUserInput | null;
}

export interface UserCreateWithoutSalesRepInfoInput {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  role?: user_role | null;
  team: team_type;
  googleToken?: string | null;
  lastLogin?: any | null;
  enabled?: boolean | null;
  passwordKey?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  profilePic?: string | null;
  expoTokens?: UserCreateexpoTokensInput | null;
  roles?: UserCreaterolesInput | null;
  appAccess?: UserCreateappAccessInput | null;
  featureAccess?: UserCreatefeatureAccessInput | null;
  setterInfo?: SetterCreateNestedOneWithoutUserInput | null;
  leaderboard?: LeaderboardCreateNestedOneWithoutTopInput | null;
  participantCaptain?: ParticipantCreateNestedManyWithoutCaptainsInput | null;
  participant?: ParticipantCreateNestedManyWithoutUserInput | null;
  TimeSheet?: TimeSheetCreateNestedManyWithoutUserInput | null;
  Notification?: NotificationCreateNestedManyWithoutUserInput | null;
}

export interface UserCreateWithoutSetterInfoInput {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  role?: user_role | null;
  team: team_type;
  googleToken?: string | null;
  lastLogin?: any | null;
  enabled?: boolean | null;
  passwordKey?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  profilePic?: string | null;
  expoTokens?: UserCreateexpoTokensInput | null;
  roles?: UserCreaterolesInput | null;
  appAccess?: UserCreateappAccessInput | null;
  featureAccess?: UserCreatefeatureAccessInput | null;
  salesRepInfo?: SalesRepCreateNestedOneWithoutUserInput | null;
  leaderboard?: LeaderboardCreateNestedOneWithoutTopInput | null;
  participantCaptain?: ParticipantCreateNestedManyWithoutCaptainsInput | null;
  participant?: ParticipantCreateNestedManyWithoutUserInput | null;
  TimeSheet?: TimeSheetCreateNestedManyWithoutUserInput | null;
  Notification?: NotificationCreateNestedManyWithoutUserInput | null;
}

export interface UserCreateWithoutTimeSheetInput {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  role?: user_role | null;
  team: team_type;
  googleToken?: string | null;
  lastLogin?: any | null;
  enabled?: boolean | null;
  passwordKey?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  profilePic?: string | null;
  expoTokens?: UserCreateexpoTokensInput | null;
  roles?: UserCreaterolesInput | null;
  appAccess?: UserCreateappAccessInput | null;
  featureAccess?: UserCreatefeatureAccessInput | null;
  salesRepInfo?: SalesRepCreateNestedOneWithoutUserInput | null;
  setterInfo?: SetterCreateNestedOneWithoutUserInput | null;
  leaderboard?: LeaderboardCreateNestedOneWithoutTopInput | null;
  participantCaptain?: ParticipantCreateNestedManyWithoutCaptainsInput | null;
  participant?: ParticipantCreateNestedManyWithoutUserInput | null;
  Notification?: NotificationCreateNestedManyWithoutUserInput | null;
}

export interface UserCreateappAccessInput {
  set?: string[] | null;
}

export interface UserCreateexpoTokensInput {
  set?: string[] | null;
}

export interface UserCreatefeatureAccessInput {
  set?: string[] | null;
}

export interface UserCreaterolesInput {
  set?: string[] | null;
}

export interface UserListRelationFilter {
  every?: UserWhereInput | null;
  some?: UserWhereInput | null;
  none?: UserWhereInput | null;
}

export interface UserOrderByInput {
  id?: SortOrder | null;
  name?: SortOrder | null;
  email?: SortOrder | null;
  password?: SortOrder | null;
  role?: SortOrder | null;
  team?: SortOrder | null;
  googleToken?: SortOrder | null;
  lastLogin?: SortOrder | null;
  enabled?: SortOrder | null;
  passwordKey?: SortOrder | null;
  expoTokens?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  profilePic?: SortOrder | null;
  leaderboardId?: SortOrder | null;
  roles?: SortOrder | null;
  appAccess?: SortOrder | null;
  featureAccess?: SortOrder | null;
}

export interface UserScalarWhereInput {
  AND?: UserScalarWhereInput[] | null;
  OR?: UserScalarWhereInput[] | null;
  NOT?: UserScalarWhereInput[] | null;
  id?: IntFilter | null;
  name?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  password?: StringNullableFilter | null;
  role?: Enumuser_roleNullableFilter | null;
  team?: Enumteam_typeFilter | null;
  googleToken?: StringNullableFilter | null;
  lastLogin?: DateTimeNullableFilter | null;
  enabled?: BoolNullableFilter | null;
  passwordKey?: StringNullableFilter | null;
  expoTokens?: StringNullableListFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  profilePic?: StringNullableFilter | null;
  leaderboardId?: IntNullableFilter | null;
  roles?: StringNullableListFilter | null;
  appAccess?: StringNullableListFilter | null;
  featureAccess?: StringNullableListFilter | null;
}

export interface UserUpdateInput {
  name?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  role?: NullableEnumuser_roleFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  googleToken?: NullableStringFieldUpdateOperationsInput | null;
  lastLogin?: NullableDateTimeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  passwordKey?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  profilePic?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: UserUpdateexpoTokensInput | null;
  roles?: UserUpdaterolesInput | null;
  appAccess?: UserUpdateappAccessInput | null;
  featureAccess?: UserUpdatefeatureAccessInput | null;
  salesRepInfo?: SalesRepUpdateOneWithoutUserInput | null;
  setterInfo?: SetterUpdateOneWithoutUserInput | null;
  leaderboard?: LeaderboardUpdateOneWithoutTopInput | null;
  participantCaptain?: ParticipantUpdateManyWithoutCaptainsInput | null;
  participant?: ParticipantUpdateManyWithoutUserInput | null;
  TimeSheet?: TimeSheetUpdateManyWithoutUserInput | null;
  Notification?: NotificationUpdateManyWithoutUserInput | null;
}

export interface UserUpdateManyMutationInput {
  name?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  role?: NullableEnumuser_roleFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  googleToken?: NullableStringFieldUpdateOperationsInput | null;
  lastLogin?: NullableDateTimeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  passwordKey?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  profilePic?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: UserUpdateexpoTokensInput | null;
  roles?: UserUpdaterolesInput | null;
  appAccess?: UserUpdateappAccessInput | null;
  featureAccess?: UserUpdatefeatureAccessInput | null;
}

export interface UserUpdateManyWithWhereWithoutParticipantCaptainInput {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
}

export interface UserUpdateManyWithWhereWithoutParticipantInput {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
}

export interface UserUpdateManyWithoutParticipantCaptainInput {
  create?: UserCreateWithoutParticipantCaptainInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutParticipantCaptainInput[] | null;
  upsert?: UserUpsertWithWhereUniqueWithoutParticipantCaptainInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
  set?: UserWhereUniqueInput[] | null;
  disconnect?: UserWhereUniqueInput[] | null;
  delete?: UserWhereUniqueInput[] | null;
  update?: UserUpdateWithWhereUniqueWithoutParticipantCaptainInput[] | null;
  updateMany?: UserUpdateManyWithWhereWithoutParticipantCaptainInput[] | null;
  deleteMany?: UserScalarWhereInput[] | null;
}

export interface UserUpdateManyWithoutParticipantInput {
  create?: UserCreateWithoutParticipantInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutParticipantInput[] | null;
  upsert?: UserUpsertWithWhereUniqueWithoutParticipantInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
  set?: UserWhereUniqueInput[] | null;
  disconnect?: UserWhereUniqueInput[] | null;
  delete?: UserWhereUniqueInput[] | null;
  update?: UserUpdateWithWhereUniqueWithoutParticipantInput[] | null;
  updateMany?: UserUpdateManyWithWhereWithoutParticipantInput[] | null;
  deleteMany?: UserScalarWhereInput[] | null;
}

export interface UserUpdateOneWithoutSalesRepInfoInput {
  create?: UserCreateWithoutSalesRepInfoInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSalesRepInfoInput | null;
  upsert?: UserUpsertWithoutSalesRepInfoInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutSalesRepInfoInput | null;
}

export interface UserUpdateOneWithoutSetterInfoInput {
  create?: UserCreateWithoutSetterInfoInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSetterInfoInput | null;
  upsert?: UserUpsertWithoutSetterInfoInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutSetterInfoInput | null;
}

export interface UserUpdateOneWithoutTimeSheetInput {
  create?: UserCreateWithoutTimeSheetInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutTimeSheetInput | null;
  upsert?: UserUpsertWithoutTimeSheetInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutTimeSheetInput | null;
}

export interface UserUpdateWithWhereUniqueWithoutParticipantCaptainInput {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutParticipantCaptainInput;
}

export interface UserUpdateWithWhereUniqueWithoutParticipantInput {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutParticipantInput;
}

export interface UserUpdateWithoutParticipantCaptainInput {
  name?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  role?: NullableEnumuser_roleFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  googleToken?: NullableStringFieldUpdateOperationsInput | null;
  lastLogin?: NullableDateTimeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  passwordKey?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  profilePic?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: UserUpdateexpoTokensInput | null;
  roles?: UserUpdaterolesInput | null;
  appAccess?: UserUpdateappAccessInput | null;
  featureAccess?: UserUpdatefeatureAccessInput | null;
  salesRepInfo?: SalesRepUpdateOneWithoutUserInput | null;
  setterInfo?: SetterUpdateOneWithoutUserInput | null;
  leaderboard?: LeaderboardUpdateOneWithoutTopInput | null;
  participant?: ParticipantUpdateManyWithoutUserInput | null;
  TimeSheet?: TimeSheetUpdateManyWithoutUserInput | null;
  Notification?: NotificationUpdateManyWithoutUserInput | null;
}

export interface UserUpdateWithoutParticipantInput {
  name?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  role?: NullableEnumuser_roleFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  googleToken?: NullableStringFieldUpdateOperationsInput | null;
  lastLogin?: NullableDateTimeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  passwordKey?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  profilePic?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: UserUpdateexpoTokensInput | null;
  roles?: UserUpdaterolesInput | null;
  appAccess?: UserUpdateappAccessInput | null;
  featureAccess?: UserUpdatefeatureAccessInput | null;
  salesRepInfo?: SalesRepUpdateOneWithoutUserInput | null;
  setterInfo?: SetterUpdateOneWithoutUserInput | null;
  leaderboard?: LeaderboardUpdateOneWithoutTopInput | null;
  participantCaptain?: ParticipantUpdateManyWithoutCaptainsInput | null;
  TimeSheet?: TimeSheetUpdateManyWithoutUserInput | null;
  Notification?: NotificationUpdateManyWithoutUserInput | null;
}

export interface UserUpdateWithoutSalesRepInfoInput {
  name?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  role?: NullableEnumuser_roleFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  googleToken?: NullableStringFieldUpdateOperationsInput | null;
  lastLogin?: NullableDateTimeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  passwordKey?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  profilePic?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: UserUpdateexpoTokensInput | null;
  roles?: UserUpdaterolesInput | null;
  appAccess?: UserUpdateappAccessInput | null;
  featureAccess?: UserUpdatefeatureAccessInput | null;
  setterInfo?: SetterUpdateOneWithoutUserInput | null;
  leaderboard?: LeaderboardUpdateOneWithoutTopInput | null;
  participantCaptain?: ParticipantUpdateManyWithoutCaptainsInput | null;
  participant?: ParticipantUpdateManyWithoutUserInput | null;
  TimeSheet?: TimeSheetUpdateManyWithoutUserInput | null;
  Notification?: NotificationUpdateManyWithoutUserInput | null;
}

export interface UserUpdateWithoutSetterInfoInput {
  name?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  role?: NullableEnumuser_roleFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  googleToken?: NullableStringFieldUpdateOperationsInput | null;
  lastLogin?: NullableDateTimeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  passwordKey?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  profilePic?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: UserUpdateexpoTokensInput | null;
  roles?: UserUpdaterolesInput | null;
  appAccess?: UserUpdateappAccessInput | null;
  featureAccess?: UserUpdatefeatureAccessInput | null;
  salesRepInfo?: SalesRepUpdateOneWithoutUserInput | null;
  leaderboard?: LeaderboardUpdateOneWithoutTopInput | null;
  participantCaptain?: ParticipantUpdateManyWithoutCaptainsInput | null;
  participant?: ParticipantUpdateManyWithoutUserInput | null;
  TimeSheet?: TimeSheetUpdateManyWithoutUserInput | null;
  Notification?: NotificationUpdateManyWithoutUserInput | null;
}

export interface UserUpdateWithoutTimeSheetInput {
  name?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  role?: NullableEnumuser_roleFieldUpdateOperationsInput | null;
  team?: Enumteam_typeFieldUpdateOperationsInput | null;
  googleToken?: NullableStringFieldUpdateOperationsInput | null;
  lastLogin?: NullableDateTimeFieldUpdateOperationsInput | null;
  enabled?: NullableBoolFieldUpdateOperationsInput | null;
  passwordKey?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  profilePic?: NullableStringFieldUpdateOperationsInput | null;
  expoTokens?: UserUpdateexpoTokensInput | null;
  roles?: UserUpdaterolesInput | null;
  appAccess?: UserUpdateappAccessInput | null;
  featureAccess?: UserUpdatefeatureAccessInput | null;
  salesRepInfo?: SalesRepUpdateOneWithoutUserInput | null;
  setterInfo?: SetterUpdateOneWithoutUserInput | null;
  leaderboard?: LeaderboardUpdateOneWithoutTopInput | null;
  participantCaptain?: ParticipantUpdateManyWithoutCaptainsInput | null;
  participant?: ParticipantUpdateManyWithoutUserInput | null;
  Notification?: NotificationUpdateManyWithoutUserInput | null;
}

export interface UserUpdateappAccessInput {
  set?: string[] | null;
  push?: string | null;
}

export interface UserUpdateexpoTokensInput {
  set?: string[] | null;
  push?: string | null;
}

export interface UserUpdatefeatureAccessInput {
  set?: string[] | null;
  push?: string | null;
}

export interface UserUpdaterolesInput {
  set?: string[] | null;
  push?: string | null;
}

export interface UserUpsertWithWhereUniqueWithoutParticipantCaptainInput {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutParticipantCaptainInput;
  create: UserCreateWithoutParticipantCaptainInput;
}

export interface UserUpsertWithWhereUniqueWithoutParticipantInput {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutParticipantInput;
  create: UserCreateWithoutParticipantInput;
}

export interface UserUpsertWithoutSalesRepInfoInput {
  update: UserUpdateWithoutSalesRepInfoInput;
  create: UserCreateWithoutSalesRepInfoInput;
}

export interface UserUpsertWithoutSetterInfoInput {
  update: UserUpdateWithoutSetterInfoInput;
  create: UserCreateWithoutSetterInfoInput;
}

export interface UserUpsertWithoutTimeSheetInput {
  update: UserUpdateWithoutTimeSheetInput;
  create: UserCreateWithoutTimeSheetInput;
}

export interface UserWhereInput {
  AND?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  NOT?: UserWhereInput[] | null;
  id?: IntFilter | null;
  name?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  password?: StringNullableFilter | null;
  role?: Enumuser_roleNullableFilter | null;
  team?: Enumteam_typeFilter | null;
  googleToken?: StringNullableFilter | null;
  lastLogin?: DateTimeNullableFilter | null;
  enabled?: BoolNullableFilter | null;
  passwordKey?: StringNullableFilter | null;
  expoTokens?: StringNullableListFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  profilePic?: StringNullableFilter | null;
  salesRepInfo?: SalesRepWhereInput | null;
  setterInfo?: SetterWhereInput | null;
  leaderboard?: LeaderboardWhereInput | null;
  leaderboardId?: IntNullableFilter | null;
  roles?: StringNullableListFilter | null;
  appAccess?: StringNullableListFilter | null;
  featureAccess?: StringNullableListFilter | null;
  participantCaptain?: ParticipantListRelationFilter | null;
  participant?: ParticipantListRelationFilter | null;
  TimeSheet?: TimeSheetListRelationFilter | null;
  Notification?: NotificationListRelationFilter | null;
}

export interface UserWhereUniqueInput {
  id?: number | null;
  email?: string | null;
  googleToken?: string | null;
}

export interface WelcomeCallCreateManySalesRepInput {
  id?: number | null;
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  link?: string | null;
  appItemId?: number | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateOfWelcomeCall?: any | null;
  followUpDate?: any | null;
  contractSignedDate?: any | null;
  hoaFormsPreparedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  siteSurveyDate?: any | null;
  voiceMailDate?: any | null;
  scheduledInstallDate?: any | null;
  ntpApprovedDateOld?: any | null;
  assignedTimestamp?: any | null;
  accountUpdate?: string | null;
  changeOrderDescription?: string | null;
  notes?: string | null;
  projectId?: string | null;
  lender?: string | null;
  fundingStipulations?: string | null;
  notesRegardingUtilityBill?: string | null;
  meterServiceAcct?: string | null;
  notesRegardingPhotos?: string | null;
  treeRemovalDescription?: string | null;
  name?: string | null;
  hoaInfo?: string | null;
  giftCardsPromised?: string | null;
  promisesMadeByRepIfAny?: string | null;
  additionalQuestions?: string | null;
  email?: string | null;
  phone?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  systemSizeWatts?: number | null;
  estimatedYearlyProduction?: number | null;
  ofInverters?: number | null;
  ofModules?: number | null;
  n18MonthCoverageAmount?: number | null;
  coverageAmountPromised?: number | null;
  systemPriceFromProposal?: number | null;
  totalPurchasePrice?: number | null;
  rebateAmount?: number | null;
  salesEmployeeId_SalesRep?: number | null;
  bucketActivation?: WelcomeCallCreateManybucketActivationInput | null;
  status?: WelcomeCallCreateManystatusInput | null;
  projectStage?: WelcomeCallCreateManyprojectStageInput | null;
  stepsToComplete?: WelcomeCallCreateManystepsToCompleteInput | null;
  slaStatus?: WelcomeCallCreateManyslaStatusInput | null;
  managedBy?: WelcomeCallCreateManymanagedByInput | null;
  changeOrder?: WelcomeCallCreateManychangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallCreateManyproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallCreateManyoffsetVerifiedInput | null;
  adders?: WelcomeCallCreateManyaddersInput | null;
  mpuPromised?: WelcomeCallCreateManympuPromisedInput | null;
  batteryPromised?: WelcomeCallCreateManybatteryPromisedInput | null;
  generatorPromised?: WelcomeCallCreateManygeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallCreateManycritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallCreateManytiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallCreateManytrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallCreateManysecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallCreateManyn1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallCreateManyextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallCreateManypremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallCreateManyenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallCreateManyonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallCreateManyenergyPackageInput | null;
  addThermostat?: WelcomeCallCreateManyaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallCreateManyadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallCreateManyadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallCreateManyaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallCreateManyn18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallCreateManycontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallCreateManyagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallCreateManycreateSiteSurveyInput | null;
  createHoa?: WelcomeCallCreateManycreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallCreateManyleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallCreateManysyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallCreateManyleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallCreateManyscheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallCreateManyreRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallCreateManytreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallCreateManysunrunInput | null;
  region?: WelcomeCallCreateManyregionInput | null;
  customerTextWc?: WelcomeCallCreateManycustomerTextWcInput | null;
  repTextWc?: WelcomeCallCreateManyrepTextWcInput | null;
  loanDocsSigned?: WelcomeCallCreateManyloanDocsSignedInput | null;
  creditApproval?: WelcomeCallCreateManycreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallCreateManyntpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallCreateManyutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallCreateManyhomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallCreateManyphotosInput | null;
  siteSurveyNeeded?: WelcomeCallCreateManysiteSurveyNeededInput | null;
  reRoof?: WelcomeCallCreateManyreRoofInput | null;
  reRoofNeeded?: WelcomeCallCreateManyreRoofNeededInput | null;
  reRoofFunding?: WelcomeCallCreateManyreRoofFundingInput | null;
  treeRemoval?: WelcomeCallCreateManytreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallCreateManytreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallCreateManytreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallCreateManycopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallCreateManyinverterTypeInput | null;
  promises?: WelcomeCallCreateManypromisesInput | null;
  taxAdvisorStatement?: WelcomeCallCreateManytaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallCreateManyverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallCreateManyverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallCreateManyverifiedEmailInput | null;
  communicationPreference?: WelcomeCallCreateManycommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallCreateManyisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallCreateManygateInput | null;
  verifiedPhysicalAddress?: WelcomeCallCreateManyverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallCreateManyexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallCreateManynotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallCreateManyscheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallCreateManyscheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallCreateManyaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallCreateManygaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallCreateManyresendWelcomeCallInput | null;
  updated?: WelcomeCallCreateManyupdatedInput | null;
  test?: WelcomeCallCreateManytestInput | null;
}

export interface WelcomeCallCreateManySalesRepInputEnvelope {
  data?: WelcomeCallCreateManySalesRepInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface WelcomeCallCreateManyaddBulbsInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyaddThermostatInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyaddersInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyadditionalBulbs8Input {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyadditionalNestThermostatInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyagreementVerificationInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyaskedCustomerIfTheyHadAnyQuestionsInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManybatteryPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManybucketActivationInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManychangeOrderInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycommunicationPreferenceInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycontractUploadedToDropboxInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycopyOfAgreementInEmailInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycreateHoaInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycreateSiteSurveyInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycreditApprovalInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycritterGuardPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManycustomerTextWcInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyenergyEfficiencyPackageNest24BulbsInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyenergyPackageInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyexistingSolarSystemInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyextendedSolarEdgeWarrantyPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManygateInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManygaveCustomerOurNumberInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManygeneratorPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyhomeownersInsuranceUploadedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyinverterTypeInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyisYourHomePartOfAnHoaInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyleadSourceTypeInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyleftCustomerVoiceMailInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyloanDocsSignedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManymanagedByInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManympuPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyn18MonthCoverageInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyn1KRebateInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManynotifiedCustomerOfAddersListedOnTheAgreementInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyntpApprovalOverrideInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyoffsetVerifiedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyonlyLedBulbs24Input {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyphotosInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManypremiumEfficiencyPackagePromisedInAgreementInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyprojectStageInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManypromisesInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyproposalIdAndContractMatchInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyreRoofFundingInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyreRoofInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyreRoofNeededInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyreRoofTasksCreatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyregionInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyrepTextWcInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyresendWelcomeCallInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyscheduleInstallInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyscheduleInstallTaskCreatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyscheduledInstallDateChangedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManysecurityPackageInProposalInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManysiteSurveyNeededInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyslaStatusInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManystatusInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManystepsToCompleteInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManysunrunInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManysyncFromFluentSolarInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytaxAdvisorStatementInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytestInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytiltKitPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytreeRemovalInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytreeRemovalTasksCreatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytreeRemovalTrimmingFundingInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytreeRemovalTrimmingNeededInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManytrenchingPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyupdatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyutilityBillReceivedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyverifiedEmailInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyverifiedPhoneNumberInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyverifiedPhysicalAddressInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateManyverifiedSpellingOfNameInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateNestedManyWithoutSalesRepInput {
  create?: WelcomeCallCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutSalesRepInput[] | null;
  createMany?: WelcomeCallCreateManySalesRepInputEnvelope | null;
  connect?: WelcomeCallWhereUniqueInput[] | null;
}

export interface WelcomeCallCreateNestedOneWithoutHoaInput {
  create?: WelcomeCallCreateWithoutHoaInput | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutHoaInput | null;
  connect?: WelcomeCallWhereUniqueInput | null;
}

export interface WelcomeCallCreateNestedOneWithoutProjectInput {
  create?: WelcomeCallCreateWithoutProjectInput | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutProjectInput | null;
  connect?: WelcomeCallWhereUniqueInput | null;
}

export interface WelcomeCallCreateNestedOneWithoutProposalInput {
  create?: WelcomeCallCreateWithoutProposalInput | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutProposalInput | null;
  connect?: WelcomeCallWhereUniqueInput | null;
}

export interface WelcomeCallCreateOrConnectWithoutHoaInput {
  where: WelcomeCallWhereUniqueInput;
  create: WelcomeCallCreateWithoutHoaInput;
}

export interface WelcomeCallCreateOrConnectWithoutProjectInput {
  where: WelcomeCallWhereUniqueInput;
  create: WelcomeCallCreateWithoutProjectInput;
}

export interface WelcomeCallCreateOrConnectWithoutProposalInput {
  where: WelcomeCallWhereUniqueInput;
  create: WelcomeCallCreateWithoutProposalInput;
}

export interface WelcomeCallCreateOrConnectWithoutSalesRepInput {
  where: WelcomeCallWhereUniqueInput;
  create: WelcomeCallCreateWithoutSalesRepInput;
}

export interface WelcomeCallCreateWithoutHoaInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  link?: string | null;
  appItemId?: number | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateOfWelcomeCall?: any | null;
  followUpDate?: any | null;
  contractSignedDate?: any | null;
  hoaFormsPreparedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  siteSurveyDate?: any | null;
  voiceMailDate?: any | null;
  scheduledInstallDate?: any | null;
  ntpApprovedDateOld?: any | null;
  assignedTimestamp?: any | null;
  accountUpdate?: string | null;
  changeOrderDescription?: string | null;
  notes?: string | null;
  projectId?: string | null;
  lender?: string | null;
  fundingStipulations?: string | null;
  notesRegardingUtilityBill?: string | null;
  meterServiceAcct?: string | null;
  notesRegardingPhotos?: string | null;
  treeRemovalDescription?: string | null;
  name?: string | null;
  hoaInfo?: string | null;
  giftCardsPromised?: string | null;
  promisesMadeByRepIfAny?: string | null;
  additionalQuestions?: string | null;
  email?: string | null;
  phone?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  systemSizeWatts?: number | null;
  estimatedYearlyProduction?: number | null;
  ofInverters?: number | null;
  ofModules?: number | null;
  n18MonthCoverageAmount?: number | null;
  coverageAmountPromised?: number | null;
  systemPriceFromProposal?: number | null;
  totalPurchasePrice?: number | null;
  rebateAmount?: number | null;
  bucketActivation?: WelcomeCallCreatebucketActivationInput | null;
  status?: WelcomeCallCreatestatusInput | null;
  projectStage?: WelcomeCallCreateprojectStageInput | null;
  stepsToComplete?: WelcomeCallCreatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallCreateslaStatusInput | null;
  managedBy?: WelcomeCallCreatemanagedByInput | null;
  changeOrder?: WelcomeCallCreatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallCreateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallCreateoffsetVerifiedInput | null;
  adders?: WelcomeCallCreateaddersInput | null;
  mpuPromised?: WelcomeCallCreatempuPromisedInput | null;
  batteryPromised?: WelcomeCallCreatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallCreategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallCreatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallCreatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallCreatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallCreatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallCreaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallCreateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallCreatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallCreateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallCreateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallCreateenergyPackageInput | null;
  addThermostat?: WelcomeCallCreateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallCreateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallCreateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallCreateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallCreaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallCreatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallCreateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallCreatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallCreatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallCreateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallCreatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallCreateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallCreatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallCreatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallCreatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallCreatesunrunInput | null;
  region?: WelcomeCallCreateregionInput | null;
  customerTextWc?: WelcomeCallCreatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallCreaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallCreateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallCreatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallCreatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallCreateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallCreatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallCreatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallCreatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallCreatereRoofInput | null;
  reRoofNeeded?: WelcomeCallCreatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallCreatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallCreatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallCreatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallCreatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallCreatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallCreateinverterTypeInput | null;
  promises?: WelcomeCallCreatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallCreatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallCreateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallCreateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallCreateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallCreatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallCreateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallCreategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallCreateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallCreateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallCreatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallCreatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallCreatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallCreateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallCreategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallCreateresendWelcomeCallInput | null;
  updated?: WelcomeCallCreateupdatedInput | null;
  test?: WelcomeCallCreatetestInput | null;
  project?: ProjectCreateNestedOneWithoutWelcomeCallInput | null;
  proposal?: ProposalCreateNestedOneWithoutWelcomeCallInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallCreateWithoutProjectInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  link?: string | null;
  appItemId?: number | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateOfWelcomeCall?: any | null;
  followUpDate?: any | null;
  contractSignedDate?: any | null;
  hoaFormsPreparedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  siteSurveyDate?: any | null;
  voiceMailDate?: any | null;
  scheduledInstallDate?: any | null;
  ntpApprovedDateOld?: any | null;
  assignedTimestamp?: any | null;
  accountUpdate?: string | null;
  changeOrderDescription?: string | null;
  notes?: string | null;
  projectId?: string | null;
  lender?: string | null;
  fundingStipulations?: string | null;
  notesRegardingUtilityBill?: string | null;
  meterServiceAcct?: string | null;
  notesRegardingPhotos?: string | null;
  treeRemovalDescription?: string | null;
  name?: string | null;
  hoaInfo?: string | null;
  giftCardsPromised?: string | null;
  promisesMadeByRepIfAny?: string | null;
  additionalQuestions?: string | null;
  email?: string | null;
  phone?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  systemSizeWatts?: number | null;
  estimatedYearlyProduction?: number | null;
  ofInverters?: number | null;
  ofModules?: number | null;
  n18MonthCoverageAmount?: number | null;
  coverageAmountPromised?: number | null;
  systemPriceFromProposal?: number | null;
  totalPurchasePrice?: number | null;
  rebateAmount?: number | null;
  bucketActivation?: WelcomeCallCreatebucketActivationInput | null;
  status?: WelcomeCallCreatestatusInput | null;
  projectStage?: WelcomeCallCreateprojectStageInput | null;
  stepsToComplete?: WelcomeCallCreatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallCreateslaStatusInput | null;
  managedBy?: WelcomeCallCreatemanagedByInput | null;
  changeOrder?: WelcomeCallCreatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallCreateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallCreateoffsetVerifiedInput | null;
  adders?: WelcomeCallCreateaddersInput | null;
  mpuPromised?: WelcomeCallCreatempuPromisedInput | null;
  batteryPromised?: WelcomeCallCreatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallCreategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallCreatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallCreatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallCreatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallCreatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallCreaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallCreateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallCreatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallCreateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallCreateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallCreateenergyPackageInput | null;
  addThermostat?: WelcomeCallCreateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallCreateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallCreateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallCreateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallCreaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallCreatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallCreateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallCreatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallCreatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallCreateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallCreatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallCreateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallCreatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallCreatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallCreatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallCreatesunrunInput | null;
  region?: WelcomeCallCreateregionInput | null;
  customerTextWc?: WelcomeCallCreatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallCreaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallCreateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallCreatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallCreatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallCreateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallCreatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallCreatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallCreatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallCreatereRoofInput | null;
  reRoofNeeded?: WelcomeCallCreatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallCreatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallCreatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallCreatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallCreatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallCreatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallCreateinverterTypeInput | null;
  promises?: WelcomeCallCreatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallCreatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallCreateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallCreateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallCreateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallCreatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallCreateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallCreategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallCreateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallCreateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallCreatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallCreatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallCreatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallCreateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallCreategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallCreateresendWelcomeCallInput | null;
  updated?: WelcomeCallCreateupdatedInput | null;
  test?: WelcomeCallCreatetestInput | null;
  proposal?: ProposalCreateNestedOneWithoutWelcomeCallInput | null;
  hoa?: HOACreateNestedOneWithoutWelcomeCallInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallCreateWithoutProposalInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  link?: string | null;
  appItemId?: number | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateOfWelcomeCall?: any | null;
  followUpDate?: any | null;
  contractSignedDate?: any | null;
  hoaFormsPreparedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  siteSurveyDate?: any | null;
  voiceMailDate?: any | null;
  scheduledInstallDate?: any | null;
  ntpApprovedDateOld?: any | null;
  assignedTimestamp?: any | null;
  accountUpdate?: string | null;
  changeOrderDescription?: string | null;
  notes?: string | null;
  projectId?: string | null;
  lender?: string | null;
  fundingStipulations?: string | null;
  notesRegardingUtilityBill?: string | null;
  meterServiceAcct?: string | null;
  notesRegardingPhotos?: string | null;
  treeRemovalDescription?: string | null;
  name?: string | null;
  hoaInfo?: string | null;
  giftCardsPromised?: string | null;
  promisesMadeByRepIfAny?: string | null;
  additionalQuestions?: string | null;
  email?: string | null;
  phone?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  systemSizeWatts?: number | null;
  estimatedYearlyProduction?: number | null;
  ofInverters?: number | null;
  ofModules?: number | null;
  n18MonthCoverageAmount?: number | null;
  coverageAmountPromised?: number | null;
  systemPriceFromProposal?: number | null;
  totalPurchasePrice?: number | null;
  rebateAmount?: number | null;
  bucketActivation?: WelcomeCallCreatebucketActivationInput | null;
  status?: WelcomeCallCreatestatusInput | null;
  projectStage?: WelcomeCallCreateprojectStageInput | null;
  stepsToComplete?: WelcomeCallCreatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallCreateslaStatusInput | null;
  managedBy?: WelcomeCallCreatemanagedByInput | null;
  changeOrder?: WelcomeCallCreatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallCreateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallCreateoffsetVerifiedInput | null;
  adders?: WelcomeCallCreateaddersInput | null;
  mpuPromised?: WelcomeCallCreatempuPromisedInput | null;
  batteryPromised?: WelcomeCallCreatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallCreategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallCreatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallCreatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallCreatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallCreatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallCreaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallCreateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallCreatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallCreateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallCreateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallCreateenergyPackageInput | null;
  addThermostat?: WelcomeCallCreateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallCreateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallCreateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallCreateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallCreaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallCreatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallCreateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallCreatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallCreatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallCreateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallCreatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallCreateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallCreatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallCreatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallCreatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallCreatesunrunInput | null;
  region?: WelcomeCallCreateregionInput | null;
  customerTextWc?: WelcomeCallCreatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallCreaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallCreateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallCreatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallCreatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallCreateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallCreatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallCreatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallCreatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallCreatereRoofInput | null;
  reRoofNeeded?: WelcomeCallCreatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallCreatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallCreatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallCreatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallCreatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallCreatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallCreateinverterTypeInput | null;
  promises?: WelcomeCallCreatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallCreatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallCreateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallCreateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallCreateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallCreatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallCreateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallCreategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallCreateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallCreateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallCreatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallCreatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallCreatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallCreateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallCreategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallCreateresendWelcomeCallInput | null;
  updated?: WelcomeCallCreateupdatedInput | null;
  test?: WelcomeCallCreatetestInput | null;
  project?: ProjectCreateNestedOneWithoutWelcomeCallInput | null;
  hoa?: HOACreateNestedOneWithoutWelcomeCallInput | null;
  salesRep?: SalesRepCreateNestedOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallCreateWithoutSalesRepInput {
  podioId?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  link?: string | null;
  appItemId?: number | null;
  archived?: boolean | null;
  createdOn?: any | null;
  dateOfWelcomeCall?: any | null;
  followUpDate?: any | null;
  contractSignedDate?: any | null;
  hoaFormsPreparedDate?: any | null;
  bucketActivatedDate?: any | null;
  bucketCompletionDate?: any | null;
  loanDocsSignedDate?: any | null;
  creditExpirationDate?: any | null;
  ntpSubmittedDate?: any | null;
  ntpApprovedDate?: any | null;
  siteSurveyDate?: any | null;
  voiceMailDate?: any | null;
  scheduledInstallDate?: any | null;
  ntpApprovedDateOld?: any | null;
  assignedTimestamp?: any | null;
  accountUpdate?: string | null;
  changeOrderDescription?: string | null;
  notes?: string | null;
  projectId?: string | null;
  lender?: string | null;
  fundingStipulations?: string | null;
  notesRegardingUtilityBill?: string | null;
  meterServiceAcct?: string | null;
  notesRegardingPhotos?: string | null;
  treeRemovalDescription?: string | null;
  name?: string | null;
  hoaInfo?: string | null;
  giftCardsPromised?: string | null;
  promisesMadeByRepIfAny?: string | null;
  additionalQuestions?: string | null;
  email?: string | null;
  phone?: string | null;
  formattedAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  lat?: any | null;
  lng?: any | null;
  trenchingRawGroundFootage?: number | null;
  trenchingConcreteGroundFootage?: number | null;
  nestThermostats?: number | null;
  ledBulbs?: number | null;
  systemSizeWatts?: number | null;
  estimatedYearlyProduction?: number | null;
  ofInverters?: number | null;
  ofModules?: number | null;
  n18MonthCoverageAmount?: number | null;
  coverageAmountPromised?: number | null;
  systemPriceFromProposal?: number | null;
  totalPurchasePrice?: number | null;
  rebateAmount?: number | null;
  bucketActivation?: WelcomeCallCreatebucketActivationInput | null;
  status?: WelcomeCallCreatestatusInput | null;
  projectStage?: WelcomeCallCreateprojectStageInput | null;
  stepsToComplete?: WelcomeCallCreatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallCreateslaStatusInput | null;
  managedBy?: WelcomeCallCreatemanagedByInput | null;
  changeOrder?: WelcomeCallCreatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallCreateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallCreateoffsetVerifiedInput | null;
  adders?: WelcomeCallCreateaddersInput | null;
  mpuPromised?: WelcomeCallCreatempuPromisedInput | null;
  batteryPromised?: WelcomeCallCreatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallCreategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallCreatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallCreatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallCreatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallCreatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallCreaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallCreateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallCreatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallCreateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallCreateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallCreateenergyPackageInput | null;
  addThermostat?: WelcomeCallCreateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallCreateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallCreateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallCreateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallCreaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallCreatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallCreateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallCreatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallCreatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallCreateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallCreatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallCreateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallCreatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallCreatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallCreatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallCreatesunrunInput | null;
  region?: WelcomeCallCreateregionInput | null;
  customerTextWc?: WelcomeCallCreatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallCreaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallCreateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallCreatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallCreatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallCreateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallCreatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallCreatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallCreatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallCreatereRoofInput | null;
  reRoofNeeded?: WelcomeCallCreatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallCreatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallCreatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallCreatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallCreatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallCreatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallCreateinverterTypeInput | null;
  promises?: WelcomeCallCreatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallCreatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallCreateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallCreateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallCreateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallCreatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallCreateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallCreategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallCreateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallCreateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallCreatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallCreatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallCreatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallCreateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallCreategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallCreateresendWelcomeCallInput | null;
  updated?: WelcomeCallCreateupdatedInput | null;
  test?: WelcomeCallCreatetestInput | null;
  project?: ProjectCreateNestedOneWithoutWelcomeCallInput | null;
  proposal?: ProposalCreateNestedOneWithoutWelcomeCallInput | null;
  hoa?: HOACreateNestedOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeCreateNestedOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallCreateaddBulbsInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateaddThermostatInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateaddersInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateadditionalBulbs8Input {
  set?: string[] | null;
}

export interface WelcomeCallCreateadditionalNestThermostatInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateagreementVerificationInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateaskedCustomerIfTheyHadAnyQuestionsInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatebatteryPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatebucketActivationInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatechangeOrderInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecommunicationPreferenceInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecontractUploadedToDropboxInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecopyOfAgreementInEmailInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecreateHoaInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecreateSiteSurveyInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecreditApprovalInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecritterGuardPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatecustomerTextWcInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateenergyEfficiencyPackageNest24BulbsInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateenergyPackageInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateexistingSolarSystemInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateextendedSolarEdgeWarrantyPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreategateInput {
  set?: string[] | null;
}

export interface WelcomeCallCreategaveCustomerOurNumberInput {
  set?: string[] | null;
}

export interface WelcomeCallCreategeneratorPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatehomeownersInsuranceUploadedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateinverterTypeInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateisYourHomePartOfAnHoaInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateleadSourceTypeInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateleftCustomerVoiceMailInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateloanDocsSignedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatemanagedByInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatempuPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreaten18MonthCoverageInput {
  set?: string[] | null;
}

export interface WelcomeCallCreaten1KRebateInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatenotifiedCustomerOfAddersListedOnTheAgreementInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatentpApprovalOverrideInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateoffsetVerifiedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateonlyLedBulbs24Input {
  set?: string[] | null;
}

export interface WelcomeCallCreatephotosInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatepremiumEfficiencyPackagePromisedInAgreementInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateprojectStageInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatepromisesInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateproposalIdAndContractMatchInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatereRoofFundingInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatereRoofInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatereRoofNeededInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatereRoofTasksCreatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateregionInput {
  set?: string[] | null;
}

export interface WelcomeCallCreaterepTextWcInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateresendWelcomeCallInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatescheduleInstallInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatescheduleInstallTaskCreatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatescheduledInstallDateChangedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatesecurityPackageInProposalInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatesiteSurveyNeededInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateslaStatusInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatestatusInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatestepsToCompleteInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatesunrunInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatesyncFromFluentSolarInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetaxAdvisorStatementInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetestInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetiltKitPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetreeRemovalInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetreeRemovalTasksCreatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetreeRemovalTrimmingFundingInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetreeRemovalTrimmingNeededInput {
  set?: string[] | null;
}

export interface WelcomeCallCreatetrenchingPromisedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateupdatedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateutilityBillReceivedInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateverifiedEmailInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateverifiedPhoneNumberInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateverifiedPhysicalAddressInput {
  set?: string[] | null;
}

export interface WelcomeCallCreateverifiedSpellingOfNameInput {
  set?: string[] | null;
}

export interface WelcomeCallListRelationFilter {
  every?: WelcomeCallWhereInput | null;
  some?: WelcomeCallWhereInput | null;
  none?: WelcomeCallWhereInput | null;
}

export interface WelcomeCallScalarWhereInput {
  AND?: WelcomeCallScalarWhereInput[] | null;
  OR?: WelcomeCallScalarWhereInput[] | null;
  NOT?: WelcomeCallScalarWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  dateOfWelcomeCall?: DateTimeNullableFilter | null;
  followUpDate?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  hoaFormsPreparedDate?: DateTimeNullableFilter | null;
  bucketActivatedDate?: DateTimeNullableFilter | null;
  bucketCompletionDate?: DateTimeNullableFilter | null;
  loanDocsSignedDate?: DateTimeNullableFilter | null;
  creditExpirationDate?: DateTimeNullableFilter | null;
  ntpSubmittedDate?: DateTimeNullableFilter | null;
  ntpApprovedDate?: DateTimeNullableFilter | null;
  siteSurveyDate?: DateTimeNullableFilter | null;
  voiceMailDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  ntpApprovedDateOld?: DateTimeNullableFilter | null;
  assignedTimestamp?: DateTimeNullableFilter | null;
  accountUpdate?: StringNullableFilter | null;
  changeOrderDescription?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  projectId?: StringNullableFilter | null;
  lender?: StringNullableFilter | null;
  fundingStipulations?: StringNullableFilter | null;
  notesRegardingUtilityBill?: StringNullableFilter | null;
  meterServiceAcct?: StringNullableFilter | null;
  notesRegardingPhotos?: StringNullableFilter | null;
  treeRemovalDescription?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  hoaInfo?: StringNullableFilter | null;
  giftCardsPromised?: StringNullableFilter | null;
  promisesMadeByRepIfAny?: StringNullableFilter | null;
  additionalQuestions?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  formattedAddress?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  state?: StringNullableFilter | null;
  country?: StringNullableFilter | null;
  postalCode?: StringNullableFilter | null;
  lat?: DecimalNullableFilter | null;
  lng?: DecimalNullableFilter | null;
  trenchingRawGroundFootage?: IntNullableFilter | null;
  trenchingConcreteGroundFootage?: IntNullableFilter | null;
  nestThermostats?: IntNullableFilter | null;
  ledBulbs?: IntNullableFilter | null;
  systemSizeWatts?: FloatNullableFilter | null;
  estimatedYearlyProduction?: FloatNullableFilter | null;
  ofInverters?: IntNullableFilter | null;
  ofModules?: IntNullableFilter | null;
  n18MonthCoverageAmount?: FloatNullableFilter | null;
  coverageAmountPromised?: FloatNullableFilter | null;
  systemPriceFromProposal?: FloatNullableFilter | null;
  totalPurchasePrice?: FloatNullableFilter | null;
  rebateAmount?: FloatNullableFilter | null;
  bucketActivation?: StringNullableListFilter | null;
  status?: StringNullableListFilter | null;
  projectStage?: StringNullableListFilter | null;
  stepsToComplete?: StringNullableListFilter | null;
  slaStatus?: StringNullableListFilter | null;
  managedBy?: StringNullableListFilter | null;
  changeOrder?: StringNullableListFilter | null;
  proposalIdAndContractMatch?: StringNullableListFilter | null;
  offsetVerified?: StringNullableListFilter | null;
  adders?: StringNullableListFilter | null;
  mpuPromised?: StringNullableListFilter | null;
  batteryPromised?: StringNullableListFilter | null;
  generatorPromised?: StringNullableListFilter | null;
  critterGuardPromised?: StringNullableListFilter | null;
  tiltKitPromised?: StringNullableListFilter | null;
  trenchingPromised?: StringNullableListFilter | null;
  securityPackageInProposal?: StringNullableListFilter | null;
  n1KRebate?: StringNullableListFilter | null;
  extendedSolarEdgeWarrantyPromised?: StringNullableListFilter | null;
  premiumEfficiencyPackagePromisedInAgreement?: StringNullableListFilter | null;
  energyEfficiencyPackageNest24Bulbs?: StringNullableListFilter | null;
  onlyLedBulbs24?: StringNullableListFilter | null;
  energyPackage?: StringNullableListFilter | null;
  addThermostat?: StringNullableListFilter | null;
  additionalNestThermostat?: StringNullableListFilter | null;
  additionalBulbs8?: StringNullableListFilter | null;
  addBulbs?: StringNullableListFilter | null;
  n18MonthCoverage?: StringNullableListFilter | null;
  contractUploadedToDropbox?: StringNullableListFilter | null;
  agreementVerification?: StringNullableListFilter | null;
  createSiteSurvey?: StringNullableListFilter | null;
  createHoa?: StringNullableListFilter | null;
  leftCustomerVoiceMail?: StringNullableListFilter | null;
  syncFromFluentSolar?: StringNullableListFilter | null;
  leadSourceType?: StringNullableListFilter | null;
  scheduleInstallTaskCreated?: StringNullableListFilter | null;
  reRoofTasksCreated?: StringNullableListFilter | null;
  treeRemovalTasksCreated?: StringNullableListFilter | null;
  sunrun?: StringNullableListFilter | null;
  region?: StringNullableListFilter | null;
  customerTextWc?: StringNullableListFilter | null;
  repTextWc?: StringNullableListFilter | null;
  loanDocsSigned?: StringNullableListFilter | null;
  creditApproval?: StringNullableListFilter | null;
  ntpApprovalOverride?: StringNullableListFilter | null;
  utilityBillReceived?: StringNullableListFilter | null;
  homeownersInsuranceUploaded?: StringNullableListFilter | null;
  photos?: StringNullableListFilter | null;
  siteSurveyNeeded?: StringNullableListFilter | null;
  reRoof?: StringNullableListFilter | null;
  reRoofNeeded?: StringNullableListFilter | null;
  reRoofFunding?: StringNullableListFilter | null;
  treeRemoval?: StringNullableListFilter | null;
  treeRemovalTrimmingNeeded?: StringNullableListFilter | null;
  treeRemovalTrimmingFunding?: StringNullableListFilter | null;
  copyOfAgreementInEmail?: StringNullableListFilter | null;
  inverterType?: StringNullableListFilter | null;
  promises?: StringNullableListFilter | null;
  taxAdvisorStatement?: StringNullableListFilter | null;
  verifiedSpellingOfName?: StringNullableListFilter | null;
  verifiedPhoneNumber?: StringNullableListFilter | null;
  verifiedEmail?: StringNullableListFilter | null;
  communicationPreference?: StringNullableListFilter | null;
  isYourHomePartOfAnHoa?: StringNullableListFilter | null;
  gate?: StringNullableListFilter | null;
  verifiedPhysicalAddress?: StringNullableListFilter | null;
  existingSolarSystem?: StringNullableListFilter | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: StringNullableListFilter | null;
  scheduleInstall?: StringNullableListFilter | null;
  scheduledInstallDateChanged?: StringNullableListFilter | null;
  askedCustomerIfTheyHadAnyQuestions?: StringNullableListFilter | null;
  gaveCustomerOurNumber?: StringNullableListFilter | null;
  resendWelcomeCall?: StringNullableListFilter | null;
  updated?: StringNullableListFilter | null;
  test?: StringNullableListFilter | null;
  salesRepId?: IntNullableFilter | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface WelcomeCallUpdateManyMutationInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOfWelcomeCall?: NullableDateTimeFieldUpdateOperationsInput | null;
  followUpDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsPreparedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  voiceMailDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  assignedTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  projectId?: NullableStringFieldUpdateOperationsInput | null;
  lender?: NullableStringFieldUpdateOperationsInput | null;
  fundingStipulations?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingUtilityBill?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  treeRemovalDescription?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  giftCardsPromised?: NullableStringFieldUpdateOperationsInput | null;
  promisesMadeByRepIfAny?: NullableStringFieldUpdateOperationsInput | null;
  additionalQuestions?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeWatts?: NullableFloatFieldUpdateOperationsInput | null;
  estimatedYearlyProduction?: NullableFloatFieldUpdateOperationsInput | null;
  ofInverters?: NullableIntFieldUpdateOperationsInput | null;
  ofModules?: NullableIntFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  systemPriceFromProposal?: NullableFloatFieldUpdateOperationsInput | null;
  totalPurchasePrice?: NullableFloatFieldUpdateOperationsInput | null;
  rebateAmount?: NullableFloatFieldUpdateOperationsInput | null;
  bucketActivation?: WelcomeCallUpdatebucketActivationInput | null;
  status?: WelcomeCallUpdatestatusInput | null;
  projectStage?: WelcomeCallUpdateprojectStageInput | null;
  stepsToComplete?: WelcomeCallUpdatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallUpdateslaStatusInput | null;
  managedBy?: WelcomeCallUpdatemanagedByInput | null;
  changeOrder?: WelcomeCallUpdatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallUpdateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallUpdateoffsetVerifiedInput | null;
  adders?: WelcomeCallUpdateaddersInput | null;
  mpuPromised?: WelcomeCallUpdatempuPromisedInput | null;
  batteryPromised?: WelcomeCallUpdatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallUpdategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallUpdatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallUpdatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallUpdatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallUpdatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallUpdaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallUpdateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallUpdatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallUpdateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallUpdateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallUpdateenergyPackageInput | null;
  addThermostat?: WelcomeCallUpdateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallUpdateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallUpdateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallUpdateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallUpdaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallUpdatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallUpdateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallUpdatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallUpdatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallUpdateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallUpdatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallUpdateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallUpdatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallUpdatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallUpdatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallUpdatesunrunInput | null;
  region?: WelcomeCallUpdateregionInput | null;
  customerTextWc?: WelcomeCallUpdatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallUpdaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallUpdateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallUpdatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallUpdatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallUpdateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallUpdatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallUpdatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallUpdatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallUpdatereRoofInput | null;
  reRoofNeeded?: WelcomeCallUpdatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallUpdatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallUpdatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallUpdatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallUpdatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallUpdatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallUpdateinverterTypeInput | null;
  promises?: WelcomeCallUpdatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallUpdatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallUpdateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallUpdateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallUpdateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallUpdatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallUpdateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallUpdategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallUpdateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallUpdateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallUpdatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallUpdatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallUpdatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallUpdateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallUpdategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallUpdateresendWelcomeCallInput | null;
  updated?: WelcomeCallUpdateupdatedInput | null;
  test?: WelcomeCallUpdatetestInput | null;
}

export interface WelcomeCallUpdateManyWithWhereWithoutSalesRepInput {
  where: WelcomeCallScalarWhereInput;
  data: WelcomeCallUpdateManyMutationInput;
}

export interface WelcomeCallUpdateManyWithoutSalesRepInput {
  create?: WelcomeCallCreateWithoutSalesRepInput[] | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutSalesRepInput[] | null;
  upsert?: WelcomeCallUpsertWithWhereUniqueWithoutSalesRepInput[] | null;
  createMany?: WelcomeCallCreateManySalesRepInputEnvelope | null;
  connect?: WelcomeCallWhereUniqueInput[] | null;
  set?: WelcomeCallWhereUniqueInput[] | null;
  disconnect?: WelcomeCallWhereUniqueInput[] | null;
  delete?: WelcomeCallWhereUniqueInput[] | null;
  update?: WelcomeCallUpdateWithWhereUniqueWithoutSalesRepInput[] | null;
  updateMany?: WelcomeCallUpdateManyWithWhereWithoutSalesRepInput[] | null;
  deleteMany?: WelcomeCallScalarWhereInput[] | null;
}

export interface WelcomeCallUpdateOneWithoutHoaInput {
  create?: WelcomeCallCreateWithoutHoaInput | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutHoaInput | null;
  upsert?: WelcomeCallUpsertWithoutHoaInput | null;
  connect?: WelcomeCallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: WelcomeCallUpdateWithoutHoaInput | null;
}

export interface WelcomeCallUpdateOneWithoutProjectInput {
  create?: WelcomeCallCreateWithoutProjectInput | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutProjectInput | null;
  upsert?: WelcomeCallUpsertWithoutProjectInput | null;
  connect?: WelcomeCallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: WelcomeCallUpdateWithoutProjectInput | null;
}

export interface WelcomeCallUpdateOneWithoutProposalInput {
  create?: WelcomeCallCreateWithoutProposalInput | null;
  connectOrCreate?: WelcomeCallCreateOrConnectWithoutProposalInput | null;
  upsert?: WelcomeCallUpsertWithoutProposalInput | null;
  connect?: WelcomeCallWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: WelcomeCallUpdateWithoutProposalInput | null;
}

export interface WelcomeCallUpdateWithWhereUniqueWithoutSalesRepInput {
  where: WelcomeCallWhereUniqueInput;
  data: WelcomeCallUpdateWithoutSalesRepInput;
}

export interface WelcomeCallUpdateWithoutHoaInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOfWelcomeCall?: NullableDateTimeFieldUpdateOperationsInput | null;
  followUpDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsPreparedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  voiceMailDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  assignedTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  projectId?: NullableStringFieldUpdateOperationsInput | null;
  lender?: NullableStringFieldUpdateOperationsInput | null;
  fundingStipulations?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingUtilityBill?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  treeRemovalDescription?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  giftCardsPromised?: NullableStringFieldUpdateOperationsInput | null;
  promisesMadeByRepIfAny?: NullableStringFieldUpdateOperationsInput | null;
  additionalQuestions?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeWatts?: NullableFloatFieldUpdateOperationsInput | null;
  estimatedYearlyProduction?: NullableFloatFieldUpdateOperationsInput | null;
  ofInverters?: NullableIntFieldUpdateOperationsInput | null;
  ofModules?: NullableIntFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  systemPriceFromProposal?: NullableFloatFieldUpdateOperationsInput | null;
  totalPurchasePrice?: NullableFloatFieldUpdateOperationsInput | null;
  rebateAmount?: NullableFloatFieldUpdateOperationsInput | null;
  bucketActivation?: WelcomeCallUpdatebucketActivationInput | null;
  status?: WelcomeCallUpdatestatusInput | null;
  projectStage?: WelcomeCallUpdateprojectStageInput | null;
  stepsToComplete?: WelcomeCallUpdatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallUpdateslaStatusInput | null;
  managedBy?: WelcomeCallUpdatemanagedByInput | null;
  changeOrder?: WelcomeCallUpdatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallUpdateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallUpdateoffsetVerifiedInput | null;
  adders?: WelcomeCallUpdateaddersInput | null;
  mpuPromised?: WelcomeCallUpdatempuPromisedInput | null;
  batteryPromised?: WelcomeCallUpdatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallUpdategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallUpdatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallUpdatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallUpdatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallUpdatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallUpdaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallUpdateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallUpdatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallUpdateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallUpdateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallUpdateenergyPackageInput | null;
  addThermostat?: WelcomeCallUpdateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallUpdateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallUpdateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallUpdateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallUpdaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallUpdatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallUpdateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallUpdatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallUpdatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallUpdateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallUpdatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallUpdateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallUpdatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallUpdatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallUpdatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallUpdatesunrunInput | null;
  region?: WelcomeCallUpdateregionInput | null;
  customerTextWc?: WelcomeCallUpdatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallUpdaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallUpdateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallUpdatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallUpdatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallUpdateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallUpdatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallUpdatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallUpdatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallUpdatereRoofInput | null;
  reRoofNeeded?: WelcomeCallUpdatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallUpdatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallUpdatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallUpdatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallUpdatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallUpdatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallUpdateinverterTypeInput | null;
  promises?: WelcomeCallUpdatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallUpdatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallUpdateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallUpdateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallUpdateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallUpdatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallUpdateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallUpdategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallUpdateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallUpdateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallUpdatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallUpdatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallUpdatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallUpdateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallUpdategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallUpdateresendWelcomeCallInput | null;
  updated?: WelcomeCallUpdateupdatedInput | null;
  test?: WelcomeCallUpdatetestInput | null;
  project?: ProjectUpdateOneWithoutWelcomeCallInput | null;
  proposal?: ProposalUpdateOneWithoutWelcomeCallInput | null;
  salesRep?: SalesRepUpdateOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallUpdateWithoutProjectInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOfWelcomeCall?: NullableDateTimeFieldUpdateOperationsInput | null;
  followUpDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsPreparedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  voiceMailDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  assignedTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  projectId?: NullableStringFieldUpdateOperationsInput | null;
  lender?: NullableStringFieldUpdateOperationsInput | null;
  fundingStipulations?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingUtilityBill?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  treeRemovalDescription?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  giftCardsPromised?: NullableStringFieldUpdateOperationsInput | null;
  promisesMadeByRepIfAny?: NullableStringFieldUpdateOperationsInput | null;
  additionalQuestions?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeWatts?: NullableFloatFieldUpdateOperationsInput | null;
  estimatedYearlyProduction?: NullableFloatFieldUpdateOperationsInput | null;
  ofInverters?: NullableIntFieldUpdateOperationsInput | null;
  ofModules?: NullableIntFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  systemPriceFromProposal?: NullableFloatFieldUpdateOperationsInput | null;
  totalPurchasePrice?: NullableFloatFieldUpdateOperationsInput | null;
  rebateAmount?: NullableFloatFieldUpdateOperationsInput | null;
  bucketActivation?: WelcomeCallUpdatebucketActivationInput | null;
  status?: WelcomeCallUpdatestatusInput | null;
  projectStage?: WelcomeCallUpdateprojectStageInput | null;
  stepsToComplete?: WelcomeCallUpdatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallUpdateslaStatusInput | null;
  managedBy?: WelcomeCallUpdatemanagedByInput | null;
  changeOrder?: WelcomeCallUpdatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallUpdateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallUpdateoffsetVerifiedInput | null;
  adders?: WelcomeCallUpdateaddersInput | null;
  mpuPromised?: WelcomeCallUpdatempuPromisedInput | null;
  batteryPromised?: WelcomeCallUpdatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallUpdategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallUpdatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallUpdatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallUpdatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallUpdatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallUpdaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallUpdateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallUpdatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallUpdateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallUpdateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallUpdateenergyPackageInput | null;
  addThermostat?: WelcomeCallUpdateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallUpdateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallUpdateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallUpdateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallUpdaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallUpdatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallUpdateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallUpdatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallUpdatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallUpdateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallUpdatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallUpdateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallUpdatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallUpdatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallUpdatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallUpdatesunrunInput | null;
  region?: WelcomeCallUpdateregionInput | null;
  customerTextWc?: WelcomeCallUpdatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallUpdaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallUpdateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallUpdatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallUpdatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallUpdateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallUpdatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallUpdatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallUpdatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallUpdatereRoofInput | null;
  reRoofNeeded?: WelcomeCallUpdatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallUpdatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallUpdatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallUpdatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallUpdatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallUpdatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallUpdateinverterTypeInput | null;
  promises?: WelcomeCallUpdatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallUpdatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallUpdateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallUpdateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallUpdateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallUpdatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallUpdateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallUpdategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallUpdateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallUpdateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallUpdatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallUpdatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallUpdatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallUpdateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallUpdategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallUpdateresendWelcomeCallInput | null;
  updated?: WelcomeCallUpdateupdatedInput | null;
  test?: WelcomeCallUpdatetestInput | null;
  proposal?: ProposalUpdateOneWithoutWelcomeCallInput | null;
  hoa?: HOAUpdateOneWithoutWelcomeCallInput | null;
  salesRep?: SalesRepUpdateOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallUpdateWithoutProposalInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOfWelcomeCall?: NullableDateTimeFieldUpdateOperationsInput | null;
  followUpDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsPreparedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  voiceMailDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  assignedTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  projectId?: NullableStringFieldUpdateOperationsInput | null;
  lender?: NullableStringFieldUpdateOperationsInput | null;
  fundingStipulations?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingUtilityBill?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  treeRemovalDescription?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  giftCardsPromised?: NullableStringFieldUpdateOperationsInput | null;
  promisesMadeByRepIfAny?: NullableStringFieldUpdateOperationsInput | null;
  additionalQuestions?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeWatts?: NullableFloatFieldUpdateOperationsInput | null;
  estimatedYearlyProduction?: NullableFloatFieldUpdateOperationsInput | null;
  ofInverters?: NullableIntFieldUpdateOperationsInput | null;
  ofModules?: NullableIntFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  systemPriceFromProposal?: NullableFloatFieldUpdateOperationsInput | null;
  totalPurchasePrice?: NullableFloatFieldUpdateOperationsInput | null;
  rebateAmount?: NullableFloatFieldUpdateOperationsInput | null;
  bucketActivation?: WelcomeCallUpdatebucketActivationInput | null;
  status?: WelcomeCallUpdatestatusInput | null;
  projectStage?: WelcomeCallUpdateprojectStageInput | null;
  stepsToComplete?: WelcomeCallUpdatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallUpdateslaStatusInput | null;
  managedBy?: WelcomeCallUpdatemanagedByInput | null;
  changeOrder?: WelcomeCallUpdatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallUpdateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallUpdateoffsetVerifiedInput | null;
  adders?: WelcomeCallUpdateaddersInput | null;
  mpuPromised?: WelcomeCallUpdatempuPromisedInput | null;
  batteryPromised?: WelcomeCallUpdatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallUpdategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallUpdatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallUpdatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallUpdatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallUpdatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallUpdaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallUpdateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallUpdatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallUpdateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallUpdateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallUpdateenergyPackageInput | null;
  addThermostat?: WelcomeCallUpdateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallUpdateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallUpdateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallUpdateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallUpdaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallUpdatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallUpdateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallUpdatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallUpdatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallUpdateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallUpdatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallUpdateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallUpdatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallUpdatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallUpdatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallUpdatesunrunInput | null;
  region?: WelcomeCallUpdateregionInput | null;
  customerTextWc?: WelcomeCallUpdatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallUpdaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallUpdateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallUpdatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallUpdatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallUpdateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallUpdatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallUpdatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallUpdatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallUpdatereRoofInput | null;
  reRoofNeeded?: WelcomeCallUpdatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallUpdatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallUpdatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallUpdatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallUpdatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallUpdatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallUpdateinverterTypeInput | null;
  promises?: WelcomeCallUpdatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallUpdatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallUpdateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallUpdateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallUpdateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallUpdatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallUpdateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallUpdategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallUpdateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallUpdateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallUpdatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallUpdatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallUpdatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallUpdateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallUpdategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallUpdateresendWelcomeCallInput | null;
  updated?: WelcomeCallUpdateupdatedInput | null;
  test?: WelcomeCallUpdatetestInput | null;
  project?: ProjectUpdateOneWithoutWelcomeCallInput | null;
  hoa?: HOAUpdateOneWithoutWelcomeCallInput | null;
  salesRep?: SalesRepUpdateOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallUpdateWithoutSalesRepInput {
  podioId?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  updatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  link?: NullableStringFieldUpdateOperationsInput | null;
  appItemId?: NullableIntFieldUpdateOperationsInput | null;
  archived?: NullableBoolFieldUpdateOperationsInput | null;
  createdOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  dateOfWelcomeCall?: NullableDateTimeFieldUpdateOperationsInput | null;
  followUpDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  contractSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  hoaFormsPreparedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketActivatedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  bucketCompletionDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  loanDocsSignedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  creditExpirationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpSubmittedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  siteSurveyDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  voiceMailDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  scheduledInstallDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  ntpApprovedDateOld?: NullableDateTimeFieldUpdateOperationsInput | null;
  assignedTimestamp?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountUpdate?: NullableStringFieldUpdateOperationsInput | null;
  changeOrderDescription?: NullableStringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  projectId?: NullableStringFieldUpdateOperationsInput | null;
  lender?: NullableStringFieldUpdateOperationsInput | null;
  fundingStipulations?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingUtilityBill?: NullableStringFieldUpdateOperationsInput | null;
  meterServiceAcct?: NullableStringFieldUpdateOperationsInput | null;
  notesRegardingPhotos?: NullableStringFieldUpdateOperationsInput | null;
  treeRemovalDescription?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  hoaInfo?: NullableStringFieldUpdateOperationsInput | null;
  giftCardsPromised?: NullableStringFieldUpdateOperationsInput | null;
  promisesMadeByRepIfAny?: NullableStringFieldUpdateOperationsInput | null;
  additionalQuestions?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  formattedAddress?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  lat?: NullableDecimalFieldUpdateOperationsInput | null;
  lng?: NullableDecimalFieldUpdateOperationsInput | null;
  trenchingRawGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  trenchingConcreteGroundFootage?: NullableIntFieldUpdateOperationsInput | null;
  nestThermostats?: NullableIntFieldUpdateOperationsInput | null;
  ledBulbs?: NullableIntFieldUpdateOperationsInput | null;
  systemSizeWatts?: NullableFloatFieldUpdateOperationsInput | null;
  estimatedYearlyProduction?: NullableFloatFieldUpdateOperationsInput | null;
  ofInverters?: NullableIntFieldUpdateOperationsInput | null;
  ofModules?: NullableIntFieldUpdateOperationsInput | null;
  n18MonthCoverageAmount?: NullableFloatFieldUpdateOperationsInput | null;
  coverageAmountPromised?: NullableFloatFieldUpdateOperationsInput | null;
  systemPriceFromProposal?: NullableFloatFieldUpdateOperationsInput | null;
  totalPurchasePrice?: NullableFloatFieldUpdateOperationsInput | null;
  rebateAmount?: NullableFloatFieldUpdateOperationsInput | null;
  bucketActivation?: WelcomeCallUpdatebucketActivationInput | null;
  status?: WelcomeCallUpdatestatusInput | null;
  projectStage?: WelcomeCallUpdateprojectStageInput | null;
  stepsToComplete?: WelcomeCallUpdatestepsToCompleteInput | null;
  slaStatus?: WelcomeCallUpdateslaStatusInput | null;
  managedBy?: WelcomeCallUpdatemanagedByInput | null;
  changeOrder?: WelcomeCallUpdatechangeOrderInput | null;
  proposalIdAndContractMatch?: WelcomeCallUpdateproposalIdAndContractMatchInput | null;
  offsetVerified?: WelcomeCallUpdateoffsetVerifiedInput | null;
  adders?: WelcomeCallUpdateaddersInput | null;
  mpuPromised?: WelcomeCallUpdatempuPromisedInput | null;
  batteryPromised?: WelcomeCallUpdatebatteryPromisedInput | null;
  generatorPromised?: WelcomeCallUpdategeneratorPromisedInput | null;
  critterGuardPromised?: WelcomeCallUpdatecritterGuardPromisedInput | null;
  tiltKitPromised?: WelcomeCallUpdatetiltKitPromisedInput | null;
  trenchingPromised?: WelcomeCallUpdatetrenchingPromisedInput | null;
  securityPackageInProposal?: WelcomeCallUpdatesecurityPackageInProposalInput | null;
  n1KRebate?: WelcomeCallUpdaten1KRebateInput | null;
  extendedSolarEdgeWarrantyPromised?: WelcomeCallUpdateextendedSolarEdgeWarrantyPromisedInput | null;
  premiumEfficiencyPackagePromisedInAgreement?: WelcomeCallUpdatepremiumEfficiencyPackagePromisedInAgreementInput | null;
  energyEfficiencyPackageNest24Bulbs?: WelcomeCallUpdateenergyEfficiencyPackageNest24BulbsInput | null;
  onlyLedBulbs24?: WelcomeCallUpdateonlyLedBulbs24Input | null;
  energyPackage?: WelcomeCallUpdateenergyPackageInput | null;
  addThermostat?: WelcomeCallUpdateaddThermostatInput | null;
  additionalNestThermostat?: WelcomeCallUpdateadditionalNestThermostatInput | null;
  additionalBulbs8?: WelcomeCallUpdateadditionalBulbs8Input | null;
  addBulbs?: WelcomeCallUpdateaddBulbsInput | null;
  n18MonthCoverage?: WelcomeCallUpdaten18MonthCoverageInput | null;
  contractUploadedToDropbox?: WelcomeCallUpdatecontractUploadedToDropboxInput | null;
  agreementVerification?: WelcomeCallUpdateagreementVerificationInput | null;
  createSiteSurvey?: WelcomeCallUpdatecreateSiteSurveyInput | null;
  createHoa?: WelcomeCallUpdatecreateHoaInput | null;
  leftCustomerVoiceMail?: WelcomeCallUpdateleftCustomerVoiceMailInput | null;
  syncFromFluentSolar?: WelcomeCallUpdatesyncFromFluentSolarInput | null;
  leadSourceType?: WelcomeCallUpdateleadSourceTypeInput | null;
  scheduleInstallTaskCreated?: WelcomeCallUpdatescheduleInstallTaskCreatedInput | null;
  reRoofTasksCreated?: WelcomeCallUpdatereRoofTasksCreatedInput | null;
  treeRemovalTasksCreated?: WelcomeCallUpdatetreeRemovalTasksCreatedInput | null;
  sunrun?: WelcomeCallUpdatesunrunInput | null;
  region?: WelcomeCallUpdateregionInput | null;
  customerTextWc?: WelcomeCallUpdatecustomerTextWcInput | null;
  repTextWc?: WelcomeCallUpdaterepTextWcInput | null;
  loanDocsSigned?: WelcomeCallUpdateloanDocsSignedInput | null;
  creditApproval?: WelcomeCallUpdatecreditApprovalInput | null;
  ntpApprovalOverride?: WelcomeCallUpdatentpApprovalOverrideInput | null;
  utilityBillReceived?: WelcomeCallUpdateutilityBillReceivedInput | null;
  homeownersInsuranceUploaded?: WelcomeCallUpdatehomeownersInsuranceUploadedInput | null;
  photos?: WelcomeCallUpdatephotosInput | null;
  siteSurveyNeeded?: WelcomeCallUpdatesiteSurveyNeededInput | null;
  reRoof?: WelcomeCallUpdatereRoofInput | null;
  reRoofNeeded?: WelcomeCallUpdatereRoofNeededInput | null;
  reRoofFunding?: WelcomeCallUpdatereRoofFundingInput | null;
  treeRemoval?: WelcomeCallUpdatetreeRemovalInput | null;
  treeRemovalTrimmingNeeded?: WelcomeCallUpdatetreeRemovalTrimmingNeededInput | null;
  treeRemovalTrimmingFunding?: WelcomeCallUpdatetreeRemovalTrimmingFundingInput | null;
  copyOfAgreementInEmail?: WelcomeCallUpdatecopyOfAgreementInEmailInput | null;
  inverterType?: WelcomeCallUpdateinverterTypeInput | null;
  promises?: WelcomeCallUpdatepromisesInput | null;
  taxAdvisorStatement?: WelcomeCallUpdatetaxAdvisorStatementInput | null;
  verifiedSpellingOfName?: WelcomeCallUpdateverifiedSpellingOfNameInput | null;
  verifiedPhoneNumber?: WelcomeCallUpdateverifiedPhoneNumberInput | null;
  verifiedEmail?: WelcomeCallUpdateverifiedEmailInput | null;
  communicationPreference?: WelcomeCallUpdatecommunicationPreferenceInput | null;
  isYourHomePartOfAnHoa?: WelcomeCallUpdateisYourHomePartOfAnHoaInput | null;
  gate?: WelcomeCallUpdategateInput | null;
  verifiedPhysicalAddress?: WelcomeCallUpdateverifiedPhysicalAddressInput | null;
  existingSolarSystem?: WelcomeCallUpdateexistingSolarSystemInput | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: WelcomeCallUpdatenotifiedCustomerOfAddersListedOnTheAgreementInput | null;
  scheduleInstall?: WelcomeCallUpdatescheduleInstallInput | null;
  scheduledInstallDateChanged?: WelcomeCallUpdatescheduledInstallDateChangedInput | null;
  askedCustomerIfTheyHadAnyQuestions?: WelcomeCallUpdateaskedCustomerIfTheyHadAnyQuestionsInput | null;
  gaveCustomerOurNumber?: WelcomeCallUpdategaveCustomerOurNumberInput | null;
  resendWelcomeCall?: WelcomeCallUpdateresendWelcomeCallInput | null;
  updated?: WelcomeCallUpdateupdatedInput | null;
  test?: WelcomeCallUpdatetestInput | null;
  project?: ProjectUpdateOneWithoutWelcomeCallInput | null;
  proposal?: ProposalUpdateOneWithoutWelcomeCallInput | null;
  hoa?: HOAUpdateOneWithoutWelcomeCallInput | null;
  SalesRep?: SalesEmployeeUpdateOneWithoutWelcomeCallsAsSalesRepInput | null;
}

export interface WelcomeCallUpdateaddBulbsInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateaddThermostatInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateaddersInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateadditionalBulbs8Input {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateadditionalNestThermostatInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateagreementVerificationInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateaskedCustomerIfTheyHadAnyQuestionsInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatebatteryPromisedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatebucketActivationInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatechangeOrderInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecommunicationPreferenceInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecontractUploadedToDropboxInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecopyOfAgreementInEmailInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecreateHoaInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecreateSiteSurveyInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecreditApprovalInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecritterGuardPromisedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatecustomerTextWcInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateenergyEfficiencyPackageNest24BulbsInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateenergyPackageInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateexistingSolarSystemInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateextendedSolarEdgeWarrantyPromisedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdategateInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdategaveCustomerOurNumberInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdategeneratorPromisedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatehomeownersInsuranceUploadedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateinverterTypeInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateisYourHomePartOfAnHoaInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateleadSourceTypeInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateleftCustomerVoiceMailInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateloanDocsSignedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatemanagedByInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatempuPromisedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdaten18MonthCoverageInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdaten1KRebateInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatenotifiedCustomerOfAddersListedOnTheAgreementInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatentpApprovalOverrideInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateoffsetVerifiedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateonlyLedBulbs24Input {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatephotosInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatepremiumEfficiencyPackagePromisedInAgreementInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateprojectStageInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatepromisesInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateproposalIdAndContractMatchInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatereRoofFundingInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatereRoofInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatereRoofNeededInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatereRoofTasksCreatedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateregionInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdaterepTextWcInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateresendWelcomeCallInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatescheduleInstallInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatescheduleInstallTaskCreatedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatescheduledInstallDateChangedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatesecurityPackageInProposalInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatesiteSurveyNeededInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateslaStatusInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatestatusInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatestepsToCompleteInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatesunrunInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatesyncFromFluentSolarInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetaxAdvisorStatementInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetestInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetiltKitPromisedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetreeRemovalInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetreeRemovalTasksCreatedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetreeRemovalTrimmingFundingInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetreeRemovalTrimmingNeededInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdatetrenchingPromisedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateupdatedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateutilityBillReceivedInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateverifiedEmailInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateverifiedPhoneNumberInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateverifiedPhysicalAddressInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpdateverifiedSpellingOfNameInput {
  set?: string[] | null;
  push?: string | null;
}

export interface WelcomeCallUpsertWithWhereUniqueWithoutSalesRepInput {
  where: WelcomeCallWhereUniqueInput;
  update: WelcomeCallUpdateWithoutSalesRepInput;
  create: WelcomeCallCreateWithoutSalesRepInput;
}

export interface WelcomeCallUpsertWithoutHoaInput {
  update: WelcomeCallUpdateWithoutHoaInput;
  create: WelcomeCallCreateWithoutHoaInput;
}

export interface WelcomeCallUpsertWithoutProjectInput {
  update: WelcomeCallUpdateWithoutProjectInput;
  create: WelcomeCallCreateWithoutProjectInput;
}

export interface WelcomeCallUpsertWithoutProposalInput {
  update: WelcomeCallUpdateWithoutProposalInput;
  create: WelcomeCallCreateWithoutProposalInput;
}

export interface WelcomeCallWhereInput {
  AND?: WelcomeCallWhereInput[] | null;
  OR?: WelcomeCallWhereInput[] | null;
  NOT?: WelcomeCallWhereInput[] | null;
  id?: IntFilter | null;
  podioId?: StringNullableFilter | null;
  createdAt?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeNullableFilter | null;
  link?: StringNullableFilter | null;
  appItemId?: IntNullableFilter | null;
  archived?: BoolNullableFilter | null;
  createdOn?: DateTimeNullableFilter | null;
  dateOfWelcomeCall?: DateTimeNullableFilter | null;
  followUpDate?: DateTimeNullableFilter | null;
  contractSignedDate?: DateTimeNullableFilter | null;
  hoaFormsPreparedDate?: DateTimeNullableFilter | null;
  bucketActivatedDate?: DateTimeNullableFilter | null;
  bucketCompletionDate?: DateTimeNullableFilter | null;
  loanDocsSignedDate?: DateTimeNullableFilter | null;
  creditExpirationDate?: DateTimeNullableFilter | null;
  ntpSubmittedDate?: DateTimeNullableFilter | null;
  ntpApprovedDate?: DateTimeNullableFilter | null;
  siteSurveyDate?: DateTimeNullableFilter | null;
  voiceMailDate?: DateTimeNullableFilter | null;
  scheduledInstallDate?: DateTimeNullableFilter | null;
  ntpApprovedDateOld?: DateTimeNullableFilter | null;
  assignedTimestamp?: DateTimeNullableFilter | null;
  accountUpdate?: StringNullableFilter | null;
  changeOrderDescription?: StringNullableFilter | null;
  notes?: StringNullableFilter | null;
  projectId?: StringNullableFilter | null;
  lender?: StringNullableFilter | null;
  fundingStipulations?: StringNullableFilter | null;
  notesRegardingUtilityBill?: StringNullableFilter | null;
  meterServiceAcct?: StringNullableFilter | null;
  notesRegardingPhotos?: StringNullableFilter | null;
  treeRemovalDescription?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  hoaInfo?: StringNullableFilter | null;
  giftCardsPromised?: StringNullableFilter | null;
  promisesMadeByRepIfAny?: StringNullableFilter | null;
  additionalQuestions?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  formattedAddress?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  state?: StringNullableFilter | null;
  country?: StringNullableFilter | null;
  postalCode?: StringNullableFilter | null;
  lat?: DecimalNullableFilter | null;
  lng?: DecimalNullableFilter | null;
  trenchingRawGroundFootage?: IntNullableFilter | null;
  trenchingConcreteGroundFootage?: IntNullableFilter | null;
  nestThermostats?: IntNullableFilter | null;
  ledBulbs?: IntNullableFilter | null;
  systemSizeWatts?: FloatNullableFilter | null;
  estimatedYearlyProduction?: FloatNullableFilter | null;
  ofInverters?: IntNullableFilter | null;
  ofModules?: IntNullableFilter | null;
  n18MonthCoverageAmount?: FloatNullableFilter | null;
  coverageAmountPromised?: FloatNullableFilter | null;
  systemPriceFromProposal?: FloatNullableFilter | null;
  totalPurchasePrice?: FloatNullableFilter | null;
  rebateAmount?: FloatNullableFilter | null;
  bucketActivation?: StringNullableListFilter | null;
  status?: StringNullableListFilter | null;
  projectStage?: StringNullableListFilter | null;
  stepsToComplete?: StringNullableListFilter | null;
  slaStatus?: StringNullableListFilter | null;
  managedBy?: StringNullableListFilter | null;
  changeOrder?: StringNullableListFilter | null;
  proposalIdAndContractMatch?: StringNullableListFilter | null;
  offsetVerified?: StringNullableListFilter | null;
  adders?: StringNullableListFilter | null;
  mpuPromised?: StringNullableListFilter | null;
  batteryPromised?: StringNullableListFilter | null;
  generatorPromised?: StringNullableListFilter | null;
  critterGuardPromised?: StringNullableListFilter | null;
  tiltKitPromised?: StringNullableListFilter | null;
  trenchingPromised?: StringNullableListFilter | null;
  securityPackageInProposal?: StringNullableListFilter | null;
  n1KRebate?: StringNullableListFilter | null;
  extendedSolarEdgeWarrantyPromised?: StringNullableListFilter | null;
  premiumEfficiencyPackagePromisedInAgreement?: StringNullableListFilter | null;
  energyEfficiencyPackageNest24Bulbs?: StringNullableListFilter | null;
  onlyLedBulbs24?: StringNullableListFilter | null;
  energyPackage?: StringNullableListFilter | null;
  addThermostat?: StringNullableListFilter | null;
  additionalNestThermostat?: StringNullableListFilter | null;
  additionalBulbs8?: StringNullableListFilter | null;
  addBulbs?: StringNullableListFilter | null;
  n18MonthCoverage?: StringNullableListFilter | null;
  contractUploadedToDropbox?: StringNullableListFilter | null;
  agreementVerification?: StringNullableListFilter | null;
  createSiteSurvey?: StringNullableListFilter | null;
  createHoa?: StringNullableListFilter | null;
  leftCustomerVoiceMail?: StringNullableListFilter | null;
  syncFromFluentSolar?: StringNullableListFilter | null;
  leadSourceType?: StringNullableListFilter | null;
  scheduleInstallTaskCreated?: StringNullableListFilter | null;
  reRoofTasksCreated?: StringNullableListFilter | null;
  treeRemovalTasksCreated?: StringNullableListFilter | null;
  sunrun?: StringNullableListFilter | null;
  region?: StringNullableListFilter | null;
  customerTextWc?: StringNullableListFilter | null;
  repTextWc?: StringNullableListFilter | null;
  loanDocsSigned?: StringNullableListFilter | null;
  creditApproval?: StringNullableListFilter | null;
  ntpApprovalOverride?: StringNullableListFilter | null;
  utilityBillReceived?: StringNullableListFilter | null;
  homeownersInsuranceUploaded?: StringNullableListFilter | null;
  photos?: StringNullableListFilter | null;
  siteSurveyNeeded?: StringNullableListFilter | null;
  reRoof?: StringNullableListFilter | null;
  reRoofNeeded?: StringNullableListFilter | null;
  reRoofFunding?: StringNullableListFilter | null;
  treeRemoval?: StringNullableListFilter | null;
  treeRemovalTrimmingNeeded?: StringNullableListFilter | null;
  treeRemovalTrimmingFunding?: StringNullableListFilter | null;
  copyOfAgreementInEmail?: StringNullableListFilter | null;
  inverterType?: StringNullableListFilter | null;
  promises?: StringNullableListFilter | null;
  taxAdvisorStatement?: StringNullableListFilter | null;
  verifiedSpellingOfName?: StringNullableListFilter | null;
  verifiedPhoneNumber?: StringNullableListFilter | null;
  verifiedEmail?: StringNullableListFilter | null;
  communicationPreference?: StringNullableListFilter | null;
  isYourHomePartOfAnHoa?: StringNullableListFilter | null;
  gate?: StringNullableListFilter | null;
  verifiedPhysicalAddress?: StringNullableListFilter | null;
  existingSolarSystem?: StringNullableListFilter | null;
  notifiedCustomerOfAddersListedOnTheAgreement?: StringNullableListFilter | null;
  scheduleInstall?: StringNullableListFilter | null;
  scheduledInstallDateChanged?: StringNullableListFilter | null;
  askedCustomerIfTheyHadAnyQuestions?: StringNullableListFilter | null;
  gaveCustomerOurNumber?: StringNullableListFilter | null;
  resendWelcomeCall?: StringNullableListFilter | null;
  updated?: StringNullableListFilter | null;
  test?: StringNullableListFilter | null;
  project?: ProjectWhereInput | null;
  proposal?: ProposalWhereInput | null;
  hoa?: HOAWhereInput | null;
  salesRepId?: IntNullableFilter | null;
  salesRep?: SalesRepWhereInput | null;
  SalesRep?: SalesEmployeeWhereInput | null;
  salesEmployeeId_SalesRep?: IntNullableFilter | null;
}

export interface WelcomeCallWhereUniqueInput {
  id?: number | null;
  podioId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
