import { team_type, CommonDashboardProps } from '@fluentsolar/types';
import { Textography } from '@fluentsolar/shared-web/Textography';
import {
  localItemsVar,
  setLocalStorageInfo,
} from '@fluentsolar/shared-web/utils';
import { useApolloState } from '@fluentsolar/shared-web/localHook';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBookOpen,
  faCalendar,
  faChevronCircleLeft,
  faCog,
  faFile,
  faFileInvoice,
  faHome,
  faSignOutAlt,
  faUserPlus,
  faUsers,
  faVideo,
  faMicrophoneAlt,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import * as React from 'react';

interface SideMenuProps extends CommonDashboardProps {
  onClose?: () => void;
}

export const SideMenu = (props: SideMenuProps): JSX.Element => {
  const apolloState = useApolloState();

  const info = apolloState?.data;

  const admin = info?.role === 'SUPER_ADMIN' || info?.role === 'ADMIN';

  const manager =
    info?.role === 'SUPER_ADMIN' ||
    info?.role === 'ADMIN' ||
    info?.role === 'TEAM_MANAGER' ||
    info?.role === 'SALES_MANAGER';

  const setterOrCloser = info?.role === 'SETTER' || info?.role === 'SALES_REP';

  return (
    <div style={{ padding: 12 }}>
      <div style={{ padding: 8, color: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'row', minWidth: 200 }}>
          <div
            style={{
              height: 50,
              width: 50,
              borderRadius: 25,
              backgroundColor: 'white',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: 8,
              flexGrow: 1,
            }}
          >
            <Textography variant="normal" style={{ color: 'white' }}>
              {info?.name}
            </Textography>
            <Textography  style={{ color: 'white' }}>
              {info?.officeName}
            </Textography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              onClick={props.onClose}
              icon={faChevronCircleLeft}
              style={{ width: 25, height: 25 }}
            />
          </div>
        </div>
        <div style={{ height: 30 }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {admin && (
            <>
              <div style={{ flexGrow: 1, cursor: 'pointer' }}>
                {[
                  {
                    text: 'Office Builder',
                    icon: faHome,
                    href: '/dashboard/officebuilder',
                    show: true,
                  },
                ].map((navItem) => (
                  <NavButton
                    {...navItem}
                    key={navItem.text}
                    onClose={props.onClose}
                  />
                ))}
              </div>
              <div style={{ borderBottom: '1px solid white' }} />
            </>
          )}

          <div style={{ flexGrow: 1, cursor: 'pointer' }}>
            {[
              {
                text: 'Dashboard',
                icon: faHome,
                href: '/dashboard/profile',
                show: true,
              },
              {
                text: 'Team',
                icon: faFileInvoice,
                href: '/dashboard/officeview',
                show: manager,
              },
              {
                text: 'Team (beta)',
                icon: faFileInvoice,
                href: '/dashboard/teamviewer',
                show: manager,
              },
              {
                text: 'Add Lead',
                icon: faUserPlus,
                href: '/dashboard/addlead',
                show: admin,
              },
              {
                text: 'Leads',
                icon: faUsers,
                href: '/dashboard/leads?period=YTD',
                show: true,
              },
              {
                text: 'Appointments',
                icon: faCalendar,
                // href: '/dashboard/appointments',
                href: '/dashboard/appointments',
                // show: info?.role !== 'SETTER',
                show: false,
              },
              {
                text: 'Training',
                icon: faChalkboardTeacher,
                // href: '/dashboard/appointments',
                href: '/dashboard/training',
                show: true,
              },
              {
                text: 'Media',
                icon: faVideo,
                href: '/dashboard/media',
                show: true,
              },
              {
                text: 'Podcasts',
                icon: faMicrophoneAlt,
                href: '/dashboard/podcasts',
                show: admin,
              },
              {
                text: 'Resources',
                icon: faFile,
                // href: '/dashboard/appointments',
                href: '/dashboard/resources',
                show:
                  info?.team === team_type.DAUNTLESS ||
                  info?.team === team_type.ALL,
              },

              // { text: 'Vault', icon: faWarehouse },
              {
                text: 'Analytics',
                href: '/dashboard/analytics',
                icon: faBookOpen,
                show: admin,
              },
              // { text: 'Recruiting', icon: faUsers },
            ].map((navItem) => (
              <NavButton
                {...navItem}
                key={navItem.text}
                onClose={props.onClose}
              />
            ))}
          </div>
          <div style={{ borderBottom: '1px solid white' }} />
          <div style={{ cursor: 'pointer' }}>
            {[
              {
                text: 'Settings',
                icon: faCog,
                href: '/dashboard/settings',
                show: info?.role === 'SUPER_ADMIN' || info?.role === 'ADMIN',
              },
              // { text: 'Tools', icon: faWrench },
            ].map((navItem) => (
              <NavButton
                {...navItem}
                key={navItem.text}
                onClose={props.onClose}
              />
            ))}
            <NavButton
              text="Logout"
              icon={faSignOutAlt}
              href="/"
              show
              onClose={() => {
                localItemsVar({ token: '' });
                setLocalStorageInfo({ token: '' });
              }}
            />
          </div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <div>{process.env.tagVersion?.slice(0, 6)}</div>
            <div>{process.env.VERCEL_GIT_COMMIT_SHA?.slice(0, 6)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const NavButton = (props: {
  icon?: IconProp;
  text?: string;
  numNotifications?: number;
  href?: string;
  onClose?: () => void;
  show?: boolean;
}): JSX.Element => {
  const router = useRouter();

  const handleClick = () => {
    if (props.onClose) {
      props.onClose();
    }
    if (props.href) {
      router.push(props.href);
    }
  };

  const color = props.href ? 'white' : '#B0BEC5';
  if (!props.show) {
    return null;
  }
  return (
    <div onClick={handleClick}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '12px 0px',
        }}
        // onClick={handleClick}
      >
        <div style={{ width: 30, display: 'flex', justifyContent: 'center' }}>
          <FontAwesomeIcon
            icon={props.icon}
            style={{ width: 20, height: 20, color }}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <Textography style={{ marginLeft: 16, color }}>
            {props.text}
          </Textography>
        </div>
        {props.numNotifications && (
          <div
            style={{
              backgroundColor: 'white',
              height: 16,
              minWidth: 12,
              borderRadius: 8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Textography
              variant="small"
              style={{
                color: '#5B1DF4',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {props.numNotifications}
            </Textography>
          </div>
        )}
      </div>
    </div>
  );
};
