import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { sizing } from './config';


export const NavIconButton = (props: { icon: IconProp; }) => {
  return (
    <div className="flex items-center justify-center" style={sizing}>
      <FontAwesomeIcon icon={props.icon} className="text-white" size={'lg'} />
    </div>
  );
};
