
import { HeadFont } from '../../feature-HeadFont';
import { Textography } from '../../Textography';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import { SideMenu } from './SideMenu/SideMenu';
import { DashboardProps } from '../types';

export function DashboardMobile(props: DashboardProps) {
  const [open, setOpen] = React.useState(false);

  const animProps = useSpring({ left: open ? '0vw' : '-100vw' });
  const onClose = () => {
    setOpen(false);
  };
  const onMenuPress = () => {
    setOpen(true);
  };

  return (
    <div className="w-full min-h-screen theme-general theme-dark bg-primary text-normal">
      <HeadFont />
      <div className="flex flex-row">
        <animated.div
          className="bg-primary"
          style={{
            minHeight: '100vh',
            width: '100%',
            // background: `linear-gradient(45deg, ${DASHBOARD_LIGHT}, ${DASHBOARD_DARK})`,
            position: 'absolute',

            zIndex: 10,
            ...animProps,
          }}
        >
          <SideMenu {...props} page={props.page} onClose={onClose} />
        </animated.div>
        <div className="w-full min-h-screen bg-secondary">
          <div className="flex flex-row w-full p-4 border-b border-gray-500 bg-primary">
            <div className="flex-grow">
              <Textography>App Bar Mobile</Textography>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faBars}
                color="white"
                style={{ width: 20, height: 20 }}
                onClick={onMenuPress}
              />
            </div>
          </div>
          <div className="p-4">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
