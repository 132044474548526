export const LIGHT_BRAND_COLOR = '#C8CDF2';

export const STANDARD_SCREEN_PADDING = 16;
export const STANDARD_SCREEN_PADDING_STYLE: React.CSSProperties = {
  paddingLeft: STANDARD_SCREEN_PADDING,
  paddingRight: STANDARD_SCREEN_PADDING,
};

export const LIGHT_MODE_FONT_COLOR = '#607D8B';

//
export const LIGHT_ORANGE = '#ffb74d';
export const DARK_ORANGE = '#FF8993';

export const LIGHT_BLUE = '#81d4fa';
export const DARK_BLUE = '#479DFF';

export const LIGHT_GREEN = '#00e676';
export const DARK_GREEN = '#4caf50';

export const LIGHT_PURPLE = '#FF85F3';
export const DARK_PURPLE = '#887BFF';

export const DASHBOARD_LIGHT = '#2196F3';
export const DASHBOARD_DARK = '#1976D2';

export * from './tailwindColors';
