import * as React from 'react';
import { HeadFont } from '@fluentsolar/shared-web/feature-HeadFont';
import { TopMenuBar } from '../feature-TopMenuBar';
import { SideMenu } from '../feature-SideMenu';
import { CommonDashboardProps } from '@fluentsolar/types';
import { DASHBOARD_DARK, DASHBOARD_LIGHT } from '@fluentsolar/shared/theme';

// export const isDev = process.env.NODE_ENV === 'development';
// 1
const BLUE_THEME = {
  color1: DASHBOARD_LIGHT,
  color2: DASHBOARD_DARK,
};

const DARK_THEME = {
  color1: '#333333',
  color2: '#212121',
};

export const DesktopDashboard = (props: CommonDashboardProps): JSX.Element => {
  return (
    <div style={{}}>
      <HeadFont />
      <div
        style={{
          minHeight: '100vh',
          width: '100%',
          background: `linear-gradient(0deg, ${BLUE_THEME.color1}, ${BLUE_THEME.color2})`,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* Sidebar */}
          <SideMenu {...props} />

          {/* Main content area */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              width: '100%',
              backgroundColor: '#EAEDFA',
              overflowY: 'scroll',
            }}
          >
            <div>
              <TopMenuBar />
            </div>
            <div
              style={
                {
                  // padding: 16,
                }
              }
            >
              {props.children}
            </div>
          </div>
          {/* End Main content area */}
        </div>
      </div>
    </div>
  );
};
