import * as React from 'react';

export const ToolTipContainer = (props: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={props.className}>
      {/* old */}
      {/* <div className={`py-2 px-3 rounded shadow-lg ${props.className} `}> */}
      {props.children}
    </div>
  );
};
