import {
  faBusinessTime,
  faCalculator,
  faClipboardList,
  faDatabase,
  faHiking,
  faMicrophoneAlt,
  faProjectDiagram,
  faTable,
  faToolbox,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { SideMenuItem } from '../types';

export const defaultSchema: SideMenuItem[] = [
  {
    text: 'Dashboard',
    icon: faTable,
    href: '/dashboard/demo',
  },
  {
    text: 'Users',
    icon: faUsers,
    href: '/dashboard/users',
  },
  {
    text: 'Lead Tools',
    icon: faToolbox,
    href: '/dashboard/leadtools',
    branches: [
      {
        text: 'Webhooks',
        icon: faProjectDiagram,
        href: '/dashboard/leadtools/webhooks',
      },
      {
        text: 'Dispatch',
        icon: faDatabase,
        href: '/dashboard/leadtools/dispatch',
      },
    ],
  },
  {
    text: 'Timesheets',
    icon: faBusinessTime,
    href: '/dashboard/timesheets',
    branches: [
      {
        text: 'Bread Trails',
        icon: faHiking,
        href: '/dashboard/timesheets/breadtrails',
      },
      {
        text: 'Payment Calculator',
        icon: faCalculator,
        href: '/dashboard/timesheets/payment',
      },
    ],
  },
  {
    text: 'Marketing',
    icon: faBusinessTime,
    href: '/dashboard/marketing',
    branches: [
      {
        text: 'Bread Trails',
        icon: faHiking,
        href: '/dashboard/marketing/repstats',
      },
    ],
  },
  {
    text: 'Podcasts',
    icon: faMicrophoneAlt,
    href: '/dashboard/podcasts',
    branches: [
      {
        text: 'Podcasts List',
        icon: faClipboardList,
        href: '/dashboard/podcasts/podcastsList',
      },
    ],
  },
];
