import { faDownload, faFastForward, faLink, faTable, faBezierCurve } from '@fortawesome/free-solid-svg-icons';

export const podioMenuSchema = [
  {
    text: 'Home',
    icon: faTable,
    href: '/dashboard/demo',
  },
  {
    text: 'Schema Import',
    icon: faFastForward,
    href: '/dashboard/podio/schema-import',
  },
  {
    text: 'Schema Link',
    icon: faLink,
    href: '/dashboard/podio/schema-link',
  },
  {
    text: 'Data Import',
    icon: faDownload,
    href: '/dashboard/podio/data-import',
  },
  {
    text: 'Webhooks',
    icon: faBezierCurve,
    href: '/dashboard/podio/webhooks',
    branches: [
      {
        text: 'App Webhooks',
        icon: faBezierCurve,
        href: '/dashboard/podio/webhooks/app',
      },
      {
        text: 'Field Webhooks',
        icon: faBezierCurve,
        href: '/dashboard/podio/webhooks/field',
      },
    ],
  },
];
