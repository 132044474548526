import { HeadFont } from '@fluentsolar/shared-web/feature-HeadFont';
import { SideMenu } from '../feature-SideMenu';
import { DASHBOARD_DARK, DASHBOARD_LIGHT } from '@fluentsolar/shared/theme';
import { CommonDashboardProps } from '@fluentsolar/types';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';

export const MobileDashboard = (props: CommonDashboardProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const animProps = useSpring({ left: open ? '0vw' : '-100vw' });
  const onClose = () => {
    setOpen(false);
  };
  const onMenuPress = () => {
    setOpen(true);
  };

  return (
    <div style={{}}>
      <HeadFont />
      <div>
        <div
          style={{
            padding: '0px 8px',
            display: 'flex',
            flexDirection: 'row',
            position: 'sticky',
            zIndex: 5,
            top: 0,
            backgroundColor: DASHBOARD_DARK, // theme color
          }}
        >
          <animated.div
            style={{
              minHeight: '100vh',
              width: '100%',
              background: `linear-gradient(45deg, ${DASHBOARD_LIGHT}, ${DASHBOARD_DARK})`,
              position: 'absolute',

              zIndex: 10,
              ...animProps,
            }}
          >
            <SideMenu onClose={onClose} />
          </animated.div>

          <div
            style={{
              width: 30,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '16px 0px',
            }}
          >
            <FontAwesomeIcon
              icon={faBars}
              color="white"
              style={{ width: 20, height: 20 }}
              onClick={onMenuPress}
            />
          </div>
          <div
            style={{
              flexGrow: 1,
              textAlign: 'center',
              color: 'white',
              padding: '16px 0px',
            }}
          >
            Fluent Solar
          </div>
          <div
            style={{
              width: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faBell}
              color="white"
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        {/* Main content area */}
        <div
          style={{
            minHeight: '100vh',
          }}
        >
          {props.children}
        </div>
        {/* End Main content area */}
      </div>
    </div>
  );
};
