import * as React from 'react';
import { useSpring } from 'react-spring';
import { HeadFont } from '../../feature-HeadFont';
import { DashboardProps } from '../types';
import { DashboardProvider, useDashboardProvider } from './Dashboard.dux';
import { SideMenu } from './SideMenu/SideMenu';
import { Topbar } from './Topbar';

export function DashboardDesktop(props: DashboardProps) {
  const [open, setOpen] = React.useState(false);

  const animProps = useSpring({ left: open ? '0vw' : '-100vw' });
  const onClose = () => {
    setOpen(false);
  };
  const onMenuPress = () => {
    setOpen(true);
  };
  const contextValue = useDashboardProvider();

  return (
    <DashboardProvider value={contextValue}>
      <div className="w-full min-h-screen ">
        <HeadFont />
        <div className="flex flex-row">
          <SideMenu
            {...props}
            page={props.page}
            onClose={onClose}
            schemas={props.menuSchemas}
          />
          <div className="w-full min-h-screen ">
            <div className="overflow-y-scroll sm:px-1 md:px-4 flex flex-col">
              <Topbar />

              {props.children}
            </div>
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
}
