import * as React from 'react';
import { DashboardViewContextType, DashboardViewRoleType } from './types';

interface MenuStateProps {
  role?: DashboardViewRoleType;
  context?: DashboardViewContextType;
}

type MenuStateActions = 'CHANGE_ROLE' | 'CHANGE_CONTEXT';

type ActionType = {
  type: MenuStateActions;
  data: {
    role?: DashboardViewRoleType;
    context?: DashboardViewContextType;
  };
};

const DashboardContext = React.createContext<{
  state: MenuStateProps;
  dispatch: React.Dispatch<ActionType>;
}>({ state: {}, dispatch: null });

const dashboardReducer = (
  state: MenuStateProps,
  action: {
    type: MenuStateActions;
    data: {
      role?: DashboardViewRoleType;
      context?: DashboardViewContextType;
    };
  }
): MenuStateProps => {
  switch (action.type) {
    case 'CHANGE_ROLE':
      return { ...state, role: action.data.role };
    case 'CHANGE_CONTEXT': {
      console.log('action', action);
      return { ...state, context: action.data.context };
    }
    default:
      return state;
  }
};

export const DashboardProvider = DashboardContext.Provider;

export const useDashboardProvider = () => {
  const [state, dispatch] = React.useReducer(dashboardReducer, {
    role: 'DEVELOPER',
    context: 'ADMIN',
  });

  return { state, dispatch };
};

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext);
  return context;
};
