/* eslint-disable */

import { ApolloProvider, useQuery } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
// import * as ga from '@fluentsolar/ga';
import { QUERY_GET_LOCAL_ITEMS } from '@fluentsolar/graphql';
import { Dashboard as Dashboard1 } from '@fluentsolar/lib-stats/feature-Dashboard';
import { Dashboard as Dashboard2 } from '@fluentsolar/shared-web/feature-Dashboard2';
import { schemas } from '@fluentsolar/shared-web/feature-Dashboard2/components/SideMenu/schemas';
import { useApollo } from '@fluentsolar/shared-web/utils';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useRouter } from 'next/router';
import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';


import '@fluentsolar/shared-web/styles/globals.crm.css';

export default function App({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps.initialApolloState, '/api');

  const localInfo = useQuery(QUERY_GET_LOCAL_ITEMS, { client: apolloClient });
  const router = useRouter();

  const isDashboard1 = router.asPath.search('/dashboard') >= 0;
  const isDashboard2 = router.asPath.search('/d/') >= 0;

  const isDashboard = isDashboard1 || isDashboard2;

  const Dashboard = isDashboard1 ? Dashboard1 : Dashboard2;

  // React.useEffect(() => {
  //   const handleRouteChange = (url: string) => {
  //     ga.pageview(url);
  //   };
  //   //When the component is mounted, subscribe to router changes
  //   //and log those page views
  //   router.events.on('routeChangeComplete', handleRouteChange);

  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router.events]);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <ApolloProvider
      client={{
        ...apolloClient,
        context: {
          headers: {
            authorization: `Bearer ${localInfo.data.localItems.token}`,
          },
        },
      }}
    >
      <div
        className="theme-general theme-light text-normal"
        style={{ backgroundColor: 'white' }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
          {isDashboard && (
            <Dashboard {...pageProps} menuSchemas={schemas}>
              <Component {...pageProps} err={err} />
            </Dashboard>
          )}
          {!isDashboard && <Component {...pageProps} err={err} />}
          </>
        </MuiPickersUtilsProvider>
      </div>
    </ApolloProvider>
  );
}
