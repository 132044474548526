import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as React from 'react';
import { sizing } from './config';
import { NavIconButton } from './NavIconButton';
import { SubMenu } from './SubMenu';
import { SideMenuItem } from './types';
interface SideNavButton {
  active?: boolean;
  text?: string;
  icon?: IconProp;
  href?: string;
  onClick?: () => void;
  branches?: SideMenuItem[];
}

export const SideNavButton = (props: SideNavButton) => {
  const [show, setShow] = React.useState(false);
  const onMouseOver = () => {
    setShow(true);
  };
  const onMouseLeave = () => {
    setShow(false);
  };

  const navButtonActivityStyle = `rounded-lg cursor-pointer hover:bg-green-800 ${
    props.active && 'bg-green-500'
  }`;

  const showStyle = `${!show && 'hidden'}`;

  return (
    <div style={sizing} onMouseLeave={onMouseLeave}>
      <div
        onClick={props.onClick}
        onMouseOver={onMouseOver}
        className={navButtonActivityStyle}
      >
        <NavIconButton icon={props.icon} />
      </div>
      <div className={showStyle}>
        {/* <ToolTipRight text={props.text} /> */}
        <SubMenu position="top" {...props} />
      </div>
    </div>
  );
};
