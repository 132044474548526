import { MenuSchema } from '../../types';
import { databaseMenuSchema } from './databaseMenuSchema';
import { defaultSchema } from './defaultSchema';
import { podioMenuSchema } from './podioMenuSchema';

export const schemas: MenuSchema[] = [
  { key: 'PODIO', schema: podioMenuSchema },
  { key: 'DATABASE', schema: databaseMenuSchema },
  { key: 'ADMIN', schema: defaultSchema },
];
