import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRouter } from 'next/router';
import * as React from 'react';
import { SideNavButton } from './SideNavButton';
import { padding } from './config';

interface SideNavTreeProps {
  text: string;
  icon: IconProp;
  href: string;
  branches?: SideNavTreeProps[];
  page?: string;
  onClose?: () => void;
}

export const SideNavTree = (props: SideNavTreeProps) => {
  const router = useRouter();
  return (
    <div className={`flex px-${padding} items-center`}>
      <SideNavButton
        active={props.page === props.href}
        onClick={() => {
          router.push(props.href);
          props.onClose();
        }}
        {...props}
      />
      {/* {props.branches && (
        <div className="pl-8">
          {props.branches?.map((child, index) => (
            <SideNavButton
              key={index}
              active={props.page === child.href}
              onClick={() => {
                router.push(child.href);
                props.onClose();
              }}
              {...child}
            />
          ))}
        </div>
      )} */}
    </div>
  );
};
