import * as React from 'react';
import { sizing } from './config';
import { ToolTipContainer } from './ToolTipContainer';

export const SubMenuContainer = ({
  className,
  position,
  children,
}: {
  className?: string;
  position?: 'top' | 'bottom';
  children?: React.ReactNode;
}) => {
  const pos = position === 'top' ? 'items-start' : 'items-end';
  return (
    <div
      className={`transform -translate-y-full translate-x-full`}
      // old
      // className={`transform -translate-y-full translate-x-full z-20`}
      style={{ height: sizing.height }}
    >
      {/* old */}
      {/* <div className={`flex h-full ml-1 ${pos}`}> */}
      {/* <ToolTipContainer className="bg-white">{children}</ToolTipContainer> */}
      <div className={`h-full  flex ${pos}`}>
        <ToolTipContainer className={className}>{children}</ToolTipContainer>
      </div>
    </div>
  );
};
