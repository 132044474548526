import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import * as React from 'react';
import { Textography } from '../../../Textography';
import { SubMenuContainer } from './SubMenuContainer';
import { SideMenuItem } from './types';

export const SubMenu = (props: {
  position?: 'top' | 'bottom';
  text?: string;
  icon?: IconProp;
  href?: string;
  branches?: SideMenuItem[];
  onClick?: () => void;
}) => {
  const router = useRouter();
  return (
    <SubMenuContainer
      className="px-6 py-2 ml-1 space-y-2 bg-white rounded shadow-lg whitespace-nowrap hover:shadow-2xl"
      {...props}
    >
      <Textography
        className="font-bold cursor-pointer hover:text-gray-700"
        onClick={() => router.push(props.href)}
      >
        {props.text}
      </Textography>

      {props?.branches?.map((item, index) => {
        return (
          <div
          key={index}
            onClick={() => router.push(item.href)}
            className="flex items-center space-x-2 cursor-pointer hover:text-gray-700"
          >
            <FontAwesomeIcon className="" icon={item.icon} size="sm" />
            <Textography className="hover:text-gray-700">
              {item.text}
            </Textography>
          </div>
        );
      })}
    </SubMenuContainer>
  );
};

{
  /* OLD
<Textography>title</Textography>
<div className="flex items-center justify-center space-x-2 cursor-pointer hover:text-gray-700">
  <FontAwesomeIcon className="" icon={faPlus} size="sm" />
  <Textography className="hover:text-gray-700">item 1</Textography>
</div>
<div className="flex items-center justify-center space-x-2 cursor-pointer hover:text-gray-700">
  <FontAwesomeIcon icon={faPencilAlt} size="sm" />
  <Textography className="hover:text-gray-700">item 2</Textography>
</div>
<div className="flex items-center justify-center space-x-2 cursor-pointer hover:text-gray-700">
  <FontAwesomeIcon icon={faTrash} size="sm" />
  <Textography className="hover:text-gray-700">item 3</Textography>
</div>
*/
}

/* old
export const SubMenu = (props: { position?: 'top' | 'bottom' }) => {
  return (
    <SubMenuContainer {...props}>
      <div className="h-20 ">
        <Textography className="whitespace-nowrap">Submenu stuff</Textography>
        <Button />
      </div>
    </SubMenuContainer>
  );
};

export const Button = () => {
  return (
    <div>
      <FontAwesomeIcon icon={faPlusSquare} size={'lg'} />
      <Textography className=" whitespace-nowrap">Submenu stuff</Textography>
    </div>
  );
};
 */
