/* eslint-disable */

import { useApolloState } from '@fluentsolar/shared-web/localHook';
import { DARK_GREEN } from '@fluentsolar/shared/theme';
import { Textography } from '@fluentsolar/shared-web/Textography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { DesktopDashboard } from './DesktopDashboard';
import { MobileDashboard } from './MobileDashboard';

// hello

export const Dashboard = (props: { children?: React.ReactNode }) => {
  // const router = useRouter();
  const apolloState = useApolloState();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const DashboardComponent = matches ? MobileDashboard : DesktopDashboard;

  return (
    <>
      {apolloState?.data?.altUser?.token && (
        <div
          style={{
            backgroundColor: DARK_GREEN,
            padding: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const restoreUser = apolloState.data.altUser;
            console.log('restoreUser', apolloState.data.altUser);
            apolloState.setAllState({ ...restoreUser, altUser: undefined });
          }}
        >
          <Textography style={{ textAlign: 'center', color: 'white' }}>
            Logged in as: {apolloState?.data?.name}{' '}
            <Textography
              style={{
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Switch Back
            </Textography>
          </Textography>
        </div>
      )}
      <DashboardComponent {...props}>{props.children}</DashboardComponent>
    </>
  );
};
