import { DASHBOARD_DARK, DASHBOARD_LIGHT } from '@fluentsolar/shared/theme';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const BLUE_THEME = {
  color1: DASHBOARD_LIGHT,
  color2: DASHBOARD_DARK,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      background: `linear-gradient(90deg, ${BLUE_THEME.color2},${BLUE_THEME.color1})`,
      color: 'white',
    },
  })
);

export const TopMenuBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Fluent Solar
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};
