import * as React from 'react';
import { DashboardDesktop } from './components/Dashboard.desktop';
import { DashboardMobile } from './components/Dashboard.mobile';
import { Dimensions } from 'react-native';
import { DashboardProps } from './types';

const { width: deviceWidth } = Dimensions.get('window');

const Dashboard = (props: DashboardProps) => {
  if (deviceWidth < 800) {
    return <DashboardMobile {...props} />;
  }

  return <DashboardDesktop {...props} />;
};

export default React.memo(Dashboard);
