import * as React from 'react';
import { Textography } from '../../../Textography';
import { useDashboardContext } from '../Dashboard.dux';
import { MenuSchema } from '../types';
import { defaultSchema } from './schemas/defaultSchema';
import { SideNavTree } from './SideNavTree';
import { SideMenuItem } from './types';

export function SideMenu(props: {
  page: string;
  onClose?: () => void;
  appName?: string;
  schemas: MenuSchema[];
}) {
  const { state } = useDashboardContext();

  const menuSchema: SideMenuItem[] = React.useMemo(() => {
    const schema = props.schemas?.find(
      (schema) => schema.key === state.context
    )?.schema;

    if (schema) {
      return schema;
    }

    return defaultSchema;
  }, [state.context]);

  return (
    <div
      // style={{ minWidth: 250 }}
      className="min-h-screen border-r border-gray-500 bg-c-fluent-steel-100 z-50"
    >
      <div className="flex items-center p-4 bg-c-fluent-green-400">
        <Textography variant="title" className="text-white">
          FS
        </Textography>
      </div>
      <div className="py-3">
        {menuSchema?.map((nav, index) => (
          <SideNavTree key={index} {...props} {...nav} />
        ))}
      </div>
    </div>
  );
}
